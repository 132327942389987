import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { logout } from "../../store";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import "./style.css"
const MasterListValidation = ({ setShowMessage }) => {
    const [show, setShow] = useState(true);
    const dispatch = useDispatch()
    const handleClose = () => {
        setShowMessage(false)
        setShow(false)
    }
    return (
        <Modal show={show} backdrop="static" className="masterlist-modal">
            <Modal.Body className="text-center">
                Not Eligible! The RWS Blood Chemistry App is for graduates only. Please send your RWS graduate certificate to <a className="primary-fg fw-bold" href="mailto:app@restorativewellnesssolutions.com">app@restorativewellnesssolutions.com </a>if you meet this criteria and are unable to access the app.

            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button
                    variant="primary"
                    size="md"
                    className="w-100 primary-bg next-btn"
                    onClick={() => { handleClose(); toast.success("Logout Successful"); dispatch(logout()) }}
                >
                    Ok
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export default MasterListValidation