import { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ReactToPrint from 'react-to-print';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../store/actions";
import { clinicalPearlData } from "./clinicalPearlData";
import ClinicalPearlsItem from "./ClinicalPearlsItem";
import { selectClientSearchData } from "../../store";
import * as moment from "moment-timezone";
const ClinicalPearls = ({ name, id, date }) => {
    const clinicalPearlItems = useSelector(selectClientSearchData)?.clinicalPearlData
    const dispatch = useDispatch()
    useEffect(() => {
        if (id) {
            dispatch({
                type: actions.CLINICAL_PEARLS,
                payload: { id },
            });
        }
    }, [id]);
    return (
        <>
            <div className="accordion-section">
                <ReactToPrint
                    trigger={() => {
                        return <Button
                            variant="primary"
                            size="md"
                            className="d-block ms-auto primary-bg btn-export mb-4"
                        >
                            Export as Pdf
                        </Button>
                    }}
                    content={() => document.body}
                    onBeforePrint={() => document.title = `${name} - ${moment(date).format("MMMM DD, YYYY")} - Clinical Pearls`}
                    onAfterPrint={() => document.title = 'Restorative Wellness Solutions'}
                />
                <Row className="blood-sugar">
                    <Col className="">
                        <div className="login-card">
                            <div className="nameLabel mb-30">Clinical Pearls</div>
                            <Row className="">
                            {clinicalPearlData.map((data, index) => (
                                <ClinicalPearlsItem data={clinicalPearlItems?.clinical_pearls?.[data.dataKey]} name={data.name} dataKey={data.dataKey} key={index} index={index} />
                            ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )

}

export default ClinicalPearls