import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomTextField from "../CustomTextField/CustomTextField";
import { Form } from "react-bootstrap";
const UpdateClientData = ({ clientName, updateClientName }) => {
    const [show, setShow] = useState(false);
    const [form, setForm] = useState({
        client_name: {
            value: "",
            error: false,
            required: true,
        }
    })
    const handleClose = () => {
        setShow(false)
    }
    useEffect(() => {
        if (clientName) {
            setShow(true)
            setForm({
                client_name: {
                    value: clientName,
                    error: false,
                    required: true
                },
            })
        }
        else setShow(false)
    }, [clientName])
    const handleFormSubmit = (e) => {
        e.preventDefault()
        if (form.client_name.value) {
            updateClientName(form.client_name.value)
            handleClose()
        }
    }
    return (
        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleFormSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Client Name</Modal.Title>
                </Modal.Header>
                <Modal.Body className="form-card p-3">
                    <CustomTextField
                        label={"Client Name"}
                        type="text"
                        form={form}
                        setForm={setForm}
                        fkey="client_name"
                    />
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="primary"
                        size="md"
                        className="w-100 primary-bg next-btn"
                        type="submit"
                    >
                        Update
                    </Button>

                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default UpdateClientData