import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AccountSettingLogo } from "./../../images/accountSettingLogo.svg";
import { ReactComponent as AddIcon } from "./../../images/addicon.svg";
import { ReactComponent as ClientSearchLogo } from "./../../images/clientSearchLogo.svg";
import rwsLogo from "./../../images/rwslogo.svg";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navbarLinks = [
    {
      linkTitle: "Add New Results",
      path: "/client",
      component: <AddIcon className="me-3 no-fill" />,
    },
    {
      linkTitle: "Client Search",
      path: "/clientscreensearch",
      component: <ClientSearchLogo className="me-3" />,
    },
    {
      linkTitle: "Account Settings",
      path: "/accountsettings",
      component: <AccountSettingLogo className="me-3" />,
    },
  ];
  return (
    <div className="sidebar">
      <Col xs={12} sm={12} md={12} className="text-center">
        <img
          className="rws-logo-sidebar cursor-pointer"
          src={rwsLogo}
          alt="img"
          onClick={() => {
            navigate("/");
          }}
        ></img>
        <div className="divider mb-5 w-100 mx-auto"></div>
        <div className="">
          {navbarLinks.map((item, index) => (
            <div
              key={index}
              className={`nav_item d-flex align-items-center${
                location.pathname === item.path ? " link-active" : ""
              }`}
            >
              <Link to={item.path}>
                <div className="sidebar_icon">{item.component}</div>
                <div className="sidebar_text">{item.linkTitle}</div>
              </Link>
            </div>
          ))}
        </div>
      </Col>
    </div>
  );
};
export default Sidebar;
