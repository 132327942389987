import { Accordion, Button, Col, Row } from "react-bootstrap";
import { useState,useEffect } from "react";
const ClinicalPearlsItem = ({ data, name, dataKey, index }) => {
    const [active, setActive] = useState(null);
    useEffect(()=>{
        if(data) setActive(index)
    },[data])
return(
    <Col lg='12' className="mx-auto pattern-analysis-accordion">
    <Accordion className="blueLight"
        key={index}
        activeKey={active}
        onSelect={(e) => setActive(parseInt(active) >= 0 ? null : index)}
    >
        <Accordion.Item eventKey={index}>
            <Accordion.Header>{name} </Accordion.Header>
            <Accordion.Body>
               <div>
                <p className="mb-0" dangerouslySetInnerHTML={{__html: data?.replace(/(?:\r\n|\r|\n)/g, '<br/><br/>')}}></p>
               </div>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
</Col>
)
}
export default ClinicalPearlsItem