export const actions = {
  LOGIN: "LOGIN",
  SIGN_UP: "SIGN_UP",
  STRIPE_SUBSCRIPTION_AND_ELIGIBILITY: "STRIPE_SUBSCRIPTION_AND_ELIGIBILITY", // Areeb's Code
  STRIPE_CUSTOMER_PORTAL: "STRIPE_CUSTOMER_PORTAL", // Areeb's Code
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  PAY_MONTHLY:"PAY_MONTHLY",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  UPDATE_USER: "UPDATE_USER",
  COMPARISON_REVIEW: "COMPARISON_REVIEW",
  ADD_NEW_CLIENT: "ADD_NEW_CLIENT",
  GET_CLIENT_SEARCH: "GET_CLIENT_SEARCH",
  COMPLETE_BLOOD_COUNT: "completebloodcount",
  CMP: "completemetabolicpanel",
  CHOLESTROL: "cholestrol",
  THYROID: "thyroid",
  IRON: "iron",
  PAY_PER_REPORT:"PAY_PER_REPORT",
  GET_CLIENT_SEARCH_NAME: "GET_CLIENT_SEARCH_NAME",
  DELETE_CLIENT: "DELETE_RWS_CLIENT",
  UPDATE_CLIENT_NAME:"UPDATE_CLIENT_NAME",
  OTHER: "other",
  MAKER_ANALYSIS: "MAKER_ANALYSIS",
  CLINICAL_PEARLS:"CLINICAL_PEARLS",
  GENERATE_CLIENT_REPORT: "GENERATE_CLIENT_REPORT",
  PATTERN_ANALYSIS: "PATTERN_ANALYSIS",
  CLIENT_BY_ID: "CLIENT_BY_ID",
  ADD_NEW_TEST: "ADD_NEW_TEST",
  UPDATE_TEST_DATA: "UPDATE_TEST_DATA",
  UPLOAD_FILE: "UPLOAD_FILE",
  PATTERN_COMPARISON_REVIEW: "PATTERN_COMPARISON_REVIEW",
  ACCOUNT_SETTINGS: "ACCOUNT_SETTINGS",
};
