import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectClientSearchData } from "../../store";
import { actions } from "../../store/actions";
import "./style.css";
import { getAge } from "../../services/formValidation";
import * as moment from "moment-timezone";
import { bloodMarkerData } from "./bloodMarkerData";
import MakerAnalysisItem from "./MarkerAnalysisItem";
import ReactToPrint from 'react-to-print';
import CustomTextField from "../CustomTextField/CustomTextField";

const MakerAnalysis = ({ id, name, date }) => {
  const clientSearchData = useSelector(selectClientSearchData);
  const dispatch = useDispatch();
  const [bm_data, setBMData] = useState({});
  const [age, setAge] = useState(0)
  const [gender, setGender] = useState("")
  const [scale, setScale] = useState("")
  useEffect(() => {
    if (id) {
      dispatch({
        type: actions.MAKER_ANALYSIS,
        payload: { id },
      });
    }
  }, [id]);

  useEffect(() => {
    if (clientSearchData?.clientData) {
      setAge(getAge(moment(new Date(clientSearchData?.clientData?.rws_input?.date_of_birth)).format("YYYY-MM-DD")) <= 59 ? 59 : 60)
      setGender(clientSearchData?.clientData?.rws_input?.sex)
      setScale(clientSearchData?.clientData?.rws_input?.units_us_or_intl)
    }
  }, [clientSearchData])
  useEffect(() => {
    if (clientSearchData?.bloodMakerAnalysisData) {
      setBMData(clientSearchData.bloodMakerAnalysisData);
    }
  }, [clientSearchData.bloodMakerAnalysisData]);

  const [form, setForm] = useState({
    sortBy: {
      value: "",
      error: "",
    },
  });
  return (
    <>
      <div className="accordion-section">
      <ReactToPrint
          trigger={() => {
            return <Button
            variant="primary"
            size="md"
            className="d-block ms-auto primary-bg btn-export mb-4"
          >
            Export as Pdf
          </Button>
          }}
          content={() => document.body}
          onBeforePrint={() => document.title = `${name} - ${moment(date).format("MMMM DD, YYYY")} - Marker Analysis`}
          onAfterPrint={() => document.title = 'Restorative Wellness Solutions'}
        />
        
        <div className="sortSelect d-flex align-items-center justify-content-end mb-36 hide-section-print">
          <CustomTextField
            form={form}
            setForm={setForm}
            fkey="sortBy"
            label={"Sort By:"}
            options={[
              { value: "", key: "Category" },
              { value: "functional", key: "Outside of functional range" },
              { value: "conventional", key: "Outside of conventional range" },
            ]}
          />
        </div>
        <Row className="">
          <Col className="">
            {bloodMarkerData.map((dm, i2) => {
              return (
                <div key={i2} className="login-card cbc-section">
                  <div className="nameLabel mb-30">{dm.name}</div>
                  <div className="blood-count-accordions">
                    {dm?.childs?.filter((item)=> (!form.sortBy.value || form.sortBy.value === "functional" && bm_data?.[dm?.key]?.[item.key]?.level === "Yellow" || form.sortBy.value === "conventional" && bm_data?.[dm?.key]?.[item.key]?.level === "Red") && bm_data?.[dm?.key]?.[item.key]?.result_value).map((cbc_temp, i) => {
                      return (
                       <MakerAnalysisItem bm_data={bm_data} dm={dm} cbc_temp={cbc_temp} age={age} key={i} index={i} sort={form.sortBy.value} gender={gender} scale={scale}/>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MakerAnalysis;
