import { put, takeEvery } from "redux-saga/effects";
import instance from "../../services/requestHandler";
import { actions } from "../actions.js";
import { loginReducer, signupReducer, setPaymentStatus } from "../index";
import { toast } from 'react-toastify';

const baseURL = process.env.REACT_APP_URL;

// let callAPI = async ({ url, method, data }) => {
//   return await Axios({
//     url,
//     method,
//     data,
//   });
// };

export function* doLogin(action) {
  try {
    let result = yield instance.post(
      `${baseURL}/rest-auth/login/`,
      action.payload
    );
    if (result.data.key) {
      toast.success("Login Successful")
      yield put(loginReducer(result.data));
    }
  } catch (e) {
    toast.error('Invalid Credentials')
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* forgotPassword(action) {
  try {
    let result = yield instance.post(
      `${baseURL}/rest-auth/password/reset/`,
      action.payload
    );
    if (result) {
      toast.success("Email Sent Successfully")
      return result
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export function* changePassword(action) {
  try {
    const formData = new FormData();
    formData.append("new_password1",action.payload.password1)
    formData.append("new_password2",action.payload.password2)
    formData.append("uid",action.payload.uid)
    formData.append("token",action.payload.token)
    let result = yield instance.post(
      `${baseURL}/password/reset/confirm/${action.payload.uid}/${action.payload.token}/`,
      formData
    );
    if (result.status === 200) {
      toast.success("Password Changed Successfully")
      action.payload.navigate("/")
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
    Object.keys(e.response.data).forEach((err)=>{
      toast.error(e.response.data[err][0])
   })
  }
}
export function* updateUser(action) {
  try {
    let result = yield instance.put(
      `${baseURL}/rws_input/${action.payload.id}/`,
      action.payload
    );
    if (result) {
      toast.success(action.message)
      // return result
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export function* doSignUp(action) {
  try {
    let result = yield instance.post(
      `${baseURL}/rest-auth/registration/`,
      action.payload
    );
    if (result.data.key) {
      toast.success("Registered Successfully")
      yield put(signupReducer(result.data));
    }
  } catch (e) {
    Object.keys(e.response.data).forEach((err)=>{
      toast.error(e.response.data[err][0])
   })
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}


// Areeb's Code
export function* eligibilityAndSubscription(action) {

  try {
      let result = yield instance.get(
        `eligibility-status/`,
        action.payload
      );
      
      if (result.data.master_list_graduate === "true") {
            try {
              let result = yield instance.get(
                `subscription-status/`,
                  action.payload
              );
              yield put(setPaymentStatus(result.data));

            } catch (e) {
              toast.error(e)
            }
      } else {
        action.payload.openNext();        
      } 
  } catch (e) {
    Object.keys(e.response.data).forEach((err)=>{
      toast.error(e.response.data[err][0])
   })
  }

}

// Areeb's Code
export function* stripeCustomerPortal(action) {
  
  try {
    let portal = yield instance.get(
      `${baseURL}/stripe-customer-portal/`,
      action.payload
    );

    // Variable with subscription portal URL
    // var portalUrl = result.data.master_list_graduate

    window.location.replace(portal.data.portal_url);
  } catch (e) {
    toast.error(e)
  }
}
export function* payMonthly(action) {
  try {
    let portal = yield instance.get(
      `stripe-checkout-session/`,
      action.payload
    );
    window.location.replace(portal.data.session_url);
  } catch (e) {
      Object.keys(e.response.data).forEach((err)=>{
      toast.error(e.response.data[err][0])
    })
  }
}

export function* payPerReport(action) {
  
  try {
    let portal = yield instance.post(
      `${baseURL}/user_credits_purchase/`,
      action.payload
    );

    window.location.replace(portal.data.stripe_checkout_page);
  } catch (e) {
    toast.error(e)
  }
}

export function* fetchComparisonReview(action) {
  try {
    let result = yield instance.get(
      `${baseURL}/comparison-review/${action?.payload?.name}/`
    );
    if (result.data.key) {
      yield put(signupReducer(result.data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export default function* rootSaga() {
  yield takeEvery(actions.LOGIN, doLogin);
  yield takeEvery(actions.SIGN_UP, doSignUp);
  yield takeEvery(actions.STRIPE_SUBSCRIPTION_AND_ELIGIBILITY, eligibilityAndSubscription); // Areeb's Code
  yield takeEvery(actions.STRIPE_CUSTOMER_PORTAL, stripeCustomerPortal); // Areeb's Code
  yield takeEvery(actions.PAY_MONTHLY, payMonthly)
  yield takeEvery(actions.PAY_PER_REPORT, payPerReport);
  yield takeEvery(actions.FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(actions.CHANGE_PASSWORD, changePassword)
  yield takeEvery(actions.UPDATE_USER, updateUser)

}
