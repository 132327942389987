import React, { useEffect, useState, Fragment } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectClientSearchData } from "../../store";
import { useDispatch } from "react-redux";
import { actions } from "../../store/actions";
import arrowDown from "../../images/arrow-down-black.svg";
import arrowUp from "../../images/arrow-up-black.svg";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getFieldName, scales, getFieldTitle } from "../../utils/constants";
import { v4 as uuid } from "uuid";
import ComparisonNotes from "./ComparisonNotes";
import ReactToPrint from 'react-to-print';

const ComparisonReview = () => {
  const clientSearchData = useSelector(selectClientSearchData);
  const location = useLocation();
  const [comparisonData, setComparisonData] = useState({});
  const [patternComparisonData, setPatternComparisonData] = useState({});

  const dispatch = useDispatch();
  let { name } = useParams();
  useEffect(() => {
    // console.log("useffect");
    dispatch({
      type: actions.COMPARISON_REVIEW,
      payload: { name: name, dates: location.search },
    });
    dispatch({
      type: actions.PATTERN_COMPARISON_REVIEW,
      payload: { name: name, dates: location.search },
    });
  }, []);
  useEffect(() => {
    let data = {};
    let patternComparisonData = {};
    if (clientSearchData.comparisonReviewData) {
      for (let key of Object.keys(clientSearchData.comparisonReviewData)) {
        if (key === "complete_bloodcount")
          data["Complete Blood Count (CBC)"] =
            clientSearchData.comparisonReviewData[key];

        if (key === "complete_metabolicpanel")
          data["Complete Metabolic Panel (CMP)"] =
            clientSearchData.comparisonReviewData[key];

        if (key === "cholesterol"){
          data["Cholesterol"] = clientSearchData.comparisonReviewData[key];
          console.log(data["Cholesterol"])
        }

        if (key === "iron")
          data["Iron"] = clientSearchData.comparisonReviewData[key];

        if (key === "other")
          data["Other"] = clientSearchData.comparisonReviewData[key];

        if (key === "thyroid")
          data["Thyroid"] = clientSearchData.comparisonReviewData[key];
      }
      // console.log("data", data);
      setComparisonData(data);
    }

    if (clientSearchData.patternComparisonReviewData) {
      for (let key of Object.keys(
        clientSearchData.patternComparisonReviewData
      )) {
        if (key === "blood_sugar_pattern")
          patternComparisonData["Blood Sugar"] =
            clientSearchData.patternComparisonReviewData[key];

        if (key === "cardiovascular_pattern")
          patternComparisonData["Cardiovascular"] =
            clientSearchData.patternComparisonReviewData[key];

        if (key === "immune_pattern")
          patternComparisonData["Immune"] =
            clientSearchData.patternComparisonReviewData[key];

        if (key === "inflammation_pattern")
          patternComparisonData["Inflammation"] =
            clientSearchData.patternComparisonReviewData[key];

        if (key === "kidney_adrenal_dehydration_pattern")
          patternComparisonData["Kidney, Adrenals, & Dehydration"] =
            clientSearchData.patternComparisonReviewData[key];

        if (key === "liver_gall_bladder_pattern")
          patternComparisonData["Liver & Gallbladder"] =
            clientSearchData.patternComparisonReviewData[key];
        if (key === "nutritional_deficiency_and_anemia_pattern")
          patternComparisonData["Nutritional Deficiency Anemia"] =
            clientSearchData.patternComparisonReviewData[key];
        if (key === "thyroid_pattern")
          patternComparisonData["Thyroid"] =
            clientSearchData.patternComparisonReviewData[key];
      }
      setPatternComparisonData(patternComparisonData);
    }
    // console.log("data", data);
  }, [clientSearchData]);

  const formatDate = (date) => {
    return moment(date).format("MM-DD-YYYY");
  };
  return (
    <Container fluid className="main-container comparison-accordions px-0">
      <div className="d-flex align-items-center justify-content-between mb-36 comparison-heading">
        <div className="nameLabel">{name}</div>
        {/* <ReactToPrint
          trigger={() => {
            return <Button
            variant="primary"
            size="md"
            className="d-block primary-bg btn-export mb-0"
            >
            Export as Pdf
          </Button>
          }}
          content={() => document.body}
          onBeforePrint={() => {
            let dates = ''
            Object.values(clientSearchData?.comparisonReviewData?.date).forEach((date)=>dates+=` - ${moment(date).format('MM-DD-YYYY')}`)
            document.title = `${name} - Comparison Review${dates}`;
          }
          }
          onAfterPrint={() => document.title = 'Restorative Wellness Solutions'}
        /> */}
      </div>
      {clientSearchData?.comparisonReviewData &&
        Object.values(clientSearchData?.comparisonReviewData?.date).map(
          (date, index) => {
            return (
              <ComparisonNotes date={formatDate(date)} key={uuid()} notes={clientSearchData.comparisonReviewData?.note[index]}/>
            );
          }
        )}
        <h4 className="fw-bold text-center mb-3 print-comparison-title">Comparison Review Table</h4>
      <table role="table" aria-busy="false" aria-colcount="3" class="table b-table b-table-stacked print-comparison-table">
        <tbody role="rowgroup">
          {Object.keys(comparisonData).length > 0 &&
            Object.keys(comparisonData).map((key) => (
              Object.keys(comparisonData[key]).map((data, id) => (
                <tr role="row" class="">
                   <td aria-colindex="1"  role="cell" className="d-flex justify-content-center">
                    <div className='fw-bold'>{key}</div>
                    </td>
                  <td aria-colindex="1" data-label="Marker" role="cell" className="d-flex justify-content-center">
                    <div className='fw-bold'>Marker</div>
                    <div className="ms-3">{getFieldTitle(data)}</div>
                    </td>
                  <td aria-colindex="2" role="cell" className="d-flex justify-content-center">
                    <div className="fw-bold">Range</div>
                    <div className="ms-3">
                    {scales[getFieldName(data)] &&
                      scales[getFieldName(data)].map((scale) => (
                        <Fragment key={uuid()}>
                          <span key={uuid()} dangerouslySetInnerHTML={{ __html: scale }} ></span>
                          <br />
                        </Fragment>
                      ))}
                      </div>
                  </td>
                  {comparisonData[key][data].map((value, index) => (
                            <td key={`${index}-value`} className="d-flex justify-content-center align-items-center">
                              <div className="fw-bold">
                                {formatDate(clientSearchData?.comparisonReviewData?.date[index])}
                              </div>
                              <div
                                className={`${value[0] ? `ms-3 m-0 ${value[2] !== ""  ? value[2] === "Red"
                                  ? "lightRed circle"
                                  : "lightYellow circle"
                                  : ''
                                  }`:''}`}
                              >
                                {value[0] && value[1] !== '' ? 
                                <img
                                  src={
                                    value[1] === "Increased"
                                      ? arrowUp
                                      : arrowDown
                                  }
                                  alt="arrow"
                                />
                                :null}
                                <span>{value[0] ? value[0]: '-'}</span>
                              </div>
                            </td>
                          ))}
                </tr>
              ))
            ))
          }

        </tbody>
      </table>

      <div className="">
        <div className="center-card comparison-table">
          <Table bordered hover className="table" id="comparisonReview">
            <thead className="tableHeader">
              <tr className="tableHeader">
                <th>Marker</th>
                <th data-key="scales">Range</th>
                {clientSearchData?.comparisonReviewData &&
                  Object.values(
                    clientSearchData?.comparisonReviewData?.date
                  ).map((date) => {
                    return <th key={uuid()}>{formatDate(date)}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {Object.keys(comparisonData).length > 0 &&
                Object.keys(comparisonData).map((key) => (
                  <Fragment key={uuid()}>
                    <tr>
                      <td colSpan={6} className="mid-row">
                        {key}
                      </td>
                    </tr>
                    {Object.keys(comparisonData[key]).map((data, id) => (
                      <Fragment key={data}>
                        <tr>
                          <td className="field_name">{getFieldTitle(data)}</td>
                          <td>
                            <div>
                              {scales[getFieldName(data)] &&
                                scales[getFieldName(data)].map((scale) => (
                                  <Fragment key={uuid()}>
                                    <span key={uuid()} dangerouslySetInnerHTML={{ __html: scale }} ></span>
                                    <br />
                                  </Fragment>
                                ))}
                            </div>
                          </td>
                          {comparisonData[key][data].map((value, index) => (
                            <td key={`${index}-value`} className={`${value[0] ? '':'text-center'}`}>
                              
                              <div
                                  className={`${value[0] ? `${value[2] !== '' ? value[2] === "Red"
                                  ? "lightRed circle"
                                  : "lightYellow circle"
                                  : ''
                                  }`:''}`}
                              >
                                {value[0] && value[1] !== ''?
                                <img
                                  src={
                                    value[1] === "Increased"
                                      ? arrowUp
                                      : arrowDown
                                  }
                                  alt="arrow"
                                />
                                : null}
                                <span>{value[0] ? value[0]:'-'}</span>
                              </div>
                            </td>
                          ))}
                        </tr>
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div class="pagebreak"> </div>
      <h4 className="fw-bold text-center mb-3 print-pattern-comparison-title">Pattern Comparison Review Table</h4>
      <table role="table" aria-busy="false" aria-colcount="3" class="table b-table b-table-stacked print-pattern-comparison-table">
        <tbody role="rowgroup">
          {Object.keys(patternComparisonData).length > 0 &&
            Object.keys(patternComparisonData).map((key) => (
              Object.keys(patternComparisonData[key]).map((data, id) => (
                <tr role="row" class="">
                   <td aria-colindex="1"  role="cell" className="d-flex justify-content-center">
                    <div className='fw-bold'>{key}</div>
                    </td>
                  <td aria-colindex="1" data-label="Marker" role="cell" className="d-flex justify-content-center">
                    <div className='fw-bold'>Marker</div>
                    <div className="ms-3">{getFieldTitle(data)}</div>
                    </td>            
                    {patternComparisonData[key][data].map((info, index) => (
                            <td key={uuid()} className="d-flex justify-content-center">
                              <div className="fw-bold">
                              {formatDate(clientSearchData?.comparisonReviewData?.date[index])}
                              </div>
                              <div className="ps-0 ms-3">
                                {info &&
                                  info.map((d) => (
                                    <Fragment key={uuid()}>
                                      <span
                                        key={uuid()}
                                        className={d.bold ? "fw-bold" : ""}
                                      >
                                        {d.status}
                                      </span>
                                      <br />
                                    </Fragment>
                                  ))}
                              </div>
                            </td>
                          ))}
                </tr>
              ))
            ))
          }

        </tbody>
      </table>
      <div className="">
        <div className="center-card comparison-table pattern-table">
          <Table bordered hover className="table" id="pattern_comparison">
            <thead>
              <tr className="tableHeader">
                <th>Marker</th>
                {clientSearchData?.patternComparisonReviewData &&
                  Object.values(
                    clientSearchData?.patternComparisonReviewData?.date
                  ).map((date) => {
                    return <th key={uuid()}>{formatDate(date)}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {Object.keys(patternComparisonData).length > 0 &&
                Object.keys(patternComparisonData).map((key) => (
                  <Fragment key={uuid()}>
                    <tr>
                      <td colSpan={6} className="mid-row">
                        {key}
                      </td>
                    </tr>
                    {Object.keys(patternComparisonData[key]).map((data) => (
                      <Fragment key={uuid()}>
                        <tr>
                          <td className="field_name">{getFieldTitle(data)}</td>
                          {patternComparisonData[key][data].map((info) => (
                            <td key={uuid()}>
                              <div className="ps-0">
                                {info &&
                                  info.map((d) => (
                                    <Fragment key={uuid()}>
                                      <span
                                        key={uuid()}
                                        className={d.bold ? "fw-bold" : ""}
                                      >
                                        {d.status}
                                      </span>
                                      <br />
                                    </Fragment>
                                  ))}
                              </div>
                            </td>
                          ))}
                        </tr>
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
            </tbody>
          </Table>
        </div>
      </div>

    </Container>
  );
};

export default ComparisonReview;
