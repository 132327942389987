//FC=Female Cycling
//FNC=Female Non-Cycling
const client_info = {
  client_name: "tests",
  date: "2023-02-17T21:41:38Z",
  note: "RWS_app checking",
};
const data = {
  FC: {
    US: {
      "60>": {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 3.0,
              wbc_choice: "",
              rbc: 3.5,
              rbc_choice: "million/ul",
              hgb: 11.0,
              hgb_choice: "g/dl",
              hct: 35.0,
              hct_choice: "%",
              mcv: 70.0,
              mcv_choice: "μ^3",
              mch: 24.2,
              mch_choice: "pg",
              mchc: 15.0,
              mchc_choice: "g/dl",
              rdw: 10.0,
              rdw_choice: "%",
              plt_count: 140.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 4.2,
              mpv_choice: "",
              neutrophils: 15.0,
              neutrophils_choice: "%",
              lymphocytes: 5.0,
              lymphocytes_choice: "%",
              monocytes: 3.0,
              monocytes_choice: "%",
              eosinophils: -0.5,
              eosinophils_choice: "%",
              basophils: -0.2,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 55.0,
              gluc_choice: "mg/dl",
              bun: 4.2,
              bun_choice: "mg/dl",
              crea: 0.2,
              crea_choice: "mg/dl",
              egfr: 56.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 5.0,
              bcrt_choice: "",
              sod: 123.0,
              sod_choice: "meq/l",
              pot: 2.0,
              pot_choice: "meq/l",
              chl: 55.0,
              chl_choice: "meq/l",
              co2: 14.0,
              co2_choice: "meq/l",
              cal: 4.2,
              cal_choice: "meq/l",
              tpro: 5.0,
              tpro_choice: "g/dl",
              alb: 3.0,
              alb_choice: "g/dl",
              glob: 1.0,
              glob_choice: "g/dl",
              agrt: 0.5,
              agrt_choice: "",
              tbil: -1.0,
              tbil_choice: "mg/dl",
              aphi: 20.2,
              aphi_choice: "u/l",
              ast: -2.0,
              ast_choice: "u/l",
              alt: -3.0,
              alt_choice: "u/l",
              a1c: 3.0,
              a1c_choice: "%",
              eag: 34.2,
              eag_choice: "mg/dl",
              mag: 1.0,
              mag_choice: "mg/dl",
              phos: 2.0,
              phos_choice: "mg/dl",
              uacd: 1.0,
              uacd_choice: "mg/dl",
              ldh: 94.2,
              ldh_choice: "u/l",
              ggt: 0.5,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 99.0,
              tcho_choice: "mg/dl",
              hdl: 36.0,
              hdl_choice: "mg/dl",
              trig: -2.0,
              trig_choice: "mg/dl",
              ldl: -1.0,
              ldl_choice: "mg/dl",
              chrt: -3.0,
              chrt_choice: "",
              vldl: 4.0,
              vldl_choice: "mg/dl",
              apa1: 89.0,
              apa1_choice: "mg/dl",
              apb: -4.0,
              apb_choice: "mg/dl",
              bart: 0.38,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.24,
              tsh_choice: "mlu/l",
              ft4: 0.4,
              ft4_choice: "ng/dl",
              ft3: 2.0,
              ft3_choice: "pg/dl",
              tt4: 4.0,
              tt4_choice: "ng/dl",
              tt3: 71.0,
              tt3_choice: "ng/dl%",
              ut3: 18.0,
              ut3_choice: "ng/dl",
              rt3: 3.0,
              rt3_choice: "ng/dl",
              tgb: 8.0,
              tgb_choice: "ug/ml",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron: 17.7,
              iron_choice: "µg/dl",
              fert: 8.0,
              fert_choice: "ng/ml",
              tibc: 230.0,
              tibc_choice: "µg/dl",
              tsat: 12.0,
              tsat_choice: "%",
              retc: 0.3,
              retc_choice: "%",
            },
            other: {
              vitd: 21.0,
              vitd_choice: "ng/ml",
              oh2d: 12.0,
              oh2d_choice: "pg/ml",
              fibr: 50.0,
              fibr_choice: "mg/dl",
              crp: -1.0,
              crp_choice: "mg/l",
              hcrp: -2.0,
              hcrp_choice: "mg/l",
              sedr: -1.0,
              sedr_choice: "nmol/l",
              homo: -2.0,
              homo_choice: "umol/l",
              insu: 1.0,
              insu_choice: "μu/ml",
              fruc: 140.0,
              fruc_choice: "umol/l",
              cpep: 0.4,
              cpep_choice: "ng/ml",
              lep: 3.0,
              lep_choice: "ng/dl",
            },
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 12.0,
              wbc_choice: "",
              rbc: 6.1,
              rbc_choice: "million/ul",
              hgb: 17.0,
              hgb_choice: "g/dl",
              hct: 49.0,
              hct_choice: "%",
              mcv: 99.0,
              mcv_choice: "μ^3",
              mch: 34.0,
              mch_choice: "pg",
              mchc: 39.0,
              mchc_choice: "g/dl",
              rdw: 16.4,
              rdw_choice: "%",
              plt_count: 390.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 14.5,
              mpv_choice: "",
              neutrophils: 74.9,
              neutrophils_choice: "%",
              lymphocytes: 47.0,
              lymphocytes_choice: "%",
              monocytes: 14.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 4.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 116.0,
              gluc_choice: "mg/dl",
              bun: 27,
              bun_choice: "mg/dl",
              crea: 3.5,
              crea_choice: "mg/dl",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 25.0,
              bcrt_choice: "",
              sod: 150.0,
              sod_choice: "meq/l",
              pot: 6.0,
              pot_choice: "meq/l",
              chl: 110,
              chl_choice: "meq/l",
              co2: 34.0,
              co2_choice: "meq/l",
              cal: 12.8,
              cal_choice: "meq/l",
              tpro: 9.5,
              tpro_choice: "g/dl",
              alb: 5.8,
              alb_choice: "g/dl",
              glob: 4.9,
              glob_choice: "g/dl",
              agrt: 3.0,
              agrt_choice: "",
              tbil: 1.9,
              tbil_choice: "mg/dl",
              aphi: 122.0,
              aphi_choice: "u/l",
              ast: 45.0,
              ast_choice: "u/l",
              alt: 47.0,
              alt_choice: "u/l",
              a1c: 6.0,
              a1c_choice: "%",
              eag: 118,
              eag_choice: "mg/dl",
              mag: 3.0,
              mag_choice: "mg/dl",
              phos:5.0,
              phos_choice: "mg/dl",
              uacd: 8.0,
              uacd_choice: "mg/dl",
              ldh: 225.0,
              ldh_choice: "u/l",
              ggt: 80.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 202.0,
              tcho_choice: "mg/dl",
              hdl: 10000.9,
              hdl_choice: "mg/dl",
              trig: 152.0,
              trig_choice: "mg/dl",
              ldl: 101.0,
              ldl_choice: "mg/dl",
              chrt: 5.0,
              chrt_choice: "",
              vldl: 42.0,
              vldl_choice: "mg/dl",
              apa1: 212.0,
              apa1_choice: "mg/dl",
              apb: 95.0,
              apb_choice: "mg/dl",
              bart: 10001.0,
              bart_choice: "",
            },
            thyroid: {
              tsh: 5.5,
              tsh_choice: "mlu/l",
              ft4: 3.0,
              ft4_choice: "ng/dl",
              ft3: 5.2,
              ft3_choice: "pg/dl",
              tt4: 14.5,
              tt4_choice: "ng/dl",
              tt3: 235.0,
              tt3_choice: "ng/dl%",
              ut3: 43.0,
              ut3_choice: "ng/dl",
              rt3: 26.1,
              rt3_choice: "ng/dl",
              tgb: 32.9,
              tgb_choice: "ug/ml",
              tpo: 'positive',
              tpo_choice: "",
              atgb: 'positive',
              atgb_choice: "",
              tgt: 'positive',
              tgt_choice: "",
            },
            iron: {
              iron: 172.0,
              iron_choice: "µg/dl",
              fert: 129.0,
              fert_choice: "ng/ml",
              tibc: 358.0,
              tibc_choice: "µg/dl",
              tsat: 62.0,
              tsat_choice: "%",
              retc: 2.0,
              retc_choice: "%",
            },
            other: {
              vitd: 103.0,
              vitd_choice: "ng/ml",
              oh2d: 56.3,
              oh2d_choice: "pg/ml",
              fibr: 412.0,
              fibr_choice: "mg/dl",
              crp: 10.0,
              crp_choice: "mg/l",
              hcrp: 3.0,
              hcrp_choice: "mg/l",
              sedr: 29.0,
              sedr_choice: "nmol/l",
              homo: 12.4,
              homo_choice: "umol/l",
              insu: 20.6,
              insu_choice: "μu/ml",
              fruc: 275.0,
              fruc_choice: "umol/l",
              cpep: 4.5,
              cpep_choice: "ng/ml",
              lep: 12.0,
              lep_choice: "ng/dl",
            },
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 4.0,
              wbc_choice: "",
              rbc: 2.9,
              rbc_choice: "million/ul",
              hgb: 11.5,
              hgb_choice: "g/dl",
              hct: 35.0,
              hct_choice: "%",
              mcv: 79.0,
              mcv_choice: "μ^3",
              mch: 26.0,
              mch_choice: "pg",
              mchc: 31.0,
              mchc_choice: "g/dl",
              rdw: 8.5,
              rdw_choice: "%",
              plt_count: 150.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 6.5,
              mpv_choice: "",
              neutrophils: 35.0,
              neutrophils_choice: "%",
              lymphocytes: 23.0,
              lymphocytes_choice: "%",
              monocytes: -1.5,
              monocytes_choice: "%",
              eosinophils: -1.0,
              eosinophils_choice: "%",
              basophils: -1.2,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 65.0,
              gluc_choice: "mg/dl",
              bun: 9.0,
              bun_choice: "mg/dl",
              crea: 0.5,
              crea_choice: "mg/dl",
              egfr: 55.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 9.0,
              bcrt_choice: "",
              sod: 125.0,
              sod_choice: "meq/l",
              pot: 3.0,
              pot_choice: "meq/l",
              chl: 99.0,
              chl_choice: "meq/l",
              co2: 24.0,
              co2_choice: "meq/l",
              cal: 9.0,
              cal_choice: "meq/l",
              tpro: 6.0,
              tpro_choice: "g/dl",
              alb: 4.0,
              alb_choice: "g/dl",
              glob: 2.2,
              glob_choice: "g/dl",
              agrt: 1.0,
              agrt_choice: "",
              tbil: 0.2,
              tbil_choice: "mg/dl",
              aphi: 38.0,
              aphi_choice: "u/l",
              ast: 8.0,
              ast_choice: "u/l",
              alt: 9.0,
              alt_choice: "u/l",
              a1c: 3.9,
              a1c_choice: "%",
              eag: 69.0,
              eag_choice: "mg/dl",
              mag: 1.0,
              mag_choice: "mg/dl",
              phos:2.5,
              phos_choice: "mg/dl",
              uacd: 2.5,
              uacd_choice: "mg/dl",
              ldh: 139.0,
              ldh_choice: "u/l",
              ggt: 9.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 119.0,
              tcho_choice: "mg/dl",
              hdl: 63.0,
              hdl_choice: "mg/dl",
              trig: 49.0,
              trig_choice: "mg/dl",
              ldl: 39.0,
              ldl_choice: "mg/dl",
              chrt: -1.0,
              chrt_choice: "",
              vldl: 4.0,
              vldl_choice: "mg/dl",
              apa1: 138.0,
              apa1_choice: "mg/dl",
              apb: -1.5,
              apb_choice: "mg/dl",
              bart: -1.2,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.8,
              tsh_choice: "mlu/l",
              ft4: 0.9,
              ft4_choice: "ng/dl",
              ft3: 2.5,
              ft3_choice: "pg/dl",
              tt4: 7.0,
              tt4_choice: "ng/dl",
              tt3: 85.0,
              tt3_choice: "ng/dl%",
              ut3: 25.0,
              ut3_choice: "ng/dl",
              rt3: 13.9,
              rt3_choice: "ng/dl",
              tgb: 17.0,
              tgb_choice: "ug/ml",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 83.0,
              iron_choice: "µg/dl",
              fert: 48.0,
              fert_choice: "ng/ml",
              tibc: 248.0,
              tibc_choice: "µg/dl",
              tsat: 19.0,
              tsat_choice: "%",
              retc: 0.4,
              retc_choice: "%",
            },
            other: {
              vitd: 29.0,
              vitd_choice: "ng/ml",
              oh2d: 19.0,
              oh2d_choice: "pg/ml",
              fibr: 199.0,
              fibr_choice: "mg/dl",
              crp: -1.0,
              crp_choice: "mg/l",
              hcrp: -1.2,
              hcrp_choice: "mg/l",
              sedr: -1.3,
              sedr_choice: "nmol/l",
              homo: 3.6,
              homo_choice: "umol/l",
              insu: 1.8,
              insu_choice: "μu/ml",
              fruc: 186.0,
              fruc_choice: "umol/l",
              cpep: 0.9,
              cpep_choice: "ng/ml",
              lep: 3.0,
              lep_choice: "ng/dl",
            },
          },
          HIGH: { 
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 8.0,
              wbc_choice: "",
              rbc: 5.0,
              rbc_choice: "million/ul",
              hgb: 15.0,
              hgb_choice: "g/dl",
              hct: 45.0,
              hct_choice: "%",
              mcv: 90.9,
              mcv_choice: "μ^3",
              mch: 32.9,
              mch_choice: "pg",
              mchc: 36.0,
              mchc_choice: "g/dl",
              rdw: 14.0,
              rdw_choice: "%",
              plt_count: 386.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 13.5,
              mpv_choice: "",
              neutrophils: 61.0,
              neutrophils_choice: "%",
              lymphocytes: 45.0,
              lymphocytes_choice: "%",
              monocytes: 8.0,
              monocytes_choice: "%",
              eosinophils: 4.0,
              eosinophils_choice: "%",
              basophils: 2.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 91.0,
              gluc_choice: "mg/dl",
              bun: 17,
              bun_choice: "mg/dl",
              crea: 1.2,
              crea_choice: "mg/dl",
              egfr: 10012.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 17.0,
              bcrt_choice: "",
              sod: 147.0,
              sod_choice: "meq/l",
              pot: 5.0,
              pot_choice: "meq/l",
              chl: 107.0,
              chl_choice: "meq/l",
              co2: 31.0,
              co2_choice: "meq/l",
              cal: 11.0,
              cal_choice: "meq/l",
              tpro: 7.9,
              tpro_choice: "g/dl",
              alb: 5.5,
              alb_choice: "g/dl",
              glob: 3.9,
              glob_choice: "g/dl",
              agrt: 2.6,
              agrt_choice: "",
              tbil: 1.6,
              tbil_choice: "mg/dl",
              aphi: 105.0,
              aphi_choice: "u/l",
              ast: 23.0,
              ast_choice: "u/l",
              alt: 20.0,
              alt_choice: "u/l",
              a1c: 6.4,
              a1c_choice: "%",
              eag: 109.0,
              eag_choice: "mg/dl",
              mag: 2.7,
              mag_choice: "mg/dl",
              phos:4.5,
              phos_choice: "mg/dl",
              uacd: 6.5,
              uacd_choice: "mg/dl",
              ldh: 209.0,
              ldh_choice: "u/l",
              ggt: 25.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 241.0,
              tcho_choice: "mg/dl",
              hdl: 86.0,
              hdl_choice: "mg/dl",
              trig: 110.0,
              trig_choice: "mg/dl",
              ldl: 125.0,
              ldl_choice: "mg/dl",
              chrt: 4.0,
              chrt_choice: "",
              vldl: 42.0,
              vldl_choice: "mg/dl",
              apa1: 10003.0,
              apa1_choice: "mg/dl",
              apb: 95.0,
              apb_choice: "mg/dl",
              bart: 0.73,
              bart_choice: "",
            },
            thyroid: {
              tsh: 3.0,
              tsh_choice: "mlu/l",
              ft4: 2.0,
              ft4_choice: "ng/dl",
              ft3: 4.25,
              ft3_choice: "pg/dl",
              tt4: 7.9,
              tt4_choice: "ng/dl",
              tt3: 166.0,
              tt3_choice: "ng/dl%",
              ut3: 39.0,
              ut3_choice: "ng/dl",
              rt3: 29.7,
              rt3_choice: "ng/dl",
              tgb: 28.0,
              tgb_choice: "ug/ml",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 132.0,
              iron_choice: "µg/dl",
              fert: 125.0,
              fert_choice: "ng/ml",
              tibc: 353.0,
              tibc_choice: "µg/dl",
              tsat: 37.0,
              tsat_choice: "%",
              retc: 2.0,
              retc_choice: "%",
            },
            other: {
              vitd: 52.0,
              vitd_choice: "ng/ml",
              oh2d: 56.0,
              oh2d_choice: "pg/ml",
              fibr: 301.0,
              fibr_choice: "mg/dl",
              crp: 8.5,
              crp_choice: "mg/l",
              hcrp: 1.9,
              hcrp_choice: "mg/l",
              sedr: 10.9,
              sedr_choice: "nmol/l",
              homo: 7.5,
              homo_choice: "umol/l",
              insu: 8.5,
              insu_choice: "μu/ml",
              fruc: 272.0,
              fruc_choice: "umol/l",
              cpep: 2.5,
              cpep_choice: "ng/ml",
              lep: 6.5,
              lep_choice: "ng/dl",
            }
          },
        },
      },
      "59<": {
        CONV: {
          LOW: {
            rws_input: {
            ...client_info,
            sex: "female-cycling",
            date_of_birth: "1973-02-23T16:04:50Z",
            units_us_or_intl: "us",
          },
          cbc: {
            wbc: 2.7,
            wbc_choice: "",
            rbc: 3.0,
            rbc_choice: "million/ul",
            hgb: 11.0,
            hgb_choice: "g/dl",
            hct: 35.0,
            hct_choice: "%",
            mcv: 70.0,
            mcv_choice: "μ^3",
            mch: 25.0,
            mch_choice: "pg",
            mchc: 15.0,
            mchc_choice: "g/dl",
            rdw: 8.0,
            rdw_choice: "%",
            plt_count: 132.0,
            plt_count_choice: "mm^3, x 1000",
            mpv: 4.0,
            mpv_choice: "",
            neutrophils: 13.0,
            neutrophils_choice: "%",
            lymphocytes: 5.0,
            lymphocytes_choice: "%",
            monocytes: 3.0,
            monocytes_choice: "%",
            eosinophils: -1.0,
            eosinophils_choice: "%",
            basophils: -1.5,
            basophils_choice: "%",
          },
          cmp: {
            gluc: 64.0,
            gluc_choice: "mg/dl",
            bun: 4.5,
            bun_choice: "mg/dl",
            crea: 0.5,
            crea_choice: "mg/dl",
            egfr: 58.0,
            egfr_choice: "ml/min/1.73^2",
            bcrt: 5.0,
            bcrt_choice: "",
            sod: 125.0,
            sod_choice: "meq/l",
            pot: 3.0,
            pot_choice: "meq/l",
            chl: 95.0,
            chl_choice: "meq/l",
            co2: 22.0,
            co2_choice: "meq/l",
            cal: 8.0,
            cal_choice: "meq/l",
            tpro: 5.9,
            tpro_choice: "g/dl",
            alb: 2.8,
            alb_choice: "g/dl",
            glob: 1.8,
            glob_choice: "g/dl",
            agrt: 1.0,
            agrt_choice: "",
            tbil: -2.0,
            tbil_choice: "mg/dl",
            aphi: 24.0,
            aphi_choice: "u/l",
            ast: -1.0,
            ast_choice: "u/l",
            alt: -1.2,
            alt_choice: "u/l",
            a1c: 4.0,
            a1c_choice: "%",
            eag: 69.0,
            eag_choice: "mg/dl",
            mag: 1.0,
            mag_choice: "mg/dl",
            phos: 2.0,
            phos_choice: "mg/dl",
            uacd: 2.0,
            uacd_choice: "mg/dl",
            ldh: 99.0,
            ldh_choice: "u/l",
            ggt:0.8,
            ggt_choice: "u/l",
          },
          cholestrol: {
            tcho: 99.0,
            tcho_choice: "mg/dl",
            hdl: 38.0,
            hdl_choice: "mg/dl",
            trig: -1.0,
            trig_choice: "mg/dl",
            ldl: -1.1,
            ldl_choice: "mg/dl",
            chrt: -1.2,
            chrt_choice: "",
            vldl: 4.5,
            vldl_choice: "mg/dl",
            apa1: 112.0,
            apa1_choice: "mg/dl",
            apb: -1.3,
            apb_choice: "mg/dl",
            bart: 0.69,
            bart_choice: "",
          },
          thyroid: {
            tsh: 0.2,
            tsh_choice: "mlu/l",
            ft4: 0.6,
            ft4_choice: "ng/dl",
            ft3: 2.0,
            ft3_choice: "pg/dl",
            tt4: 4.0,
            tt4_choice: "ng/dl",
            tt3: 79.0,
            tt3_choice: "ng/dl%",
            ut3: 23.0,
            ut3_choice: "ng/dl",
            rt3: 8.2,
            rt3_choice: "ng/dl",
            tgb: 11.7,
            tgb_choice: "ug/ml",
            tpo: 'negative',
            tpo_choice: "",
            atgb: 'negative',
            atgb_choice: "",
            tgt: 'negative',
            tgt_choice: "",
          },
          iron: {
            iron: 28.0,
            iron_choice: "µg/dl",
            fert: 9.0,
            fert_choice: "ng/ml",
            tibc: 249.0,
            tibc_choice: "µg/dl",
            tsat: 15.0,
            tsat_choice: "%",
            retc: 0.4,
            retc_choice: "%",
          },
          other: {
            vitd: 29.0,
            vitd_choice: "ng/ml",
            oh2d: 18.6,
            oh2d_choice: "pg/ml",
            fibr: 148.0,
            fibr_choice: "mg/dl",
            crp: -1.3,
            crp_choice: "mg/l",
            hcrp: -1.2,
            hcrp_choice: "mg/l",
            sedr: -1.1,
            sedr_choice: "nmol/l",
            homo: -4.5,
            homo_choice: "umol/l",
            insu: 1.7,
            insu_choice: "μu/ml",
            fruc: 189.0,
            fruc_choice: "umol/l",
            cpep: 0.7,
            cpep_choice: "ng/ml",
            lep: 3.0,
            lep_choice: "ng/dl",
          },},
          HIGH: { rws_input: {
            ...client_info,
            sex: "female-cycling",
            date_of_birth: "1973-02-23T16:04:50Z",
            units_us_or_intl: "us",
          },
          cbc: {
            wbc: 12.0,
            wbc_choice: "",
            rbc: 5.9,
            rbc_choice: "million/ul",
            hgb: 17.0,
            hgb_choice: "g/dl",
            hct: 49.2,
            hct_choice: "%",
            mcv: 98.0,
            mcv_choice: "μ^3",
            mch: 34.0,
            mch_choice: "pg",
            mchc: 38.0,
            mchc_choice: "g/dl",
            rdw: 16.4,
            rdw_choice: "%",
            plt_count: 389.0,
            plt_count_choice: "mm^3, x 1000",
            mpv: 13.5,
            mpv_choice: "",
            neutrophils: 75.0,
            neutrophils_choice: "%",
            lymphocytes: 47.0,
            lymphocytes_choice: "%",
            monocytes: 14.0,
            monocytes_choice: "%",
            eosinophils: 8.0,
            eosinophils_choice: "%",
            basophils: 4.0,
            basophils_choice: "%",
          },
          cmp: {
            gluc: 116.0,
            gluc_choice: "mg/dl",
            bun: 26.0,
            bun_choice: "mg/dl",
            crea: 2.0,
            crea_choice: "mg/dl",
            egfr: 10012.0,
            egfr_choice: "ml/min/1.73^2",
            bcrt: 24.0,
            bcrt_choice: "",
            sod: 147.0,
            sod_choice: "meq/l",
            pot: 6.3,
            pot_choice: "meq/l",
            chl: 111.0,
            chl_choice: "meq/l",
            co2: 33.0,
            co2_choice: "meq/l",
            cal: 11.8,
            cal_choice: "meq/l",
            tpro: 9.5,
            tpro_choice: "g/dl",
            alb: 5.0,
            alb_choice: "g/dl",
            glob: 4.5,
            glob_choice: "g/dl",
            agrt: 3.0,
            agrt_choice: "",
            tbil: 1.8,
            tbil_choice: "mg/dl",
            aphi: 125.0,
            aphi_choice: "u/l",
            ast: 41.0,
            ast_choice: "u/l",
            alt: 46.0,
            alt_choice: "u/l",
            a1c: 6.1,
            a1c_choice: "%",
            eag: 118.0,
            eag_choice: "mg/dl",
            mag: 3.0,
            mag_choice: "mg/dl",
            phos:4.9,
            phos_choice: "mg/dl",
            uacd: 7.9,
            uacd_choice: "mg/dl",
            ldh: 221.0,
            ldh_choice: "u/l",
            ggt: 72.0,
            ggt_choice: "u/l",
          },
          cholestrol: {
            tcho: 200.9,
            tcho_choice: "mg/dl",
            hdl: 10014.0,
            hdl_choice: "mg/dl",
            trig: 150.5,
            trig_choice: "mg/dl",
            ldl: 99.9,
            ldl_choice: "mg/dl",
            chrt: 4.8,
            chrt_choice: "",
            vldl: 40.8,
            vldl_choice: "mg/dl",
            apa1: 209.8,
            apa1_choice: "mg/dl",
            apb: 92.0,
            apb_choice: "mg/dl",
            bart: 10011.0,
            bart_choice: "",
          },
          thyroid: {
            tsh: 5.0,
            tsh_choice: "mlu/l",
            ft4: 3.0,
            ft4_choice: "ng/dl",
            ft3: 5.0,
            ft3_choice: "pg/dl",
            tt4: 13.5,
            tt4_choice: "ng/dl",
            tt3: 232.0,
            tt3_choice: "ng/dl%",
            ut3: 41.5,
            ut3_choice: "ng/dl",
            rt3: 24.9,
            rt3_choice: "ng/dl",
            tgb: 31.9,
            tgb_choice: "ug/ml",
            tpo: 'positive',
            tpo_choice: "",
            atgb: 'positive',
            atgb_choice: "",
            tgt: 'positive',
            tgt_choice: "",
          },
          iron: {
            iron: 171.0,
            iron_choice: "µg/dl",
            fert: 124.0,
            fert_choice: "ng/ml",
            tibc: 352.0,
            tibc_choice: "µg/dl",
            tsat: 69.0,
            tsat_choice: "%",
            retc: 1.3,
            retc_choice: "%",
          },
          other: {
            vitd: 102.0,
            vitd_choice: "ng/ml",
            oh2d: 55.3,
            oh2d_choice: "pg/ml",
            fibr: 401.0,
            fibr_choice: "mg/dl",
            crp: 9.0,
            crp_choice: "mg/l",
            hcrp: 1.5,
            hcrp_choice: "mg/l",
            sedr: 22.0,
            sedr_choice: "nmol/l",
            homo: 12.4,
            homo_choice: "umol/l",
            insu: 20.6,
            insu_choice: "μu/ml",
            fruc: 272.0,
            fruc_choice: "umol/l",
            cpep: 4.5,
            cpep_choice: "ng/ml",
            lep: 9.0,
            lep_choice: "ng/dl",
          },},
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1975-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 4.5,
              wbc_choice: "",
              rbc: 3.5,
              rbc_choice: "million/ul",
              hgb: 12.5,
              hgb_choice: "g/dl",
              hct: 36.0,
              hct_choice: "%",
              mcv: 81.0,
              mcv_choice: "μ^3",
              mch: 27.0,
              mch_choice: "pg",
              mchc: 31.0,
              mchc_choice: "g/dl",
              rdw: 8.5,
              rdw_choice: "%",
              plt_count: 153.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 7.0,
              mpv_choice: "",
              neutrophils: 39.0,
              neutrophils_choice: "%",
              lymphocytes: 23.0,
              lymphocytes_choice: "%",
              monocytes: -1.2,
              monocytes_choice: "%",
              eosinophils: -1.1,
              eosinophils_choice: "%",
              basophils: -1.4,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 69.5,
              gluc_choice: "mg/dl",
              bun: 9.5,
              bun_choice: "mg/dl",
              crea: 0.6,
              crea_choice: "mg/dl",
              egfr: 58.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 9.0,
              bcrt_choice: "",
              sod: 133.0,
              sod_choice: "meq/l",
              pot: 3.5,
              pot_choice: "meq/l",
              chl: 99.0,
              chl_choice: "meq/l",
              co2: 23.0,
              co2_choice: "meq/l",
              cal: 9.0,
              cal_choice: "meq/l",
              tpro: 6.3,
              tpro_choice: "g/dl",
              alb: 4.0,
              alb_choice: "g/dl",
              glob: 2.0,
              glob_choice: "g/dl",
              agrt: 1.0,
              agrt_choice: "",
              tbil: 0.3,
              tbil_choice: "mg/dl",
              aphi: 39.0,
              aphi_choice: "u/l",
              ast: 8.0,
              ast_choice: "u/l",
              alt: 9.5,
              alt_choice: "u/l",
              a1c: 3.5,
              a1c_choice: "%",
              eag: 65.0,
              eag_choice: "mg/dl",
              mag: 1.5,
              mag_choice: "mg/dl",
              phos:3.0,
              phos_choice: "mg/dl",
              uacd: 2.5,
              uacd_choice: "mg/dl",
              ldh: 139.0,
              ldh_choice: "u/l",
              ggt: 9.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 119.0,
              tcho_choice: "mg/dl",
              hdl: 64.0,
              hdl_choice: "mg/dl",
              trig: 49.0,
              trig_choice: "mg/dl",
              ldl: 39.0,
              ldl_choice: "mg/dl",
              chrt: -1.1,
              chrt_choice: "",
              vldl: 4.5,
              vldl_choice: "mg/dl",
              apa1: 139.0,
              apa1_choice: "mg/dl",
              apb: -1.1,
              apb_choice: "mg/dl",
              bart: -1.4,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.8,
              tsh_choice: "mlu/l",
              ft4: 0.4,
              ft4_choice: "ng/dl",
              ft3: 2.0,
              ft3_choice: "pg/dl",
              tt4: 6.5,
              tt4_choice: "ng/dl",
              tt3: 88.0,
              tt3_choice: "ng/dl%",
              ut3: 26.0,
              ut3_choice: "ng/dl",
              rt3: 13.9,
              rt3_choice: "ng/dl",
              tgb: 17.0,
              tgb_choice: "ug/ml",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 83.0,
              iron_choice: "µg/dl",
              fert: 45.0,
              fert_choice: "ng/ml",
              tibc: 245.0,
              tibc_choice: "µg/dl",
              tsat: 19.0,
              tsat_choice: "%",
              retc: 0.4,
              retc_choice: "%",
            },
            other: {
              vitd: 26.0,
              vitd_choice: "ng/ml",
              oh2d: 19.0,
              oh2d_choice: "pg/ml",
              fibr: 199.0,
              fibr_choice: "mg/dl",
              crp: -1.1,
              crp_choice: "mg/l",
              hcrp: -1.1,
              hcrp_choice: "mg/l",
              sedr: -1.1,
              sedr_choice: "nmol/l",
              homo: 3.0,
              homo_choice: "umol/l",
              insu: 1.5,
              insu_choice: "μu/ml",
              fruc: 188.0,
              fruc_choice: "umol/l",
              cpep: 0.7,
              cpep_choice: "ng/ml",
              lep: 3.0,
              lep_choice: "ng/dl",
            },
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1973-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 8.5,
              wbc_choice: "",
              rbc: 5.5,
              rbc_choice: "million/ul",
              hgb: 15.5,
              hgb_choice: "g/dl",
              hct: 44.4,
              hct_choice: "%",
              mcv: 90.9,
              mcv_choice: "μ^3",
              mch: 32.9,
              mch_choice: "pg",
              mchc: 35.5,
              mchc_choice: "g/dl",
              rdw: 14.0,
              rdw_choice: "%",
              plt_count: 386.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 13.5,
              mpv_choice: "",
              neutrophils: 61.0,
              neutrophils_choice: "%",
              lymphocytes: 45.0,
              lymphocytes_choice: "%",
              monocytes: 8.0,
              monocytes_choice: "%",
              eosinophils: 4.0,
              eosinophils_choice: "%",
              basophils: 2.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 92.0,
              gluc_choice: "mg/dl",
              bun: 17,
              bun_choice: "mg/dl",
              crea: 1.9,
              crea_choice: "mg/dl",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 17.0,
              bcrt_choice: "",
              sod: 145.0,
              sod_choice: "meq/l",
              pot: 4.8,
              pot_choice: "meq/l",
              chl: 108.0,
              chl_choice: "meq/l",
              co2: 32.0,
              co2_choice: "meq/l",
              cal: 11.0,
              cal_choice: "meq/l",
              tpro: 8.0,
              tpro_choice: "g/dl",
              alb: 5.5,
              alb_choice: "g/dl",
              glob: 3.8,
              glob_choice: "g/dl",
              agrt: 2.5,
              agrt_choice: "",
              tbil: 1.6,
              tbil_choice: "mg/dl",
              aphi: 112.0,
              aphi_choice: "u/l",
              ast: 24.0,
              ast_choice: "u/l",
              alt: 21.0,
              alt_choice: "u/l",
              a1c: 5.9,
              a1c_choice: "%",
              eag: 110.0,
              eag_choice: "mg/dl",
              mag: 3.0,
              mag_choice: "mg/dl",
              phos:4.5,
              phos_choice: "mg/dl",
              uacd: 6.5,
              uacd_choice: "mg/dl",
              ldh: 210.0,
              ldh_choice: "u/l",
              ggt: 25.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 245.0,
              tcho_choice: "mg/dl",
              hdl: 86.0,
              hdl_choice: "mg/dl",
              trig: 52.0,
              trig_choice: "mg/dl",
              ldl: 122.0,
              ldl_choice: "mg/dl",
              chrt: 4.0,
              chrt_choice: "",
              vldl: 6.0,
              vldl_choice: "mg/dl",
              apa1: 10011.0,
              apa1_choice: "mg/dl",
              apb: 90.9,
              apb_choice: "mg/dl",
              bart: 0.7,
              bart_choice: "",
            },
            thyroid: {
              tsh: 3.0,
              tsh_choice: "mlu/l",
              ft4: 2.5,
              ft4_choice: "ng/dl",
              ft3: 4.25,
              ft3_choice: "pg/dl",
              tt4: 8.8,
              tt4_choice: "ng/dl",
              tt3: 170.0,
              tt3_choice: "ng/dl%",
              ut3: 39.0,
              ut3_choice: "ng/dl",
              rt3: 27.7,
              rt3_choice: "ng/dl",
              tgb: 28.0,
              tgb_choice: "ug/ml",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 131.0,
              iron_choice: "µg/dl",
              fert: 124.0,
              fert_choice: "ng/ml",
              tibc: 352.0,
              tibc_choice: "µg/dl",
              tsat: 36.0,
              tsat_choice: "%",
              retc: 1.4,
              retc_choice: "%",
            },
            other: {
              vitd: 51.0,
              vitd_choice: "ng/ml",
              oh2d: 56.0,
              oh2d_choice: "pg/ml",
              fibr: 301.0,
              fibr_choice: "mg/dl",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 1.5,
              hcrp_choice: "mg/l",
              sedr: 10.9,
              sedr_choice: "nmol/l",
              homo: 8.0,
              homo_choice: "umol/l",
              insu: 9.0,
              insu_choice: "μu/ml",
              fruc: 272.0,
              fruc_choice: "umol/l",
              cpep: 2.8,
              cpep_choice: "ng/ml",
              lep: 7.0,
              lep_choice: "ng/dl",
            }
  
          },
        },
      },
    },
    Intl: {
      "60>": {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 2.7,
              wbc_choice: "x 109/l",
              rbc: 2.9,
              rbc_choice: "x 1012/l",
              hgb: 119.0,
              hgb_choice: "g/l",
              hct: 35.0,
              hct_choice: "%",
              mcv: 78.0,
              mcv_choice: "fl",
              mch: 25.6,
              mch_choice: "pg",
              mchc: 29.0,
              mchc_choice: "mmol/l",
              rdw: 8.0,
              rdw_choice: "%",
              plt_count: 145.0,
              plt_count_choice: "10^9/l",
              mpv: 6.5,
              mpv_choice: "fl",
              neutrophils: 25.0,
              neutrophils_choice: "%",
              lymphocytes: 13.0,
              lymphocytes_choice: "%",
              monocytes: 3.0,
              monocytes_choice: "%",
              eosinophils: -1.2,
              eosinophils_choice: "%",
              basophils: -1.1,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 3.4,
              gluc_choice: "mmol/l",
              bun: 1.4,
              bun_choice: "mmol/l",
              crea: 52.0,
              crea_choice: "µmol/l",
              egfr: 58.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 5.0,
              bcrt_choice: "",
              sod: 125.0,
              sod_choice: "mmol/l",
              pot: 2.5,
              pot_choice: "mmol/l",
              chl: 94.0,
              chl_choice: "mmol/l",
              co2: 21.0,
              co2_choice: "mmol/l",
              cal: 2.0,
              cal_choice: "mmol/l",
              tpro: 58.0,
              tpro_choice: "g/l",
              alb: 34.0,
              alb_choice: "g/l",
              glob: 19.0,
              glob_choice: "g/l",
              agrt: 1.0,
              agrt_choice: "",
              tbil: -1.1,
              tbil_choice: "µmol/l",
              aphi: 23.0,
              aphi_choice: "u/l",
              ast: -1.0,
              ast_choice: "u/l",
              alt: -1.0,
              alt_choice: "u/l",
              a1c: 19.31,
              a1c_choice: "mmol/mol",
              eag: 2.94,
              eag_choice: "mmol/l",
              mag: 0.2,
              mag_choice: "mmol/l",
              phos: 0.4,
              phos_choice: "mmol/l",
              uacd: 127.9,
              uacd_choice: "µmol/l",
              ldh: 1.0,
              ldh_choice: "µkat/l",
              ggt: 0.6,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 2.0,
              tcho_choice: "mmol/l",
              hdl: 0.5,
              hdl_choice: "mmol/l",
              trig: -2.1,
              trig_choice: "mmol/l",
              ldl: -1.1,
              ldl_choice: "mmol/l",
              chrt: -1.5,
              chrt_choice: "",
              vldl: -1.0,
              vldl_choice: "mmol/l",
              apa1: 39.0,
              apa1_choice: "µmol/l",
              apb: -2.3,
              apb_choice: "mg/dl",
              bart: 0.5,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.35,
              tsh_choice: "mlu/l",
              ft4: 8.0,
              ft4_choice: "pmol/l",
              ft3: 2.5,
              ft3_choice: "pmol/l",
              tt4: 54.9,
              tt4_choice: "pmol/l",
              tt3: 1.0,
              tt3_choice: "pmol/l",
              ut3: 23.0,
              ut3_choice: "%",
              rt3: -1.1,
              rt3_choice: "nmol/l",
              tgb: 234.2,
              tgb_choice: "nmol/l",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron: 5.0,
              iron_choice: "µmol/l",
              fert: 9.0,
              fert_choice: "ug/l",
              tibc: 43.8,
              tibc_choice: "µmol/l",
              tsat: 15.0,
              tsat_choice: "%",
              retc: 0.2,
              retc_choice: "%",
            },
            other: {
              vitd: 73.9,
              vitd_choice: "nmol/l",
              oh2d: 45,
              oh2d_choice: "pmol/l",
              fibr: 4.0,
              fibr_choice: "µmol/l",
              crp: -1.4,
              crp_choice: "mg/l",
              hcrp: -1.5,
              hcrp_choice: "nmol/l",
              sedr: -2.5,
              sedr_choice: "mm/hr",
              homo: 3.0,
              homo_choice: "µmol/l",
              insu: 11.9,
              insu_choice: "pmol/l",
              fruc: 185.0,
              fruc_choice: "umol/l",
              cpep: 0.1,
              cpep_choice: "nmol/ml",
              lep: 2.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 13,
              wbc_choice: "x 109/l",
              rbc: 5.8,
              rbc_choice: "x 1012/l",
              hgb: 164.0,
              hgb_choice: "g/l",
              hct: 49.2,
              hct_choice: "%",
              mcv: 99.0,
              mcv_choice: "fl",
              mch: 35.0,
              mch_choice: "pg",
              mchc: 25.94,
              mchc_choice: "mmol/l",
              rdw: 16.4,
              rdw_choice: "%",
              plt_count: 395.0,
              plt_count_choice: "10^9/l",
              mpv: 13.5,
              mpv_choice: "fl",
              neutrophils: 75.0,
              neutrophils_choice: "%",
              lymphocytes: 47.0,
              lymphocytes_choice: "%",
              monocytes: 14.0,
              monocytes_choice: "%",
              eosinophils: 8.0,
              eosinophils_choice: "%",
              basophils: 4.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 7.4,
              gluc_choice: "mmol/l",
              bun: 9.9,
              bun_choice: "mmol/l",
              crea: 133.6,
              crea_choice: "µmol/l",
              egfr:10012.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 25.0,
              bcrt_choice: "",
              sod: 147.0,
              sod_choice: "mmol/l",
              pot: 6.3,
              pot_choice: "mmol/l",
              chl: 115.0,
              chl_choice: "mmol/l",
              co2: 34.0,
              co2_choice: "mmol/l",
              cal: 3.0,
              cal_choice: "mmol/l",
              tpro: 89.0,
              tpro_choice: "g/l",
              alb: 51.0,
              alb_choice: "g/l",
              glob: 41.0,
              glob_choice: "g/l",
              agrt: 4.0,
              agrt_choice: "",
              tbil: 22.5,
              tbil_choice: "µmol/l",
              aphi: 122.0,
              aphi_choice: "u/l",
              ast: 42.0,
              ast_choice: "u/l",
              alt: 46.0,
              alt_choice: "u/l",
              a1c: 39.8,
              a1c_choice: "mmol/mol",
              eag: 7.49,
              eag_choice: "mmol/l",
              mag: 1.9,
              mag_choice: "mmol/l",
              phos: 2.5,
              phos_choice: "mmol/l",
              uacd: 489.0,
              uacd_choice: "µmol/l",
              ldh: 4.0,
              ldh_choice: "µkat/l",
              ggt: 72.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 5.3,
              tcho_choice: "mmol/l",
              hdl: 10016.0,
              hdl_choice: "mmol/l",
              trig: 2.7,
              trig_choice: "mmol/l",
              ldl: 3.6,
              ldl_choice: "mmol/l",
              chrt: 4.9,
              chrt_choice: "",
              vldl: 1.9,
              vldl_choice: "mmol/l",
              apa1: 77.0,
              apa1_choice: "µmol/l",
              apb: 1.85,
              apb_choice: "mg/dl",
              bart: 10011.0,
              bart_choice: "",
            },
            thyroid: {
              tsh: 5.0,
              tsh_choice: "mlu/l",
              ft4: 31.9,
              ft4_choice: "pmol/l",
              ft3: 8.4,
              ft3_choice: "pmol/l",
              tt4: 162.9,
              tt4_choice: "pmol/l",
              tt3: 4.5,
              tt3_choice: "pmol/l",
              ut3: 40.0,
              ut3_choice: "%",
              rt3: 0.6,
              rt3_choice: "nmol/l",
              tgb: 582.2,
              tgb_choice: "nmol/l",
              tpo: 'positive',
              tpo_choice: "",
              atgb: 'positive',
              atgb_choice: "",
              tgt: 'positive',
              tgt_choice: "",
            },
            iron: {
              iron: 31.4,
              iron_choice: "µmol/l",
              fert: 124.0,
              fert_choice: "ug/l",
              tibc: 63.7,
              tibc_choice: "µmol/l",
              tsat: 61.0,
              tsat_choice: "%",
              retc: 2.0,
              retc_choice: "%",
            },
            other: {
              vitd: 250.6,
              vitd_choice: "nmol/l",
              oh2d: 131,
              oh2d_choice: "pmol/l",
              fibr: 12.8,
              fibr_choice: "µmol/l",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 10.0,
              hcrp_choice: "nmol/l",
              sedr: 21.0,
              sedr_choice: "mm/hr",
              homo: 13.0,
              homo_choice: "µmol/l",
              insu: 137.1,
              insu_choice: "pmol/l",
              fruc: 272.0,
              fruc_choice: "umol/l",
              cpep: 1.4,
              cpep_choice: "nmol/ml",
              lep: 11.0,
              lep_choice: "µg/l",
            }
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 4.5,
              wbc_choice: "x 109/l",
              rbc: 3.0,
              rbc_choice: "x 1012/l",
              hgb: 131.0,
              hgb_choice: "g/l",
              hct: 35.0,
              hct_choice: "%",
              mcv: 81.0,
              mcv_choice: "fl",
              mch: 25.0,
              mch_choice: "pg",
              mchc: 18.84,
              mchc_choice: "mmol/l",
              rdw: 8,
              rdw_choice: "%",
              plt_count: 153.0,
              plt_count_choice: "10^9/l",
              mpv: 6.5,
              mpv_choice: "fl",
              neutrophils: 39.0,
              neutrophils_choice: "%",
              lymphocytes: 23.0,
              lymphocytes_choice: "%",
              monocytes: -1.1,
              monocytes_choice: "%",
              eosinophils: -1.2,
              eosinophils_choice: "%",
              basophils: -1.3,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 3.0,
              gluc_choice: "mmol/l",
              bun: 3.0,
              bun_choice: "mmol/l",
              crea: 70.0,
              crea_choice: "µmol/l",
              egfr: 58.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 9.0,
              bcrt_choice: "",
              sod: 130.0,
              sod_choice: "mmol/l",
              pot: 3.0,
              pot_choice: "mmol/l",
              chl: 99.0,
              chl_choice: "mmol/l",
              co2: 24.0,
              co2_choice: "mmol/l",
              cal: 2.0,
              cal_choice: "mmol/l",
              tpro: 65.0,
              tpro_choice: "g/l",
              alb: 44.0,
              alb_choice: "g/l",
              glob: 23.0,
              glob_choice: "g/l",
              agrt: 1.0,
              agrt_choice: "",
              tbil: 8.0,
              tbil_choice: "µmol/l",
              aphi: 39.0,
              aphi_choice: "u/l",
              ast: 8.0,
              ast_choice: "u/l",
              alt: 9.0,
              alt_choice: "u/l",
              a1c: 19.31,
              a1c_choice: "mmol/mol",
              eag: 2.94,
              eag_choice: "mmol/l",
              mag: 0.6,
              mag_choice: "mmol/l",
              phos: 1.0,
              phos_choice: "mmol/l",
              uacd: 168.4,
              uacd_choice: "µmol/l",
              ldh: 2.0,
              ldh_choice: "µkat/l",
              ggt: 8.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 2.9,
              tcho_choice: "mmol/l",
              hdl: 1.2,
              hdl_choice: "mmol/l",
              trig: 0.4,
              trig_choice: "mmol/l",
              ldl: 0.8,
              ldl_choice: "mmol/l",
              chrt: -2.5,
              chrt_choice: "",
              vldl: -3.4,
              vldl_choice: "mmol/l",
              apa1: 45.0,
              apa1_choice: "µmol/l",
              apb: -1.3,
              apb_choice: "mg/dl",
              bart: -2.4,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.5,
              tsh_choice: "mlu/l",
              ft4: 11.9,
              ft4_choice: "pmol/l",
              ft3: 3.6,
              ft3_choice: "pmol/l",
              tt4: 95.5,
              tt4_choice: "pmol/l",
              tt3: 1.0,
              tt3_choice: "pmol/l",
              ut3: 26.0,
              ut3_choice: "%",
              rt3: -1.0,
              rt3_choice: "nmol/l",
              tgb: 332.0,
              tgb_choice: "nmol/l",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 13.2,
              iron_choice: "µmol/l",
              fert: 48.0,
              fert_choice: "ug/l",
              tibc: 41.8,
              tibc_choice: "µmol/l",
              tsat: 18.0,
              tsat_choice: "%",
              retc: -1.5,
              retc_choice: "%",
            },
            other: {
              vitd: 64.0,
              vitd_choice: "nmol/l",
              oh2d: 37,
              oh2d_choice: "pmol/l",
              fibr: 4.1,
              fibr_choice: "µmol/l",
              crp:-2.0,
              crp_choice: "mg/l",
              hcrp: 3.0,
              hcrp_choice: "nmol/l",
              sedr: 14.0,
              sedr_choice: "mm/hr",
              homo: 2.0,
              homo_choice: "µmol/l",
              insu: 11.9,
              insu_choice: "pmol/l",
              fruc: 178.0,
              fruc_choice: "umol/l",
              cpep: -2.1,
              cpep_choice: "nmol/ml",
              lep: 1.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
              rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 8.5,
              wbc_choice: "x 109/l",
              rbc: 5.5,
              rbc_choice: "x 1012/l",
              hgb: 155.0,
              hgb_choice: "g/l",
              hct: 45.0,
              hct_choice: "%",
              mcv: 90.9,
              mcv_choice: "fl",
              mch: 33.9,
              mch_choice: "pg",
              mchc: 20.84,
              mchc_choice: "mmol/l",
              rdw: 15,
              rdw_choice: "%",
              plt_count: 389.0,
              plt_count_choice: "10^9/l",
              mpv: 14.5,
              mpv_choice: "fl",
              neutrophils: 62.0,
              neutrophils_choice: "%",
              lymphocytes: 45.0,
              lymphocytes_choice: "%",
              monocytes: 8.0,
              monocytes_choice: "%",
              eosinophils: 4.0,
              eosinophils_choice: "%",
              basophils: 2.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 6,
              gluc_choice: "mmol/l",
              bun: 6.7,
              bun_choice: "mmol/l",
              crea: 98.2,
              crea_choice: "µmol/l",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 18.0,
              bcrt_choice: "",
              sod: 145.0,
              sod_choice: "mmol/l",
              pot: 5.0,
              pot_choice: "mmol/l",
              chl: 107.0,
              chl_choice: "mmol/l",
              co2: 32.0,
              co2_choice: "mmol/l",
              cal: 2.9,
              cal_choice: "mmol/l",
              tpro: 77.0,
              tpro_choice: "g/l",
              alb: 52.0,
              alb_choice: "g/l",
              glob: 30.0,
              glob_choice: "g/l",
              agrt:4.0,
              agrt_choice: "",
              tbil: 21.5,
              tbil_choice: "µmol/l",
              aphi: 109.0,
              aphi_choice: "u/l",
              ast: 22.0,
              ast_choice: "u/l",
              alt: 20.0,
              alt_choice: "u/l",
              a1c: 37.52,
              a1c_choice: "mmol/mol",
              eag: 6.9,
              eag_choice: "mmol/l",
              mag: 1.7,
              mag_choice: "mmol/l",
              phos: 1.9,
              phos_choice: "mmol/l",
              uacd: 329.1,
              uacd_choice: "µmol/l",
              ldh: 3.9,
              ldh_choice: "µkat/l",
              ggt: 25.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho:7.2,
              tcho_choice: "mmol/l",
              hdl: 3.2,
              hdl_choice: "mmol/l",
              trig: 2.1,
              trig_choice: "mmol/l",
              ldl: 4.1,
              ldl_choice: "mmol/l",
              chrt: 4.0,
              chrt_choice: "",
              vldl: 2.0,
              vldl_choice: "mmol/l",
              apa1: 10000.0,
              apa1_choice: "µmol/l",
              apb: 2.75,
              apb_choice: "mg/dl",
              bart: 1.63,
              bart_choice: "",
            },
            thyroid: {
              tsh: 3.0,
              tsh_choice: "mlu/l",
              ft4: 20.3,
              ft4_choice: "pmol/l",
              ft3: 6.0,
              ft3_choice: "pmol/l",
              tt4: 106.2,
              tt4_choice: "pmol/l",
              tt3: 3.0,
              tt3_choice: "pmol/l",
              ut3:38.0,
              ut3_choice: "%",
              rt3: 0.5,
              rt3_choice: "nmol/l",
              tgb: 501.0,
              tgb_choice: "nmol/l",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 24.3,
              iron_choice: "µmol/l",
              fert: 210.0,
              fert_choice: "ug/l",
              tibc: 352.8,
              tibc_choice: "µmol/l",
              tsat: 37.0,
              tsat_choice: "%",
              retc: 2.0,
              retc_choice: "%",
            },
            other: {
              vitd: 127.0,
              vitd_choice: "nmol/l",
              oh2d: 131,
              oh2d_choice: "pmol/l",
              fibr: 9.0,
              fibr_choice: "µmol/l",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 10.0,
              hcrp_choice: "nmol/l",
              sedr: 11.0,
              sedr_choice: "mm/hr",
              homo: 8.0,
              homo_choice: "µmol/l",
              insu: 56.6,
              insu_choice: "pmol/l",
              fruc: 272.0,
              fruc_choice: "umol/l",
              cpep: 0.9,
              cpep_choice: "nmol/ml",
              lep: 7.0,
              lep_choice: "µg/l",
            }
          },
        },
      },
      "59<": {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 3.5,
              wbc_choice: "x 109/l",
              rbc: 3.7,
              rbc_choice: "x 1012/l",
              hgb: 118.0,
              hgb_choice: "g/l",
              hct: 34.0,
              hct_choice: "%",
              mcv: 77.0,
              mcv_choice: "fl",
              mch: 24.6,
              mch_choice: "pg",
              mchc: 17.53,
              mchc_choice: "mmol/l",
              rdw: 7.0,
              rdw_choice: "%",
              plt_count: 153.0,
              plt_count_choice: "10^9/l",
              mpv: 5.5,
              mpv_choice: "fl",
              neutrophils: 33.0,
              neutrophils_choice: "%",
              lymphocytes: 12.0,
              lymphocytes_choice: "%",
              monocytes: 2.0,
              monocytes_choice: "%",
              eosinophils: -1.0,
              eosinophils_choice: "%",
              basophils: -2.0,
              basophils_choice: "%"
            },
            cmp: {
              gluc: 3.2,
              gluc_choice: "mmol/l",
              bun: 1.0,
              bun_choice: "mmol/l",
              crea: 52.0,
              crea_choice: "µmol/l",
              egfr: 59.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 5.0,
              bcrt_choice: "",
              sod: 130.0,
              sod_choice: "mmol/l",
              pot: 2.5,
              pot_choice: "mmol/l",
              chl: 95.0,
              chl_choice: "mmol/l",
              co2: 21.0,
              co2_choice: "mmol/l",
              cal: 1.1,
              cal_choice: "mmol/l",
              tpro: 56.0,
              tpro_choice: "g/l",
              alb: 34.0,
              alb_choice: "g/l",
              glob: 19.0,
              glob_choice: "g/l",
              agrt: 0.8,
              agrt_choice: "",
              tbil: 1.0,
              tbil_choice: "µmol/l",
              aphi: 23.0,
              aphi_choice: "u/l",
              ast:-1.2,
              ast_choice: "u/l",
              alt: -1.0,
              alt_choice: "u/l",
              a1c: 20.31,
              a1c_choice: "mmol/mol",
              eag: 2.94,
              eag_choice: "mmol/l",
              mag: 0.2,
              mag_choice: "mmol/l",
              phos: 0.4,
              phos_choice: "mmol/l",
              uacd: 128.9,
              uacd_choice: "µmol/l",
              ldh: 1.5,
              ldh_choice: "µkat/l",
              ggt: 0.9,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 2.0,
              tcho_choice: "mmol/l",
              hdl: 0.5,
              hdl_choice: "mmol/l",
              trig: -2.1,
              trig_choice: "mmol/l",
              ldl: -2.3,
              ldl_choice: "mmol/l",
              chrt: -2.4,
              chrt_choice: "",
              vldl: -2.0,
              vldl_choice: "mmol/l",
              apa1: 39.0,
              apa1_choice: "µmol/l",
              apb: -1.0,
              apb_choice: "mg/dl",
              bart: 0.68,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.25,
              tsh_choice: "mlu/l",
              ft4: 8.0,
              ft4_choice: "pmol/l",
              ft3: 2.5,
              ft3_choice: "pmol/l",
              tt4: 56.9,
              tt4_choice: "pmol/l",
              tt3: 1.0,
              tt3_choice: "pmol/l",
              ut3: 23.0,
              ut3_choice: "%",
              rt3: -3.0,
              rt3_choice: "nmol/l",
              tgb: 233.2,
              tgb_choice: "nmol/l",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron: 5.0,
              iron_choice: "µmol/l",
              fert: 9.0,
              fert_choice: "ug/l",
              tibc: 43.8,
              tibc_choice: "µmol/l",
              tsat: 15.0,
              tsat_choice: "%",
              retc: 0.2,
              retc_choice: "%",
            },
            other: {
              vitd: 73.9,
              vitd_choice: "nmol/l",
              oh2d: 44,
              oh2d_choice: "pmol/l",
              fibr: 4.0,
              fibr_choice: "µmol/l",
              crp: -1.1,
              crp_choice: "mg/l",
              hcrp: -2.3,
              hcrp_choice: "nmol/l",
              sedr: -2.1,
              sedr_choice: "nmol/l",
              homo: 4.0,
              homo_choice: "µmol/l",
              insu: 12.9,
              insu_choice: "pmol/l",
              fruc: 180.0,
              fruc_choice: "umol/l",
              cpep: 0.1,
              cpep_choice: "nmol/ml",
              lep: 2.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1973-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 13,
              wbc_choice: "x 109/l",
              rbc: 5.3,
              rbc_choice: "x 1012/l",
              hgb: 164.0,
              hgb_choice: "g/l",
              hct: 50.2,
              hct_choice: "%",
              mcv: 101.0,
              mcv_choice: "fl",
              mch: 35.0,
              mch_choice: "pg",
              mchc: 23.94,
              mchc_choice: "mmol/l",
              rdw: 17.4,
              rdw_choice: "%",
              plt_count: 389.0,
              plt_count_choice: "10^9/l",
              mpv: 14.5,
              mpv_choice: "fl",
              neutrophils: 76.0,
              neutrophils_choice: "%",
              lymphocytes: 48.0,
              lymphocytes_choice: "%",
              monocytes: 15.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 5.0,
              basophils_choice: "%"
            },
            cmp: {
              gluc: 7.4,
              gluc_choice: "mmol/l",
              bun: 9.9,
              bun_choice: "mmol/l",
              crea: 133.6,
              crea_choice: "µmol/l",
              egfr: 10000.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 21.0,
              bcrt_choice: "",
              sod: 148.0,
              sod_choice: "mmol/l",
              pot: 6.3,
              pot_choice: "mmol/l",
              chl: 111.0,
              chl_choice: "mmol/l",
              co2: 35.0,
              co2_choice: "mmol/l",
              cal: 3.7,
              cal_choice: "mmol/l",
              tpro: 87.0,
              tpro_choice: "g/l",
              alb: 49.0,
              alb_choice: "g/l",
              glob: 49.0,
              glob_choice: "g/l",
              agrt: 3.5,
              agrt_choice: "",
              tbil: 22.5,
              tbil_choice: "µmol/l",
              aphi: 121.0,
              aphi_choice: "u/l",
              ast: 42.0,
              ast_choice: "u/l",
              alt: 46.0,
              alt_choice: "u/l",
              a1c: 39.8,
              a1c_choice: "mmol/mol",
              eag: 7.49,
              eag_choice: "mmol/l",
              mag: 2.1,
              mag_choice: "mmol/l",
              phos: 2.5,
              phos_choice: "mmol/l",
              uacd: 490.0,
              uacd_choice: "µmol/l",
              ldh: 4.7,
              ldh_choice: "µkat/l",
              ggt: 74.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 6.2,
              tcho_choice: "mmol/l",
              hdl: 10000.9,
              hdl_choice: "mmol/l",
              trig: 2.7,
              trig_choice: "mmol/l",
              ldl: 2.9,
              ldl_choice: "mmol/l",
              chrt: 4.9,
              chrt_choice: "",
              vldl: 1.9,
              vldl_choice: "mmol/l",
              apa1: 75.9,
              apa1_choice: "µmol/l",
              apb: 2.75,
              apb_choice: "mg/dl",
              bart: 11111.0,
              bart_choice: "",
            },
            thyroid: {
              tsh: 5.5,
              tsh_choice: "mlu/l",
              ft4: 31.9,
              ft4_choice: "pmol/l",
              ft3: 8.4,
              ft3_choice: "pmol/l",
              tt4: 162.9,
              tt4_choice: "pmol/l",
              tt3: 3.9,
              tt3_choice: "pmol/l",
              ut3: 40.0,
              ut3_choice: "%",
              rt3: 0.2,
              rt3_choice: "nmol/l",
              tgb: 580.2,
              tgb_choice: "nmol/l",
              tpo: 'positive',
              tpo_choice: "",
              atgb: 'positive',
              atgb_choice: "",
              tgt: 'positive',
              tgt_choice: "",
            },
            iron: {
              iron: 32.4,
              iron_choice: "µmol/l",
              fert: 123.0,
              fert_choice: "ug/l",
              tibc: 64.7,
              tibc_choice: "µmol/l",
              tsat: 63.0,
              tsat_choice: "%",
              retc: 2.0,
              retc_choice: "%",
            },
            other: {
              vitd: 250.6,
              vitd_choice: "nmol/l",
              oh2d: 133,
              oh2d_choice: "pmol/l",
              fibr: 12.8,
              fibr_choice: "µmol/l",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.8,
              hcrp_choice: "nmol/l",
              sedr: 21.0,
              sedr_choice: "nmol/l",
              homo: 15.0,
              homo_choice: "µmol/l",
              insu: 141.1,
              insu_choice: "pmol/l",
              fruc: 275.0,
              fruc_choice: "umol/l",
              cpep: 1.6,
              cpep_choice: "nmol/ml",
              lep: 11.0,
              lep_choice: "µg/l",
            }
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1974-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 4.4,
              wbc_choice: "x 109/l",
              rbc: 3.0,
              rbc_choice: "x 1012/l",
              hgb: 125.0,
              hgb_choice: "g/l",
              hct: 34.0,
              hct_choice: "%",
              mcv: 80.0,
              mcv_choice: "fl",
              mch: 26.0,
              mch_choice: "pg",
              mchc: 18.84,
              mchc_choice: "mmol/l",
              rdw: 8,
              rdw_choice: "%",
              plt_count: 151.0,
              plt_count_choice: "10^9/l",
              mpv: 7.0,
              mpv_choice: "fl",
              neutrophils: 38.0,
              neutrophils_choice: "%",
              lymphocytes: 23.0,
              lymphocytes_choice: "%",
              monocytes: -1.2,
              monocytes_choice: "%",
              eosinophils: -1.1,
              eosinophils_choice: "%",
              basophils: -3.2,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 3.0,
              gluc_choice: "mmol/l",
              bun: 3.0,
              bun_choice: "mmol/l",
              crea: 70.0,
              crea_choice: "µmol/l",
              egfr: 54.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 9.0,
              bcrt_choice: "",
              sod: 125.0,
              sod_choice: "mmol/l",
              pot: 2.0,
              pot_choice: "mmol/l",
              chl: 95.0,
              chl_choice: "mmol/l",
              co2: 20.0,
              co2_choice: "mmol/l",
              cal: 1.3,
              cal_choice: "mmol/l",
              tpro: 65.0,
              tpro_choice: "g/l",
              alb: 43.0,
              alb_choice: "g/l",
              glob: 22.0,
              glob_choice: "g/l",
              agrt: 1.0,
              agrt_choice: "",
              tbil: 8.0,
              tbil_choice: "µmol/l",
              aphi: 35.0,
              aphi_choice: "u/l",
              ast: 8.0,
              ast_choice: "u/l",
              alt: 5.0,
              alt_choice: "u/l",
              a1c: 19.31,
              a1c_choice: "mmol/mol",
              eag: 2.49,
              eag_choice: "mmol/l",
              mag: 0.1,
              mag_choice: "mmol/l",
              phos: 0.7,
              phos_choice: "mmol/l",
              uacd: 175.4,
              uacd_choice: "µmol/l",
              ldh: 2.0,
              ldh_choice: "µkat/l",
              ggt: 9.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 2.1,
              tcho_choice: "mmol/l",
              hdl: 1.0,
              hdl_choice: "mmol/l",
              trig: 0.1,
              trig_choice: "mmol/l",
              ldl: 0.3,
              ldl_choice: "mmol/l",
              chrt: -5.4,
              chrt_choice: "",
              vldl: -3.2,
              vldl_choice: "mmol/l",
              apa1: 34.0,
              apa1_choice: "µmol/l",
              apb: -13.0,
              apb_choice: "mg/dl",
              bart: -3.6,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.4,
              tsh_choice: "mlu/l",
              ft4: 11.9,
              ft4_choice: "pmol/l",
              ft3: 3.6,
              ft3_choice: "pmol/l",
              tt4: 94.5,
              tt4_choice: "pmol/l",
              tt3: 0.4,
              tt3_choice: "pmol/l",
              ut3: 17.0,
              ut3_choice: "%",
              rt3: -2.0,
              rt3_choice: "nmol/l",
              tgb: 323.0,
              tgb_choice: "nmol/l",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 13.2,
              iron_choice: "µmol/l",
              fert: 45.0,
              fert_choice: "ug/l",
              tibc: 42.8,
              tibc_choice: "µmol/l",
              tsat: 15.0,
              tsat_choice: "%",
              retc: -5.0,
              retc_choice: "%",
            },
            other: {
              vitd: 71,
              vitd_choice: "nmol/l",
              oh2d: 47,
              oh2d_choice: "pmol/l",
              fibr: 4.9,
              fibr_choice: "µmol/l",
              crp: -1.4,
              crp_choice: "mg/l",
              hcrp: -2.1,
              hcrp_choice: "nmol/l",
              sedr: -3.1,
              sedr_choice: "nmol/l",
              homo: 3.0,
              homo_choice: "µmol/l",
              insu: 11.9,
              insu_choice: "pmol/l",
              fruc: 180.0,
              fruc_choice: "umol/l",
              cpep: 0.2,
              cpep_choice: "nmol/ml",
              lep: 2.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1975-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 8.5,
              wbc_choice: "x 109/l",
              rbc: 5.5,
              rbc_choice: "x 1012/l",
              hgb: 155.0,
              hgb_choice: "g/l",
              hct: 45.0,
              hct_choice: "%",
              mcv: 90.9,
              mcv_choice: "fl",
              mch: 33.9,
              mch_choice: "pg",
              mchc: 20.84,
              mchc_choice: "mmol/l",
              rdw: 15,
              rdw_choice: "%",
              plt_count: 395.0,
              plt_count_choice: "10^9/l",
              mpv: 15.5,
              mpv_choice: "fl",
              neutrophils: 65.0,
              neutrophils_choice: "%",
              lymphocytes: 45.0,
              lymphocytes_choice: "%",
              monocytes: 8.8,
              monocytes_choice: "%",
              eosinophils: 4.0,
              eosinophils_choice: "%",
              basophils: 9.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 7,
              gluc_choice: "mmol/l",
              bun: 7.7,
              bun_choice: "mmol/l",
              crea: 99.7,
              crea_choice: "µmol/l",
              egfr: 9999.7,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 27.0,
              bcrt_choice: "",
              sod: 172.0,
              sod_choice: "mmol/l",
              pot: 7.5,
              pot_choice: "mmol/l",
              chl: 107.7,
              chl_choice: "mmol/l",
              co2: 37.0,
              co2_choice: "mmol/l",
              cal: 2.7,
              cal_choice: "mmol/l",
              tpro: 77.0,
              tpro_choice: "g/l",
              alb: 57.0,
              alb_choice: "g/l",
              glob: 37.0,
              glob_choice: "g/l",
              agrt: 2.7,
              agrt_choice: "",
              tbil: 27.5,
              tbil_choice: "µmol/l",
              aphi: 107.0,
              aphi_choice: "u/l",
              ast: 27.0,
              ast_choice: "u/l",
              alt: 27.0,
              alt_choice: "u/l",
              a1c: 37.52,
              a1c_choice: "mmol/mol",
              eag: 7.01,
              eag_choice: "mmol/l",
              mag: 7.0,
              mag_choice: "mmol/l",
              phos: 7.3,
              phos_choice: "mmol/l",
              uacd: 327.7,
              uacd_choice: "µmol/l",
              ldh: 3.7,
              ldh_choice: "µkat/l",
              ggt: 27.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 7.2,
              tcho_choice: "mmol/l",
              hdl: 7.2,
              hdl_choice: "mmol/l",
              trig: 7.1,
              trig_choice: "mmol/l",
              ldl: 7.1,
              ldl_choice: "mmol/l",
              chrt: 7.0,
              chrt_choice: "",
              vldl: 1.7,
              vldl_choice: "mmol/l",
              apa1: 9999.8,
              apa1_choice: "µmol/l",
              apb: 1.77,
              apb_choice: "mg/dl",
              bart: 0.73,
              bart_choice: "",
            },
            thyroid: {
              tsh: 2.7,
              tsh_choice: "mlu/l",
              ft4: 19.7,
              ft4_choice: "pmol/l",
              ft3: 7.0,
              ft3_choice: "pmol/l",
              tt4: 107.2,
              tt4_choice: "pmol/l",
              tt3: 2.7,
              tt3_choice: "pmol/l",
              ut3: 37.7,
              ut3_choice: "%",
              rt3: 0.7,
              rt3_choice: "nmol/l",
              tgb: 507.0,
              tgb_choice: "nmol/l",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 27.3,
              iron_choice: "µmol/l",
              fert: 127.0,
              fert_choice: "ug/l",
              tibc: 357.0,
              tibc_choice: "µmol/l",
              tsat: 37.0,
              tsat_choice: "%",
              retc: 7.0,
              retc_choice: "%",
            },
            other: {
              vitd: 127.0,
              vitd_choice: "nmol/l",
              oh2d: 137,
              oh2d_choice: "pmol/l",
              fibr: 9.78,
              fibr_choice: "µmol/l",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.9,
              hcrp_choice: "nmol/l",
              sedr: 10.9,
              sedr_choice: "nmol/l",
              homo: 9.0,
              homo_choice: "µmol/l",
              insu: 59.6,
              insu_choice: "pmol/l",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 0.9,
              cpep_choice: "nmol/ml",
              lep: 6.9,
              lep_choice: "µg/l",
            }
          },
        },
      },
    },
  },
  FNC: {
    US: {
      "60>": {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 2.7,
              wbc_choice: "",
              rbc: 2.9,
              rbc_choice: "million/ul",
              hgb: 2.0,
              hgb_choice: "g/dl",
              hct: 6.0,
              hct_choice: "%",
              mcv: 72.0,
              mcv_choice: "μ^3",
              mch: 20.6,
              mch_choice: "pg",
              mchc: 30.5,
              mchc_choice: "g/dl",
              rdw: 8.0,
              rdw_choice: "%",
              plt_count: 152.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 2.5,
              mpv_choice: "",
              neutrophils: 25.0,
              neutrophils_choice: "%",
              lymphocytes: 12.0,
              lymphocytes_choice: "%",
              monocytes: 2.0,
              monocytes_choice: "%",
              eosinophils: -2.0,
              eosinophils_choice: "%",
              basophils: -2.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 62,
              gluc_choice: "mg/dl",
              bun: 2.0,
              bun_choice: "mg/dl",
              crea: 0.2,
              crea_choice: "mg/dl",
              egfr: 52.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 2.0,
              bcrt_choice: "",
              sod: 125.0,
              sod_choice: "meq/l",
              pot: 2.5,
              pot_choice: "meq/l",
              chl: 92.0,
              chl_choice: "meq/l",
              co2: 22.0,
              co2_choice: "meq/l",
              cal: 6.5,
              cal_choice: "meq/l",
              tpro: 2.0,
              tpro_choice: "g/dl",
              alb: 3.2,
              alb_choice: "g/dl",
              glob: 1.0,
              glob_choice: "g/dl",
              agrt: 0.1,
              agrt_choice: "",
              tbil: -2.1,
              tbil_choice: "mg/dl",
              aphi: 22.0,
              aphi_choice: "u/l",
              ast: -2.1,
              ast_choice: "u/l",
              alt: -2.2,
              alt_choice: "u/l",
              a1c: 2.1,
              a1c_choice: "%",
              eag: 62.0,
              eag_choice: "mg/dl",
              mag: -1.5,
              mag_choice: "mg/dl",
              phos: -2.5,
              phos_choice: "mg/dl",
              uacd: -2.2,
              uacd_choice: "mg/dl",
              ldh: 99.0,
              ldh_choice: "u/l",
              ggt: -2.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 90.0,
              tcho_choice: "mg/dl",
              hdl: 29.0,
              hdl_choice: "mg/dl",
              trig: -2.0,
              trig_choice: "mg/dl",
              ldl: -2.0,
              ldl_choice: "mg/dl",
              chrt: -3.0,
              chrt_choice: "",
              vldl: 2.0,
              vldl_choice: "mg/dl",
              apa1: 110.0,
              apa1_choice: "mg/dl",
              apb: -2.0,
              apb_choice: "mg/dl",
              bart: 0.68,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.25,
              tsh_choice: "mlu/l",
              ft4: -1.5,
              ft4_choice: "ng/dl",
              ft3: 2.0,
              ft3_choice: "pg/dl",
              tt4: 4.1,
              tt4_choice: "ng/dl",
              tt3: 75.0,
              tt3_choice: "ng/dl%",
              ut3: 22.0,
              ut3_choice: "ng/dl",
              rt3: 9.0,
              rt3_choice: "ng/dl",
              tgb: 12.0,
              tgb_choice: "ug/ml",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron:20.0,
              iron_choice: "µg/dl",
              fert: 30.0,
              fert_choice: "ng/ml",
              tibc: 220.0,
              tibc_choice: "µg/dl",
              tsat: 12.0,
              tsat_choice: "%",
              retc: 0.2,
              retc_choice: "%",
            },
            other: {
              vitd: 20.0,
              vitd_choice: "ng/ml",
              oh2d: 19.2,
              oh2d_choice: "pg/ml",
              fibr: 120.0,
              fibr_choice: "mg/dl",
              crp: -2.0,
              crp_choice: "mg/l",
              hcrp: -3.0,
              hcrp_choice: "mg/l",
              sedr: -3.0,
              sedr_choice: "nmol/l",
              homo: -2.5,
              homo_choice: "umol/l",
              insu: 0.5,
              insu_choice: "μu/ml",
              fruc: 180.0,
              fruc_choice: "umol/l",
              cpep: 0.4,
              cpep_choice: "ng/ml",
              lep: 2.0,
              lep_choice: "ng/dl",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 13.0,
              wbc_choice: "",
              rbc: 6.1,
              rbc_choice: "million/ul",
              hgb: 17.0,
              hgb_choice: "g/dl",
              hct: 49.2,
              hct_choice: "%",
              mcv: 99.0,
              mcv_choice: "μ^3",
              mch: 34.0,
              mch_choice: "pg",
              mchc: 39.0,
              mchc_choice: "g/dl",
              rdw: 16.4,
              rdw_choice: "%",
              plt_count: 386.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 13.5,
              mpv_choice: "",
              neutrophils: 75.0,
              neutrophils_choice: "%",
              lymphocytes: 47.0,
              lymphocytes_choice: "%",
              monocytes: 14.0,
              monocytes_choice: "%",
              eosinophils: 8.0,
              eosinophils_choice: "%",
              basophils: 5.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 116.0,
              gluc_choice: "mg/dl",
              bun: 28,
              bun_choice: "mg/dl",
              crea: 2.5,
              crea_choice: "mg/dl",
              egfr: 9999.5,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 25.0,
              bcrt_choice: "",
              sod: 155.0,
              sod_choice: "meq/l",
              pot: 7.3,
              pot_choice: "meq/l",
              chl: 110,
              chl_choice: "meq/l",
              co2: 35.0,
              co2_choice: "meq/l",
              cal: 12.8,
              cal_choice: "meq/l",
              tpro: 9.5,
              tpro_choice: "g/dl",
              alb: 6.8,
              alb_choice: "g/dl",
              glob: 5.9,
              glob_choice: "g/dl",
              agrt: 6.5,
              agrt_choice: "",
              tbil: 4.2,
              tbil_choice: "mg/dl",
              aphi: 123.0,
              aphi_choice: "u/l",
              ast: 47.0,
              ast_choice: "u/l",
              alt: 48.0,
              alt_choice: "u/l",
              a1c: 6.7,
              a1c_choice: "%",
              eag: 119.0,
              eag_choice: "mg/dl",
              mag: 4.6,
              mag_choice: "mg/dl",
              phos:7.5,
              phos_choice: "mg/dl",
              uacd: 9.7,
              uacd_choice: "mg/dl",
              ldh: 229.0,
              ldh_choice: "u/l",
              ggt: 79.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 202.0,
              tcho_choice: "mg/dl",
              hdl: 9999.9,
              hdl_choice: "mg/dl",
              trig: 150.0,
              trig_choice: "mg/dl",
              ldl: 99.9,
              ldl_choice: "mg/dl",
              chrt: 5.4,
              chrt_choice: "",
              vldl: 45.0,
              vldl_choice: "mg/dl",
              apa1: 219.0,
              apa1_choice: "mg/dl",
              apb: 100.0,
              apb_choice: "mg/dl",
              bart: 10000.0,
              bart_choice: "",
            },
            thyroid: {
              tsh: 6.5,
              tsh_choice: "mlu/l",
              ft4: 4.4,
              ft4_choice: "ng/dl",
              ft3: 6.8,
              ft3_choice: "pg/dl",
              tt4: 14.5,
              tt4_choice: "ng/dl",
              tt3: 232.0,
              tt3_choice: "ng/dl%",
              ut3: 49.0,
              ut3_choice: "ng/dl",
              rt3: 29.1,
              rt3_choice: "ng/dl",
              tgb: 35.9,
              tgb_choice: "ug/ml",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 180.0,
              iron_choice: "µg/dl",
              fert: 239.0,
              fert_choice: "ng/ml",
              tibc: 353.0,
              tibc_choice: "µg/dl",
              tsat: 63.0,
              tsat_choice: "%",
              retc: 6.0,
              retc_choice: "%",
            },
            other: {
              vitd: 109.0,
              vitd_choice: "ng/ml",
              oh2d: 57.3,
              oh2d_choice: "pg/ml",
              fibr: 404.0,
              fibr_choice: "mg/dl",
              crp: 10.0,
              crp_choice: "mg/l",
              hcrp: 3.0,
              hcrp_choice: "mg/l",
              sedr: 23.0,
              sedr_choice: "nmol/l",
              homo: 14.4,
              homo_choice: "umol/l",
              insu: 20.6,
              insu_choice: "μu/ml",
              fruc: 272.0,
              fruc_choice: "umol/l",
              cpep: 4.85,
              cpep_choice: "ng/ml",
              lep: 15.0,
              lep_choice: "ng/dl",
            },
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 3.0,
              wbc_choice: "",
              rbc: 3.0,
              rbc_choice: "million/ul",
              hgb: 10.5,
              hgb_choice: "g/dl",
              hct: 35.0,
              hct_choice: "%",
              mcv: 80.0,
              mcv_choice: "μ^3",
              mch: 18.0,
              mch_choice: "pg",
              mchc: 30.0,
              mchc_choice: "g/dl",
              rdw: 8.0,
              rdw_choice: "%",
              plt_count: 153.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 5.5,
              mpv_choice: "",
              neutrophils: 34.0,
              neutrophils_choice: "%",
              lymphocytes: 20.0,
              lymphocytes_choice: "%",
              monocytes: -2.5,
              monocytes_choice: "%",
              eosinophils: -3.2,
              eosinophils_choice: "%",
              basophils: -6.4,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 67.0,
              gluc_choice: "mg/dl",
              bun: 7.0,
              bun_choice: "mg/dl",
              crea: 0.4,
              crea_choice: "mg/dl",
              egfr: 55.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 8.0,
              bcrt_choice: "",
              sod: 125.0,
              sod_choice: "meq/l",
              pot: 2.0,
              pot_choice: "meq/l",
              chl: 90.0,
              chl_choice: "meq/l",
              co2: 20.0,
              co2_choice: "meq/l",
              cal: 6.2,
              cal_choice: "meq/l",
              tpro: 4.9,
              tpro_choice: "g/dl",
              alb: 2.6,
              alb_choice: "g/dl",
              glob: 1.4,
              glob_choice: "g/dl",
              agrt: 0.5,
              agrt_choice: "",
              tbil: -1.5,
              tbil_choice: "mg/dl",
              aphi: 30.0,
              aphi_choice: "u/l",
              ast: 8.0,
              ast_choice: "u/l",
              alt: 8.0,
              alt_choice: "u/l",
              a1c: 2.1,
              a1c_choice: "%",
              eag: 60.0,
              eag_choice: "mg/dl",
              mag: 1.0,
              mag_choice: "mg/dl",
              phos:2.5,
              phos_choice: "mg/dl",
              uacd: 2.0,
              uacd_choice: "mg/dl",
              ldh: 130.0,
              ldh_choice: "u/l",
              ggt: 8.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 190.0,
              tcho_choice: "mg/dl",
              hdl: 55.0,
              hdl_choice: "mg/dl",
              trig: 45.0,
              trig_choice: "mg/dl",
              ldl: 100.0,
              ldl_choice: "mg/dl",
              chrt: -1.1,
              chrt_choice: "",
              vldl: 3.0,
              vldl_choice: "mg/dl",
              apa1: 138.0,
              apa1_choice: "mg/dl",
              apb: -2.5,
              apb_choice: "mg/dl",
              bart: -3.4,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.3,
              tsh_choice: "mlu/l",
              ft4: 0.2,
              ft4_choice: "ng/dl",
              ft3: 2.0,
              ft3_choice: "pg/dl",
              tt4: 7.0,
              tt4_choice: "ng/dl",
              tt3: 88.0,
              tt3_choice: "ng/dl%",
              ut3: 25.0,
              ut3_choice: "ng/dl",
              rt3: 12.9,
              rt3_choice: "ng/dl",
              tgb: 15.0,
              tgb_choice: "ug/ml",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 83.0,
              iron_choice: "µg/dl",
              fert: 49.0,
              fert_choice: "ng/ml",
              tibc: 245.0,
              tibc_choice: "µg/dl",
              tsat: 19.0,
              tsat_choice: "%",
              retc: -1.2,
              retc_choice: "%",
            },
            other: {
              vitd: 29.0,
              vitd_choice: "ng/ml",
              oh2d: 19.0,
              oh2d_choice: "pg/ml",
              fibr: 190.0,
              fibr_choice: "mg/dl",
              crp: -3.4,
              crp_choice: "mg/l",
              hcrp: -2.6,
              hcrp_choice: "mg/l",
              sedr: -1.1,
              sedr_choice: "nmol/l",
              homo: 3.0,
              homo_choice: "umol/l",
              insu: 1.0,
              insu_choice: "μu/ml",
              fruc: 188.0,
              fruc_choice: "umol/l",
              cpep: -1.1,
              cpep_choice: "ng/ml",
              lep: 3.0,
              lep_choice: "ng/dl",
            },
          },
          HIGH: { 
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 8.5,
              wbc_choice: "",
              rbc: 5.5,
              rbc_choice: "million/ul",
              hgb: 15.5,
              hgb_choice: "g/dl",
              hct: 45.0,
              hct_choice: "%",
              mcv: 90.9,
              mcv_choice: "μ^3",
              mch: 33.9,
              mch_choice: "pg",
              mchc: 37.0,
              mchc_choice: "g/dl",
              rdw: 15.0,
              rdw_choice: "%",
              plt_count: 395.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 14.5,
              mpv_choice: "",
              neutrophils: 64.0,
              neutrophils_choice: "%",
              lymphocytes: 47.0,
              lymphocytes_choice: "%",
              monocytes: 10.0,
              monocytes_choice: "%",
              eosinophils: 5.0,
              eosinophils_choice: "%",
              basophils: 2.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 95.0,
              gluc_choice: "mg/dl",
              bun: 19.0,
              bun_choice: "mg/dl",
              crea: 1.9,
              crea_choice: "mg/dl",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 19.0,
              bcrt_choice: "",
              sod: 144.0,
              sod_choice: "meq/l",
              pot: 5.4,
              pot_choice: "meq/l",
              chl: 109.0,
              chl_choice: "meq/l",
              co2: 39.0,
              co2_choice: "meq/l",
              cal: 19.0,
              cal_choice: "meq/l",
              tpro: 8.4,
              tpro_choice: "g/dl",
              alb: 5.9,
              alb_choice: "g/dl",
              glob: 3.8,
              glob_choice: "g/dl",
              agrt: 2.9,
              agrt_choice: "",
              tbil: 1.9,
              tbil_choice: "mg/dl",
              aphi: 109.0,
              aphi_choice: "u/l",
              ast: 29.0,
              ast_choice: "u/l",
              alt:29.0,
              alt_choice: "u/l",
              a1c: 7.4,
              a1c_choice: "%",
              eag: 109.0,
              eag_choice: "mg/dl",
              mag: 4.5,
              mag_choice: "mg/dl",
              phos:8.0,
              phos_choice: "mg/dl",
              uacd: 7.5,
              uacd_choice: "mg/dl",
              ldh: 209.0,
              ldh_choice: "u/l",
              ggt: 26.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 309.0,
              tcho_choice: "mg/dl",
              hdl: 89.0,
              hdl_choice: "mg/dl",
              trig: 59.0,
              trig_choice: "mg/dl",
              ldl: 179.0,
              ldl_choice: "mg/dl",
              chrt: 3.9,
              chrt_choice: "",
              vldl: 5.9,
              vldl_choice: "mg/dl",
              apa1: 9999.9,
              apa1_choice: "mg/dl",
              apb: 99.0,
              apb_choice: "mg/dl",
              bart: 0.93,
              bart_choice: "",
            },
            thyroid: {
              tsh: 4.0,
              tsh_choice: "mlu/l",
              ft4: 3.5,
              ft4_choice: "ng/dl",
              ft3: 4.25,
              ft3_choice: "pg/dl",
              tt4: 9.1,
              tt4_choice: "ng/dl",
              tt3: 170.0,
              tt3_choice: "ng/dl%",
              ut3: 39.0,
              ut3_choice: "ng/dl",
              rt3: 27.7,
              rt3_choice: "ng/dl",
              tgb: 29.0,
              tgb_choice: "ug/ml",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 132.0,
              iron_choice: "µg/dl",
              fert: 202.0,
              fert_choice: "ng/ml",
              tibc: 352.0,
              tibc_choice: "µg/dl",
              tsat: 39.0,
              tsat_choice: "%",
              retc: 4.0,
              retc_choice: "%",
            },
            other: {
              vitd: 52.0,
              vitd_choice: "ng/ml",
              oh2d: 56.0,
              oh2d_choice: "pg/ml",
              fibr: 307.0,
              fibr_choice: "mg/dl",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 4.0,
              hcrp_choice: "mg/l",
              sedr: 12.0,
              sedr_choice: "nmol/l",
              homo: 8.0,
              homo_choice: "umol/l",
              insu: 9.0,
              insu_choice: "μu/ml",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 5.1,
              cpep_choice: "ng/ml",
              lep: 8.0,
              lep_choice: "ng/dl",
            }
          },
        },
      },
      "59<": {
        CONV: {
          LOW: {
            rws_input: {
            ...client_info,
            sex: "female-noncycling",
            date_of_birth: "1960-02-23T16:04:50Z",
            units_us_or_intl: "us",
          },
          cbc: {
            wbc: 1.7,
            wbc_choice: "",
            rbc: 1.9,
            rbc_choice: "million/ul",
            hgb: 11.0,
            hgb_choice: "g/dl",
            hct: 16.0,
            hct_choice: "%",
            mcv: 71.0,
            mcv_choice: "μ^3",
            mch: 21.6,
            mch_choice: "pg",
            mchc: 11.5,
            mchc_choice: "g/dl",
            rdw: 7.1,
            rdw_choice: "%",
            plt_count: 151.0,
            plt_count_choice: "mm^3, x 1000",
            mpv: 5.1,
            mpv_choice: "",
            neutrophils: 31.0,
            neutrophils_choice: "%",
            lymphocytes: 11.0,
            lymphocytes_choice: "%",
            monocytes: 1.0,
            monocytes_choice: "%",
            eosinophils: -1.9,
            eosinophils_choice: "%",
            basophils: -1.8,
            basophils_choice: "%",
          },
          cmp: {
            gluc: 61.0,
            gluc_choice: "mg/dl",
            bun: 1.0,
            bun_choice: "mg/dl",
            crea: 0.1,
            crea_choice: "mg/dl",
            egfr: 40.1,
            egfr_choice: "ml/min/1.73^2",
            bcrt: 1.0,
            bcrt_choice: "",
            sod: 131.0,
            sod_choice: "meq/l",
            pot: 1.5,
            pot_choice: "meq/l",
            chl: 91.0,
            chl_choice: "meq/l",
            co2: 21.0,
            co2_choice: "meq/l",
            cal: 8.1,
            cal_choice: "meq/l",
            tpro: 1.0,
            tpro_choice: "g/dl",
            alb: 1.6,
            alb_choice: "g/dl",
            glob: 1.0,
            glob_choice: "g/dl",
            agrt: 0.1,
            agrt_choice: "",
            tbil: 1.0,
            tbil_choice: "mg/dl",
            aphi: 15.0,
            aphi_choice: "u/l",
            ast: -1.0,
            ast_choice: "u/l",
            alt: -2.0,
            alt_choice: "u/l",
            a1c: 1.1,
            a1c_choice: "%",
            eag: 63.0,
            eag_choice: "mg/dl",
            mag: 0.5,
            mag_choice: "mg/dl",
            phos: 1.5,
            phos_choice: "mg/dl",
            uacd: 1.2,
            uacd_choice: "mg/dl",
            ldh: 95.0,
            ldh_choice: "u/l",
            ggt: 0.0,
            ggt_choice: "u/l",
          },
          cholestrol: {
            tcho: 91.0,
            tcho_choice: "mg/dl",
            hdl: 31.0,
            hdl_choice: "mg/dl",
            trig: -1.0,
            trig_choice: "mg/dl",
            ldl: -2.0,
            ldl_choice: "mg/dl",
            chrt: -3.0,
            chrt_choice: "",
            vldl: 1.0,
            vldl_choice: "mg/dl",
            apa1: 111.0,
            apa1_choice: "mg/dl",
            apb: -1.0,
            apb_choice: "mg/dl",
            bart: 0.18,
            bart_choice: "",
          },
          thyroid: {
            tsh: 0.15,
            tsh_choice: "mlu/l",
            ft4: 0.1,
            ft4_choice: "ng/dl",
            ft3: 1.3,
            ft3_choice: "pg/dl",
            tt4: 1.5,
            tt4_choice: "ng/dl",
            tt3: 71.0,
            tt3_choice: "ng/dl%",
            ut3: 21.0,
            ut3_choice: "ng/dl",
            rt3: 1.2,
            rt3_choice: "ng/dl",
            tgb: 11.7,
            tgb_choice: "ug/ml",
            tpo: 'negative',
            tpo_choice: "",
            atgb: 'negative',
            atgb_choice: "",
            tgt: 'negative',
            tgt_choice: "",
          },
          iron: {
            iron: 20,
            iron_choice: "µg/dl",
            fert: 31.0,
            fert_choice: "ng/ml",
            tibc: 241.0,
            tibc_choice: "µg/dl",
            tsat: 11.0,
            tsat_choice: "%",
            retc: -2.0,
            retc_choice: "%",
          },
          other: {
            vitd: 28.0,
            vitd_choice: "ng/ml",
            oh2d: 11.6,
            oh2d_choice: "pg/ml",
            fibr: 141.0,
            fibr_choice: "mg/dl",
            crp: -2.1,
            crp_choice: "mg/l",
            hcrp: -3.1,
            hcrp_choice: "mg/l",
            sedr: -1.1,
            sedr_choice: "nmol/l",
            homo: -2.1,
            homo_choice: "umol/l",
            insu: 1.0,
            insu_choice: "μu/ml",
            fruc: 110.0,
            fruc_choice: "umol/l",
            cpep: 0.1,
            cpep_choice: "ng/ml",
            lep: 2.0,
            lep_choice: "ng/dl",
          },},
          HIGH: { 
            rws_input: {
            ...client_info,
            sex: "female-noncycling",
            date_of_birth: "1960-02-23T16:04:50Z",
            units_us_or_intl: "us",
          },
          cbc: {
            wbc: 13.0,
            wbc_choice: "",
            rbc: 5.3,
            rbc_choice: "million/ul",
            hgb: 16.3,
            hgb_choice: "g/dl",
            hct: 48.3,
            hct_choice: "%",
            mcv: 97.3,
            mcv_choice: "μ^3",
            mch: 33.3,
            mch_choice: "pg",
            mchc: 37.3,
            mchc_choice: "g/dl",
            rdw: 35.4,
            rdw_choice: "%",
            plt_count: 385.3,
            plt_count_choice: "mm^3, x 1000",
            mpv: 13.5,
            mpv_choice: "",
            neutrophils: 83.0,
            neutrophils_choice: "%",
            lymphocytes: 53.0,
            lymphocytes_choice: "%",
            monocytes: 13.3,
            monocytes_choice: "%",
            eosinophils: 7.7,
            eosinophils_choice: "%",
            basophils: 3.7,
            basophils_choice: "%",
          },
          cmp: {
            gluc: 120.0,
            gluc_choice: "mg/dl",
            bun: 30,
            bun_choice: "mg/dl",
            crea: 3.5,
            crea_choice: "mg/dl",
            egfr: 10003.0,
            egfr_choice: "ml/min/1.73^2",
            bcrt: 30.0,
            bcrt_choice: "",
            sod: 200.0,
            sod_choice: "meq/l",
            pot: 7.3,
            pot_choice: "meq/l",
            chl: 119,
            chl_choice: "meq/l",
            co2: 37.0,
            co2_choice: "meq/l",
            cal: 13.8,
            cal_choice: "meq/l",
            tpro: 11.5,
            tpro_choice: "g/dl",
            alb: 6.8,
            alb_choice: "g/dl",
            glob: 4.9,
            glob_choice: "g/dl",
            agrt: 4.5,
            agrt_choice: "",
            tbil: 3.2,
            tbil_choice: "mg/dl",
            aphi: 123.0,
            aphi_choice: "u/l",
            ast: 43.0,
            ast_choice: "u/l",
            alt: 49.0,
            alt_choice: "u/l",
            a1c: 7.7,
            a1c_choice: "%",
            eag: 119,
            eag_choice: "mg/dl",
            mag: 2.9,
            mag_choice: "mg/dl",
            phos:5.5,
            phos_choice: "mg/dl",
            uacd: 9.7,
            uacd_choice: "mg/dl",
            ldh: 227.0,
            ldh_choice: "u/l",
            ggt: 72.0,
            ggt_choice: "u/l",
          },
          cholestrol: {
            tcho: 204.0,
            tcho_choice: "mg/dl",
            hdl: 10005.0,
            hdl_choice: "mg/dl",
            trig: 152.0,
            trig_choice: "mg/dl",
            ldl: 111.0,
            ldl_choice: "mg/dl",
            chrt: 6.4,
            chrt_choice: "",
            vldl: 45.0,
            vldl_choice: "mg/dl",
            apa1: 210.0,
            apa1_choice: "mg/dl",
            apb: 100.0,
            apb_choice: "mg/dl",
            bart: 10004.0,
            bart_choice: "",
          },
          thyroid: {
            tsh: 6.5,
            tsh_choice: "mlu/l",
            ft4: 6.4,
            ft4_choice: "ng/dl",
            ft3: 6.8,
            ft3_choice: "pg/dl",
            tt4: 15.5,
            tt4_choice: "ng/dl",
            tt3: 235.0,
            tt3_choice: "ng/dl%",
            ut3: 42.0,
            ut3_choice: "ng/dl",
            rt3: 30.1,
            rt3_choice: "ng/dl",
            tgb: 32.9,
            tgb_choice: "ug/ml",
            tpo: 'positive',
            tpo_choice: "",
            atgb: 'positive',
            atgb_choice: "",
            tgt: 'positive',
            tgt_choice: "",
          },
          iron: {
            iron: 177.0,
            iron_choice: "µg/dl",
            fert: 239.0,
            fert_choice: "ng/ml",
            tibc: 353.0,
            tibc_choice: "µg/dl",
            tsat: 67.0,
            tsat_choice: "%",
            retc: 4.0,
            retc_choice: "%",
          },
          other: {
            vitd: 110.0,
            vitd_choice: "ng/ml",
            oh2d: 56.3,
            oh2d_choice: "pg/ml",
            fibr: 410.0,
            fibr_choice: "mg/dl",
            crp: 10.0,
            crp_choice: "mg/l",
            hcrp: 3.0,
            hcrp_choice: "mg/l",
            sedr: 23.0,
            sedr_choice: "nmol/l",
            homo: 15.4,
            homo_choice: "umol/l",
            insu: 24.6,
            insu_choice: "μu/ml",
            fruc: 275.0,
            fruc_choice: "umol/l",
            cpep: 5.85,
            cpep_choice: "ng/ml",
            lep: 15.0,
            lep_choice: "ng/dl",
          },},
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 3.0,
              wbc_choice: "",
              rbc: 1.9,
              rbc_choice: "million/ul",
              hgb: 11.5,
              hgb_choice: "g/dl",
              hct: 17.0,
              hct_choice: "%",
              mcv: 81.0,
              mcv_choice: "μ^3",
              mch: 21.0,
              mch_choice: "pg",
              mchc: 31.0,
              mchc_choice: "g/dl",
              rdw: 8.1,
              rdw_choice: "%",
              plt_count: 151.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 7.1,
              mpv_choice: "",
              neutrophils: 30.0,
              neutrophils_choice: "%",
              lymphocytes: 21.0,
              lymphocytes_choice: "%",
              monocytes: -2.1,
              monocytes_choice: "%",
              eosinophils: -3.1,
              eosinophils_choice: "%",
              basophils: -4.1,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 67.0,
              gluc_choice: "mg/dl",
              bun: 9.0,
              bun_choice: "mg/dl",
              crea: 0.1,
              crea_choice: "mg/dl",
              egfr: 51.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 8.0,
              bcrt_choice: "",
              sod: 131.0,
              sod_choice: "meq/l",
              pot: 2.0,
              pot_choice: "meq/l",
              chl: 90.0,
              chl_choice: "meq/l",
              co2: 21.0,
              co2_choice: "meq/l",
              cal: 8.2,
              cal_choice: "meq/l",
              tpro: 5.9,
              tpro_choice: "g/dl",
              alb: 2.6,
              alb_choice: "g/dl",
              glob: 1.4,
              glob_choice: "g/dl",
              agrt: 0.5,
              agrt_choice: "",
              tbil: -1.5,
              tbil_choice: "mg/dl",
              aphi: 20.0,
              aphi_choice: "u/l",
              ast: 5.0,
              ast_choice: "u/l",
              alt: 6.0,
              alt_choice: "u/l",
              a1c: 3.1,
              a1c_choice: "%",
              eag: 67.0,
              eag_choice: "mg/dl",
              mag: 1.0,
              mag_choice: "mg/dl",
              phos:1.5,
              phos_choice: "mg/dl",
              uacd: 1.0,
              uacd_choice: "mg/dl",
              ldh: 130.0,
              ldh_choice: "u/l",
              ggt: 7.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 110.0,
              tcho_choice: "mg/dl",
              hdl: 61.0,
              hdl_choice: "mg/dl",
              trig: 40.0,
              trig_choice: "mg/dl",
              ldl: 30.0,
              ldl_choice: "mg/dl",
              chrt: -1.1,
              chrt_choice: "",
              vldl: 4.0,
              vldl_choice: "mg/dl",
              apa1: 130.0,
              apa1_choice: "mg/dl",
              apb: -1.1,
              apb_choice: "mg/dl",
              bart: -2.3,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.1,
              tsh_choice: "mlu/l",
              ft4: 0.1,
              ft4_choice: "ng/dl",
              ft3: 0.2,
              ft3_choice: "pg/dl",
              tt4: 5.5,
              tt4_choice: "ng/dl",
              tt3: 85.0,
              tt3_choice: "ng/dl",
              ut3: 20.0,
              ut3_choice: "ng/dl",
              rt3: 11.9,
              rt3_choice: "ng/dl",
              tgb: 13.0,
              tgb_choice: "ug/ml",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 81.0,
              iron_choice: "µg/dl",
              fert: 42.0,
              fert_choice: "ng/ml",
              tibc: 240.0,
              tibc_choice: "µg/dl",
              tsat: 15.0,
              tsat_choice: "%",
              retc: 0.0,
              retc_choice: "%",
            },
            other: {
              vitd: 20.0,
              vitd_choice: "ng/ml",
              oh2d: 15.0,
              oh2d_choice: "pg/ml",
              fibr: 190.0,
              fibr_choice: "mg/dl",
              crp: -2.5,
              crp_choice: "mg/l",
              hcrp: -3.5,
              hcrp_choice: "mg/l",
              sedr: -4.5,
              sedr_choice: "nmol/l",
              homo: 2.0,
              homo_choice: "umol/l",
              insu: 1.0,
              insu_choice: "μu/ml",
              fruc: 150.0,
              fruc_choice: "umol/l",
              cpep: 0.1,
              cpep_choice: "ng/ml",
              lep: 2.0,
              lep_choice: "ng/dl",
            },
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 9.5,
              wbc_choice: "",
              rbc: 9.5,
              rbc_choice: "million/ul",
              hgb: 19.5,
              hgb_choice: "g/dl",
              hct: 49.0,
              hct_choice: "%",
              mcv: 99.9,
              mcv_choice: "μ^3",
              mch: 39.9,
              mch_choice: "pg",
              mchc: 39.0,
              mchc_choice: "g/dl",
              rdw: 19.0,
              rdw_choice: "%",
              plt_count: 395.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 19.5,
              mpv_choice: "",
              neutrophils: 69.0,
              neutrophils_choice: "%",
              lymphocytes: 49.0,
              lymphocytes_choice: "%",
              monocytes: 9.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 9.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 99.0,
              gluc_choice: "mg/dl",
              bun: 19,
              bun_choice: "mg/dl",
              crea: 1.9,
              crea_choice: "mg/dl",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 19.0,
              bcrt_choice: "",
              sod: 149.0,
              sod_choice: "meq/l",
              pot: 9.5,
              pot_choice: "meq/l",
              chl: 109.0,
              chl_choice: "meq/l",
              co2: 39.0,
              co2_choice: "meq/l",
              cal: 19.0,
              cal_choice: "meq/l",
              tpro: 9.4,
              tpro_choice: "g/dl",
              alb: 9.0,
              alb_choice: "g/dl",
              glob: 9.8,
              glob_choice: "g/dl",
              agrt: 9.0,
              agrt_choice: "",
              tbil: 9.2,
              tbil_choice: "mg/dl",
              aphi: 109.0,
              aphi_choice: "u/l",
              ast: 29.0,
              ast_choice: "u/l",
              alt: 19.9,
              alt_choice: "u/l",
              a1c: 9.4,
              a1c_choice: "%",
              eag: 109.0,
              eag_choice: "mg/dl",
              mag: 2.9,
              mag_choice: "mg/dl",
              phos:9.0,
              phos_choice: "mg/dl",
              uacd: 9.5,
              uacd_choice: "mg/dl",
              ldh: 209.0,
              ldh_choice: "u/l",
              ggt: 29.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 249.0,
              tcho_choice: "mg/dl",
              hdl: 89.0,
              hdl_choice: "mg/dl",
              trig: 109.0,
              trig_choice: "mg/dl",
              ldl: 129.0,
              ldl_choice: "mg/dl",
              chrt: 9.0,
              chrt_choice: "",
              vldl: 49.0,
              vldl_choice: "mg/dl",
              apa1: 9999.9,
              apa1_choice: "mg/dl",
              apb: 99.0,
              apb_choice: "mg/dl",
              bart: 0.93,
              bart_choice: "",
            },
            thyroid: {
              tsh: 9.0,
              tsh_choice: "mlu/l",
              ft4: 9.5,
              ft4_choice: "ng/dl",
              ft3: 9.25,
              ft3_choice: "pg/dl",
              tt4: 9.1,
              tt4_choice: "ng/dl",
              tt3: 198.0,
              tt3_choice: "ng/dl",
              ut3: 39.0,
              ut3_choice: "ng/dl",
              rt3: 29.7,
              rt3_choice: "ng/dl",
              tgb: 29.0,
              tgb_choice: "ug/ml",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 139.0,
              iron_choice: "µg/dl",
              fert: 209.0,
              fert_choice: "ng/ml",
              tibc: 359.0,
              tibc_choice: "µg/dl",
              tsat: 39.0,
              tsat_choice: "%",
              retc: 9.0,
              retc_choice: "%",
            },
            other: {
              vitd: 59.0,
              vitd_choice: "ng/ml",
              oh2d: 59.0,
              oh2d_choice: "pg/ml",
              fibr: 309.0,
              fibr_choice: "mg/dl",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.0,
              hcrp_choice: "mg/l",
              sedr: 10.9,
              sedr_choice: "nmol/l",
              homo: 9.0,
              homo_choice: "umol/l",
              insu: 9.0,
              insu_choice: "μu/ml",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 9.1,
              cpep_choice: "ng/ml",
              lep: 9.0,
              lep_choice: "ng/dl",
            }
  
          },
        },
      },
    },
    Intl: {
      "60>": {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 3.0,
              wbc_choice: "x 109/l",
              rbc: 3.0,
              rbc_choice: "x 1012/l",
              hgb: 100.0,
              hgb_choice: "g/l",
              hct: 30.0,
              hct_choice: "%",
              mcv: 70.0,
              mcv_choice: "fl",
              mch: 20.6,
              mch_choice: "pg",
              mchc: 10.53,
              mchc_choice: "mmol/l",
              rdw: 2.0,
              rdw_choice: "%",
              plt_count: 150.0,
              plt_count_choice: "10^9/l",
              mpv: 2.5,
              mpv_choice: "fl",
              neutrophils: 30.0,
              neutrophils_choice: "%",
              lymphocytes: 10.0,
              lymphocytes_choice: "%",
              monocytes: 2.0,
              monocytes_choice: "%",
              eosinophils: -3.0,
              eosinophils_choice: "%",
              basophils: -1.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 3.0,
              gluc_choice: "mmol/l",
              bun: 1.0,
              bun_choice: "mmol/l",
              crea: 50.0,
              crea_choice: "µmol/l",
              egfr: 55.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 2.0,
              bcrt_choice: "",
              sod: 130.0,
              sod_choice: "mmol/l",
              pot: 3.0,
              pot_choice: "mmol/l",
              chl: 90.0,
              chl_choice: "mmol/l",
              co2: 20.0,
              co2_choice: "mmol/l",
              cal: 0.1,
              cal_choice: "mmol/l",
              tpro:50.0,
              tpro_choice: "g/l",
              alb: 30.0,
              alb_choice: "g/l",
              glob: 10.0,
              glob_choice: "g/l",
              agrt: 0.1,
              agrt_choice: "",
              tbil: 1.0,
              tbil_choice: "µmol/l",
              aphi: 20.0,
              aphi_choice: "u/l",
              ast: -2.0,
              ast_choice: "u/l",
              alt: -1.0,
              alt_choice: "u/l",
              a1c: 20.31,
              a1c_choice: "mmol/mol",
              eag: 1.94,
              eag_choice: "mmol/l",
              mag: -1.6,
              mag_choice: "mmol/l",
              phos: -2.8,
              phos_choice: "mmol/l",
              uacd: 100.9,
              uacd_choice: "µmol/l",
              ldh: 1.0,
              ldh_choice: "µkat/l",
              ggt: -2.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 1.6,
              tcho_choice: "mmol/l",
              hdl: -1.0,
              hdl_choice: "mmol/l",
              trig: -2.0,
              trig_choice: "mmol/l",
              ldl: -4.0,
              ldl_choice: "mmol/l",
              chrt: -6.0,
              chrt_choice: "",
              vldl: -3.1,
              vldl_choice: "mmol/l",
              apa1: 40.0,
              apa1_choice: "µmol/l",
              apb: -3.0,
              apb_choice: "mg/dl",
              bart: -1.1,
              bart_choice: "",
            },
            thyroid: {
              tsh: -2.45,
              tsh_choice: "mlu/l",
              ft4: 2.0,
              ft4_choice: "pmol/l",
              ft3: 0.5,
              ft3_choice: "pmol/l",
              tt4: 50.9,
              tt4_choice: "pmol/l",
              tt3: 0.2,
              tt3_choice: "pmol/l",
              ut3: 20.0,
              ut3_choice: "%",
              rt3: -3.1,
              rt3_choice: "nmol/l",
              tgb: 230.2,
              tgb_choice: "nmol/l",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron: 0.4,
              iron_choice: "µmol/l",
              fert: 30.0,
              fert_choice: "ug/l",
              tibc: 40.8,
              tibc_choice: "µmol/l",
              tsat: 10.0,
              tsat_choice: "%",
              retc: -2.5,
              retc_choice: "%",
            },
            other: {
              vitd: 70.9,
              vitd_choice: "nmol/l",
              oh2d: 40,
              oh2d_choice: "pmol/l",
              fibr: 4.0,
              fibr_choice: "µmol/l",
              crp: -2.0,
              crp_choice: "mg/l",
              hcrp: -3.0,
              hcrp_choice: "nmol/l",
              sedr: -5.0,
              sedr_choice: "mm/hr",
              homo: 2.0,
              homo_choice: "µmol/l",
              insu: 11.9,
              insu_choice: "pmol/l",
              fruc: 160.0,
              fruc_choice: "umol/l",
              cpep: -3.1,
              cpep_choice: "nmol/ml",
              lep: 1.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 18,
              wbc_choice: "x 109/l",
              rbc: 5.8,
              rbc_choice: "x 1012/l",
              hgb: 180.0,
              hgb_choice: "g/l",
              hct: 48.8,
              hct_choice: "%",
              mcv: 98.0,
              mcv_choice: "fl",
              mch: 38.0,
              mch_choice: "pg",
              mchc: 28.94,
              mchc_choice: "mmol/l",
              rdw: 18.4,
              rdw_choice: "%",
              plt_count: 388.0,
              plt_count_choice: "10^9/l",
              mpv: 18.5,
              mpv_choice: "fl",
              neutrophils: 84.0,
              neutrophils_choice: "%",
              lymphocytes: 86.0,
              lymphocytes_choice: "%",
              monocytes: 18.0,
              monocytes_choice: "%",
              eosinophils: 8.0,
              eosinophils_choice: "%",
              basophils: 8.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 8.4,
              gluc_choice: "mmol/l",
              bun: 9.8,
              bun_choice: "mmol/l",
              crea: 138.6,
              crea_choice: "µmol/l",
              egfr: 9999.8,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 28.0,
              bcrt_choice: "",
              sod: 185.0,
              sod_choice: "mmol/l",
              pot: 8.3,
              pot_choice: "mmol/l",
              chl: 109.8,
              chl_choice: "mmol/l",
              co2: 38.0,
              co2_choice: "mmol/l",
              cal: 2.8,
              cal_choice: "mmol/l",
              tpro: 88.0,
              tpro_choice: "g/l",
              alb: 48.8,
              alb_choice: "g/l",
              glob: 39.8,
              glob_choice: "g/l",
              agrt: 2.8,
              agrt_choice: "",
              tbil: 20.8,
              tbil_choice: "µmol/l",
              aphi: 128.0,
              aphi_choice: "u/l",
              ast: 48.0,
              ast_choice: "u/l",
              alt: 48.0,
              alt_choice: "u/l",
              a1c: 88.8,
              a1c_choice: "mmol/mol",
              eag: 8.49,
              eag_choice: "mmol/l",
              mag: 8.1,
              mag_choice: "mmol/l",
              phos: 8.5,
              phos_choice: "mmol/l",
              uacd: 488.0,
              uacd_choice: "µmol/l",
              ldh: 8.7,
              ldh_choice: "µkat/l",
              ggt: 80.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 8.2,
              tcho_choice: "mmol/l",
              hdl: 9999.8,
              hdl_choice: "mmol/l",
              trig: 8.7,
              trig_choice: "mmol/l",
              ldl: 8.6,
              ldl_choice: "mmol/l",
              chrt: 8.4,
              chrt_choice: "",
              vldl: 8.0,
              vldl_choice: "mmol/l",
              apa1: 78.0,
              apa1_choice: "µmol/l",
              apb: 1.8,
              apb_choice: "mg/dl",
              bart: 9999.8,
              bart_choice: "",
            },
            thyroid: {
              tsh: 8.5,
              tsh_choice: "mlu/l",
              ft4: 38.9,
              ft4_choice: "pmol/l",
              ft3: 8.4,
              ft3_choice: "pmol/l",
              tt4: 168.9,
              tt4_choice: "pmol/l",
              tt3: 8.5,
              tt3_choice: "pmol/l",
              ut3: 39.8,
              ut3_choice: "%",
              rt3: 0.8,
              rt3_choice: "nmol/l",
              tgb: 578.2,
              tgb_choice: "nmol/l",
              tpo: 'positive',
              tpo_choice: "",
              atgb: 'positive',
              atgb_choice: "",
              tgt: 'positive',
              tgt_choice: "",
            },
            iron: {
              iron: 32.4,
              iron_choice: "µmol/l",
              fert: 238.0,
              fert_choice: "ug/l",
              tibc: 68.7,
              tibc_choice: "µmol/l",
              tsat: 62.0,
              tsat_choice: "%",
              retc: 2.0,
              retc_choice: "%",
            },
            other: {
              vitd: 252.6,
              vitd_choice: "nmol/l",
              oh2d: 150,
              oh2d_choice: "pmol/l",
              fibr: 14.8,
              fibr_choice: "µmol/l",
              crp: 10.0,
              crp_choice: "mg/l",
              hcrp: 10.5,
              hcrp_choice: "nmol/l",
              sedr: 25.0,
              sedr_choice: "mm/hr",
              homo: 14.0,
              homo_choice: "µmol/l",
              insu: 146.1,
              insu_choice: "pmol/l",
              fruc: 274.0,
              fruc_choice: "umol/l",
              cpep: 4.3,
              cpep_choice: "nmol/ml",
              lep: 14.0,
              lep_choice: "µg/l",
            }
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 4,
              wbc_choice: "x 109/l",
              rbc: 3.4,
              rbc_choice: "x 1012/l",
              hgb: 134.0,
              hgb_choice: "g/l",
              hct: 34.0,
              hct_choice: "%",
              mcv: 43.0,
              mcv_choice: "fl",
              mch: 24.0,
              mch_choice: "pg",
              mchc: 14.84,
              mchc_choice: "mmol/l",
              rdw: 4.0,
              rdw_choice: "%",
              plt_count: 145.0,
              plt_count_choice: "10^9/l",
              mpv: 4.5,
              mpv_choice: "fl",
              neutrophils: 30.0,
              neutrophils_choice: "%",
              lymphocytes: 20.0,
              lymphocytes_choice: "%",
              monocytes: -4.0,
              monocytes_choice: "%",
              eosinophils: -5.0,
              eosinophils_choice: "%",
              basophils: -2.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 2.9,
              gluc_choice: "mmol/l",
              bun: 2.6,
              bun_choice: "mmol/l",
              crea: 20.7,
              crea_choice: "µmol/l",
              egfr: 51.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 8.0,
              bcrt_choice: "",
              sod: 125.0,
              sod_choice: "mmol/l",
              pot: 2.0,
              pot_choice: "mmol/l",
              chl: 90.0,
              chl_choice: "mmol/l",
              co2: 22.0,
              co2_choice: "mmol/l",
              cal: 1.3,
              cal_choice: "mmol/l",
              tpro: 59.0,
              tpro_choice: "g/l",
              alb: 41.0,
              alb_choice: "g/l",
              glob: 22.0,
              glob_choice: "g/l",
              agrt: 0.5,
              agrt_choice: "",
              tbil: 6.6,
              tbil_choice: "µmol/l",
              aphi: 30.0,
              aphi_choice: "u/l",
              ast: 8.0,
              ast_choice: "u/l",
              alt: 8.0,
              alt_choice: "u/l",
              a1c: 20.31,
              a1c_choice: "mmol/mol",
              eag: 2.94,
              eag_choice: "mmol/l",
              mag: 0.0,
              mag_choice: "mmol/l",
              phos: 0.1,
              phos_choice: "mmol/l",
              uacd: 170.4,
              uacd_choice: "µmol/l",
              ldh: 1.3,
              ldh_choice: "µkat/l",
              ggt: 9.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 4.5,
              tcho_choice: "mmol/l",
              hdl: 0.7,
              hdl_choice: "mmol/l",
              trig: -1.6,
              trig_choice: "mmol/l",
              ldl: 0.1,
              ldl_choice: "mmol/l",
              chrt: -2.1,
              chrt_choice: "",
              vldl: -3.1,
              vldl_choice: "mmol/l",
              apa1: 30.0,
              apa1_choice: "µmol/l",
              apb: -2.0,
              apb_choice: "mg/dl",
              bart: -1.0,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.1,
              tsh_choice: "mlu/l",
              ft4: 2.9,
              ft4_choice: "pmol/l",
              ft3: 1.6,
              ft3_choice: "pmol/l",
              tt4: 90.5,
              tt4_choice: "pmol/l",
              tt3: 0.4,
              tt3_choice: "pmol/l",
              ut3: 24.0,
              ut3_choice: "%",
              rt3: 0.1,
              rt3_choice: "nmol/l",
              tgb: 331.0,
              tgb_choice: "nmol/l",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 11.2,
              iron_choice: "µmol/l",
              fert: 40.0,
              fert_choice: "ug/l",
              tibc: 40.8,
              tibc_choice: "µmol/l",
              tsat: 15.0,
              tsat_choice: "%",
              retc: -1.5,
              retc_choice: "%",
            },
            other: {
              vitd: 70.0,
              vitd_choice: "nmol/l",
              oh2d: 40,
              oh2d_choice: "pmol/l",
              fibr: 5.0,
              fibr_choice: "µmol/l",
              crp: -1.2,
              crp_choice: "mg/l",
              hcrp: -1.1,
              hcrp_choice: "nmol/l",
              sedr: 15.0,
              sedr_choice: "mm/hr",
              homo: 2.0,
              homo_choice: "µmol/l",
              insu: 11.9,
              insu_choice: "pmol/l",
              fruc: 160.0,
              fruc_choice: "umol/l",
              cpep: -1.4,
              cpep_choice: "nmol/ml",
              lep: 2.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
              rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 9.5,
              wbc_choice: "x 109/l",
              rbc: 10.5,
              rbc_choice: "x 1012/l",
              hgb: 155.0,
              hgb_choice: "g/l",
              hct: 45.0,
              hct_choice: "%",
              mcv: 90.9,
              mcv_choice: "fl",
              mch: 35.9,
              mch_choice: "pg",
              mchc: 29.84,
              mchc_choice: "mmol/l",
              rdw: 15,
              rdw_choice: "%",
              plt_count: 389.0,
              plt_count_choice: "10^9/l",
              mpv: 14.5,
              mpv_choice: "fl",
              neutrophils: 65.0,
              neutrophils_choice: "%",
              lymphocytes: 45.0,
              lymphocytes_choice: "%",
              monocytes: 7.5,
              monocytes_choice: "%",
              eosinophils: 5.0,
              eosinophils_choice: "%",
              basophils: 2.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 9,
              gluc_choice: "mmol/l",
              bun: 9.7,
              bun_choice: "mmol/l",
              crea: 97.9,
              crea_choice: "µmol/l",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 16.9,
              bcrt_choice: "",
              sod: 149.0,
              sod_choice: "mmol/l",
              pot: 4.9,
              pot_choice: "mmol/l",
              chl: 109.0,
              chl_choice: "mmol/l",
              co2: 39.0,
              co2_choice: "mmol/l",
              cal: 9.5,
              cal_choice: "mmol/l",
              tpro: 79.0,
              tpro_choice: "g/l",
              alb: 59.0,
              alb_choice: "g/l",
              glob: 29.0,
              glob_choice: "g/l",
              agrt: 9.0,
              agrt_choice: "",
              tbil: 29.5,
              tbil_choice: "µmol/l",
              aphi: 109.0,
              aphi_choice: "u/l",
              ast: 29.0,
              ast_choice: "u/l",
              alt: 19.9,
              alt_choice: "u/l",
              a1c: 39.52,
              a1c_choice: "mmol/mol",
              eag: 9.01,
              eag_choice: "mmol/l",
              mag: 9.0,
              mag_choice: "mmol/l",
              phos: 9.3,
              phos_choice: "mmol/l",
              uacd: 329.1,
              uacd_choice: "µmol/l",
              ldh: 3.9,
              ldh_choice: "µkat/l",
              ggt: 29.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 9.8,
              tcho_choice: "mmol/l",
              hdl: 9.2,
              hdl_choice: "mmol/l",
              trig: 9.1,
              trig_choice: "mmol/l",
              ldl: 9.4,
              ldl_choice: "mmol/l",
              chrt: 9.0,
              chrt_choice: "",
              vldl: 9.0,
              vldl_choice: "mmol/l",
              apa1: 9999.9,
              apa1_choice: "µmol/l",
              apb: 1.95,
              apb_choice: "mg/dl",
              bart: 0.93,
              bart_choice: "",
            },
            thyroid: {
              tsh: 9.0,
              tsh_choice: "mlu/l",
              ft4: 29.3,
              ft4_choice: "pmol/l",
              ft3: 9.0,
              ft3_choice: "pmol/l",
              tt4: 109.2,
              tt4_choice: "pmol/l",
              tt3: 9.6,
              tt3_choice: "pmol/l",
              ut3:39.0,
              ut3_choice: "%",
              rt3: 0.9,
              rt3_choice: "nmol/l",
              tgb: 509.0,
              tgb_choice: "nmol/l",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 29.3,
              iron_choice: "µmol/l",
              fert: 209.0,
              fert_choice: "ug/l",
              tibc: 359.8,
              tibc_choice: "µmol/l",
              tsat: 39.0,
              tsat_choice: "%",
              retc: 9.0,
              retc_choice: "%",
            },
            other: {
              vitd: 129.0,
              vitd_choice: "nmol/l",
              oh2d: 139,
              oh2d_choice: "pmol/l",
              fibr: 8.9,
              fibr_choice: "µmol/l",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.9,
              hcrp_choice: "nmol/l",
              sedr: 10.9,
              sedr_choice: "mm/hr",
              homo: 7.9,
              homo_choice: "µmol/l",
              insu: 59.6,
              insu_choice: "pmol/l",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 0.9,
              cpep_choice: "nmol/ml",
              lep: 9.0,
              lep_choice: "µg/l",
            }
          },
        },
      },
      "59<": {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 1.7,
              wbc_choice: "x 109/l",
              rbc: 1.9,
              rbc_choice: "x 1012/l",
              hgb: 110.0,
              hgb_choice: "g/l",
              hct: 31.0,
              hct_choice: "%",
              mcv: 71.0,
              mcv_choice: "fl",
              mch: 21.6,
              mch_choice: "pg",
              mchc: 11.53,
              mchc_choice: "mmol/l",
              rdw: 1.0,
              rdw_choice: "%",
              plt_count: 115.0,
              plt_count_choice: "10^9/l",
              mpv: 1.5,
              mpv_choice: "fl",
              neutrophils: 31.0,
              neutrophils_choice: "%",
              lymphocytes: 11.0,
              lymphocytes_choice: "%",
              monocytes: 1.0,
              monocytes_choice: "%",
              eosinophils: -1.0,
              eosinophils_choice: "%",
              basophils: -1.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 1.6,
              gluc_choice: "mmol/l",
              bun: 0.8,
              bun_choice: "mmol/l",
              crea: 51.0,
              crea_choice: "µmol/l",
              egfr: 51.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 1.0,
              bcrt_choice: "",
              sod: 131.0,
              sod_choice: "mmol/l",
              pot: 1.5,
              pot_choice: "mmol/l",
              chl: 91.0,
              chl_choice: "mmol/l",
              co2: 21.0,
              co2_choice: "mmol/l",
              cal: 1.1,
              cal_choice: "mmol/l",
              tpro: 51.0,
              tpro_choice: "g/l",
              alb: 31.0,
              alb_choice: "g/l",
              glob: 10.0,
              glob_choice: "g/l",
              agrt: 0.1,
              agrt_choice: "",
              tbil: 0.7,
              tbil_choice: "µmol/l",
              aphi: 15.0,
              aphi_choice: "u/l",
              ast: -1.0,
              ast_choice: "u/l",
              alt: -1.0,
              alt_choice: "u/l",
              a1c: 11.31,
              a1c_choice: "mmol/mol",
              eag: 1.94,
              eag_choice: "mmol/l",
              mag: -1.6,
              mag_choice: "mmol/l",
              phos: -1.8,
              phos_choice: "mmol/l",
              uacd: 110.9,
              uacd_choice: "µmol/l",
              ldh: 0.7,
              ldh_choice: "µkat/l",
              ggt: 0.1,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 1.6,
              tcho_choice: "mmol/l",
              hdl: -1.0,
              hdl_choice: "mmol/l",
              trig: -1.0,
              trig_choice: "mmol/l",
              ldl: -2.0,
              ldl_choice: "mmol/l",
              chrt: -3.0,
              chrt_choice: "",
              vldl: -4.0,
              vldl_choice: "mmol/l",
              apa1: 11.0,
              apa1_choice: "µmol/l",
              apb: -5.0,
              apb_choice: "mg/dl",
              bart: 0.18,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.15,
              tsh_choice: "mlu/l",
              ft4: 1.0,
              ft4_choice: "pmol/l",
              ft3: 1.5,
              ft3_choice: "pmol/l",
              tt4: 51.9,
              tt4_choice: "pmol/l",
              tt3: 0.2,
              tt3_choice: "pmol/l",
              ut3: 21.0,
              ut3_choice: "%",
              rt3: -1.1,
              rt3_choice: "nmol/l",
              tgb: 231.2,
              tgb_choice: "nmol/l",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron: 1.4,
              iron_choice: "µmol/l",
              fert: 31.0,
              fert_choice: "ug/l",
              tibc: 41.8,
              tibc_choice: "µmol/l",
              tsat: 11.0,
              tsat_choice: "%",
              retc: -1.5,
              retc_choice: "%",
            },
            other: {
              vitd: 71.9,
              vitd_choice: "nmol/l",
              oh2d: 41,
              oh2d_choice: "pmol/l",
              fibr: 1.4,
              fibr_choice: "µmol/l",
              crp: -1.0,
              crp_choice: "mg/l",
              hcrp: -2.0,
              hcrp_choice: "nmol/l",
              sedr: -3.0,
              sedr_choice: "mm/hr",
              homo: 1.0,
              homo_choice: "µmol/l",
              insu: 11.9,
              insu_choice: "pmol/l",
              fruc: 180.0,
              fruc_choice: "umol/l",
              cpep: -1.3,
              cpep_choice: "nmol/ml",
              lep: 1.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 13,
              wbc_choice: "x 109/l",
              rbc: 7.1,
              rbc_choice: "x 1012/l",
              hgb: 167.0,
              hgb_choice: "g/l",
              hct: 49.2,
              hct_choice: "%",
              mcv: 99.0,
              mcv_choice: "fl",
              mch: 39.0,
              mch_choice: "pg",
              mchc: 29.94,
              mchc_choice: "mmol/l",
              rdw: 19.4,
              rdw_choice: "%",
              plt_count: 395.0,
              plt_count_choice: "10^9/l",
              mpv: 19.5,
              mpv_choice: "fl",
              neutrophils: 79.0,
              neutrophils_choice: "%",
              lymphocytes: 49.0,
              lymphocytes_choice: "%",
              monocytes: 19.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 9.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 9.4,
              gluc_choice: "mmol/l",
              bun: 9.9,
              bun_choice: "mmol/l",
              crea: 139.6,
              crea_choice: "µmol/l",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 29.0,
              bcrt_choice: "",
              sod: 149.0,
              sod_choice: "mmol/l",
              pot: 9.3,
              pot_choice: "mmol/l",
              chl: 119.0,
              chl_choice: "mmol/l",
              co2: 39.0,
              co2_choice: "mmol/l",
              cal: 9.7,
              cal_choice: "mmol/l",
              tpro: 89.0,
              tpro_choice: "g/l",
              alb: 49.0,
              alb_choice: "g/l",
              glob: 49.0,
              glob_choice: "g/l",
              agrt: 7.5,
              agrt_choice: "",
              tbil: 29.5,
              tbil_choice: "µmol/l",
              aphi: 129.0,
              aphi_choice: "u/l",
              ast: 49.0,
              ast_choice: "u/l",
              alt: 49.0,
              alt_choice: "u/l",
              a1c: 39.8,
              a1c_choice: "mmol/mol",
              eag: 9.49,
              eag_choice: "mmol/l",
              mag: 0.1,
              mag_choice: "mmol/l",
              phos: 0.5,
              phos_choice: "mmol/l",
              uacd: 487.0,
              uacd_choice: "µmol/l",
              ldh: 1.7,
              ldh_choice: "µkat/l",
              ggt: 67.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 3.2,
              tcho_choice: "mmol/l",
              hdl: 9999.3,
              hdl_choice: "mmol/l",
              trig: 0.7,
              trig_choice: "mmol/l",
              ldl: 0.6,
              ldl_choice: "mmol/l",
              chrt: 4.0,
              chrt_choice: "",
              vldl: 0.1,
              vldl_choice: "mmol/l",
              apa1: 72.0,
              apa1_choice: "µmol/l",
              apb: 1.5,
              apb_choice: "mg/dl",
              bart: 9999.9,
              bart_choice: "",
            },
            thyroid: {
              tsh: 5.5,
              tsh_choice: "mlu/l",
              ft4: 38.9,
              ft4_choice: "pmol/l",
              ft3: 8.4,
              ft3_choice: "pmol/l",
              tt4: 162.9,
              tt4_choice: "pmol/l",
              tt3: 7.5,
              tt3_choice: "pmol/l",
              ut3: 40.0,
              ut3_choice: "%",
              rt3: 0.1,
              rt3_choice: "nmol/l",
              tgb: 579.2,
              tgb_choice: "nmol/l",
              tpo: 'positive',
              tpo_choice: "",
              atgb: 'positive',
              atgb_choice: "",
              tgt: 'positive',
              tgt_choice: "",
            },
            iron: {
              iron: 35.4,
              iron_choice: "µmol/l",
              fert: 239.0,
              fert_choice: "ug/l",
              tibc: 69.7,
              tibc_choice: "µmol/l",
              tsat: 69.0,
              tsat_choice: "%",
              retc: 0.1,
              retc_choice: "%",
            },
            other: {
              vitd: 250.6,
              vitd_choice: "nmol/l",
              oh2d: 135,
              oh2d_choice: "pmol/l",
              fibr: 13.0,
              fibr_choice: "µmol/l",
              crp: 10.0,
              crp_choice: "mg/l",
              hcrp: 10.5,
              hcrp_choice: "nmol/l",
              sedr: 22.0,
              sedr_choice: "mm/hr",
              homo: 18.0,
              homo_choice: "µmol/l",
              insu: 140.1,
              insu_choice: "pmol/l",
              fruc: 275.0,
              fruc_choice: "umol/l",
              cpep: 1.9,
              cpep_choice: "nmol/ml",
              lep: 12.0,
              lep_choice: "µg/l",
            }
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 2,
              wbc_choice: "x 109/l",
              rbc: 2.9,
              rbc_choice: "x 1012/l",
              hgb: 125.0,
              hgb_choice: "g/l",
              hct: 27.0,
              hct_choice: "%",
              mcv: 22.0,
              mcv_choice: "fl",
              mch: 22.0,
              mch_choice: "pg",
              mchc: 12.84,
              mchc_choice: "mmol/l",
              rdw: 2,
              rdw_choice: "%",
              plt_count: 152.0,
              plt_count_choice: "10^9/l",
              mpv: 2.5,
              mpv_choice: "fl",
              neutrophils: 20.0,
              neutrophils_choice: "%",
              lymphocytes: 22.0,
              lymphocytes_choice: "%",
              monocytes: -2.0,
              monocytes_choice: "%",
              eosinophils: -2.0,
              eosinophils_choice: "%",
              basophils: -2.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 2.9,
              gluc_choice: "mmol/l",
              bun: 2.6,
              bun_choice: "mmol/l",
              crea: 20.7,
              crea_choice: "µmol/l",
              egfr: 29.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 2.0,
              bcrt_choice: "",
              sod: 132.0,
              sod_choice: "mmol/l",
              pot: 2.0,
              pot_choice: "mmol/l",
              chl: 92.0,
              chl_choice: "mmol/l",
              co2: 22.0,
              co2_choice: "mmol/l",
              cal: 0.3,
              cal_choice: "mmol/l",
              tpro: 29.0,
              tpro_choice: "g/l",
              alb: 26.0,
              alb_choice: "g/l",
              glob: 22.0,
              glob_choice: "g/l",
              agrt: 0.5,
              agrt_choice: "",
              tbil: 2.6,
              tbil_choice: "µmol/l",
              aphi: 20.0,
              aphi_choice: "u/l",
              ast: 2.0,
              ast_choice: "u/l",
              alt: 2.0,
              alt_choice: "u/l",
              a1c: 11.31,
              a1c_choice: "mmol/mol",
              eag: 2.94,
              eag_choice: "mmol/l",
              mag: -2.8,
              mag_choice: "mmol/l",
              phos: -2.1,
              phos_choice: "mmol/l",
              uacd: 172.4,
              uacd_choice: "µmol/l",
              ldh: 0.3,
              ldh_choice: "µkat/l",
              ggt: 2.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 2.1,
              tcho_choice: "mmol/l",
              hdl: 0.7,
              hdl_choice: "mmol/l",
              trig: -2.6,
              trig_choice: "mmol/l",
              ldl: 0.1,
              ldl_choice: "mmol/l",
              chrt: -2.0,
              chrt_choice: "",
              vldl: 2.0,
              vldl_choice: "mmol/l",
              apa1: 20.0,
              apa1_choice: "µmol/l",
              apb: -2.0,
              apb_choice: "mg/dl",
              bart: -3.0,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.1,
              tsh_choice: "mlu/l",
              ft4: 11.9,
              ft4_choice: "pmol/l",
              ft3: 2.6,
              ft3_choice: "pmol/l",
              tt4: 92.5,
              tt4_choice: "pmol/l",
              tt3: 0.4,
              tt3_choice: "pmol/l",
              ut3: 22.0,
              ut3_choice: "%",
              rt3: -1.0,
              rt3_choice: "nmol/l",
              tgb: 332.0,
              tgb_choice: "nmol/l",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 12.2,
              iron_choice: "µmol/l",
              fert: 42.0,
              fert_choice: "ug/l",
              tibc: 41.8,
              tibc_choice: "µmol/l",
              tsat: 15.0,
              tsat_choice: "%",
              retc: -2.5,
              retc_choice: "%",
            },
            other: {
              vitd: 25.0,
              vitd_choice: "nmol/l",
              oh2d: 28,
              oh2d_choice: "pmol/l",
              fibr: 2.9,
              fibr_choice: "µmol/l",
              crp: -2.0,
              crp_choice: "mg/l",
              hcrp: -3.0,
              hcrp_choice: "nmol/l",
              sedr: -2.0,
              sedr_choice: "mm/hr",
              homo: 2.0,
              homo_choice: "µmol/l",
              insu: 12.9,
              insu_choice: "pmol/l",
              fruc: 120.0,
              fruc_choice: "umol/l",
              cpep: -2.0,
              cpep_choice: "nmol/ml",
              lep: 2.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
              rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 9.5,
              wbc_choice: "x 109/l",
              rbc: 9.5,
              rbc_choice: "x 1012/l",
              hgb: 195.0,
              hgb_choice: "g/l",
              hct: 49.0,
              hct_choice: "%",
              mcv: 99.9,
              mcv_choice: "fl",
              mch: 39.9,
              mch_choice: "pg",
              mchc: 19.94,
              mchc_choice: "mmol/l",
              rdw: 19,
              rdw_choice: "%",
              plt_count: 389.0,
              plt_count_choice: "10^9/l",
              mpv: 19.5,
              mpv_choice: "fl",
              neutrophils: 90.0,
              neutrophils_choice: "%",
              lymphocytes: 49.0,
              lymphocytes_choice: "%",
              monocytes: 9.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 6.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 9,
              gluc_choice: "mmol/l",
              bun: 9.7,
              bun_choice: "mmol/l",
              crea: 99.2,
              crea_choice: "µmol/l",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 16.9,
              bcrt_choice: "",
              sod: 142.9,
              sod_choice: "mmol/l",
              pot: 9.5,
              pot_choice: "mmol/l",
              chl: 109.0,
              chl_choice: "mmol/l",
              co2: 39.0,
              co2_choice: "mmol/l",
              cal: 9.5,
              cal_choice: "mmol/l",
              tpro: 79.0,
              tpro_choice: "g/l",
              alb: 59.0,
              alb_choice: "g/l",
              glob: 29.0,
              glob_choice: "g/l",
              agrt: 9.0,
              agrt_choice: "",
              tbil: 29.5,
              tbil_choice: "µmol/l",
              aphi: 109.0,
              aphi_choice: "u/l",
              ast: 29.0,
              ast_choice: "u/l",
              alt: 19.9,
              alt_choice: "u/l",
              a1c: 35.92,
              a1c_choice: "mmol/mol",
              eag: 9.01,
              eag_choice: "mmol/l",
              mag: 9.0,
              mag_choice: "mmol/l",
              phos: 9.3,
              phos_choice: "mmol/l",
              uacd: 329.1,
              uacd_choice: "µmol/l",
              ldh: 9.3,
              ldh_choice: "µkat/l",
              ggt: 29.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 9.2,
              tcho_choice: "mmol/l",
              hdl: 9.2,
              hdl_choice: "mmol/l",
              trig: 9.1,
              trig_choice: "mmol/l",
              ldl: 9.1,
              ldl_choice: "mmol/l",
              chrt: 9.0,
              chrt_choice: "",
              vldl: 9.0,
              vldl_choice: "mmol/l",
              apa1: 9999.9,
              apa1_choice: "µmol/l",
              apb: 9.75,
              apb_choice: "mg/dl",
              bart: 0.93,
              bart_choice: "",
            },
            thyroid: {
              tsh: 9.0,
              tsh_choice: "mlu/l",
              ft4: 19.9,
              ft4_choice: "pmol/l",
              ft3: 9.0,
              ft3_choice: "pmol/l",
              tt4: 109.2,
              tt4_choice: "pmol/l",
              tt3: 9.6,
              tt3_choice: "pmol/l",
              ut3:39.0,
              ut3_choice: "%",
              rt3: 0.9,
              rt3_choice: "nmol/l",
              tgb: 509.0,
              tgb_choice: "nmol/l",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 29.3,
              iron_choice: "µmol/l",
              fert: 209.0,
              fert_choice: "ug/l",
              tibc: 359.8,
              tibc_choice: "µmol/l",
              tsat: 39.0,
              tsat_choice: "%",
              retc: 9.0,
              retc_choice: "%",
            },
            other: {
              vitd: 195.0,
              vitd_choice: "nmol/l",
              oh2d: 139,
              oh2d_choice: "pmol/l",
              fibr: 8.9,
              fibr_choice: "µmol/l",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 10.5,
              hcrp_choice: "nmol/l",
              sedr: 16.0,
              sedr_choice: "mm/hr",
              homo: 9.0,
              homo_choice: "µmol/l",
              insu: 58.6,
              insu_choice: "pmol/l",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 0.9,
              cpep_choice: "nmol/ml",
              lep: 9.0,
              lep_choice: "µg/l",
            }
          },
        },
      },
    },
  },
  M: {
    US: {
      "60>": {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 3.0,
              wbc_choice: "",
              rbc: 3.0,
              rbc_choice: "million/ul",
              hgb: 11.0,
              hgb_choice: "g/dl",
              hct: 30.0,
              hct_choice: "%",
              mcv: 70.0,
              mcv_choice: "μ^3",
              mch: 20.6,
              mch_choice: "pg",
              mchc: 30.5,
              mchc_choice: "g/dl",
              rdw: 8.0,
              rdw_choice: "%",
              plt_count: 154.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 7.0,
              mpv_choice: "",
              neutrophils: 25.0,
              neutrophils_choice: "%",
              lymphocytes: 11.0,
              lymphocytes_choice: "%",
              monocytes: 2.0,
              monocytes_choice: "%",
              eosinophils: -2.0,
              eosinophils_choice: "%",
              basophils: -3.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 60,
              gluc_choice: "mg/dl",
              bun: 1,
              bun_choice: "mg/dl",
              crea: 0.1,
              crea_choice: "mg/dl",
              egfr: 50.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 5.0,
              bcrt_choice: "",
              sod: 130.0,
              sod_choice: "meq/l",
              pot: 2.5,
              pot_choice: "meq/l",
              chl: 90.0,
              chl_choice: "meq/l",
              co2: 20.0,
              co2_choice: "meq/l",
              cal: 6.5,
              cal_choice: "meq/l",
              tpro: 5.0,
              tpro_choice: "g/dl",
              alb: 2.6,
              alb_choice: "g/dl",
              glob: 1.0,
              glob_choice: "g/dl",
              agrt: 0.1,
              agrt_choice: "",
              tbil: -1.1,
              tbil_choice: "mg/dl",
              aphi: 23.0,
              aphi_choice: "u/l",
              ast: -2.0,
              ast_choice: "u/l",
              alt: -3.0,
              alt_choice: "u/l",
              a1c: 2.1,
              a1c_choice: "%",
              eag: 65.0,
              eag_choice: "mg/dl",
              mag: 0.5,
              mag_choice: "mg/dl",
              phos: 0.5,
              phos_choice: "mg/dl",
              uacd: 0.2,
              uacd_choice: "mg/dl",
              ldh: 90.0,
              ldh_choice: "u/l",
              ggt: 0.1,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 90.0,
              tcho_choice: "mg/dl",
              hdl: 35.0,
              hdl_choice: "mg/dl",
              trig: -2.0,
              trig_choice: "mg/dl",
              ldl: -3.0,
              ldl_choice: "mg/dl",
              chrt: -4.0,
              chrt_choice: "",
              vldl: 4.0,
              vldl_choice: "mg/dl",
              apa1: 91.0,
              apa1_choice: "mg/dl",
              apb: -2.0,
              apb_choice: "mg/dl",
              bart: 0.5,
              bart_choice: "",
            },
            thyroid: {
              tsh: -1.45,
              tsh_choice: "mlu/l",
              ft4: -1.7,
              ft4_choice: "ng/dl",
              ft3: 0.3,
              ft3_choice: "pg/dl",
              tt4: 2.5,
              tt4_choice: "ng/dl",
              tt3: 70.0,
              tt3_choice: "ng/dl%",
              ut3: 20.0,
              ut3_choice: "ng/dL",
              rt3: 8.2,
              rt3_choice: "ng/dl",
              tgb: 10.7,
              tgb_choice: "ug/ml",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron:25.0,
              iron_choice: "µg/dl",
              fert: 30.0,
              fert_choice: "ng/ml",
              tibc: 240.0,
              tibc_choice: "µg/dl",
              tsat: 12.0,
              tsat_choice: "%",
              retc: -1.5,
              retc_choice: "%",
            },
            other: {
              vitd: 25.0,
              vitd_choice: "ng/ml",
              oh2d: 10.6,
              oh2d_choice: "pg/ml",
              fibr: 140.0,
              fibr_choice: "mg/dl",
              crp: -3.0,
              crp_choice: "mg/l",
              hcrp: -2.0,
              hcrp_choice: "mg/l",
              sedr: -4.0,
              sedr_choice: "nmol/l",
              homo: -6.0,
              homo_choice: "umol/l",
              insu: -1.0,
              insu_choice: "μu/ml",
              fruc: 180.0,
              fruc_choice: "umol/l",
              cpep: 0.1,
              cpep_choice: "ng/ml",
              lep: 2.0,
              lep_choice: "ng/dl",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1959-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 13.0,
              wbc_choice: "",
              rbc: 5.3,
              rbc_choice: "million/ul",
              hgb: 16.3,
              hgb_choice: "g/dl",
              hct: 48.3,
              hct_choice: "%",
              mcv: 97.3,
              mcv_choice: "μ^3",
              mch: 33.3,
              mch_choice: "pg",
              mchc: 37.3,
              mchc_choice: "g/dl",
              rdw: 19.4,
              rdw_choice: "%",
              plt_count: 395.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 19.5,
              mpv_choice: "",
              neutrophils: 79.0,
              neutrophils_choice: "%",
              lymphocytes: 49.0,
              lymphocytes_choice: "%",
              monocytes: 19.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 9.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 119.0,
              gluc_choice: "mg/dl",
              bun: 29,
              bun_choice: "mg/dl",
              crea: 9.5,
              crea_choice: "mg/dl",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 29.0,
              bcrt_choice: "",
              sod: 149.0,
              sod_choice: "meq/l",
              pot: 9.3,
              pot_choice: "meq/l",
              chl: 199,
              chl_choice: "meq/l",
              co2: 39.0,
              co2_choice: "meq/l",
              cal: 19.8,
              cal_choice: "meq/l",
              tpro: 9.5,
              tpro_choice: "g/dl",
              alb: 9.8,
              alb_choice: "g/dl",
              glob: 9.9,
              glob_choice: "g/dl",
              agrt: 9.5,
              agrt_choice: "",
              tbil: 9.2,
              tbil_choice: "mg/dl",
              aphi: 129.0,
              aphi_choice: "u/l",
              ast: 49.0,
              ast_choice: "u/l",
              alt: 49.0,
              alt_choice: "u/l",
              a1c: 9.7,
              a1c_choice: "%",
              eag: 197,
              eag_choice: "mg/dl",
              mag: 2.9,
              mag_choice: "mg/dl",
              phos:9.5,
              phos_choice: "mg/dl",
              uacd: 9.7,
              uacd_choice: "mg/dl",
              ldh: 229.0,
              ldh_choice: "u/l",
              ggt: 79.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 209.0,
              tcho_choice: "mg/dl",
              hdl: 9999.9,
              hdl_choice: "mg/dl",
              trig: 199.0,
              trig_choice: "mg/dl",
              ldl: 99.9,
              ldl_choice: "mg/dl",
              chrt: 9.4,
              chrt_choice: "",
              vldl: 90.0,
              vldl_choice: "mg/dl",
              apa1: 198.0,
              apa1_choice: "mg/dl",
              apb: 99.0,
              apb_choice: "mg/dl",
              bart: 9999.9,
              bart_choice: "",
            },
            thyroid: {
              tsh: 9.5,
              tsh_choice: "mlu/l",
              ft4: 9.4,
              ft4_choice: "ng/dl",
              ft3: 9.8,
              ft3_choice: "pg/dl",
              tt4: 19.5,
              tt4_choice: "ng/dl",
              tt3: 239.0,
              tt3_choice: "ng/dl%",
              ut3: 99.0,
              ut3_choice: "ng/dl",
              rt3: 29.1,
              rt3_choice: "ng/dl",
              tgb: 39.9,
              tgb_choice: "ug/ml",
              tpo: 'positive',
              tpo_choice: "",
              atgb: 'positive',
              atgb_choice: "",
              tgt: 'positive',
              tgt_choice: "",
            },
            iron: {
              iron: 179.0,
              iron_choice: "µg/dl",
              fert: 239.0,
              fert_choice: "ng/ml",
              tibc: 359.0,
              tibc_choice: "µg/dl",
              tsat: 69.0,
              tsat_choice: "%",
              retc: 9.0,
              retc_choice: "%",
            },
            other: {
              vitd: 109.0,
              vitd_choice: "ng/ml",
              oh2d: 59.3,
              oh2d_choice: "pg/ml",
              fibr: 409.0,
              fibr_choice: "mg/dl",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.0,
              hcrp_choice: "mg/l",
              sedr: 19.0,
              sedr_choice: "mm/hr",
              homo: 19.5,
              homo_choice: "umol/l",
              insu: 19.9,
              insu_choice: "μu/ml",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 3.95,
              cpep_choice: "ng/ml",
              lep: 10.9,
              lep_choice: "ng/dl",
            },
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1958-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 2.0,
              wbc_choice: "",
              rbc: 2.2,
              rbc_choice: "million/ul",
              hgb: 12,
              hgb_choice: "g/dl",
              hct: 20.0,
              hct_choice: "%",
              mcv: 22.0,
              mcv_choice: "μ^3",
              mch: 22.0,
              mch_choice: "pg",
              mchc: 22.0,
              mchc_choice: "g/dl",
              rdw: 2.0,
              rdw_choice: "%",
              plt_count: 125.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 2.5,
              mpv_choice: "",
              neutrophils: 20.0,
              neutrophils_choice: "%",
              lymphocytes: 22.0,
              lymphocytes_choice: "%",
              monocytes: -2.3,
              monocytes_choice: "%",
              eosinophils: -2.6,
              eosinophils_choice: "%",
              basophils: -2.3,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 20.0,
              gluc_choice: "mg/dl",
              bun: 2,
              bun_choice: "mg/dl",
              crea: -2.8,
              crea_choice: "mg/dl",
              egfr: 52.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 2.0,
              bcrt_choice: "",
              sod: 125.0,
              sod_choice: "meq/l",
              pot: 2.0,
              pot_choice: "meq/l",
              chl: 92.0,
              chl_choice: "meq/l",
              co2: 22.0,
              co2_choice: "meq/l",
              cal: 2.2,
              cal_choice: "meq/l",
              tpro: 2.9,
              tpro_choice: "g/dl",
              alb: 2.6,
              alb_choice: "g/dl",
              glob: 2.2,
              glob_choice: "g/dl",
              agrt: 1.2,
              agrt_choice: "",
              tbil: 0.2,
              tbil_choice: "mg/dl",
              aphi: 20.0,
              aphi_choice: "u/l",
              ast: 10.0,
              ast_choice: "u/l",
              alt: 12.0,
              alt_choice: "u/l",
              a1c: 2.1,
              a1c_choice: "%",
              eag: 20.0,
              eag_choice: "mg/dl",
              mag: -1,
              mag_choice: "mg/dl",
              phos:2.0,
              phos_choice: "mg/dl",
              uacd: 2.5,
              uacd_choice: "mg/dl",
              ldh: 120.0,
              ldh_choice: "u/l",
              ggt: 2.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 120.0,
              tcho_choice: "mg/dl",
              hdl: 52.0,
              hdl_choice: "mg/dl",
              trig: 20.0,
              trig_choice: "mg/dl",
              ldl: 100.0,
              ldl_choice: "mg/dl",
              chrt: -2.0,
              chrt_choice: "",
              vldl: 2.0,
              vldl_choice: "mg/dl",
              apa1: 110.0,
              apa1_choice: "mg/dl",
              apb: -2.0,
              apb_choice: "mg/dl",
              bart: -2.3,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.1,
              tsh_choice: "mlu/l",
              ft4: 0.1,
              ft4_choice: "ng/dl",
              ft3: 2.0,
              ft3_choice: "pg/dl",
              tt4: 2.5,
              tt4_choice: "ng/dl",
              tt3: 20.0,
              tt3_choice: "ng/dl%",
              ut3: 22.0,
              ut3_choice: "ng/dl",
              rt3: 12.9,
              rt3_choice: "ng/dl",
              tgb: 12.0,
              tgb_choice: "ug/ml",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 82.0,
              iron_choice: "µg/dl",
              fert: 20.0,
              fert_choice: "ng/ml",
              tibc: 220.0,
              tibc_choice: "µg/dl",
              tsat: 10.0,
              tsat_choice: "%",
              retc: 0.2,
              retc_choice: "%",
            },
            other: {
              vitd: 20.0,
              vitd_choice: "ng/ml",
              oh2d: 10.0,
              oh2d_choice: "pg/ml",
              fibr: 100.0,
              fibr_choice: "mg/dl",
              crp: -2.5,
              crp_choice: "mg/l",
              hcrp: -3.5,
              hcrp_choice: "mg/l",
              sedr: -4.5,
              sedr_choice: "mm/hr",
              homo: 2.0,
              homo_choice: "umol/l",
              insu: -1,
              insu_choice: "μu/ml",
              fruc: 120.0,
              fruc_choice: "umol/l",
              cpep: 0.1,
              cpep_choice: "ng/ml",
              lep: 2.0,
              lep_choice: "ng/dl",
            },
          },
          HIGH: { 
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1957-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 8.5,
              wbc_choice: "",
              rbc: 8.5,
              rbc_choice: "million/ul",
              hgb: 18.0,
              hgb_choice: "g/dl",
              hct: 88.0,
              hct_choice: "%",
              mcv: 189.9,
              mcv_choice: "μ^3",
              mch: 38.9,
              mch_choice: "pg",
              mchc: 38.0,
              mchc_choice: "g/dl",
              rdw: 18.0,
              rdw_choice: "%",
              plt_count: 388.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 18.5,
              mpv_choice: "",
              neutrophils: 68.0,
              neutrophils_choice: "%",
              lymphocytes: 48.0,
              lymphocytes_choice: "%",
              monocytes: 8.0,
              monocytes_choice: "%",
              eosinophils: 8.0,
              eosinophils_choice: "%",
              basophils: 8.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 98.0,
              gluc_choice: "mg/dl",
              bun: 18,
              bun_choice: "mg/dl",
              crea: 8.1,
              crea_choice: "mg/dl",
              egfr:10000.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 18.0,
              bcrt_choice: "",
              sod: 148.0,
              sod_choice: "meq/l",
              pot: 8.4,
              pot_choice: "meq/l",
              chl: 186.0,
              chl_choice: "meq/l",
              co2: 80.0,
              co2_choice: "meq/l",
              cal: 80.0,
              cal_choice: "meq/l",
              tpro: 8.4,
              tpro_choice: "g/dl",
              alb: 8.0,
              alb_choice: "g/dl",
              glob: 8.8,
              glob_choice: "g/dl",
              agrt: 8.0,
              agrt_choice: "",
              tbil: 8.2,
              tbil_choice: "mg/dl",
              aphi: 180.0,
              aphi_choice: "u/l",
              ast: 28.0,
              ast_choice: "u/l",
              alt: 28.0,
              alt_choice: "u/l",
              a1c: 8.4,
              a1c_choice: "%",
              eag: 188.0,
              eag_choice: "mg/dl",
              mag: 8.5,
              mag_choice: "mg/dl",
              phos:8.5,
              phos_choice: "mg/dl",
              uacd: 8.9,
              uacd_choice: "mg/dl",
              ldh: 208.0,
              ldh_choice: "u/l",
              ggt: 28.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 278.0,
              tcho_choice: "mg/dl",
              hdl: 78.0,
              hdl_choice: "mg/dl",
              trig: 108.0,
              trig_choice: "mg/dl",
              ldl: 178.0,
              ldl_choice: "mg/dl",
              chrt: 8.0,
              chrt_choice: "",
              vldl: 48.0,
              vldl_choice: "mg/dl",
              apa1: 9999.8,
              apa1_choice: "mg/dl",
              apb: 90.8,
              apb_choice: "mg/dl",
              bart: 8.77,
              bart_choice: "",
            },
            thyroid: {
              tsh: 8.0,
              tsh_choice: "mlu/l",
              ft4: 8.5,
              ft4_choice: "ng/dl",
              ft3: 8.25,
              ft3_choice: "pg/dl",
              tt4: 8.1,
              tt4_choice: "ng/dl",
              tt3: 188.0,
              tt3_choice: "ng/dl%",
              ut3: 38.0,
              ut3_choice: "ng/dl",
              rt3: 28.7,
              rt3_choice: "ng/dl",
              tgb: 28.0,
              tgb_choice: "ug/ml",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 138.0,
              iron_choice: "µg/dl",
              fert: 208.0,
              fert_choice: "ng/ml",
              tibc: 358.0,
              tibc_choice: "µg/dl",
              tsat: 38.0,
              tsat_choice: "%",
              retc: 8.0,
              retc_choice: "%",
            },
            other: {
              vitd: 58.0,
              vitd_choice: "ng/ml",
              oh2d: 58.0,
              oh2d_choice: "pg/ml",
              fibr: 308.0,
              fibr_choice: "mg/dl",
              crp: 8.8,
              crp_choice: "mg/l",
              hcrp: 8.0,
              hcrp_choice: "mg/l",
              sedr: 8.0,
              sedr_choice: "mm/hr",
              homo: 18.0,
              homo_choice: "umol/l",
              insu: 18.0,
              insu_choice: "μu/ml",
              fruc: 278.0,
              fruc_choice: "umol/l",
              cpep: 8.1,
              cpep_choice: "ng/ml",
              lep: 8.0,
              lep_choice: "ng/dl",
            }
          },
        },
      },
      "59<":  {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1980-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 2.7,
              wbc_choice: "",
              rbc: 2.9,
              rbc_choice: "million/ul",
              hgb: 2.0,
              hgb_choice: "g/dl",
              hct: 26.0,
              hct_choice: "%",
              mcv: 29.0,
              mcv_choice: "μ^3",
              mch: 16.6,
              mch_choice: "pg",
              mchc: 11.5,
              mchc_choice: "g/dl",
              rdw: 2.0,
              rdw_choice: "%",
              plt_count: 152.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 2.5,
              mpv_choice: "",
              neutrophils: 25.0,
              neutrophils_choice: "%",
              lymphocytes: 12.0,
              lymphocytes_choice: "%",
              monocytes: 2.0,
              monocytes_choice: "%",
              eosinophils: -2.0,
              eosinophils_choice: "%",
              basophils: -3.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 25,
              gluc_choice: "mg/dl",
              bun: 2.0,
              bun_choice: "mg/dl",
              crea: 0.2,
              crea_choice: "mg/dl",
              egfr: 20.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 2.0,
              bcrt_choice: "",
              sod: 132.0,
              sod_choice: "meq/l",
              pot: 2.5,
              pot_choice: "meq/l",
              chl: 92.0,
              chl_choice: "meq/l",
              co2: 22.0,
              co2_choice: "meq/l",
              cal: 2.5,
              cal_choice: "meq/l",
              tpro: 2.0,
              tpro_choice: "g/dl",
              alb: 2.6,
              alb_choice: "g/dl",
              glob: 1.0,
              glob_choice: "g/dl",
              agrt: 0.1,
              agrt_choice: "",
              tbil: -2.1,
              tbil_choice: "mg/dl",
              aphi: 22.0,
              aphi_choice: "u/l",
              ast: -2.2,
              ast_choice: "u/l",
              alt: -1.0,
              alt_choice: "u/l",
              a1c: 2.1,
              a1c_choice: "%",
              eag: 20.0,
              eag_choice: "mg/dl",
              mag: 0.5,
              mag_choice: "mg/dl",
              phos: 0.5,
              phos_choice: "mg/dl",
              uacd: 0.2,
              uacd_choice: "mg/dl",
              ldh: 20.0,
              ldh_choice: "u/l",
              ggt: -1.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 90.0,
              tcho_choice: "mg/dl",
              hdl: 29.0,
              hdl_choice: "mg/dl",
              trig: -2.0,
              trig_choice: "mg/dl",
              ldl: -5.0,
              ldl_choice: "mg/dl",
              chrt: -3.0,
              chrt_choice: "",
              vldl: 2.0,
              vldl_choice: "mg/dl",
              apa1: 91.0,
              apa1_choice: "mg/dl",
              apb: -2.0,
              apb_choice: "mg/dl",
              bart: -3.95,
              bart_choice: "",
            },
            thyroid: {
              tsh: -1.45,
              tsh_choice: "mlu/l",
              ft4: -1.7,
              ft4_choice: "ng/dl",
              ft3: -2.3,
              ft3_choice: "pg/dl",
              tt4: 0.5,
              tt4_choice: "ng/dl",
              tt3: 70.0,
              tt3_choice: "ng/dl%",
              ut3: 22.0,
              ut3_choice: "ng/dl",
              rt3: 2.2,
              rt3_choice: "ng/dl",
              tgb: 1.7,
              tgb_choice: "ug/ml",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron:20.0,
              iron_choice: "µg/dl",
              fert: 30.0,
              fert_choice: "ng/ml",
              tibc: 240.0,
              tibc_choice: "µg/dl",
              tsat: 10.0,
              tsat_choice: "%",
              retc:-1.5,
              retc_choice: "%",
            },
            other: {
              vitd: 25.0,
              vitd_choice: "ng/ml",
              oh2d: 10.6,
              oh2d_choice: "pg/ml",
              fibr: 140.0,
              fibr_choice: "mg/dl",
              crp: -1.2,
              crp_choice: "mg/l",
              hcrp: -1.1,
              hcrp_choice: "mg/l",
              sedr: -1.5,
              sedr_choice: "nmol/l",
              homo: -1.9,
              homo_choice: "umol/l",
              insu: -1.4,
              insu_choice: "μu/ml",
              fruc: 150.0,
              fruc_choice: "umol/l",
              cpep: 0.2,
              cpep_choice: "ng/ml",
              lep: 2.0,
              lep_choice: "ng/dl",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1976-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 19.0,
              wbc_choice: "",
              rbc: 5.9,
              rbc_choice: "million/ul",
              hgb: 19.0,
              hgb_choice: "g/dl",
              hct: 49.2,
              hct_choice: "%",
              mcv: 99.0,
              mcv_choice: "μ^3",
              mch: 39.0,
              mch_choice: "pg",
              mchc: 39.0,
              mchc_choice: "g/dl",
              rdw: 19.4,
              rdw_choice: "%",
              plt_count: 389.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 19.5,
              mpv_choice: "",
              neutrophils: 79.0,
              neutrophils_choice: "%",
              lymphocytes: 49.0,
              lymphocytes_choice: "%",
              monocytes: 19.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 9.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 119.0,
              gluc_choice: "mg/dl",
              bun: 29,
              bun_choice: "mg/dl",
              crea: 1.9,
              crea_choice: "mg/dl",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 29.0,
              bcrt_choice: "",
              sod: 149.0,
              sod_choice: "meq/l",
              pot: 9.3,
              pot_choice: "meq/l",
              chl: 199,
              chl_choice: "meq/l",
              co2: 39.0,
              co2_choice: "meq/l",
              cal: 19.8,
              cal_choice: "meq/l",
              tpro: 9.5,
              tpro_choice: "g/dl",
              alb: 9.8,
              alb_choice: "g/dl",
              glob: 9.9,
              glob_choice: "g/dl",
              agrt: 9.5,
              agrt_choice: "",
              tbil: 9.2,
              tbil_choice: "mg/dl",
              aphi: 129.0,
              aphi_choice: "u/l",
              ast: 49.0,
              ast_choice: "u/l",
              alt: 49.0,
              alt_choice: "u/l",
              a1c: 9.7,
              a1c_choice: "%",
              eag: 119,
              eag_choice: "mg/dl",
              mag: 9.6,
              mag_choice: "mg/dl",
              phos:9.5,
              phos_choice: "mg/dl",
              uacd: 9.7,
              uacd_choice: "mg/dl",
              ldh: 229.0,
              ldh_choice: "u/l",
              ggt: 79.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 209.0,
              tcho_choice: "mg/dl",
              hdl: 9999.9,
              hdl_choice: "mg/dl",
              trig: 149.9,
              trig_choice: "mg/dl",
              ldl: 99.9,
              ldl_choice: "mg/dl",
              chrt: 9.4,
              chrt_choice: "",
              vldl: 49.0,
              vldl_choice: "mg/dl",
              apa1: 179.0,
              apa1_choice: "mg/dl",
              apb: 99.0,
              apb_choice: "mg/dl",
              bart: 9999.9,
              bart_choice: "",
            },
            thyroid: {
              tsh: 9.5,
              tsh_choice: "mlu/l",
              ft4: 9.4,
              ft4_choice: "ng/dl",
              ft3: 9.8,
              ft3_choice: "pg/dl",
              tt4: 19.5,
              tt4_choice: "ng/dl",
              tt3: 239.0,
              tt3_choice: "ng/dl%",
              ut3: 39.9,
              ut3_choice: "ng/dl",
              rt3: 29.1,
              rt3_choice: "ng/dl",
              tgb: 39.9,
              tgb_choice: "ug/ml",
              tpo: 'positive',
              tpo_choice: "",
              atgb: 'positive',
              atgb_choice: "",
              tgt: 'positive',
              tgt_choice: "",
            },
            iron: {
              iron: 179.0,
              iron_choice: "µg/dl",
              fert: 239.0,
              fert_choice: "ng/ml",
              tibc: 359.0,
              tibc_choice: "µg/dl",
              tsat: 69.0,
              tsat_choice: "%",
              retc: 1.9,
              retc_choice: "%",
            },
            other: {
              vitd: 109.0,
              vitd_choice: "ng/ml",
              oh2d: 59.3,
              oh2d_choice: "pg/ml",
              fibr: 409.0,
              fibr_choice: "mg/dl",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.0,
              hcrp_choice: "mg/l",
              sedr: 19.0,
              sedr_choice: "mm/hr",
              homo: 19.5,
              homo_choice: "umol/l",
              insu: 19.9,
              insu_choice: "μu/ml",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 3.95,
              cpep_choice: "ng/ml",
              lep: 10.9,
              lep_choice: "ng/dl",
            },
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1974-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 1.0,
              wbc_choice: "",
              rbc: 1.2,
              rbc_choice: "million/ul",
              hgb: 11,
              hgb_choice: "g/dl",
              hct: 10.0,
              hct_choice: "%",
              mcv: 12.0,
              mcv_choice: "μ^3",
              mch: 18.0,
              mch_choice: "pg",
              mchc: 12.0,
              mchc_choice: "g/dl",
              rdw: 1.0,
              rdw_choice: "%",
              plt_count: 115.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 1.5,
              mpv_choice: "",
              neutrophils: 10.0,
              neutrophils_choice: "%",
              lymphocytes: 14.0,
              lymphocytes_choice: "%",
              monocytes:-1.1,
              monocytes_choice: "%",
              eosinophils: -1.1,
              eosinophils_choice: "%",
              basophils: -1.1,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 10.0,
              gluc_choice: "mg/dl",
              bun: 1,
              bun_choice: "mg/dl",
              crea: 0.1,
              crea_choice: "mg/dl",
              egfr: 51.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 1.0,
              bcrt_choice: "",
              sod: 115.0,
              sod_choice: "meq/l",
              pot: 1.0,
              pot_choice: "meq/l",
              chl: 10.0,
              chl_choice: "meq/l",
              co2: 15.0,
              co2_choice: "meq/l",
              cal: 1.2,
              cal_choice: "meq/l",
              tpro: 1.9,
              tpro_choice: "g/dl",
              alb: 1.6,
              alb_choice: "g/dl",
              glob: 1.4,
              glob_choice: "g/dl",
              agrt: 0.5,
              agrt_choice: "",
              tbil: -1.5,
              tbil_choice: "mg/dl",
              aphi: 10.0,
              aphi_choice: "u/l",
              ast: 1.0,
              ast_choice: "u/l",
              alt: 1.0,
              alt_choice: "u/l",
              a1c: 1.1,
              a1c_choice: "%",
              eag: 10.0,
              eag_choice: "mg/dl",
              mag: 1.0,
              mag_choice: "mg/dl",
              phos:1.0,
              phos_choice: "mg/dl",
              uacd: 1.5,
              uacd_choice: "mg/dl",
              ldh: 110.0,
              ldh_choice: "u/l",
              ggt: 1.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 110.0,
              tcho_choice: "mg/dl",
              hdl: 51.0,
              hdl_choice: "mg/dl",
              trig: 10.0,
              trig_choice: "mg/dl",
              ldl: 10.0,
              ldl_choice: "mg/dl",
              chrt: -1.0,
              chrt_choice: "",
              vldl: 1.0,
              vldl_choice: "mg/dl",
              apa1: 110.0,
              apa1_choice: "mg/dl",
              apb: -1.0,
              apb_choice: "mg/dl",
              bart: -1.0,
              bart_choice: "",
            },
            thyroid: {
              tsh: -1.0,
              tsh_choice: "mlu/l",
              ft4: -1.0,
              ft4_choice: "ng/dl",
              ft3: 1.0,
              ft3_choice: "pg/dl",
              tt4: 1.5,
              tt4_choice: "ng/dl",
              tt3: 10.0,
              tt3_choice: "ng/dl%",
              ut3: 17.0,
              ut3_choice: "ng/dl",
              rt3: 11.9,
              rt3_choice: "ng/dl",
              tgb: 11.0,
              tgb_choice: "ug/ml",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 15.0,
              iron_choice: "µg/dl",
              fert: 15.0,
              fert_choice: "ng/ml",
              tibc: 150.0,
              tibc_choice: "µg/dl",
              tsat: 10.0,
              tsat_choice: "%",
              retc: -1.5,
              retc_choice: "%",
            },
            other: {
              vitd: 10.0,
              vitd_choice: "ng/ml",
              oh2d: 10.0,
              oh2d_choice: "pg/ml",
              fibr: 100.0,
              fibr_choice: "mg/dl",
              crp: -1.0,
              crp_choice: "mg/l",
              hcrp: -1.0,
              hcrp_choice: "mg/l",
              sedr: -1.0,
              sedr_choice: "mm/hr",
              homo: 1.0,
              homo_choice: "umol/l",
              insu: 1.0,
              insu_choice: "μu/ml",
              fruc: 110.0,
              fruc_choice: "umol/l",
              cpep: 0.1,
              cpep_choice: "ng/ml",
              lep: 1.0,
              lep_choice: "ng/dl",
            },
          },
          HIGH: { 
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "us",
            },
            cbc: {
              wbc: 9.5,
              wbc_choice: "",
              rbc: 9.5,
              rbc_choice: "million/ul",
              hgb: 19.0,
              hgb_choice: "g/dl",
              hct: 49.0,
              hct_choice: "%",
              mcv: 99.9,
              mcv_choice: "μ^3",
              mch: 91.9,
              mch_choice: "pg",
              mchc: 39.0,
              mchc_choice: "g/dl",
              rdw: 19.0,
              rdw_choice: "%",
              plt_count: 395.0,
              plt_count_choice: "mm^3, x 1000",
              mpv: 19.5,
              mpv_choice: "",
              neutrophils: 90.0,
              neutrophils_choice: "%",
              lymphocytes: 49.0,
              lymphocytes_choice: "%",
              monocytes: 9.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 9.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 99.0,
              gluc_choice: "mg/dl",
              bun: 19,
              bun_choice: "mg/dl",
              crea: 1.9,
              crea_choice: "mg/dl",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 16.9,
              bcrt_choice: "",
              sod: 142.9,
              sod_choice: "meq/l",
              pot: 4.9,
              pot_choice: "meq/l",
              chl: 106.9,
              chl_choice: "meq/l",
              co2: 30.9,
              co2_choice: "meq/l",
              cal: 10.9,
              cal_choice: "meq/l",
              tpro: 7.9,
              tpro_choice: "g/dl",
              alb: 5.9,
              alb_choice: "g/dl",
              glob: 9.8,
              glob_choice: "g/dl",
              agrt: 9.0,
              agrt_choice: "",
              tbil: 9.2,
              tbil_choice: "mg/dl",
              aphi: 109.0,
              aphi_choice: "u/l",
              ast: 29.0,
              ast_choice: "u/l",
              alt: 29.0,
              alt_choice: "u/l",
              a1c: 9.4,
              a1c_choice: "%",
              eag: 109.0,
              eag_choice: "mg/dl",
              mag: 9.5,
              mag_choice: "mg/dl",
              phos:9.5,
              phos_choice: "mg/dl",
              uacd: 9.9,
              uacd_choice: "mg/dl",
              ldh: 209.0,
              ldh_choice: "u/l",
              ggt: 29.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 290.0,
              tcho_choice: "mg/dl",
              hdl: 79.0,
              hdl_choice: "mg/dl",
              trig: 109.0,
              trig_choice: "mg/dl",
              ldl: 190.0,
              ldl_choice: "mg/dl",
              chrt: 9.0,
              chrt_choice: "",
              vldl: 49.0,
              vldl_choice: "mg/dl",
              apa1: 9999.9,
              apa1_choice: "mg/dl",
              apb: 90.9,
              apb_choice: "mg/dl",
              bart: 0.97,
              bart_choice: "",
            },
            thyroid: {
              tsh: 9.0,
              tsh_choice: "mlu/l",
              ft4: 9.5,
              ft4_choice: "ng/dl",
              ft3: 9.25,
              ft3_choice: "pg/dl",
              tt4: 9.1,
              tt4_choice: "ng/dl",
              tt3: 198.0,
              tt3_choice: "ng/dl%",
              ut3: 39.0,
              ut3_choice: "ng/dl",
              rt3: 29.7,
              rt3_choice: "ng/dl",
              tgb: 29.0,
              tgb_choice: "ug/ml",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 139.0,
              iron_choice: "µg/dl",
              fert: 209.0,
              fert_choice: "ng/ml",
              tibc: 359.0,
              tibc_choice: "µg/dl",
              tsat: 35.9,
              tsat_choice: "%",
              retc: 1.9,
              retc_choice: "%",
            },
            other: {
              vitd: 59.0,
              vitd_choice: "ng/ml",
              oh2d: 59.0,
              oh2d_choice: "pg/ml",
              fibr: 390.0,
              fibr_choice: "mg/dl",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.0,
              hcrp_choice: "mg/l",
              sedr: 9.0,
              sedr_choice: "mm/hr",
              homo: 9.0,
              homo_choice: "umol/l",
              insu: 9.0,
              insu_choice: "μu/ml",
              fruc: 290.0,
              fruc_choice: "umol/l",
              cpep: 9.1,
              cpep_choice: "ng/ml",
              lep: 6.9,
              lep_choice: "ng/dl",
            }
          },
        },
      },
    },
    Intl: {
      "60>":  {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1960-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 1.7,
              wbc_choice: "x 109/l",
              rbc: 1.9,
              rbc_choice: "x 1012/l",
              hgb: 110.0,
              hgb_choice: "g/l",
              hct: 16.0,
              hct_choice: "%",
              mcv: 19.0,
              mcv_choice: "fl",
              mch: 16.6,
              mch_choice: "pg",
              mchc: 11.53,
              mchc_choice: "mmol/l",
              rdw: 1.0,
              rdw_choice: "%",
              plt_count: 115.0,
              plt_count_choice: "10^9/l",
              mpv: 1.5,
              mpv_choice: "fl",
              neutrophils: 15.0,
              neutrophils_choice: "%",
              lymphocytes: 4.0,
              lymphocytes_choice: "%",
              monocytes: 1.0,
              monocytes_choice: "%",
              eosinophils: -1.0,
              eosinophils_choice: "%",
              basophils: -1.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 3.0,
              gluc_choice: "mmol/l",
              bun: 1.0,
              bun_choice: "mmol/l",
              crea: 50.0,
              crea_choice: "µmol/l",
              egfr: 40.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 4.0,
              bcrt_choice: "",
              sod: 125.0,
              sod_choice: "mmol/l",
              pot: 1.5,
              pot_choice: "mmol/l",
              chl: 90.0,
              chl_choice: "mmol/l",
              co2: 21.0,
              co2_choice: "mmol/l",
              cal: 1.1,
              cal_choice: "mmol/l",
              tpro: 50.0,
              tpro_choice: "g/l",
              alb: 16.0,
              alb_choice: "g/l",
              glob: 15.0,
              glob_choice: "g/l",
              agrt: -1.1,
              agrt_choice: "",
              tbil: -1.7,
              tbil_choice: "µmol/l",
              aphi: 21.0,
              aphi_choice: "u/l",
              ast: -1.0,
              ast_choice: "u/l",
              alt: -2.0,
              alt_choice: "u/l",
              a1c: 18.31,
              a1c_choice: "mmol/mol",
              eag: 2.94,
              eag_choice: "mmol/l",
              mag: 0.1,
              mag_choice: "mmol/l",
              phos: 0.1,
              phos_choice: "mmol/l",
              uacd: 110.9,
              uacd_choice: "µmol/l",
              ldh: 0.7,
              ldh_choice: "µkat/l",
              ggt: 0.1,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 1.6,
              tcho_choice: "mmol/l",
              hdl: 0.1,
              hdl_choice: "mmol/l",
              trig: -2.0,
              trig_choice: "mmol/l",
              ldl: -3.0,
              ldl_choice: "mmol/l",
              chrt: -4.0,
              chrt_choice: "",
              vldl: -1.0,
              vldl_choice: "mmol/l",
              apa1: 32.0,
              apa1_choice: "µmol/l",
              apb: -2.0,
              apb_choice: "mg/dl",
              bart: -2.95,
              bart_choice: "",
            },
            thyroid: {
              tsh: -1.45,
              tsh_choice: "mlu/l",
              ft4: 1.0,
              ft4_choice: "pmol/l",
              ft3: 1.5,
              ft3_choice: "pmol/l",
              tt4: 17.9,
              tt4_choice: "pmol/l",
              tt3: 0.2,
              tt3_choice: "pmol/l",
              ut3: 21.0,
              ut3_choice: "%",
              rt3: -1.1,
              rt3_choice: "nmol/l",
              tgb: 215.2,
              tgb_choice: "nmol/l",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron: 1.4,
              iron_choice: "µmol/l",
              fert: 31.0,
              fert_choice: "ug/l",
              tibc: 42.8,
              tibc_choice: "µmol/l",
              tsat: 11.0,
              tsat_choice: "%",
              retc: -1.5,
              retc_choice: "%",
            },
            other: {
              vitd: 71.9,
              vitd_choice: "nmol/l",
              oh2d: 41,
              oh2d_choice: "pmol/l",
              fibr: 1.4,
              fibr_choice: "µmol/l",
              crp: -1.0,
              crp_choice: "mg/l",
              hcrp: -1.0,
              hcrp_choice: "nmol/l",
              sedr: -1.0,
              sedr_choice: "mm/hr",
              homo: 2.0,
              homo_choice: "µmol/l",
              insu: 12.9,
              insu_choice: "pmol/l",
              fruc: 120.0,
              fruc_choice: "umol/l",
              cpep: 0.1,
              cpep_choice: "nmol/ml",
              lep: 2.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1950-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 19,
              wbc_choice: "x 109/l",
              rbc: 9.1,
              rbc_choice: "x 1012/l",
              hgb: 190.0,
              hgb_choice: "g/l",
              hct: 49.2,
              hct_choice: "%",
              mcv: 99.0,
              mcv_choice: "fl",
              mch: 39.0,
              mch_choice: "pg",
              mchc: 29.94,
              mchc_choice: "mmol/l",
              rdw: 19.4,
              rdw_choice: "%",
              plt_count: 395.0,
              plt_count_choice: "10^9/l",
              mpv: 19.5,
              mpv_choice: "fl",
              neutrophils: 94.0,
              neutrophils_choice: "%",
              lymphocytes: 96.0,
              lymphocytes_choice: "%",
              monocytes: 19.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 9.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 9.4,
              gluc_choice: "mmol/l",
              bun: 19,
              bun_choice: "mmol/l",
              crea: 192.6,
              crea_choice: "µmol/l",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 29.0,
              bcrt_choice: "",
              sod: 149.0,
              sod_choice: "mmol/l",
              pot: 9.3,
              pot_choice: "mmol/l",
              chl: 199.0,
              chl_choice: "mmol/l",
              co2: 39.0,
              co2_choice: "mmol/l",
              cal: 9.7,
              cal_choice: "mmol/l",
              tpro: 89.0,
              tpro_choice: "g/l",
              alb: 49.0,
              alb_choice: "g/l",
              glob: 39.9,
              glob_choice: "g/l",
              agrt: 9.5,
              agrt_choice: "",
              tbil: 29.5,
              tbil_choice: "µmol/l",
              aphi: 129.0,
              aphi_choice: "u/l",
              ast: 49.0,
              ast_choice: "u/l",
              alt: 49.0,
              alt_choice: "u/l",
              a1c: 39.8,
              a1c_choice: "mmol/mol",
              eag: 9.49,
              eag_choice: "mmol/l",
              mag: 9.1,
              mag_choice: "mmol/l",
              phos: 9.5,
              phos_choice: "mmol/l",
              uacd: 495.0,
              uacd_choice: "µmol/l",
              ldh: 9.7,
              ldh_choice: "µkat/l",
              ggt: 79.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 9.2,
              tcho_choice: "mmol/l",
              hdl: 9999.9,
              hdl_choice: "mmol/l",
              trig: 9.7,
              trig_choice: "mmol/l",
              ldl: 9.6,
              ldl_choice: "mmol/l",
              chrt: 9.4,
              chrt_choice: "",
              vldl: 9.0,
              vldl_choice: "mmol/l",
              apa1: 95.0,
              apa1_choice: "µmol/l",
              apb: 1.95,
              apb_choice: "mg/dl",
              bart: 9999.9,
              bart_choice: "",
            },
            thyroid: {
              tsh: 9.5,
              tsh_choice: "mlu/l",
              ft4: 39.9,
              ft4_choice: "pmol/l",
              ft3: 9.4,
              ft3_choice: "pmol/l",
              tt4: 190.9,
              tt4_choice: "pmol/l",
              tt3: 9.5,
              tt3_choice: "ng/dl%",
              ut3: 99.0,
              ut3_choice: "%",
              rt3: 9.4,
              rt3_choice: "nmol/l",
              tgb: 592.2,
              tgb_choice: "nmol/l",
              tpo: 'positive',
              tpo_choice: "",
              atgb: 'positive',
              atgb_choice: "",
              tgt: 'positive',
              tgt_choice: "",
            },
            iron: {
              iron: 39.4,
              iron_choice: "µmol/l",
              fert: 296.0,
              fert_choice: "ug/l",
              tibc: 69.7,
              tibc_choice: "µmol/l",
              tsat: 69.0,
              tsat_choice: "%",
              retc: 9.0,
              retc_choice: "%",
            },
            other: {
              vitd: 299.6,
              vitd_choice: "nmol/l",
              oh2d: 139,
              oh2d_choice: "pmol/l",
              fibr: 19.8,
              fibr_choice: "µmol/l",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.9,
              hcrp_choice: "nmol/l",
              sedr: 19.0,
              sedr_choice: "mm/hr",
              homo: 19.0,
              homo_choice: "µmol/l",
              insu: 139.1,
              insu_choice: "pmol/l",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 9.3,
              cpep_choice: "nmol/ml",
              lep: 19.0,
              lep_choice: "µg/l",
            }
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1955-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 1,
              wbc_choice: "x 109/l",
              rbc: 1.2,
              rbc_choice: "x 1012/l",
              hgb: 115.0,
              hgb_choice: "g/l",
              hct: 10.0,
              hct_choice: "%",
              mcv: 12.0,
              mcv_choice: "fl",
              mch: 18.0,
              mch_choice: "pg",
              mchc: 11.84,
              mchc_choice: "mmol/l",
              rdw: 1,
              rdw_choice: "%",
              plt_count: 115.0,
              plt_count_choice: "10^9/l",
              mpv: 1.5,
              mpv_choice: "fl",
              neutrophils: 10.0,
              neutrophils_choice: "%",
              lymphocytes: 4.0,
              lymphocytes_choice: "%",
              monocytes: -1.0,
              monocytes_choice: "%",
              eosinophils: -2.0,
              eosinophils_choice: "%",
              basophils: -3.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 1.9,
              gluc_choice: "mmol/l",
              bun: 1.6,
              bun_choice: "mmol/l",
              crea: 10.7,
              crea_choice: "µmol/l",
              egfr: 19.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 1.0,
              bcrt_choice: "",
              sod: 115.0,
              sod_choice: "mmol/l",
              pot: 1.0,
              pot_choice: "mmol/l",
              chl: 90.0,
              chl_choice: "mmol/l",
              co2: 15.0,
              co2_choice: "mmol/l",
              cal: 1.3,
              cal_choice: "mmol/l",
              tpro: 62.0,
              tpro_choice: "g/l",
              alb: 41.0,
              alb_choice: "g/l",
              glob: 14.0,
              glob_choice: "g/l",
              agrt: 0.5,
              agrt_choice: "",
              tbil: 1.6,
              tbil_choice: "µmol/l",
              aphi: 20.0,
              aphi_choice: "u/l",
              ast: 10.0,
              ast_choice: "u/l",
              alt: 11.0,
              alt_choice: "u/l",
              a1c: 11.31,
              a1c_choice: "mmol/mol",
              eag: 1.94,
              eag_choice: "mmol/l",
              mag: 0.1,
              mag_choice: "mmol/l",
              phos: 0.1,
              phos_choice: "mmol/l",
              uacd: 201.2,
              uacd_choice: "µmol/l",
              ldh: 1.3,
              ldh_choice: "µkat/l",
              ggt: 1.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 2.4,
              tcho_choice: "mmol/l",
              hdl: 2.7,
              hdl_choice: "mmol/l",
              trig: -2.6,
              trig_choice: "mmol/l",
              ldl: 2.1,
              ldl_choice: "mmol/l",
              chrt: -2.0,
              chrt_choice: "",
              vldl: -2.0,
              vldl_choice: "mmol/l",
              apa1: 20.0,
              apa1_choice: "µmol/l",
              apb: -2.0,
              apb_choice: "mg/dl",
              bart: -2.0,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.2,
              tsh_choice: "mlu/l",
              ft4: 10.9,
              ft4_choice: "pmol/l",
              ft3: 2.6,
              ft3_choice: "pmol/l",
              tt4: 90.5,
              tt4_choice: "pmol/l",
              tt3: -2.4,
              tt3_choice: "pmol/l",
              ut3: 21.0,
              ut3_choice: "%",
              rt3: 0.1,
              rt3_choice: "nmol/l",
              tgb: 331.0,
              tgb_choice: "nmol/l",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 11.2,
              iron_choice: "µmol/l",
              fert: 15.0,
              fert_choice: "ug/l",
              tibc: 41.8,
              tibc_choice: "µmol/l",
              tsat: 10.0,
              tsat_choice: "%",
              retc: 0.1,
              retc_choice: "%",
            },
            other: {
              vitd: 15.0,
              vitd_choice: "nmol/l",
              oh2d: 18,
              oh2d_choice: "pmol/l",
              fibr: 1.9,
              fibr_choice: "µmol/l",
              crp: -2.1,
              crp_choice: "mg/l",
              hcrp: -1.1,
              hcrp_choice: "nmol/l",
              sedr: 15.0,
              sedr_choice: "mm/hr",
              homo: 1.0,
              homo_choice: "µmol/l",
              insu: 12.9,
              insu_choice: "pmol/l",
              fruc: 180.0,
              fruc_choice: "umol/l",
              cpep: 0.2,
              cpep_choice: "nmol/ml",
              lep: 2.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
              rws_input: {
              ...client_info,
              sex: "female-noncycling",
              date_of_birth: "1956-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 9.5,
              wbc_choice: "x 109/l",
              rbc: 9.9,
              rbc_choice: "x 1012/l",
              hgb: 159.0,
              hgb_choice: "g/l",
              hct: 49.0,
              hct_choice: "%",
              mcv: 99.9,
              mcv_choice: "fl",
              mch: 91.9,
              mch_choice: "pg",
              mchc: 19.94,
              mchc_choice: "mmol/l",
              rdw: 19,
              rdw_choice: "%",
              plt_count: 389.0,
              plt_count_choice: "10^9/l",
              mpv: 19.5,
              mpv_choice: "fl",
              neutrophils: 69.0,
              neutrophils_choice: "%",
              lymphocytes: 49.0,
              lymphocytes_choice: "%",
              monocytes: 9.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 9.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 9,
              gluc_choice: "mmol/l",
              bun: 9.7,
              bun_choice: "mmol/l",
              crea: 99.2,
              crea_choice: "µmol/l",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 19.0,
              bcrt_choice: "",
              sod: 149.0,
              sod_choice: "mmol/l",
              pot: 4.9,
              pot_choice: "mmol/l",
              chl: 109.0,
              chl_choice: "mmol/l",
              co2: 39.0,
              co2_choice: "mmol/l",
              cal: 9.5,
              cal_choice: "mmol/l",
              tpro: 79.0,
              tpro_choice: "g/l",
              alb: 59.0,
              alb_choice: "g/l",
              glob: 29.0,
              glob_choice: "g/l",
              agrt: 9.0,
              agrt_choice: "",
              tbil: 29.5,
              tbil_choice: "µmol/l",
              aphi: 109.0,
              aphi_choice: "u/l",
              ast: 29.0,
              ast_choice: "u/l",
              alt: 29.0,
              alt_choice: "u/l",
              a1c: 39.52,
              a1c_choice: "mmol/mol",
              eag: 9.01,
              eag_choice: "mmol/l",
              mag: 9.0,
              mag_choice: "mmol/l",
              phos: 9.1,
              phos_choice: "mmol/l",
              uacd: 359.9,
              uacd_choice: "µmol/l",
              ldh: 9.3,
              ldh_choice: "µkat/l",
              ggt: 29.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 9.0,
              tcho_choice: "mmol/l",
              hdl: 9.9,
              hdl_choice: "mmol/l",
              trig: 9.1,
              trig_choice: "mmol/l",
              ldl: 9.4,
              ldl_choice: "mmol/l",
              chrt: 9.0,
              chrt_choice: "",
              vldl: 9.0,
              vldl_choice: "mmol/l",
              apa1: 9999.9,
              apa1_choice: "µmol/l",
              apb: 1.95,
              apb_choice: "mg/dl",
              bart: 0.97,
              bart_choice: "",
            },
            thyroid: {
              tsh: 9.0,
              tsh_choice: "mlu/l",
              ft4: 29.3,
              ft4_choice: "pmol/l",
              ft3: 9.0,
              ft3_choice: "pmol/l",
              tt4: 109.2,
              tt4_choice: "pmol/l",
              tt3: 9.6,
              tt3_choice: "pmol/l",
              ut3:39.0,
              ut3_choice: "%",
              rt3: 0.9,
              rt3_choice: "nmol/l",
              tgb: 509.0,
              tgb_choice: "nmol/l",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 29.3,
              iron_choice: "µmol/l",
              fert: 209.0,
              fert_choice: "ug/l",
              tibc: 359.8,
              tibc_choice: "µmol/l",
              tsat: 39.0,
              tsat_choice: "%",
              retc: 9.0,
              retc_choice: "%",
            },
            other: {
              vitd: 195.0,
              vitd_choice: "nmol/l",
              oh2d: 190,
              oh2d_choice: "pmol/l",
              fibr: 8.9,
              fibr_choice: "µmol/l",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.9,
              hcrp_choice: "nmol/l",
              sedr: 9.0,
              sedr_choice: "mm/hr",
              homo: 9.0,
              homo_choice: "µmol/l",
              insu: 59.6,
              insu_choice: "pmol/l",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 0.9,
              cpep_choice: "nmol/ml",
              lep: 9.0,
              lep_choice: "µg/l",
            }
          },
        },
      },
      "59<": {
        CONV: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "female-cycling",
              date_of_birth: "1977-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 1.7,
              wbc_choice: "x 109/l",
              rbc: 1.9,
              rbc_choice: "x 1012/l",
              hgb: 110.0,
              hgb_choice: "g/l",
              hct: 31.0,
              hct_choice: "%",
              mcv: 71.0,
              mcv_choice: "fl",
              mch: 21.6,
              mch_choice: "pg",
              mchc: 11.53,
              mchc_choice: "mmol/l",
              rdw: 1.0,
              rdw_choice: "%",
              plt_count: 115.0,
              plt_count_choice: "10^9/l",
              mpv: 1.5,
              mpv_choice: "fl",
              neutrophils: 15.0,
              neutrophils_choice: "%",
              lymphocytes: 11.0,
              lymphocytes_choice: "%",
              monocytes: 1.0,
              monocytes_choice: "%",
              eosinophils: -1.0,
              eosinophils_choice: "%",
              basophils: -1.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 1.6,
              gluc_choice: "mmol/l",
              bun: 0.8,
              bun_choice: "mmol/l",
              crea: 51.0,
              crea_choice: "µmol/l",
              egfr: 10.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 1.0,
              bcrt_choice: "",
              sod: 115.0,
              sod_choice: "mmol/l",
              pot: 1.5,
              pot_choice: "mmol/l",
              chl: 91.0,
              chl_choice: "mmol/l",
              co2: 21.0,
              co2_choice: "mmol/l",
              cal: 1.1,
              cal_choice: "mmol/l",
              tpro: 10.0,
              tpro_choice: "g/l",
              alb: 16.0,
              alb_choice: "g/l",
              glob: 10.0,
              glob_choice: "g/l",
              agrt: 0.1,
              agrt_choice: "",
              tbil: 0.7,
              tbil_choice: "µmol/l",
              aphi: 21.0,
              aphi_choice: "u/l",
              ast: -1.0,
              ast_choice: "u/l",
              alt: -1.0,
              alt_choice: "u/l",
              a1c: 11.31,
              a1c_choice: "mmol/mol",
              eag: 1.94,
              eag_choice: "mmol/l",
              mag: -1.6,
              mag_choice: "mmol/l",
              phos: -1.8,
              phos_choice: "mmol/l",
              uacd: 110.9,
              uacd_choice: "µmol/l",
              ldh: 0.7,
              ldh_choice: "µkat/l",
              ggt: -2.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 0.6,
              tcho_choice: "mmol/l",
              hdl: -1.0,
              hdl_choice: "mmol/l",
              trig: -2.0,
              trig_choice: "mmol/l",
              ldl: -3.0,
              ldl_choice: "mmol/l",
              chrt: -3.0,
              chrt_choice: "",
              vldl: -3.1,
              vldl_choice: "mmol/l",
              apa1: 31.0,
              apa1_choice: "µmol/l",
              apb: -2.0,
              apb_choice: "mg/dl",
              bart: -2.95,
              bart_choice: "",
            },
            thyroid: {
              tsh: -1.45,
              tsh_choice: "mlu/l",
              ft4: 1.0,
              ft4_choice: "pmol/l",
              ft3: 1.5,
              ft3_choice: "pmol/l",
              tt4: 51.9,
              tt4_choice: "pmol/l",
              tt3:0.2,
              tt3_choice: "pmol/l",
              ut3: 23.0,
              ut3_choice: "%",
              rt3: -1.0,
              rt3_choice: "nmol/l",
              tgb: 231.2,
              tgb_choice: "nmol/l",
              tpo: 'negative',
              tpo_choice: "",
              atgb: 'negative',
              atgb_choice: "",
              tgt: 'negative',
              tgt_choice: "",
            },
            iron: {
              iron: 51.4,
              iron_choice: "µmol/l",
              fert: 31.0,
              fert_choice: "ug/l",
              tibc: 41.8,
              tibc_choice: "µmol/l",
              tsat: 11.0,
              tsat_choice: "%",
              retc: 0.1,
              retc_choice: "%",
            },
            other: {
              vitd: 71.9,
              vitd_choice: "nmol/l",
              oh2d: 41,
              oh2d_choice: "pmol/l",
              fibr: 4.1,
              fibr_choice: "µmol/l",
              crp: -1.1,
              crp_choice: "mg/l",
              hcrp: -1.1,
              hcrp_choice: "nmol/l",
              sedr: -1.1,
              sedr_choice: "mm/hr",
              homo: 1.0,
              homo_choice: "µmol/l",
              insu: 11.9,
              insu_choice: "pmol/l",
              fruc: 110.0,
              fruc_choice: "umol/l",
              cpep: -1.3,
              cpep_choice: "nmol/ml",
              lep: 1.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1980-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 15,
              wbc_choice: "x 109/l",
              rbc: 5.5,
              rbc_choice: "x 1012/l",
              hgb: 165.0,
              hgb_choice: "g/l",
              hct: 48.5,
              hct_choice: "%",
              mcv: 97.5,
              mcv_choice: "fl",
              mch: 33.5,
              mch_choice: "pg",
              mchc: 22.95,
              mchc_choice: "mmol/l",
              rdw: 15.5,
              rdw_choice: "%",
              plt_count: 385.5,
              plt_count_choice: "10^9/l",
              mpv: 15.5,
              mpv_choice: "fl",
              neutrophils: 75.0,
              neutrophils_choice: "%",
              lymphocytes: 46.5,
              lymphocytes_choice: "%",
              monocytes: 15.0,
              monocytes_choice: "%",
              eosinophils: 7.5,
              eosinophils_choice: "%",
              basophils: 5.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 6.5,
              gluc_choice: "mmol/l",
              bun: 9.5,
              bun_choice: "mmol/l",
              crea: 135.6,
              crea_choice: "µmol/l",
              egfr: 9999.5,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 25.0,
              bcrt_choice: "",
              sod: 155.0,
              sod_choice: "mmol/l",
              pot: 5.5,
              pot_choice: "mmol/l",
              chl: 109.5,
              chl_choice: "mmol/l",
              co2: 35.0,
              co2_choice: "mmol/l",
              cal: 5.7,
              cal_choice: "mmol/l",
              tpro: 85.5,
              tpro_choice: "g/l",
              alb: 48.5,
              alb_choice: "g/l",
              glob: 39.5,
              glob_choice: "g/l",
              agrt: 5.5,
              agrt_choice: "",
              tbil: 50.5,
              tbil_choice: "µmol/l",
              aphi: 125.0,
              aphi_choice: "u/l",
              ast: 45.0,
              ast_choice: "u/l",
              alt: 45.5,
              alt_choice: "u/l",
              a1c: 58.8,
              a1c_choice: "mmol/mol",
              eag: 7.49,
              eag_choice: "mmol/l",
              mag: 5.1,
              mag_choice: "mmol/l",
              phos: 5.5,
              phos_choice: "mmol/l",
              uacd: 485.5,
              uacd_choice: "µmol/l",
              ldh: 5.7,
              ldh_choice: "µkat/l",
              ggt: 75.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 5.5,
              tcho_choice: "mmol/l",
              hdl: 9999.5,
              hdl_choice: "mmol/l",
              trig: 5.7,
              trig_choice: "mmol/l",
              ldl: 5.6,
              ldl_choice: "mmol/l",
              chrt: 5.4,
              chrt_choice: "",
              vldl: 5.0,
              vldl_choice: "mmol/l",
              apa1: 75.5,
              apa1_choice: "µmol/l",
              apb: 5.75,
              apb_choice: "mg/dl",
              bart: 9999.5,
              bart_choice: "",
            },
            thyroid: {
              tsh: 5.5,
              tsh_choice: "mlu/l",
              ft4: 35.9,
              ft4_choice: "pmol/l",
              ft3: 7.5,
              ft3_choice: "pmol/l",
              tt4: 165.9,
              tt4_choice: "pmol/l",
              tt3: 5.5,
              tt3_choice: "pmol/l",
              ut3: 39.5,
              ut3_choice: "%",
              rt3: 5.4,
              rt3_choice: "nmol/l",
              tgb: 575.2,
              tgb_choice: "nmol/l",
              tpo: 'positive',
              tpo_choice: "",
              atgb: 'positive',
              atgb_choice: "",
              tgt: 'positive',
              tgt_choice: "",
            },
            iron: {
              iron: 35.4,
              iron_choice: "µmol/l",
              fert: 236.5,
              fert_choice: "ug/l",
              tibc: 65.7,
              tibc_choice: "µmol/l",
              tsat: 65.0,
              tsat_choice: "%",
              retc: 5.0,
              retc_choice: "%",
            },
            other: {
              vitd: 250.6,
              vitd_choice: "nmol/l",
              oh2d: 135,
              oh2d_choice: "pmol/l",
              fibr: 15.8,
              fibr_choice: "µmol/l",
              crp: 8.5,
              crp_choice: "mg/l",
              hcrp: 10.5,
              hcrp_choice: "nmol/l",
              sedr: 15.5,
              sedr_choice: "mm/hr",
              homo: 12.5,
              homo_choice: "µmol/l",
              insu: 136.5,
              insu_choice: "pmol/l",
              fruc: 275.0,
              fruc_choice: "umol/l",
              cpep: 1.5,
              cpep_choice: "nmol/ml",
              lep: 10.5,
              lep_choice: "µg/l",
            }
          },
        },
        FUNC: {
          LOW: {
            rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1990-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 2,
              wbc_choice: "x 109/l",
              rbc: 2.2,
              rbc_choice: "x 1012/l",
              hgb: 125.0,
              hgb_choice: "g/l",
              hct: 20.0,
              hct_choice: "%",
              mcv: 72.0,
              mcv_choice: "fl",
              mch: 22.0,
              mch_choice: "pg",
              mchc: 12.84,
              mchc_choice: "mmol/l",
              rdw: 2,
              rdw_choice: "%",
              plt_count: 125.0,
              plt_count_choice: "10^9/l",
              mpv: 2.5,
              mpv_choice: "fl",
              neutrophils: 20.0,
              neutrophils_choice: "%",
              lymphocytes: 22.0,
              lymphocytes_choice: "%",
              monocytes: -2.0,
              monocytes_choice: "%",
              eosinophils: -2.0,
              eosinophils_choice: "%",
              basophils: -2.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 2.9,
              gluc_choice: "mmol/l",
              bun: 2.6,
              bun_choice: "mmol/l",
              crea: 70.2,
              crea_choice: "µmol/l",
              egfr: 52.0,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 8.0,
              bcrt_choice: "",
              sod: 132.0,
              sod_choice: "mmol/l",
              pot: 2.0,
              pot_choice: "mmol/l",
              chl: 90.0,
              chl_choice: "mmol/l",
              co2: 22.0,
              co2_choice: "mmol/l",
              cal: 2.2,
              cal_choice: "mmol/l",
              tpro: 62.0,
              tpro_choice: "g/l",
              alb: 42.0,
              alb_choice: "g/l",
              glob: 22.0,
              glob_choice: "g/l",
              agrt: 1.2,
              agrt_choice: "",
              tbil: 8.2,
              tbil_choice: "µmol/l",
              aphi: 20.0,
              aphi_choice: "u/l",
              ast: 10.0,
              ast_choice: "u/l",
              alt: 12.0,
              alt_choice: "u/l",
              a1c: 20.31,
              a1c_choice: "mmol/mol",
              eag: 2.94,
              eag_choice: "mmol/l",
              mag: -2.8,
              mag_choice: "mmol/l",
              phos: -2.0,
              phos_choice: "mmol/l",
              uacd: 202.2,
              uacd_choice: "µmol/l",
              ldh: 0.3,
              ldh_choice: "µkat/l",
              ggt: 2.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 2.1,
              tcho_choice: "mmol/l",
              hdl: 0.7,
              hdl_choice: "mmol/l",
              trig: 0.2,
              trig_choice: "mmol/l",
              ldl: 1.1,
              ldl_choice: "mmol/l",
              chrt: -1.5,
              chrt_choice: "",
              vldl: -2.1,
              vldl_choice: "mmol/l",
              apa1: 40.0,
              apa1_choice: "µmol/l",
              apb: -2.5,
              apb_choice: "mg/dl",
              bart: -1.5,
              bart_choice: "",
            },
            thyroid: {
              tsh: 0.2,
              tsh_choice: "mlu/l",
              ft4: 10.9,
              ft4_choice: "pmol/l",
              ft3: 2.6,
              ft3_choice: "pmol/l",
              tt4: 92.5,
              tt4_choice: "pmol/l",
              tt3: 0.4,
              tt3_choice: "pmol/l",
              ut3: 22.0,
              ut3_choice: "%",
              rt3: 0.1,
              rt3_choice: "nmol/l",
              tgb: 331.0,
              tgb_choice: "nmol/l",
              tpo: "negative",
              tpo_choice: "",
              atgb: "negative",
              atgb_choice: "",
              tgt: "negative",
              tgt_choice: "",
            },
            iron: {
              iron: 12.2,
              iron_choice: "µmol/l",
              fert: 20.0,
              fert_choice: "ug/l",
              tibc: 42.8,
              tibc_choice: "µmol/l",
              tsat: 10.0,
              tsat_choice: "%",
              retc: -2.5,
              retc_choice: "%",
            },
            other: {
              vitd: 70.0,
              vitd_choice: "nmol/l",
              oh2d: 40,
              oh2d_choice: "pmol/l",
              fibr: 5.0,
              fibr_choice: "µmol/l",
              crp: -2.0,
              crp_choice: "mg/l",
              hcrp: -3.0,
              hcrp_choice: "nmol/l",
              sedr: 15.0,
              sedr_choice: "mm/hr",
              homo: 2.0,
              homo_choice: "µmol/l",
              insu: 11.9,
              insu_choice: "pmol/l",
              fruc: 180.0,
              fruc_choice: "umol/l",
              cpep: 0.1,
              cpep_choice: "nmol/ml",
              lep: 2.0,
              lep_choice: "µg/l",
            }
          },
          HIGH: {
              rws_input: {
              ...client_info,
              sex: "male",
              date_of_birth: "1992-02-23T16:04:50Z",
              units_us_or_intl: "intl",
            },
            cbc: {
              wbc: 9.5,
              wbc_choice: "x 109/l",
              rbc: 9.9,
              rbc_choice: "x 1012/l",
              hgb: 159.0,
              hgb_choice: "g/l",
              hct: 49.0,
              hct_choice: "%",
              mcv: 99.9,
              mcv_choice: "fl",
              mch: 39.9,
              mch_choice: "pg",
              mchc: 19.94,
              mchc_choice: "mmol/l",
              rdw: 19,
              rdw_choice: "%",
              plt_count: 395.0,
              plt_count_choice: "10^9/l",
              mpv: 19.5,
              mpv_choice: "fl",
              neutrophils: 69.0,
              neutrophils_choice: "%",
              lymphocytes: 49.0,
              lymphocytes_choice: "%",
              monocytes: 9.0,
              monocytes_choice: "%",
              eosinophils: 9.0,
              eosinophils_choice: "%",
              basophils: 9.0,
              basophils_choice: "%",
            },
            cmp: {
              gluc: 9,
              gluc_choice: "mmol/l",
              bun: 9.7,
              bun_choice: "mmol/l",
              crea: 99.2,
              crea_choice: "µmol/l",
              egfr: 9999.9,
              egfr_choice: "ml/min/1.73^2",
              bcrt: 16.9,
              bcrt_choice: "",
              sod: 149.0,
              sod_choice: "mmol/l",
              pot: 9.5,
              pot_choice: "mmol/l",
              chl: 109.0,
              chl_choice: "mmol/l",
              co2: 309.0,
              co2_choice: "mmol/l",
              cal: 9.5,
              cal_choice: "mmol/l",
              tpro: 79.0,
              tpro_choice: "g/l",
              alb: 59.0,
              alb_choice: "g/l",
              glob: 29.0,
              glob_choice: "g/l",
              agrt: 9.0,
              agrt_choice: "",
              tbil: 29.5,
              tbil_choice: "µmol/l",
              aphi: 109.0,
              aphi_choice: "u/l",
              ast: 29.0,
              ast_choice: "u/l",
              alt: 29.0,
              alt_choice: "u/l",
              a1c: 39.52,
              a1c_choice: "mmol/mol",
              eag: 9.01,
              eag_choice: "mmol/l",
              mag: 9.0,
              mag_choice: "mmol/l",
              phos: 9.1,
              phos_choice: "mmol/l",
              uacd: 359.9,
              uacd_choice: "µmol/l",
              ldh: 3.9,
              ldh_choice: "µkat/l",
              ggt: 29.0,
              ggt_choice: "u/l",
            },
            cholestrol: {
              tcho: 9.2,
              tcho_choice: "mmol/l",
              hdl: 9.9,
              hdl_choice: "mmol/l",
              trig: 9.1,
              trig_choice: "mmol/l",
              ldl: 9.1,
              ldl_choice: "mmol/l",
              chrt: 9.0,
              chrt_choice: "",
              vldl: 9.0,
              vldl_choice: "mmol/l",
              apa1: 9999.9,
              apa1_choice: "µmol/l",
              apb: 9.75,
              apb_choice: "mg/dl",
              bart: 9.77,
              bart_choice: "",
            },
            thyroid: {
              tsh: 9.0,
              tsh_choice: "mlu/l",
              ft4: 29.3,
              ft4_choice: "pmol/l",
              ft3: 9.0,
              ft3_choice: "pmol/l",
              tt4: 109.2,
              tt4_choice: "pmol/l",
              tt3: 9.6,
              tt3_choice: "pmol/l",
              ut3:39.0,
              ut3_choice: "%",
              rt3: 0.9,
              rt3_choice: "nmol/l",
              tgb: 509.0,
              tgb_choice: "nmol/l",
              tpo: "positive",
              tpo_choice: "",
              atgb: "positive",
              atgb_choice: "",
              tgt: "positive",
              tgt_choice: "",
            },
            iron: {
              iron: 29.3,
              iron_choice: "µmol/l",
              fert: 209.0,
              fert_choice: "ug/l",
              tibc: 359.8,
              tibc_choice: "µmol/l",
              tsat: 39.0,
              tsat_choice: "%",
              retc: 9.0,
              retc_choice: "%",
            },
            other: {
              vitd: 129.0,
              vitd_choice: "nmol/l",
              oh2d: 139,
              oh2d_choice: "pmol/l",
              fibr: 8.9,
              fibr_choice: "µmol/l",
              crp: 9.0,
              crp_choice: "mg/l",
              hcrp: 9.9,
              hcrp_choice: "nmol/l",
              sedr: 9.0,
              sedr_choice: "mm/hr",
              homo: 9.0,
              homo_choice: "µmol/l",
              insu: 59.6,
              insu_choice: "pmol/l",
              fruc: 279.0,
              fruc_choice: "umol/l",
              cpep: 0.9,
              cpep_choice: "nmol/ml",
              lep: 9.0,
              lep_choice: "µg/l",
            }
          },
        },
      },
    },
  }
};
export const testObjs = [
  {
    gender: "FC",
    unit_type: "US",
    age_group: "60>",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "FC",
    unit_type: "US",
    age_group: "60>",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "FC",
    unit_type: "US",
    age_group: "60>",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "FC",
    unit_type: "US",
    age_group: "60>",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "FC",
    unit_type: "US",
    age_group: "59<",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "FC",
    unit_type: "US",
    age_group: "59<",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "FC",
    unit_type: "US",
    age_group: "59<",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "FC",
    unit_type: "US",
    age_group: "59<",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "FC",
    unit_type: "Intl",
    age_group: "60>",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "FC",
    unit_type: "Intl",
    age_group: "60>",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "FC",
    unit_type: "Intl",
    age_group: "60>",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "FC",
    unit_type: "Intl",
    age_group: "60>",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "FC",
    unit_type: "Intl",
    age_group: "59<",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "FC",
    unit_type: "Intl",
    age_group: "59<",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "FC",
    unit_type: "Intl",
    age_group: "59<",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "FC",
    unit_type: "Intl",
    age_group: "59<",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "FNC",
    unit_type: "US",
    age_group: "60>",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "FNC",
    unit_type: "US",
    age_group: "60>",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "FNC",
    unit_type: "US",
    age_group: "60>",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "FNC",
    unit_type: "US",
    age_group: "60>",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "FNC",
    unit_type: "US",
    age_group: "59<",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "FNC",
    unit_type: "US",
    age_group: "59<",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "FNC",
    unit_type: "US",
    age_group: "59<",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "FNC",
    unit_type: "US",
    age_group: "59<",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "FNC",
    unit_type: "Intl",
    age_group: "60>",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "FNC",
    unit_type: "Intl",
    age_group: "60>",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "FNC",
    unit_type: "Intl",
    age_group: "60>",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "FNC",
    unit_type: "Intl",
    age_group: "60>",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "FNC",
    unit_type: "Intl",
    age_group: "59<",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "FNC",
    unit_type: "Intl",
    age_group: "59<",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "FNC",
    unit_type: "Intl",
    age_group: "59<",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "FNC",
    unit_type: "Intl",
    age_group: "59<",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "M",
    unit_type: "US",
    age_group: "60>",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "M",
    unit_type: "US",
    age_group: "60>",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "M",
    unit_type: "US",
    age_group: "60>",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "M",
    unit_type: "US",
    age_group: "60>",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "M",
    unit_type: "US",
    age_group: "59<",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "M",
    unit_type: "US",
    age_group: "59<",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "M",
    unit_type: "US",
    age_group: "59<",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "M",
    unit_type: "US",
    age_group: "59<",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "M",
    unit_type: "Intl",
    age_group: "60>",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "M",
    unit_type: "Intl",
    age_group: "60>",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "M",
    unit_type: "Intl",
    age_group: "60>",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "M",
    unit_type: "Intl",
    age_group: "60>",
    common: "FUNC",
    altitude: "HIGH",
  },
  {
    gender: "M",
    unit_type: "Intl",
    age_group: "59<",
    common: "CONV",
    altitude: "LOW",
  },
  {
    gender: "M",
    unit_type: "Intl",
    age_group: "59<",
    common: "CONV",
    altitude: "HIGH",
  },
  {
    gender: "M",
    unit_type: "Intl",
    age_group: "59<",
    common: "FUNC",
    altitude: "LOW",
  },
  {
    gender: "M",
    unit_type: "Intl",
    age_group: "59<",
    common: "FUNC",
    altitude: "HIGH",
  },

]

export const getTestData = (info) => {
  const { gender, unit_type, age_group, common, altitude } = info;
  return data[gender][unit_type][age_group][common][altitude];
};
