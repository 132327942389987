/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import CustomTextField from "../CustomTextField/CustomTextField";
import "./style.css";
import { actions } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectClientSearchData } from "../../store";

const AccountSettings = () => {
  const { accountSetting } = useSelector(selectClientSearchData);

  const dispatch = useDispatch();
  const [form, setForm] = useState({
    practitionerName: {
      value: "",
      error: false,
      required: true,
    },
    businessName: {
      value: "",
      error: false,
      required: true,
    },
    logo: {
      value: "",
      error: false,
      required: false,
    },
  });
  const [file, setFile] = useState(null);
  // const data
  useEffect(() => {
    if (accountSetting) {
      setForm({
        practitionerName: {
          ...form["practitionerName"],
          value: accountSetting["practitioner_name"],
        },
        businessName: {
          ...form["businessName"],
          value: accountSetting["business_name"],
        },
        logo: {
          ...form["logo"],
          value: accountSetting["logo"],
          required: accountSetting["logo"] ? false : true,
        },
      });
    }
  }, [accountSetting]);
  useEffect(() => {
      dispatch({
        type: actions.ACCOUNT_SETTINGS,
        payload: {
          type: "GET",
        },
      });
  }, []);
  const validate = (form) => {
    let n_form = {};
    let isValid = true;
    for (let fkey of Object.keys(form)) {
      if (!form[fkey].value && fkey !=='logo') {
        n_form[fkey] = {
          ...form[fkey],
          error: true,
        };
        isValid = false;
      } else {
        n_form[fkey] = {
          ...form[fkey],
        };
      }
    }

    // console.log("n_form", n_form);
    return {
      isValid,
      n_form,
    };
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, n_form } = validate(form);
    if (!file) {
      delete n_form.logo
    }
    setForm(n_form);

    // console.log("isValid", isValid);
    if (!isValid) {
      return;
    } else {
      //api call
      dispatch({
        type: actions.ACCOUNT_SETTINGS,
        payload: {
          type: "PUT",
          data: {
            practitioner_name: form["practitionerName"].value,
            business_name: form["businessName"].value,
            ...!file? {}: {logo: file},
          },
        },
      });
    }
  };

  const handlefile = (e) => {
    setForm((prev) => {
      return {
        ...prev,
        logo: {
          ...prev["logo"],
          value: e.target.files[0].name,
          required:true,
          error: false,
        },
      };
    });
    setFile(e.target.files[0]);
  };

  // Areeb's Code
  const handlePortal = () => {
  
    dispatch({
      type: actions.STRIPE_CUSTOMER_PORTAL,
      payload: {
        type: "GET",
      },
    });
  };
  return (
    <Container fluid className="main-container p-sm-4 p-3">
      <Row>
        <Col md={12} lg={8} className="center-card setting-form mx-auto">
          <div className="acSet">Account Settings</div>
          <Form onSubmit={handleSubmit}>
            <div className="position-relative">
              <CustomTextField
                label={"Practitioner Name"}
                form={form}
                setForm={setForm}
                fkey="practitionerName"
                helpertext={"Enter Practitioner Name"}
              />
            </div>
            <div className="position-relative">
              <CustomTextField
                label={"Business Name"}
                form={form}
                setForm={setForm}
                fkey="businessName"
                helpertext={"Enter Business Name"}
              />
            </div>
            <div className="position-relative">
              <CustomTextField
                label={"Logo (Must be 500x500 px)"}
                form={file}
                setForm={handlefile}
                fkey="logo"
                type={"file"}
                helpertext={"Browse"}
              />
            </div>
            {accountSetting?.logo ?
              <div className="my-3">
                <a className="text-primary" href={accountSetting?.logo} target="_blank"><u>View Existing Logo</u></a>
              </div>
              : null}
            <div className="text-end">
              <Button
                variant="primary"
                size="md"
                className="primary-bg ms-auto"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
          <Button
              variant="primary"
              size="md"
              className="w-100 primary-bg account-btn"
              type="button"
              onClick={handlePortal}
            >
              Manage Plan
            </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountSettings;
