import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PreloginScreen from "./PreloginScreen";
import SignInPage from "./SignInPage";
import SignUpPage from "./SignUpPage";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import { Tests } from "../Tests/Tests";

const PreloginRouter = () => {
  return (
    <Router>
      <PreloginScreen>
        <Routes>
          <Route path="*" element={<Navigate to="/signin" />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={<ChangePassword />}
          />
        </Routes>
      </PreloginScreen>
    </Router>
  );
};

export default PreloginRouter;
