/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { selectClientSearchData } from "../../store";
import { actions } from "../../store/actions";
import { testsData } from "../../utils/constants";
import AddNewClient2 from "./AddNewClient2";
import SelectUnitDropdown from "./SelectUnitDropdown";
import "./style.css";
import UnitCountTextfield from "./UnitCountTextfield";
import AddNewClient1 from "./AddNewClient1";
const AddNewClient3 = ({ form, file }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clientSearchSecondScreen, setClientSearchSecondScreen] = useState(false);
  const clientSearchData = useSelector(selectClientSearchData);
  let { id } = useParams();
  const [CBC, setCBC] = useState({});
  const [CMP, setCMP] = useState({});
  const [IRON, setIRON] = useState({});
  const [THYROID, setTHYROID] = useState({});
  const [Cholestrol, setCholestrol] = useState({});
  const [Other, setOther] = useState({});
  const [scale, setScale] = useState("");
  const params = useParams();

  useEffect(() => {
    testsData.forEach((m) => {
      let d__ = {};
      m.childs?.forEach((n) => {
        if (n?.value) {
          if (n?.value === "atgb" || n?.value === "tgt" || n?.value === "tpo") {
            if (
              id &&
              clientSearchData.clientResults &&
              clientSearchData.clientResults[m.name]
            ) {
              d__[n?.value] = clientSearchData.clientResults[m.name][n?.value];
            } else if (file) {
              if (clientSearchData.clientResults[m.name][n?.value] !=="") {
              if (clientSearchData.clientResults[m.name][n?.value] === 'positive') {
                d__[n?.value] = "positive";
              } else {
                d__[n?.value] = "negative";
              }
            }
            else{
              d__[n?.value] = "";
            }
              d__[n?.value + "_choice"] =
                n?.units?.[form.units.value]?.[0] ?? null;
            } else if (form) {
              d__[n?.value] = "";
              d__[n?.value + "_choice"] =
                n?.units?.[form.units.value]?.[0] ?? null;
            }
          } else {
            if (
              id &&
              clientSearchData.clientResults &&
              clientSearchData.clientResults[m.name]
            ) {
              d__[n?.value] = clientSearchData.clientResults[m.name][n?.value];
            } else if (file) {
              d__[n?.value] = clientSearchData.clientResults[m.name][n?.value] ? clientSearchData.clientResults[m.name][n?.value] : 0;
              d__[n?.value + "_choice"] =
                n?.units?.[form.units.value]?.[0] ?? null;
            } else if (form) {
              d__[n?.value] = 0;
              d__[n?.value + "_choice"] =
                n?.units?.[form.units.value]?.[0] ?? null;
            }
          }
        }
      });
      if (m.name === "Complete Blood Count (CBC)") {
        // console.log("CBC", d__);
        setCBC(d__);
      }
      if (m.name === "Complete Metabolic Panel (CMP)") {
        // console.log("CMP", d__);

        setCMP(d__);
      }
      if (m.name === "Cholesterol") {
        // console.log("Cholestrol", d__);
        setCholestrol(d__);
      }
      if (m.name === "Thyroid") {
        // console.log("Thyroid", d__);

        setTHYROID(d__);
      }
      if (m.name === "Iron") {
        // console.log("Iron", d__);

        setIRON(d__);
      }
      if (m.name === "Other") {
        // console.log("Other", d__);
        setOther(d__);
      }
    });
  }, [clientSearchData.clientResults]);
  useEffect(() => {
    if (!form) {
      dispatch({
        type: actions.ADD_NEW_TEST,
        payload: {
          id,
          openNext: (scale) => {
            setScale(scale);
          },
        },
      });
    } else {
      setScale(form.units.value);
    }
  }, []);

  const save = (e) => {
    e.preventDefault()
    let payload = {
      cbc: CBC,
      cmp: CMP,
      cholestrol: Cholestrol,
      thyroid: THYROID,
      iron: IRON,
      other: Other,
    };

    if (form) {
      payload = {
        rws_input: {
          client_name: form.client_name.value,
          date: new Date(form.date.value).toISOString().split("T")[0]+"T"+new Date().toISOString().split("T")[1].split(".")[0]+"Z",
          sex: form.sex.value,
          date_of_birth: new Date(form.dob.value).toISOString(),
          units_us_or_intl: form.units.value,
          note: "",
        },
        ...payload,
      };
      if(!id){
      dispatch({
        type: actions.ADD_NEW_TEST,
        payload: {
          data: {...payload, date:new Date(form.date.value).toISOString().split("T")[0]+"T"+new Date().toISOString().split("T")[1].split(".")[0]+"Z"},
          openNext: (data) => {
            // console.log('dId', data)
            navigate("/client-report/" + data.id + "/" + data.client_name, {
              state: { data },
            });
          },
        },
      });
    } else {
      payload = {
        ...payload,
        rws_input:{
          client_name: form.client_name.value,
          date: new Date(form.date.value).toISOString().split("T")[0]+"T"+new Date().toISOString().split("T")[1].split(".")[0]+"Z",
          sex: form.sex.value,
          date_of_birth: new Date(form.dob.value).toISOString(),
          units_us_or_intl: form.units.value,
          note: "",
        },
      };
      dispatch({
        type: actions.UPDATE_TEST_DATA,
        payload: {
          id: params.id,
          data: payload,
          openNext: (data) => {
            navigate("/client-report/" + data.id + "/" + data.client_name, {
              state: { data },
            });
          },
        },
      });
    }
  }
  };
  const getValue = useCallback(
    (category, field) => {
      if (category.includes("CBC") && Object.keys(CBC).length > 0) {
        return CBC[field];
      }
      if (category.includes("CMP") && Object.keys(CMP).length > 0) {
        return CMP[field];
      }
      if (
        category.includes("Cholesterol") &&
        Object.keys(Cholestrol).length > 0
      ) {
        return Cholestrol[field];
      }
      if (category.includes("Thyroid") && Object.keys(THYROID).length > 0) {
        return THYROID[field];
      }
      if (category.includes("Iron") && Object.keys(IRON).length > 0) {
        return IRON[field];
      }
      if (category.includes("Other") && Object.keys(Other).length > 0) {
        return Other[field];
      }

      return undefined;
    },
    [CBC, CMP, Cholestrol, THYROID, IRON, Other]
  );
  const updateValue = (category, field, value) => {
    if (category.includes("CBC") && Object.keys(CBC).length > 0) {
      setCBC((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
    if (category.includes("CMP") && Object.keys(CMP).length > 0) {
      setCMP((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
    if (category.includes("Cholesterol") && Object.keys(Cholestrol).length > 0) {
      setCholestrol((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
    if (category.includes("Thyroid") && Object.keys(THYROID).length > 0) {
      setTHYROID((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
    if (category.includes("Iron") && Object.keys(IRON).length > 0) {
      setIRON((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
    if (category.includes("Other") && Object.keys(Other).length > 0) {
      setOther((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
  };
  if (clientSearchSecondScreen) {
  if(id) return <AddNewClient1/>
  return <AddNewClient2 form={form ? form: {}}/>;
  }
  return (
    <Container fluid className="main-container p-sm-4 p-3">
      <Form onSubmit={save}>
      <Row>
        <Col md={12} lg={10} className="d-flex justify-content-between align-items-center px-0 my-5 mx-auto">
        <div className="mt-3 text-center">
        <Link
            onClick={() => setClientSearchSecondScreen(true)}
            className="primary-fg cursor-pointer"
          >
            <u>Go Back</u>
          </Link>
          </div>
          <Button
            variant="primary"
            size="md"
            className="w-100 primary-bg save-submit d-block ms-auto"
            type='submit'
          >
            Save & Submit
          </Button>
        </Col>
        <Col md={12} lg={10} className="mx-auto bg-white p-0 pt-4 rounded">
          {testsData.map((t, i) => {
            return (
              <div
                className="center-card blood-count-section shadow-none"
                key={i}
              >
                <div className="section-label">{t.name}</div>
                <div className="scroll-div">
                  {t.childs.map((m, i) => {
                    return (
                      <div
                        className="d-flex align-items-center blood-detail-section"
                        key={i}
                      >
                        <div className="item-label">{m.name}</div>
                        {scale ? (
                          <SelectUnitDropdown data={m} scale={scale} />
                        ) : null}
                        <UnitCountTextfield
                          className="blood-count-input"
                          field={m.value}
                          onChangeUnit={(value) =>
                            updateValue(t.name, m.value, value)
                          }
                          value={getValue(t.name, m.value)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Col>
      </Row>
      <Button
        variant="primary"
        size="md"
        className="w-100 p-6 primary-bg save-submit mx-auto d-block mt-4"
        type="submit"
      >
        Save & Submit
      </Button>
      </Form>
    </Container>
  );
};

export default AddNewClient3;
