import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectClientSearchData } from "../../store";
import { actions } from "../../store/actions";
import PatternAnalysisItem from "./PatternAnalysisItem";
import ReactToPrint from 'react-to-print';
import * as moment from "moment-timezone";

const PatternAnalysis = ({ id, name, date }) => {
  const dispatch = useDispatch();
  const { patternAnalysisData } = useSelector(selectClientSearchData);
  useEffect(() => {
    if (id) {
      dispatch({
        type: actions.PATTERN_ANALYSIS,
        payload: { id },
      });
    }
  }, [dispatch, id]);

  

  return (
    <>
      <div className="accordion-section">
        <ReactToPrint
          trigger={() => {
            return <Button
            variant="primary"
            size="md"
            className="d-block ms-auto primary-bg btn-export"
          >
            Export as Pdf
          </Button>
          }}
          content={() => document.body}
          onBeforePrint={() => document.title = `${name} - ${moment(date).format("MMMM DD, YYYY")} - Pattern Analysis`}
          onAfterPrint={() => document.title = 'Restorative Wellness Solutions'}
        />
        <div className="blood-sugar">
          <Row className="">
            <Col lg={12} className="mb-26">
              <div className="login-card">
                <div className="nameLabel">Blood Sugar</div>
                <Row className="">
                  <PatternAnalysisItem column={12} title={'Hyperglycemia'} data={patternAnalysisData?.blood_sugar_pattern?.trend_analysis?.hyperglycemia} section={'redLight'} index={"1"} />
                  <PatternAnalysisItem column={6} title={'Fasting Hypoglycemia'} data={patternAnalysisData?.blood_sugar_pattern?.pattern_analysis?.fasting_hypoglycemia} section={'blueLight'} index={"2"} />
                  <PatternAnalysisItem column={6} title={'Reactive Hypoglycemia'} data={patternAnalysisData?.blood_sugar_pattern?.pattern_analysis?.reactive_hypoglycemia} section={'blueLight'} index={"3"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Insulin Resistance'} data={patternAnalysisData?.blood_sugar_pattern?.pattern_analysis?.insulin_resistance} section={'blueLight'} index={"4"} />
                  <PatternAnalysisItem column={6} title={'Metabolic Syndrome'} data={patternAnalysisData?.blood_sugar_pattern?.pattern_analysis?.metabolic_syndrome} section={'blueLight'} index={"5"} />
                  <PatternAnalysisItem column={12} title={'Type 2 Diabetes'} data={patternAnalysisData?.blood_sugar_pattern?.pattern_analysis?.type_2_diabetes} section={'blueLight'} index={"6"} />
                </Row>
              </div>
            </Col>

            <Col lg={12} className="mb-26">
              <div className="login-card">
                <div className="nameLabel">Cardiovascular</div>
                <Row className="">
                  <PatternAnalysisItem column={6} title={'High Cardiovacular Risk'} data={patternAnalysisData?.cardiovascular_pattern?.trend_analysis?.high_cardiovascular_risk} section={'redLight'} index={"7"} />
                  <PatternAnalysisItem column={6} title={'Hyperlipidemia'} data={patternAnalysisData?.cardiovascular_pattern?.trend_analysis?.hyperlipidemia} section={'redLight'} index={"8"} />
                  <PatternAnalysisItem column={12} title={'Cardiovascular Disease'} data={patternAnalysisData?.cardiovascular_pattern?.trend_analysis?.cardiovascular_disease} section={'redLight'} index={"9"} />
                  {/* <PatternAnalysisItem column={6} title={'Metabolic Syndrome'} data={patternAnalysisData?.cardiovascular_pattern?.pattern_analysis?.metabolic_syndrome} section={'blueLight'} index={"10"} /> */}
                  <PatternAnalysisItem column={6} title={'Non-Specific CV Risk'} data={patternAnalysisData?.cardiovascular_pattern?.pattern_analysis?.cvd_non_specific} section={'blueLight'} index={"11"} />
                  <PatternAnalysisItem column={6} title={'Atherosclerosis Risk'} data={patternAnalysisData?.cardiovascular_pattern?.pattern_analysis?.atherosclerosis} section={'blueLight'} index={"12"} />
                </Row>
              </div>
            </Col>
            <Col lg={12} className="mb-26">
              <div className="login-card">
                <div className="nameLabel">Immune</div>
                <Row className="">
                  <PatternAnalysisItem column={12} title={'Immune Dysfunction Engagement'} data={patternAnalysisData?.immune_patterns?.trend_analysis?.immune_dysfunction_engagement} section={'redLight'} index={"13"} />
                  <PatternAnalysisItem column={12} title={'Allergy and/or Parasitic Infection'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.allergy_parasitic_infection} section={'blueLight'} index={"14"} showNotes={true}/>
                  <PatternAnalysisItem column={12} title={'H. Pylori Infection'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.h_pylori_infection} section={'blueLight'} index={"21"} />
                  <PatternAnalysisItem column={6} title={'Acute Bacterial Infection'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.acute_bacterial_infection} section={'blueLight'} index={"15"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Acute Viral Infection'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.acute_viral_infection_pattern} section={'blueLight'} index={"16"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Chronic Bacterial Infection'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.chronic_bacterial_infection} section={'blueLight'} index={"17"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Chronic Viral Infection'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.chronic_viral_infection} section={'blueLight'} index={"18"} showNotes={true}/>
                  {/* <PatternAnalysisItem column={6} title={'Food Sensitivities/Allergies'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.food_sensitivities_allergies} section={'blueLight'} index={"19"} /> */}
                  {/* <PatternAnalysisItem column={6} title={'Parasitic Infection'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.parasitic_infection} section={'blueLight'} index={"21"} /> */}
                  <PatternAnalysisItem column={6} title={'Spleen Hypofunction'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.spleen_hypofunction} section={'blueLight'} index={"19"} />
                  <PatternAnalysisItem column={6} title={'Spleen Hyperfunction'} data={patternAnalysisData?.immune_patterns?.pattern_analysis?.spleen_metabolism_patterns} section={'blueLight'} index={"20"} />
                </Row>
              </div>
            </Col>

            <Col lg={12} className="mb-26">
              <div className="login-card">
                <div className="nameLabel">
                  Nutritional Deficiency Anemia
                </div>
                <Row className="">
                  <PatternAnalysisItem column={12} title={'Hypochlorhydria Malabsorption'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.trend_analysis?.["hypochlorhydria_malabsorption_(includes vitamins and mineral deficiencies)_protein need"]} section={'redLight'} index={"22"} />
                  <PatternAnalysisItem column={6} title={'Low Vitamin D'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.trend_analysis?.low_vitamin_d} section={'redLight'} index={"23"} />
                  <PatternAnalysisItem column={6} title={'Excess Vitamin D'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.trend_analysis?.excess_vitamin_d} section={'redLight'} index={"24"} />
                  <PatternAnalysisItem column={6} title={'Thiamine (B1) Need'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.trend_analysis?.["thiamine_(B1)_need"]} section={'redLight'} index={"25"} />
                  <PatternAnalysisItem column={6} title={'Vitamin C Need'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.trend_analysis?.vitamin_c_need} section={'redLight'} index={"26"} />
                  <PatternAnalysisItem column={12} title={'B6 B12 Folate Need'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.trend_analysis?.b6_b12_folate_need} section={'redLight'} index={"27"} />
                  <PatternAnalysisItem column={6} title={'Iron Anemia'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.pattern_analysis?.iron_anemia} section={'blueLight'} index={"28"} />
                  <PatternAnalysisItem column={6} title={'B12 Folate Dysregulation'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.pattern_analysis?.b12_folate_dysregulation} section={'blueLight'} index={"29"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Normocytic Normochromic'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.pattern_analysis?.normocytic_normochromic} section={'blueLight'} index={"30"} />
                  <PatternAnalysisItem column={6} title={'Anemia of Chronic Disease'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.pattern_analysis?.anemia_of_chronic_disease} section={'blueLight'} index={"31"} showNotes={true}/>
                  <PatternAnalysisItem column={12} title={'Hemochromatosis'} data={patternAnalysisData?.nutritional_deficiency_anemia_patterns?.pattern_analysis?.hemochromatosis} section={'blueLight'} index={"32"} showNotes={true}/>
                </Row>
              </div>
            </Col>

            <Col lg={12} className="mb-26">
              <div className="login-card">
                <div className="nameLabel">Liver & Gallbladder</div>
                <Row className="">
                  <PatternAnalysisItem column={12} title={'Suboptimal Liver Biliary Function'} data={patternAnalysisData?.liver_gall_bladder_patterns?.trend_analysis?.sub_optimal_liver_biliary_function} section={'redLight'} index={"33"} />
                  <PatternAnalysisItem column={6} title={'Liver Congestion'} data={patternAnalysisData?.liver_gall_bladder_patterns?.pattern_analysis?.liver_congestion} section={'blueLight'} index={"34"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Fatty Liver'} data={patternAnalysisData?.liver_gall_bladder_patterns?.pattern_analysis?.fatty_liver} section={'blueLight'} index={"35"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Liver Dysfunction'} data={patternAnalysisData?.liver_gall_bladder_patterns?.pattern_analysis?.liver_dysfunction} section={'blueLight'} index={"36"} />
                  <PatternAnalysisItem column={6} title={'Biliary Tract Obstruction'} data={patternAnalysisData?.liver_gall_bladder_patterns?.pattern_analysis?.biliary_tract_obstruction} section={'blueLight'} index={"37"} />
                </Row>
              </div>
            </Col>

            <Col lg={12} className="mb-26">
              <div className="login-card">
                <div className="nameLabel">Inflammation</div>
                <Row className="">
                <PatternAnalysisItem column={12} title={'Inflammation'} data={patternAnalysisData?.inflammation_patterns?.trend_analysis?.inflammation_trend} section={'redLight'} index={"38"} />
                  <PatternAnalysisItem column={12} title={'Oxidative Stress'} data={patternAnalysisData?.inflammation_patterns?.trend_analysis?.oxidative_stress} section={'redLight'} index={"39"} />
                  <PatternAnalysisItem column={6} title={'Inflammation'} data={patternAnalysisData?.inflammation_patterns?.pattern_analysis?.inflammation} section={'blueLight'} index={"40"} />
                  <PatternAnalysisItem column={6} title={'Non Specific Inflammation'} data={patternAnalysisData?.inflammation_patterns?.pattern_analysis?.non_specific_inflammation} section={'blueLight'} index={"41"} />
                </Row>
              </div>
            </Col>
            <Col lg={12} className="mb-26">
              <div className="login-card">
                <div className="nameLabel">Kidney, Adrenals, & Dehydration</div>
                <Row className="">
                  <PatternAnalysisItem column={12} title={'Suboptimal Renal Function'} data={patternAnalysisData?.kidney_adrenal_dehydration_patterns?.trend_analysis?.sub_optimal_renal_function} section={'redLight'} index={"42"} />
                  <PatternAnalysisItem column={6} title={'Dehydration'} data={patternAnalysisData?.kidney_adrenal_dehydration_patterns?.pattern_analysis?.dehydration} section={'blueLight'} index={"43"} />
                  <PatternAnalysisItem column={6} title={`Electrolyte Evaluation: ${patternAnalysisData?.kidney_adrenal_dehydration_patterns?.pattern_analysis?.electrolyte_evaluation?.[0]?.label_value}`} data={patternAnalysisData?.kidney_adrenal_dehydration_patterns?.pattern_analysis?.electrolyte_evaluation} section={'blueLight'} index={"44"} />
                  <PatternAnalysisItem column={6} title={'Gout'} data={patternAnalysisData?.kidney_adrenal_dehydration_patterns?.pattern_analysis?.gout} section={'blueLight'} index={"45"} />
                  <PatternAnalysisItem column={6} title={'Hyperadrenal'} data={patternAnalysisData?.kidney_adrenal_dehydration_patterns?.pattern_analysis?.hyperadrenal} section={'blueLight'} index={"46"} />
                  <PatternAnalysisItem column={6} title={'Hypoadrenal'} data={patternAnalysisData?.kidney_adrenal_dehydration_patterns?.pattern_analysis?.hypoadrenal} section={'blueLight'} index={"47"} />
                  <PatternAnalysisItem column={6} title={'Impaired Renal Function'} data={patternAnalysisData?.kidney_adrenal_dehydration_patterns?.pattern_analysis?.impaired_renal_function} section={'blueLight'} index={"48"} />
                  <PatternAnalysisItem column={12} title={'Renal Insufficiency'} data={patternAnalysisData?.kidney_adrenal_dehydration_patterns?.pattern_analysis?.renal_insufficiency} section={'blueLight'} index={"49"} />
                </Row>
              </div>
            </Col>


            <Col lg={12} className="">
              <div className="login-card">
                <div className="nameLabel">Thyroid</div>
                <Row className="">
                  <PatternAnalysisItem column={6} title={'Hyperthyroidism'} data={patternAnalysisData?.thyroid_patterns?.trend_analysis?.hyperthyroidism_trend} section={'redLight'} index={"50"} />
                  <PatternAnalysisItem column={6} title={'Hypothyroidism'} data={patternAnalysisData?.thyroid_patterns?.trend_analysis?.hypothyroidism_trend} section={'redLight'} index={"51"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Hypothyroidism'} data={patternAnalysisData?.thyroid_patterns?.pattern_analysis?.hypothyroidism} section={'blueLight'} index={"52"} />
                  <PatternAnalysisItem column={6} title={'Under Conversion'} data={patternAnalysisData?.thyroid_patterns?.pattern_analysis?.under_conversion} section={'blueLight'} index={"53"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Cellular Hypothyroidism'} data={patternAnalysisData?.thyroid_patterns?.pattern_analysis?.cellular_hypothyroidism} section={'blueLight'} index={"54"} showNotes={true}/>
                  <PatternAnalysisItem column={6} title={'Brain Based Thyroid'} data={patternAnalysisData?.thyroid_patterns?.pattern_analysis?.brain_based_thyroid_pattern} section={'blueLight'} index={"55"} showNotes={true}/>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PatternAnalysis;
