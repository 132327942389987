export const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const testsData = [
  {
    name: "Complete Blood Count (CBC)",
    childs: [
      {
        name: "WBC",
        value: "wbc",
        count: 4,
        units: { us: ["million/ul"], intl: ["x 109/l"] },
      },
      {
        name: "RBC",
        value: "rbc",
        count: 4,
        units: { us: ["million/ul"], intl: ["x 1012/l"] },
      },
      {
        name: "Hemoglobin",
        value: "hgb",
        count: 4,
        units: { us: ["g/dl"], intl: ["g/l"] },
      },
      {
        name: "Hematocrit",
        value: "hct",
        count: 4,
        units: { us: ["%"], intl: ["%"] },
      },
      {
        name: "MCV",
        value: "mcv",
        count: 4,
        units: { us: ["μ^3"], intl: ["fl"] },
      },
      {
        name: "MCHC",
        value: "mchc",
        count: 4,
        units: { us: ["g/dl"], intl: ["mmol/l"] },
      },
      {
        name: "MCH",
        value: "mch",
        count: 4,
        units: { us: ["pg"], intl: ["pg"] },
      },
      {
        name: "RDW",
        value: "rdw",
        count: 4,
        units: { us: ["%"], intl: ["%"] },
      },
      {
        name: "Platelet Count",
        value: "plt_count",
        count: 4,
        units: { us: ["mm^3, x 1000"], intl: ["10^9/l"] },
      },
      {
        name: "MPV",
        value: "mpv",
        count: 4,
        units: { us: [""], intl: ["fl"] },
      },
      {
        name: "Neutrophils",
        value: "neutrophils",
        count: 4,
        units: { us: ["%"], intl: ["%"] },
      },
      {
        name: "Lymphocytes",
        value: "lymphocytes",
        count: 4,
        units: { us: ["%"], intl: ["%"] },
      },
      {
        name: "Monocytes",
        value: "monocytes",
        count: 4,
        units: { us: ["%"], intl: ["%"] },
      },
      {
        name: "Eosinophils",
        value: "eosinophils",
        count: 4,
        units: { us: ["%"], intl: ["%"] },
      },
      {
        name: "Basophils",
        value: "basophils",
        count: 4,
        units: { us: ["%"], intl: ["%"] },
      },
    ],
  },
  {
    name: "Complete Metabolic Panel (CMP)",
    childs: [
      {
        name: "Glucose (fasting)",
        value: "gluc",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "Blood Urea Nitrogen (BUN)",
        value: "bun",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "Creatinine",
        value: "crea",
        count: 4,
        units: { us: ["mg/dl"], intl: ["µmol/l"] },
      },
      {
        name: "Glomerular Filtration Rate",
        value: "egfr",
        count: 4,
        units: { us: ["ml/min/1.73^2"], intl: ["ml/min/1.73^2"] },
      },
      {
        name: "BUN/Creat ratio",
        value: "bcrt",
        count: 4,
        units: { us: [""], intl: [""] },
      },
      {
        name: "Sodium (Na)",
        value: "sod",
        count: 4,
        units: { us: ["meq/l"], intl: ["mmol/l"] },
      },
      {
        name: "Potassium (K)",
        value: "pot",
        count: 4,
        units: { us: ["meq/l"], intl: ["mmol/l"] },
      },
      {
        name: "Chloride (Cl)",
        value: "chl",
        count: 4,
        units: { us: ["meq/l"], intl: ["mmol/l"] },
      },
      {
        name: "Carbon Dioxide (CO2)",
        value: "co2",
        count: 4,
        units: { us: ["meq/l"], intl: ["mmol/l"] },
      },
      {
        name: "Calcium",
        value: "cal",
        count: 4,
        units: { us: ["meq/l"], intl: ["mmol/l"] },
      },
      {
        name: "Total Protein",
        value: "tpro",
        count: 4,
        units: { us: ["g/dl"], intl: ["g/l"] },
      },
      {
        name: "Albumin",
        value: "alb",
        count: 4,
        units: { us: ["g/dl"], intl: ["g/l"] },
      },
      {
        name: "Globulin",
        value: "glob",
        count: 4,
        units: { us: ["g/dl"], intl: ["g/l"] },
      },
      {
        name: "A/G ratio",
        value: "agrt",
        count: 4,
        units: { us: [""], intl: [""] },
      },
      {
        name: "Total Bilirubin",
        value: "tbil",
        count: 4,
        units: { us: ["mg/dl"], intl: ["µmol/l"] },
      },
      {
        name: "Alkaline Phosphatase",
        value: "aphi",
        count: 4,
        units: { us: ["u/l"], intl: ["u/l"] },
      },
      {
        name: "SGOT (AST)",
        value: "ast",
        count: 4,
        units: { us: ["u/l"], intl: ["u/l"] },
      },
      {
        name: "SGPT (ALT)",
        value: "alt",
        count: 4,
        units: { us: ["u/l"], intl: ["u/l"] },
      },
      {
        name: "Hemoglobin A1c",
        value: "a1c",
        count: 4,
        units: { us: ["%"], intl: ["mmol/mol"] },
      },
      {
        name: "Estimated Average Glucose (eAG)",
        value: "eag",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "Magnesium",
        value: "mag",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "Phosphorus",
        value: "phos",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "Uric Acid",
        value: "uacd",
        count: 4,
        units: { us: ["mg/dl"], intl: ["µmol/l"] },
      },
      {
        name: "LDH",
        value: "ldh",
        count: 4,
        units: { us: ["u/l"], intl: ["µkat/l"] },
      },
      {
        name: "GGTP/GGT",
        value: "ggt",
        count: 4,
        units: { us: ["u/l"], intl: ["u/l"] },
      },
    ],
  },
  {
    name: "Cholesterol",
    childs: [
      {
        name: "Total Cholesterol",
        value: "tcho",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "High Density Lipoprotein",
        value: "hdl",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "Triglycerides",
        value: "trig",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "Low Density Lipoprotein",
        value: "ldl",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "Very Low Density Lipoprotein",
        value: "vldl",
        count: 4,
        units: { us: ["mg/dl"], intl: ["mmol/l"] },
      },
      {
        name: "Apolipoprotein A-1",
        value: "apa1",
        count: 4,
        units: { us: ["mg/dl"], intl: ["µmol/l"] },
      },
      {
        name: "Apolipoprotein B",
        value: "apb",
        count: 4,
        units: { us: ["mg/dl"], intl: ["µmol/l"] },
      },
      {
        name: "Cholesterol : HDL Ratio",
        value: "chrt",
        count: 4,
        units: { us: [""], intl: [""] },
      },
      {
        name: "Apolipoprotein B:A-1 ratio",
        value: "bart",
        count: 4,
        units: { us: [""], intl: [""] },
      },
    ],
  },
  {
    name: "Thyroid",
    childs: [
      {
        name: "Thyroid Stimulating Hormone",
        value: "tsh",
        count: 4,
        units: { us: ["mlu/l"], intl: ["mlu/l"] },
      },
      {
        name: "Free T4 (Thyroxine)",
        value: "ft4",
        count: 4,
        units: { us: ["ng/dl"], intl: ["pmol/l"] },
      },
      {
        name: "Free T3 (Triiodothyronine)",
        value: "ft3",
        count: 4,
        units: { us: ["pg/dl"], intl: ["pmol/l"] },
      },
      {
        name: "Total T4 (Thyroxine)",
        value: "tt4",
        count: 4,
        units: { us: ["ng/dl"], intl: ["pmol/l"] },
      },
      {
        name: "Total T3 (Triiodothyronine)",
        value: "tt3",
        count: 4,
        units: { us: ["ng/dl%"], intl: ["pmol/l"] },
      },
      {
        name: "T3 Uptake",
        value: "ut3",
        count: 4,
        units: { us: ["ng/dl"], intl: ["%"] },
      },
      {
        name: "Reverse T3 (rT3)",
        value: "rt3",
        count: 4,
        units: { us: ["ng/dl"], intl: ["nmol/l"] },
      },
      {
        name: "Thyroxine Binding Globulin (TGB)",
        value: "tgb",
        count: 4,
        units: { us: ["ug/ml"], intl: ["nmol/l"] },
      },
      {
        name: "TPO Antibody (Thyroid Peroxidase Ab)",
        value: "tpo",
        count: 4,
        units: { us: [""], intl: [""] },
      },
      {
        name: "TGB Antibody (Anti-Thyroglobulin Ab)",
        value: "atgb",
        count: 4,
        units: { us: [""], intl: [""] },
      },
      {
        name: "Tg (Thyroglobulin)",
        value: "tgt",
        count: 4,
        units: { us: [""], intl: [""] },
      },
    ],
  },
  {
    name: "Iron",
    childs: [
      {
        name: "Total Iron",
        value: "iron",
        count: 4,
        units: { us: ["µg/dl"], intl: ["µmol/l"] },
      },
      {
        name: "Ferritin",
        value: "fert",
        count: 4,
        units: { us: ["ng/ml"], intl: ["ug/l"] },
      },
      {
        name: "Total Iron Binding Capacity (TIBC)",
        value: "tibc",
        count: 4,
        units: { us: ["µg/dl"], intl: ["µmol/l"] },
      },
      {
        name: "% Transferrin Saturation",
        value: "tsat",
        count: 4,
        units: { us: ["%"], intl: ["%"] },
      },
      {
        name: "Reticulocyte Count”",
        value: "retc",
        count: 4,
        units: { us: ["%"], intl: ["%"] },
      },
    ],
  },
  {
    name: "Other",
    childs: [
      {
        name: "25-hydroxy Vitamin D",
        value: "vitd",
        count: 4,
        units: { us: ["ng/ml"], intl: ["nmol/l"] },
      },
      {
        name: "1,25-(OH)2D",
        value: "oh2d",
        count: 4,
        units: { us: ["pg/ml"], intl: ["pmol/l"] },
      },
      {
        name: "Fibrinogen",
        value: "fibr",
        count: 4,
        units: { us: ["mg/dl"], intl: ["µmol/l"] },
      },
      {
        name: "C-Reactive Protein (CRP)",
        value: "crp",
        count: 4,
        units: { us: ["mg/l"], intl: ["mg/l"] },
      },
      {
        name: "High Sensitivity C-Reactive Protein (HsCRP)",
        value: "hcrp",
        count: 4,
        units: { us: ["mg/l"], intl: ["nmol/l"] },
      },
      {
        name: "Sedimentation Rate",
        value: "sedr",
        count: 4,
        units: { us: ["nmol/l"], intl: ["mm/hr"] },
      },
      {
        name: "Homocysteine",
        value: "homo",
        count: 4,
        units: { us: ["umol/l"], intl: ["µmol/l"] },
      },
      {
        name: "Insulin",
        value: "insu",
        count: 4,
        units: { us: ["μu/ml"], intl: ["pmol/l"] },
      },
      {
        name: "Fructosamine",
        value: "fruc",
        count: 4,
        units: { us: ["umol/l"], intl: ["umol/l"] },
      },
      {
        name: "C-peptide",
        value: "cpep",
        count: 4,
        units: { us: ["ng/ml"], intl: ["nmol/ml"] },
      },
      {
        name: "Leptin",
        value: "lep",
        count: 4,
        units: { us: ["ng/dl"], intl: ["µg/l"] },
      },
    ],
  },
];

export const clients = [
  {
    name: "Frances Swann",
    dates: [
      "August 21, 2022",
      "September 21, 2022",
      "October 21, 2022",
      "December 21, 2022",
    ],
  },
  {
    name: "Kimberly Mastrangelo",
  },
  {
    name: "Daniel Hamilton",
  },
  {
    name: "Frances Swann",
  },
  {
    name: "Frances Swann",
  },
  {
    name: "Frances Swann",
  },
  {
    name: "Frances Swann",
  },
  {
    name: "Frances Swann",
  },
  {
    name: "Frances Swann",
  },
  {
    name: "Frances Swann",
  },
];

export const scales = {
  WBC: [
    "Functional Range (US): 5 - 7.5",
    "Conventional Range (US): 3.7 - 11",
    "Functional Range (Int): 5 - 7.5 x 109/L",
    "Conventional Range (Int): 3.7 - 11 x 109/L",
  ],
  RBC: [
    "Functional Range (F) (US): 3.9 - 4.5 million/ul",
    "Conventional Range (F) (US): 3.9 - 5.1 million/ul",
    "Functional Range (F) (Int): 3.9 - 4.5 x 1012/L",
    "Conventional Range (F) (Int): 3.9 - 5.1 x 1012/L",
    "Functional Range (M) (US): 4.2 - 4.9 million/ul",
    "Conventional Range (M) (US): 3.9 - 5.1 million/ul",
    "Functional Range (M) (Int): 4.2 - 4.9 x 1012/L",
    "Conventional Range (M) (Int): 3.9 - 5.1 x 1012/L",
  ],
  HGB: [
    "Functional Range (F) (US): 13.5 - 14.5 g/dl",
    "Conventional Range (F) (US): 12 - 16 g/dl",
    "Functional Range (F) (Int): 135 - 145  g/dl",
    "Conventional Range (F) (Int): 120 - 160  g/dl",
    "Functional Range (M) (US): 14 - 15 g/dl",
    "Conventional Range (M) (US): 12 - 16 g/dl",
    "Functional Range (M) (Int): 140 - 150  g/dl",
    "Conventional Range (M) (Int): 120 - 160  g/dl",
  ],
  HCT: [
    "Functional Range (F) (US): 37 - 44 %",
    "Conventional Range (F) (US): 36 - 48.2 %",
    "Functional Range (F) (Int): 37 - 44 %",
    "Conventional Range (F) (Int): 37 - 44 %",
    "Functional Range (M) (US): 37 - 44 %",
    "Conventional Range (M) (US): 37 - 44 %",
    "Functional Range (M) (Int): 37 - 44 %",
    "Conventional Range (M) (Int): 37 - 44 %",
  ],
  MCV: [
    "Functional Range (US): 82 - 89.9 µ^3",
    "Conventional Range (US): 79 - 97 µ^ 3",
    "Functional Range (Intl): 82 - 89.9 fL",
    "Conventional Range (Intl): 79 - 97 fL",
  ],
  MCH: ["Functional Range : 28 - 31.9 pg", "Conventional Range : 26.6 - 33 pg"],
  MCHC: [
    "Functional Range (US) : 31.5 - 37 g/dL",
    "Conventional Range (US): 32 - 35 g/dL",
    "Functional Range (Intl): 19.84 - 21.7 mmol/L",
    "Conventional Range (Intl) : 19.53 - 22.94 mmol/L",
  ],
  RDW: [
    "Functional Range (US) : 9 - 13 %",
    "Conventional Range (US) : 12.3 - 15.4 %",
    "Functional Range (Intl) : 9 - 13 %",
    "Conventional Range (Intl) : 12.3 - 15.4 %",
  ],
  PLATELETS: [
    "Functional Range (US) : 7.5 - 12.5 ",
    "Conventional Range (US) : 7.5 - 12.5 ",
    "Functional Range (Intl) : 7.5 - 12.5 fL",
    "Conventional Range (Intl) : 7.5 - 12.5 fL",
  ],
  MPV: [
    "Functional Range (US) : 7.5 - 12.5 ",
    "Conventional Range (US) : 7.5 - 12.5 ",
    "Functional Range (Intl) : 7.5 - 12.5 fL",
    "Conventional Range (Intl) : 7.5 - 12.5 fL",
  ],
  NEUTROPHILS: [
    "Functional Range (US) : 40 - 60 %",
    "Conventional Range (US) : 35 - 74 %",
    "Functional Range (Intl) : 40 - 60 %",
    "Conventional Range (Intl) : 35 - 74 %",
  ],
  LYMPHOCYTES: [
    "Functional Range (US) : 24 - 44 %",
    "Conventional Range (US) : 14 - 46 %",
    "Functional Range (Intl) : 24 - 44 %",
    "Conventional Range (Intl) : 14 - 46 %",
  ],
  MONOCYTES: [
    "Functional Range (US) : 0 - 7 %",
    "Conventional Range (US) : 4 - 13 %",
    "Functional Range (Intl) : 0 - 7 %",
    "Conventional Range (Intl) : 4 - 13 %",
  ],
  EOSINOPHILS: [
    "Functional Range (US) : 0 - 3 %",
    "Conventional Range (US) : 0 - 7 %",
    "Functional Range (Intl) : 0 - 3 %",
    "Conventional Range (Intl) : 0 - 7 %",
  ],
  BASOPHILS: [
    "Functional Range (US) : 0 - 1 %",
    "Conventional Range (US) : 0 - 3 %",
    "Functional Range (Intl) : 0 - 1 %",
    "Conventional Range (Intl) : 0 - 3 %",
  ],
  GLUCOSE: [
    "Functional Range (US) : 70 - 90 mg/dL",
    "Conventional Range (US) : 65 - 115 mg/dL",
    "Functional Range (Intl) : 3.9 - 5 mmol/L",
    "Conventional Range (Intl) : 3.6 - 6.4 mmol/L",
  ],
  BUN: [
    "Functional Range (US) : 10 - 16 mg/dL",
    "Conventional Range (US) : 5 - 25 mg/dL",
    "Functional Range (Intl) : 3.6 - 5.7 mmol/L",
    "Conventional Range (Intl) : 1.8 - 9 mmol/L",
  ],
  CREATININE: [
    "Functional Range (US) : 0.8 - 1.1 mg/dL",
    "Conventional Range (US) : 0.6 - 1.5 mg/dL",
    "Functional Range (Intl) : 70.7 - 97.2 µmol/L",
    "Conventional Range (Intl) : 53 - 132 µmol/L",
  ],
  GFR: [
    "Functional Range (US) : 59 - 9999 mL/min/1.73^2",
    "Conventional Range (US) : 60 - 9999 mL/min/1.73^2",
    "Functional Range (Intl) : 59 - 9999 mL/min/1.73^2",
    "Conventional Range (Intl) : 60 - 9999 mL/min/1.73^2",
  ],
  BCR: [
    "Functional Range (US/Intl) : 10 - 16 ",
    "Conventional Range (US/Intl) : 6 - 20 ",
  ],
  SODIUM: [
    "Functional Range (US) : 135 - 142 mEq/L",
    "Conventional Range (US) : 135 - 145 mEq/L",
    "Functional Range (Intl) : 135 - 142 mmol/L",
    "Conventional Range (Intl) : 135 - 145 mmol/L",
  ],
  POTASSIUM: [
    "Functional Range (US) : 4 - 4.5 mEq/L",
    "Conventional Range (US) : 3.5 - 5.3 mEq/L",
    "Functional Range (Intl) : 4 - 4.5 mmol/L",
    "Conventional Range (Intl) : 3.5 - 5.3 mmol/L",
  ],
  CHLORIDE: [
    "Functional Range (US) : 100 - 106 mEq/L",
    "Conventional Range (US) : 96 - 109 mEq/L",
    "Functional Range (Intl) : 100 - 106 mmol/L",
    "Conventional Range (Intl) : 96 - 109 mmol/L",
  ],
  CO2: [
    "Functional Range (US) : 25 - 30 mEq/L",
    "Conventional Range (US) : 23 - 32 mEq/L",
    "Functional Range (Intl) : 100 - 106 mmol/L",
    "Conventional Range (Intl) : 96 - 109 mmol/L",
  ],
  CALCIUM: [
    "Functional Range (US) : 9.2 - 10 mEq/L",
    "Conventional Range (US) : 8.5 - 10.8 mEq/L",
    "Functional Range (Intl) : 2.3 - 2.5 mmol/L",
    "Conventional Range (Intl) : 2.1 - 2.7 mmol/L",
  ],
  TPRO: [
    "Functional Range (US) : 6.9 - 7.4 g/dL",
    "Conventional Range (US) : 6 - 8.5 g/dL",
    "Functional Range (Intl) : 69 - 74 g/L",
    "Conventional Range (Intl) : 60 - 85 g/L",
  ],
  ALBUMIN: [
    "Functional Range (US) : 6.9 - 5 g/dL",
    "Conventional Range (US) : 4.6 - 4.8 g/dL",
    "Functional Range (Intl) : 46 - 50 g/L",
    "Conventional Range (Intl) : 36 - 48 g/L",
  ],
  GLOBULIN: [
    "Functional Range (US) : 2.4 - 2.8 g/dL",
    "Conventional Range (US) : 2 - 3.9 g/dL",
    "Functional Range (Intl) : 24 - 28 g/L",
    "Conventional Range (Intl) : 20 - 39 g/L",
  ],
  AGR: [
    "Functional Range (US/Intl) : 1.5 - 2",
    "Conventional Range (US/Intl) : 1.1 - 2.5",
  ],
  TBIL: [
    "Functional Range (US) : 0.5 - 1.2 mg/dL",
    "Conventional Range (US) : 0.1 - 1.2 mg/dL",
    "Functional Range (Intl) : 8.6 - 20.5 µmol/L",
    "Conventional Range (Intl) : 1.7 - 20.5 µmol/L",
  ],
  APHO: [
    "Functional Range (US/Intl) : 40 - 100 µ/L",
    "Conventional Range (US/Intl) : 25 - 120 µ/L",
  ],
  SGOT: [
    "Functional Range (F-C/F-NC) (US/Intl) : 9 - 21 µ/L",
    "Conventional Range (F-C/F-NC) (US/Intl) : 0 - 40 µ/L",
    "Functional Range (M) (US/Intl) : 13 - 22 µ/L",
    "Conventional Range (M) (US/Intl) : 0 - 45 µ/L",
  ],
  SGPT: [
    "Functional Range (F-C/F-NC) (US/Intl) : 10 - 19 µ/L",
    "Conventional Range (F-C/F-NC) (US/Intl) : 0 - 45 µ/L",
    "Functional Range (M) (US/Intl) : 13 - 22 µ/L",
    "Conventional Range (M) (US/Intl) : 0 - 45 µ/L",
  ],
  A1C: [
    "Functional Range (US) : 4.1 - 5.4 %",
    "Conventional Range (US) : 4.1 - 5.7 %",
    "Functional Range (Intl) : 21.31 - 35.52 mmol/mol",
    "Conventional Range (Intl) : 21.31 - 38.8 mmol/mol",
  ],
  EAG: [
    "Functional Range (US) : 70 - 108 mg/dL",
    "Conventional Range (US) : 70 - 117 mg/dL",
    "Functional Range (Intl) : 3.94 - 6.01 mmol/L",
    "Conventional Range (Intl) : 3.94 - 6.49 mmol/L",
  ],
  Mg: [
    "Functional Range (US) : 2 - 2.5 mg/dL",
    "Conventional Range (US) : 1.5 - 2.6 mg/dL",
    "Functional Range (Intl) : 0.8 - 1 mmol/L",
    "Conventional Range (Intl) : 0.6 - 1.1 mmol/L",
  ],
  Ph: [
    "Functional Range (F-C/F-NC)(US) : 3.5 - 4 mg/dL",
    "Conventional Range  (F-C/F-NC)(US) : 2.5 - 4.5 mg/dL",
    "Functional Range (F-C/F-NC)(Intl) : 1.1 - 1.3 mmol/L",
    "Conventional Range  (F-C/F-NC)(Intl) : 0.8 - 1.5 mmol/L",
    "Functional Range (M)(US) : 3 - 3.5 mg/dL",
    "Conventional Range (M)(US) : 2.5 - 4.5 mg/dL",
    "Functional Range (M)(Intl) : 1 - 1.1 mmol/L",
    "Conventional Range (M)(Intl) : 0.8 - 1.5 mmol/L",
  ],
  UACD: [
    "Functional Range (F-C/F-NC)(US) : 3 - 5.5 mg/dL",
    "Conventional Range  (F-C/F-NC)(US) : 2.2 - 7.7 mg/dL",
    "Functional Range (F-C/F-NC)(Intl) : 178.4 - 327.1 mol/L",
    "Conventional Range  (F-C/F-NC)(Intl) : 130.9 - 458 µmol/L",
    "Functional Range (M)(US) : 3.5 - 5.9 mg/dL",
    "Conventional Range (M)(US) : 2.2 - 7.7 mg/dL",
    "Functional Range (M)(Intl) : 208.2 - 350.9 µmol/L",
    "Conventional Range (M)(Intl) : 130.9 - 458 µmol/L",
  ],
  LDH: [
    "Functional Range (US) : 140 - 200 U/L",
    "Conventional Range (US) : 100 - 220 µkat/L",
    "Functional Range (Intl) : 2.3 - 3.3 U/L",
    "Conventional Range (Intl) : 1.7 - 3.7 µkat/L",
  ],
  GGT: [
    "Functional Range (US/Intl) : 10 - 24 U/L",
    "Conventional Range (US/Intl) : 1 - 70 U/L",
  ],
  TCHO:[
    "Functional Range (<60 F/M) (US): 120 - 240 mg/dL",
    "Conventional Range (<60 F/M) (US): 100 - 200 mg/dL",
    "Functional Range (<60 F/M) (Int): 3.1 - 6.2 mmol/L",
    "Conventional Range (<60 F/M) (Int): 2.6 - 5.2 mmol/L",
    "Functional Range (>60 M) (US): 170 - 270 mg/dL",
    "Conventional Range (>60 M) (US): 100 - 200 mg/dL",
    "Functional Range (>60 M) (Int): 4.4 - 7 mmol/L",
    "Conventional Range (>60 M) (Int): 2.6 - 5.2 mmol/L",
    "Functional Range (>60 F) (US): 200 - 300 mg/dL",
    "Conventional Range (>60 F) (US): 100 - 200 mg/dL",
    "Functional Range  (>60 F)(Int): 5.2 - 7.8 mmol/L",
    "Conventional Range (>60 F) (Int): 2.6 - 5.2 mmol/L",
  ],
  HDL:[
    "Functional Range (M) (US): 55 - 75 mg/dL",
    "Conventional Range (US): 39 - 9999 mg/dL",
    "Functional Range (M) (Int): 1.4 - 1.9 mmol/L",
    "Conventional Range (Int): 1 - 9999 mmol/L",
    "Functional Range (F) (US): 65 - 85 mg/dL",
    "Conventional Range (F) (US): 39 - 9999 mg/dL",
    "Functional Range (F) (Int): 1.7 - 2.2 mmol/L",
    "Conventional Range (F) (Int): 1 - 9999 mmol/L",
  ],
  Trig:[
    "Functional Range (US): 50 - 100 mg/dL",
    "Conventional Range (US): 0 - 149 mg/dL",
    "Functional Range (Int): 0.6 - 1.1 mmol/L",
    "Conventional Range (Int): 0 - 1.7 mmol/L"
  ],
  LDL:[
   "Functional Range (<60) (US): 40 - 120 mg/dL",
    "Conventional Range  (<60) (US): 0 - 99 mg/dL",
    "Functional Range (<60) (Int): 1 - 3.1 mmol/L",
    "Conventional Range (<60) (Int): 0 - 2.6 mmol/L",
    "Functional Range (>60) (US): 120 - 170 mg/dL",
    "Conventional Range (>60) (US): 0 - 99 mg/dL",
    "Functional Range (>60) (Int): 3.1 - 4.4 mmol/L",
    "Conventional Range (>60) (Int): 0 - 2.6 mmol/L"
  ],
  CHRT:[
    "Functional Range (US): 0 - 3", 
    "Conventional Range (US): 0 - 4.4",
    "Functional Range (Int): 0 - 3", 
    "Conventional Range (Int): 0 - 4.4" 
  ],
  VLDL:[
    "Functional Range (US): 5 - 40 mg/dL",
    "Conventional Range (US): 5 - 40 mg/dL",
    "Functional Range (Int): 0.1 - 1 mmol/L",
    "Conventional Range (Int): 0.1 - 1 mmol/L"
  ],
  APA1:[
    "Functional Range (M) (US): 120 - 9999 mg/dL",
    "Conventional Range (M) (US): 101 - 178 mg/dL",
    "Functional Range (M) (Int): 43 - 9999 µmol/L",
    "Conventional Range (M) (Int): 36 - 64 µmol/L",
    "Functional Range (F) (US): 140 - 9999 mg/dL",
    "Conventional Range (F) (US): 116 - 209 mg/dL",
    "Functional Range (F) (Int): 50 - 9999 µmol/L",
    "Conventional Range (F) (Int): 41 - 75 µmol/L"
  ],
  APB:[
    "Functional Range (US): 0 - 90 mg/dL",
    "Conventional Range (US): 0 - 90 mg/dL",
    "Functional Range (Int): 0 - 1.75 µmol/L",
    "Conventional Range (Int): 0 - 1.75 µmol/L"
  ],
  BART:[
    "Functional Range (M) (US): 0 - 0.77", 
    "Conventional Range (M) (US): 0.95 - 9999",
    "Functional Range (M) (Int): 0 - 0.77",
    "Conventional Range (M) (Int): 0.95 - 9999",
    "Functional Range (F) (US): 0 - 0.63",
    "Conventional Range (F) (US): 0.78 - 9999",
    "Functional Range (F) (Int): 0 - 0.63",
    "Conventional Range (F) (Int): 0.78 - 9999"
  ],
  TSH: [
    "Functional Range (US) : 1 - 2 mlU/L",
    "Conventional Range (Intl) : 0.45 - 4.5 mlU/L",
  ],
  FT4: [
    "Functional Range (US) : 1 - 1.5 ng/dL",
    "Conventional Range (US) : 0.7 - 2.4 ng/dL",
    "Functional Range (Intl) : 12.9 - 19.3 pmol/L",
    "Conventional Range (Intl) : 9 - 30.9 pmol/L",
  ],
  FT3: [
    "Functional Range (US) : 3 - 3.25 pg/dL",
    "Conventional Range (US) : 2.3 - 4.8 pg/dL",
    "Functional Range (Intl) : 4.6 - 5 pmol/L",
    "Conventional Range (Intl) : 3.5 - 7.4 pmol/L",
  ],
  TT4: [
    "Functional Range (US) : 3 - 3.25 pg/dL",
    "Conventional Range (US) : 2.3 - 4.8 pg/dL",
    "Functional Range (Intl) : 4.6 - 5 pmol/L",
    "Conventional Range (Intl) : 3.5 - 7.4 pmol/L",
  ],
  TT3: [
    "Functional Range (US) : 90 - 168 ng/dL",
    "Conventional Range (US) : 80 - 230 ng/dL",
    "Functional Range (Intl) : 1.4 - 2.6 nmol/L",
    "Conventional Range (Intl) : 1.2 - 3.5 nmol/L",
  ],
  UT3: [
    "Functional Range (US) : 27 - 37 ng/dL %",
    "Conventional Range (US) : 24 - 39 ng/dL %",
    "Functional Range (Intl) : 27 - 37 %",
    "Conventional Range (Intl) : 24 - 39 %",
  ],
  RT3: [
    "Functional Range (US) : 14.9 - 26.7 ng/dL",
    "Conventional Range (US) : 9.2 - 24.1 ng/dL",
    "Functional Range (Intl) : 0.2 - 0.4 nmol/L",
    "Conventional Range (Intl) : 0.1 - 0.4 nmol/L",
  ],
  TBG: [
    "Functional Range (US) : 18 - 27 ug/mL",
    "Conventional Range (US) : 12.7 - 30.9 ug/mL",
    "Functional Range (Intl) : 333.3 - 500 nmol/L",
    "Conventional Range (Intl) : 235.2 - 572.2 nmol/L",
  ],
  TPO: [
    "Functional Range (US/Intl) : negative - positive",
    "Conventional Range (US/Intl) : 0 - 1",
  ],
  ATGB: [
    "Functional Range (US/Intl) : negative - positive",
    "Conventional Range (US/Intl) : 0 - 1",
  ],
  THYROGLOBULIN: [
    "Functional Range (US/Intl) : negative - positive",
    "Conventional Range (US/Intl) : 0 - 1",
  ],
  IRON: [
    "Functional Range (US) : 85 - 130 µg/dL",
    "Conventional Range (US) : 30 - 170 µg/dL",
    "Functional Range (Intl) : 15.2 - 23.3 µmol/L",
    "Conventional Range (Intl) : 5.4 - 30.4 µmol/L",
  ],
  FERRETIN: [
    "Functional Range (F-C) (US) : 50 - 122 ng/ml",
    "Conventional Range (F-C) (US) : 10 - 122 ng/ml",
    "Functional Range (F-NC) (US) : 50 - 200 ng/ml",
    "Conventional Range (F-NC) (US) : 33 - 236 ng/ml",
    "Functional Range (F-C) (Intl) : 50 - 122 µg/L",
    "Conventional Range (F-C)(Intl) : 10 - 122 µg/L",
    "Functional Range (F-NC) (Intl) : 50 - 200 µg/L",
    "Conventional Range (F-NC)(Intl) : 30 - 236 µg/L",
    "Functional Range (M) (US) : 50 - 200 ng/ml",
    "Conventional Range (M) (US) : 33 - 236 ng/ml",
    "Functional Range (M) (Intl) : 50 - 200 ug/ml",
    "Conventional Range (M) (Intl) : 33 - 236 ug/ml",
  ],
  TIBC: [
    "Functional Range (US) : 250 - 350 µg/dL",
    "Conventional Range (US) : 250 - 350 µg/dL",
    "Functional Range (Intl) : 44.8 - 62.7 µmol/L",
    "Conventional Range (Intl) : 44.8 - 62.7 µmol/L",
  ],
  TSAT: [
    "Functional Range (US) : 20 - 35 %",
    "Conventional Range (US) : 20 - 35 %",
    "Functional Range (Intl) : 16 - 60 %",
    "Conventional Range (Intl) : 16 - 60 %",
  ],
  RETC: [
    "Functional Range (US) : 0.5 - 1 %",
    "Conventional Range (US) : 0.5 - 1 %",
    "Functional Range (Intl) : 0.5 - 1 %",
    "Conventional Range (Intl) : 0.5 - 1 %",
  ],
  VITD: [
    "Functional Range (US) : 30 - 50 ng/ml",
    "Conventional Range (US) : 30 - 100 ng/ml",
    "Functional Range (Intl) : 75 - 125 nmol/L",
    "Conventional Range (Intl) : 74.9 - 249.6 nmol/L",
  ],
  OH2D: [
    "Functional Range (US) : 20 - 55 pg/ml",
    "Conventional Range (US) : 19.6 - 54.3 pg/ml",
    "Functional Range (Intl) : 48 - 130 pmol/L",
    "Conventional Range (Intl) : 47 - 130 pmol/L",
  ],
  FIBR: [
    "Functional Range (US) : 200 - 300 mg/ml",
    "Conventional Range (US) : 150 - 400 mg/ml",
    "Functional Range (Intl) : 5.9 - 8.8 umol/L",
    "Conventional Range (Intl) : 4.4 - 11.8 umol/L",
  ],
  CRP: [
    "Functional Range (US/Intl) : 0 - 8 mg/L",
    "Conventional Range (US/Intl) : 0 - 8 mg/L",
  ],
  HCRP: [
    "Functional Range (US) : 0 - 1 mg/L",
    "Conventional Range (US) : 0 - 1 mg/L",
    "Functional Range (Intl) : 0 - 9.5 nmol/L",
    "Conventional Range (Intl) : 0 - 9.5 nmol/L",
  ],
  SEDR: [
    "Functional Range (F-C/F-NC/M) (US/Intl) : 0 - 10 mm/hr",
    "Conventional Range (F-C/F-NC/M) (US/Intl) : 0 - 20 mm/hr",
  ],
  HOMO: [
    "Functional Range (F-C/F-NC/M) (US/Intl) : 4 - 7 µmol/L",
    "Conventional Range (F-C/F-NC/M) (US) : 0 - 10.4 µmol/L",
    "Conventional Range (F-C/F-NC/M) (Intl) : 5 - 12 µmol/L",
  ],
  INSU: [
    "Functional Range (US) : 2 - 8 µU/ml",
    "Conventional Range (US) : 2 - 19.6 µU/ml",
    "Functional Range  (Intl) : 13.9 - 55.6 pmol/L",
    "Conventional Range  (Intl) : 13.9 - 156.1 pmol/L",
  ],
  FRUC: [
    "Functional Range (US/Intl) : 190 - 270 umol/L",
    "Conventional Range  (US/Intl) : 190 - 270 umol/L",
  ],
  CPEP: [
    "Functional Range (US) : 1.1 - 2.1 ng/mL",
    "Conventional Range (US) : 0.8 - 3.85 ng/mL",
    "Functional Range  (Intl) : 0.4 - 0.7 nmol/mL",
    "Conventional Range  (Intl) : 0.3 - 1.3 nmol/mL",
  ],
  LEP: [
    "Functional Range (US) : 4 - 6 ng/dL",
    "Conventional Range (US) : 4 - 10 ng/dL",
    "Functional Range  (Intl) : 4 - 6 µg/L",
    "Conventional Range  (Intl) : 4 - 10 µg/L",
  ],
};



export const getFieldName = (field) => {
  if (field.includes("wbc")) {
    return "WBC";
  }
  if (field.includes("rbc")) {
    return "RBC";
  }
  if (field.includes("hgb")) {
    return "HGB";
  }
  if (field.includes("hct")) {
    return "HCT";
  }
  if (field.includes("mcv")) {
    return "MCV";
  }
  if (field === "mean_corpuscular_hemoglobin_mch") {
    return "MCH";
  }
  if (field.includes("rdw")) {
    return "RDW";
  }
  if (field.includes("mpv")) {
    return "MPV";
  }
  if (field === "mean_corpuscular_hemoglobin_concentration_mchc") {
    return "MCHC";
  }
  if (field === "glomerular_filtration_rate") {
    return "GFR";
  }
  if (field === "uric_acid") {
    return "UACD";
  }
  if (field === "hemoglobin_a1c") {
    return "A1C";
  }
  if (field === "bun_creat_ratio") {
    return "BCR";
  }
  if (field === "a_g_ratio") {
    return "AGR";
  }
  if (field === "estimated_average_glucose") {
    return "EAG";
  }
  if (field === "ggtp_ggt") {
    return "GGT";
  }
  if(field === 'total_cholesterol'){
    return "TCHO"
  }
  if(field === 'high_density_lipoprotein'){
    return "HDL"
  }
  if(field === 'triglycerides'){
    return "Trig"
  }
  if(field === 'cholesterol_hdl_ratio'){
    return "CHRT"
  }
  if(field === 'low_density_lipoprotein'){
    return "LDL"
  }
  if(field === 'very_low_density_lipoprotein'){
    return "VLDL"
  }
  if(field === 'apolipoprotein_a_1'){
    return 'APA1'
  }
  if(field === "apolipoprotein_b"){
    return "APB"
  }
  if(field === "apolipoprotein_ratio"){
    return "BART"
  }
  if (field === "magnesium") {
    return "Mg";
  }
  if (field === "phosphorus") {
    return "Ph";
  }
  if (field === "carbon_dioxide") {
    return "CO2";
  }
  if (field === "total_protien") {
    return "TPRO";
  }
  if (field === "blood_urea_nitogen") {
    return "BUN";
  }
  if (field === "reverse_t3") {
    return "RT3";
  }
  if (field === "tpo_antibody") {
    return "TPO";
  }
  if (field === "tgb_antibody") {
    return "ATGB";
  }
  if (field === "total_bilirubin") {
    return "TBIL";
  }
  if (field === "thyroid_stimulating_hormone") {
    return "TSH";
  }
  if (field === "free_t4") {
    return "FT4";
  }
  if (field === "free_t3") {
    return "FT3";
  }
  if (field === "total_t4") {
    return "TT4";
  }
  if (field === "total_t3") {
    return "TT3";
  }
  if (field === "t3_uptake") {
    return "UT3";
  }
  if (field === "alkaline_phosphatase") {
    return "APHO";
  }
  if (field === "thyroxine_binding_globulin") {
    return "TBG";
  }
  if (field.includes("_")) {
    const fieldName = field.split("_");
    let result = "";
    fieldName.map((name) => {
      result = result + name + " ";
    });
    return result;
  } else {
    return field.toUpperCase();
  }
};

export const getFieldTitle = (field) => {
  if (field.includes("wbc")) {
    return "Total White Blood Cells";
  }
  if (field.includes("rbc")) {
    return "Red Blood Cells";
  }
  if (field.includes("hgb")) {
    return "Hemoglobin";
  }
  if (field.includes("hct")) {
    return "Hematocrit";
  }
  if (field.includes("mcv")) {
    return "Mean Corpuscular Volume";
  }
  if (field === "mean_corpuscular_hemoglobin_mch") {
    return "Mean Corpuscular Hemoglobin";
  }
  if (field.includes("rdw")) {
    return "RDW";
  }
  if (field.includes("mpv")) {
    return "Mean Platelet Volume";
  }
  if (field === "mean_corpuscular_hemoglobin_concentration_mchc") {
    return "Mean Corpuscular Hemoglobin Concentration";
  }
  if (field === "glomerular_filtration_rate") {
    return "Glomerular Filtration Rate";
  }
  if (field === "uric_acid") {
    return "Uric Acid";
  }
  if (field === "hemoglobin_a1c") {
    return "Hemoglobin A1c";
  }
  if (field === "bun_creat_ratio") {
    return "BUN/Creat Ratio";
  }
  if (field === "a_g_ratio") {
    return "A/G Ratio";
  }
  if (field === "estimated_average_glucose") {
    return "Estimated Average Glucose";
  }
  if (field === "ggtp_ggt") {
    return "GGTP/GGT";
  }
  if (field === "magnesium") {
    return "Magnesium";
  }
  if (field === "phosphorus") {
    return "Phosphorus";
  }
  if (field === "carbon_dioxide") {
    return "Carbon Dioxide";
  }
  if (field === "total_protien") {
    return "Total Protein";
  }
  if (field === "blood_urea_nitogen") {
    return "Blood Urea Nitogen";
  }
  if (field === "reverse_t3") {
    return "Reverse T3";
  }
  if (field === "tpo_antibody") {
    return "TPO Antibody";
  }
  if (field === "tgb_antibody") {
    return "TGB Antibody";
  }
  if (field === "total_bilirubin") {
    return "Total Bilirubin";
  }
  if (field === "thyroid_stimulating_hormone") {
    return "Thyroid Stimulating Hormone";
  }
  if (field === "free_t4") {
    return "Free T4";
  }
  if (field === "free_t3") {
    return "Free T3";
  }
  if (field === "total_t4") {
    return "Total T4";
  }
  if (field === "total_t3") {
    return "Total T3";
  }
  if (field === "t3_uptake") {
    return "T3 Uptake";
  }
  if(field === 'triglycerides'){
    return "Triglycerides"
  }
  if (field === "alkaline_phosphatase") {
    return "Alkaline Phosphatase";
  }
  if (field === "thyroxine_binding_globulin") {
    return "Thyroxine Binding Globulin";
  }
  if (field === "platelets") {
    return "Platelets";
  }
  if (field === "neutrophils") {
    return "Neutrophils";
  }
  if (field === "lymphocytes") {
    return "Lymphocytes";
  }
  if (field === "monocytes") {
    return "Monocytes";
  }
  if (field === "eosinophils") {
    return "Eosinophils";
  }
  if (field === "basophils") {
    return "Basophils";
  }
  if (field === "glucose") {
    return "Glucose";
  }
  if (field === "creatinine") {
    return "Creatinine";
  }
  if (field === "sodium") {
    return "Sodium";
  }
  
  if (field === "potassium") {
    return "potassium";
  }
  if (field === "chloride") {
    return "Chloride";
  }

  if (field === "calcium") {
    return "Calcium";
  }

  if (field === "albumin") {
    return "Albumin";
  }

  if (field === "globulin") {
    return "Globulin";
  }
  if (field === "thyroglobulin") {
    return "Thyroglobulin";
  }
  
  if (field === "iron") {
    return "Total Iron";
  }
  if (field === "ferretin") {
    return "Ferritin";
  }
  if (field === 'tibc'){
    return "Total Iron Binding Capacity"
  }
  if (field === 'tsat'){
    return "% Transferrin Saturation"
  }
  if (field === 'retc'){
    return "Reticulocyte Count"
  }
  if(field === 'vitd' ){
    return "25-hydroxy Vitamin D"
  }
  if(field === 'oh2d'){
    return "1,25-(OH)2D"
  }
  if(field === 'fibr'){
    return "Fibrinogen"
  }
  if(field === 'crp'){
    return "C-Reactive Protein"
  }
  if(field === 'hcrp'){
    return "High Sensitivity C-Reactive Protein"
  }
  if(field === 'sedr'){
    return "Sedimentation Rate"
  }
  if(field === 'homo'){
    return "Homocysteine"
  }
  if(field === 'insu'){
    return "Insulin"
  }
  if(field === 'fruc'){
    return "Fructosamine"
  }
  if(field === 'cpep'){
    return "C-peptide"
  }
  if(field === 'lep'){
    return "Leptin"
  }
  if (field === "hemochromatosis") {
    return "Hemochromatosis";
  }
  if (field === "inflammation") {
    return "Inflammation";
  }
  
  if (field === "dehydration") {
    return "Dehydration";
  }
  if (field === "hypoadrenal") {
    return "Hypoadrenal";
  }
  if (field === "hypothyroidism") {
    return "Hypothyroidism";
  }
  if (field === "hyperlipidemia") {
    return "Hyperlipidemia (Trend)";
  }
  if (field === "atherosclerosis") {
    return "Atherosclerosis Risk";
  }
  if (field === 'cvd_non_specific'){
    return "Non-Specific CV Risk"
  }
  if (field ==='brain_based_thyroid_pattern'){
    return "Brain Based Thyroid"
  }
  if (field === "hyperglycemia") {
    return "Hyperglycemia (Trend)";
  }
  if(field === "high_cardiovascular_risk"){
  return "High Cardiovascular Risk (Trend)"
  }
  if(field === "cardiovascular_disease"){
    return "Cardiovascular Disease (Trend)"
  }
  if (field === "immune_dysfunction_engagement"){
    return "Immune Dysfunction Engagement (Trend)"
  }
  if (field === "sub_optimal_liver_biliary_function"){
    return "Suboptimal Liver Biliary Function (Trend)"
  }
  if (field === "low_vitamin_d"){
    return "Low Vitamin D (Trend)"
  }
  if (field === "low_vitamin_d"){
    return "Low Vitamin D (Trend)"
  }
  if (field === "low_vitamin_d"){
    return "Low Vitamin D (Trend)"
  }
  if (field === "excess_vitamin_d"){
    return "Excess Vitamin D (Trend)"
  }
  if (field === "thiamine_(B1)_need"){
    return "Thiamine (B1) Need (Trend)"
  }
  if (field === "vitamin_c_need"){
    return "Vitamin C Need (Trend)"
  }
  if (field === "b6_b12_folate_need"){
    return "B6 B12 Folate Need (Trend)"
  }
  if (field === "oxidative_stress"){
    return "Oxidative Stress (Trend)"
  }
  if(field === "hyperthyroidism"){
    return "Hyperthyroidism"
  }
  if(field === 'sub_optimal_renal_function'){
    return "Suboptimal Renal Function (Trend)"
  }
  if(field === 'hypochlorhydria_malabsorption_(includes vitamins and mineral deficiencies)_protein need'){
    return "Hypochlorhydria Malabsorption (Trend)"
  }
  if(field === 'h_pylori_infection'){
    return "H. Pylori Infection"
  }
  if(field === 'allergy_parasitic_infection'){
    return "Allergy and/or Parasitic Infection"
  }
  if(field === 'spleen_metabolism_patterns'){
    return "Spleen Hyperfunction"
  }
  if(field === 'food_sensitivities_allergies'){
    return "Food Sensitivities/Allergies"
  }
  if(field.includes("trend")){
    const fieldName = field.split("_");
    let result = fieldName[0]+ " " +"(Trend)"
    return result
  }
  if (field.includes("_")) {
    const fieldName = field.split("_");
    let result = "";
    fieldName.map((name) => {
      result = result + name + " ";
    });
    return result;
  } else {
    return field.toUpperCase();
  }
};