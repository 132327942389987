export const validate = (form) => {
  let error = false;
  let n_form = form;
  for (let i in n_form) {
    if (n_form[i]) {
      if (
        n_form[i]?.required === true &&
        (n_form[i]?.value === "" ||
          !n_form[i]?.value ||
          (n_form[i]?.regex && !n_form[i]?.regex.test(n_form[i]?.value)))
      ) {
        n_form[i]["error"] = true;
        error = true;
      } else {
        n_form[i]["error"] = false;
      }
    }
  }
  return { n_form, error };
};

export const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

export const getClientFormDate = (d) => {
  const date = new Date(d);
  return date.getFullYear() + "-" + date.getDay() + "-" + date.getDate();
};
