import React, { useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { actions } from "../../store/actions";
import { useDispatch } from "react-redux";
const AddPayment = () => {
    const dispatch = useDispatch();

    const paypPerReport = ()=>{
        dispatch({
            type: actions.PAY_PER_REPORT,
            payload:{
            data:{},
          }
          });
    }
    const payMonthly = () => {
  
        dispatch({
          type: actions.PAY_MONTHLY,
          payload: {
            type: "GET",
          },
        });
      };
    return (
        <Container fluid className="main-container p-sm-4 p-3">
            <div className="center-card manual-pdf-section mx-auto">
                <Button
                    variant="primary"
                    size="md"
                    className="mx-auto d-block primary-bg manual-btn"
                    onClick={payMonthly}
                >
                    <span className="fw-bold">Start Monthly Subscription - $49</span><br />
                    <span className="font-16">Unlimited Client Uploads</span>
                </Button>
                <Button
                    variant="primary"
                    size="md"
                    className="mx-auto d-block primary-bg manual-btn mt-3"
                    onClick={paypPerReport}
                >
                    <span className="fw-bold">Single Result Upload - $20</span>
                </Button>
            </div>
        </Container>
    );
};

export default AddPayment;
