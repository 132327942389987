import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import rwsLogo from "./../../images/rwslogo.svg";
import HeaderButtons from "../Shared/HeaderButtons";
import RWSLogo from "./RWSLogo";
import "./style.css";
import Footer from "../Shared/Footer";
const PreloginScreen = ({ children }) => {
  return (
    <Container fluid className="main-container">
      <div className="wrapper">
      <Row>
          <HeaderButtons />
      </Row>
      <RWSLogo />
      <Row>
        <Col className="login-card mx-auto" sm={9} md={7} lg={5}>
          <Row>
            <div className="text-center">
              <img alt="logo" className="rws-login-logo" src={rwsLogo}></img>
              </div>
          </Row>
          {children}
        </Col>
      </Row>
      </div>
      <Footer />
    </Container>
  );
};

export default PreloginScreen;
