import React from "react";
import { Form } from "react-bootstrap";

const UnitCountTextfield = ({ value, onChangeUnit, field }) => {
  return (
    <>
      {field === "atgb" || field === "tgt" || field === "tpo" ? (
        <Form.Select
          className="greyTextfield unit-dropdown"
          onChange={(e) => {
            onChangeUnit(e.target.value);
          }}
          value={value === "" ? "" : value==="positive"?"positive":"negative"}
        >
          <option value=""></option>
          <option value="positive">
            Positive
          </option>
          <option value="negative">
            Negative
          </option>
        </Form.Select>
      ) : (
        <Form.Control
          className="greyTextfield"
          type={"number"}
          onWheel={(e) => e.target.blur()}
          placeholder={"Enter"}
          value={value ?? 0}
          onChange={(e) => {
            onChangeUnit(e.target.value);
          }}
        />
      )}
    </>
  );
};

export default UnitCountTextfield;
