import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const DeletePopup = ({message, deleteRecord, data,setData}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setData("")
        setShow(false)
    }
    useEffect(() => {
        if(data) setShow(true)
        else setShow(false)
      },[data])
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Report</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">{message}</Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button variant="danger" onClick={()=>{
                    deleteRecord(data);
                    handleClose()
                }}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    No
                </Button>

            </Modal.Footer>
        </Modal>
    )
}
export default DeletePopup