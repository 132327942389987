import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loginReducer,
  logout,
  selectLoader,
  selectUserData,
} from "../../store";
import HomePageRouter from "../Home/HomePageRouter";
import PreloginRouter from "../PreloginScreens/PreloginRouter";
import Spinner from "react-bootstrap/Spinner";
const RootRouter = () => {
  const userData = useSelector(selectUserData);
  const loader = useSelector(selectLoader);
  const dispatch = useDispatch();
  useEffect(() => {
    let token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
    if (token) {
      dispatch(loginReducer({ key: token }));
    } else {
      dispatch(logout({ key: null }));
    }
  }, []);
  return (
    <>
      {loader ? (
        <div className="loader">
          <Spinner animation="grow">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <></>
      )}
      {userData.login !== undefined ? (
        userData.login ? (
          <HomePageRouter />
        ) : (
          <PreloginRouter />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default RootRouter;
