import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const MakerAnalysisItem = ({ bm_data, dm, cbc_temp, index, age, sort,gender, scale }) => {
    const [active, setActive] = useState(null);
    useEffect(() => {
        setActive(bm_data?.[dm?.key]?.[cbc_temp.key]?.level ===
            "Yellow" ||
            bm_data?.[dm?.key]?.[cbc_temp.key]?.level === "Red" ? index : null)
    }, [bm_data, sort])
    return (
        <Accordion
            key={index}
            activeKey={active}
            onSelect={(e) => setActive(parseInt(active) >=0 ? null : index)}
            className={`marker-analysis-accordion ${bm_data?.[dm?.key]?.[cbc_temp.key]?.level ===
                "Yellow"
                ? "yellowAccordion"
                : bm_data?.[dm?.key]?.[cbc_temp.key]?.level ===
                    "Red"
                    ? "redAccordion"
                    : "otherAccordion"
                }`}
        >
            <Accordion.Item eventKey={index}>
                <Accordion.Header>
                    {bm_data?.[dm?.key]?.[cbc_temp.key]?.indicator ===
                        "Increased" ? (
                        <FontAwesomeIcon
                            icon={faArrowUp}
                            className={`me-2 ${bm_data?.[dm?.key]?.[cbc_temp.key]
                                ?.level === "Yellow"
                                ? "yellow-arrow"
                                : bm_data?.[dm?.key]?.[cbc_temp.key]
                                    ?.level === "Red"
                                    ? "red-arrow"
                                    : ""
                                }`}
                        />
                    ) : (
                        <></>
                    )}
                    {bm_data?.[dm?.key]?.[cbc_temp.key]?.indicator ===
                        "Decreased" ? (
                        <FontAwesomeIcon
                            icon={faArrowDown}
                            className={`me-2 ${bm_data?.[dm?.key]?.[cbc_temp.key]
                                ?.level === "Yellow"
                                ? "yellow-arrow"
                                : bm_data?.[dm?.key]?.[cbc_temp.key]
                                    ?.level === "Red"
                                    ? "red-arrow"
                                    : ""
                                }`}
                        />
                    ) : (
                        <></>
                    )}
                    {cbc_temp.name}:{" "}
                    {bm_data?.[dm?.key]?.[cbc_temp.key]?.result_value}
                </Accordion.Header>
                <Accordion.Body>
                    <div className="d-flex">
                        <div
                            className={`arrow ${bm_data?.[dm?.key]?.[cbc_temp.key]
                                ?.level === "Yellow"
                                ? "yellow-bg mx-auto mb-3 mb-sm-0"
                                : bm_data?.[dm?.key]?.[cbc_temp.key]
                                    ?.level === "Red"
                                    ? "red-bg mx-auto mb-3 mb-sm-0"
                                    : "d-none"
                                } `}
                        >
                            {bm_data?.[dm?.key]?.[cbc_temp.key]
                                ?.indicator === "Increased" ? (
                                <FontAwesomeIcon
                                    icon={faArrowUp}
                                    className={`arrow-width ${bm_data?.[dm?.key]?.[cbc_temp.key]
                                        ?.level === "Yellow"
                                        ? "yellow-arrow"
                                        : bm_data?.[dm?.key]?.[cbc_temp.key]
                                            ?.level === "Red"
                                            ? "red-arrow"
                                            : ""
                                        }`}
                                />
                            ) : (
                                <></>
                            )}
                            {bm_data?.[dm?.key]?.[cbc_temp.key]
                                ?.indicator === "Decreased" ? (
                                <FontAwesomeIcon
                                    icon={faArrowDown}
                                    className={`arrow-width ${bm_data?.[dm?.key]?.[cbc_temp.key]
                                        ?.level === "Yellow"
                                        ? "yellow-arrow"
                                        : bm_data?.[dm?.key]?.[cbc_temp.key]
                                            ?.level === "Red"
                                            ? "red-arrow"
                                            : ""
                                        }`}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="d-flex justify-content-between w-100 body-section">
                            <div className="w-100">
                                <ul className="functional border-right h-100">
                                    {cbc_temp.scales?.[gender]?.[age] ?
                                        <div>
                                            <li>Functional Range ({gender=== 'female-cycling' ? 'F-C' : gender=== 'female-noncycling' ? 'F-NC' : 'M'}) ({scale === 'us' ? 'US' : 'Int'}): {cbc_temp.scales?.[gender]?.[age]?.[scale]?.['functional']}</li>
                                            <li>Conventional Range ({gender=== 'female-cycling' ? 'F-C' : gender=== 'female-noncycling' ? 'F-NC' : 'M'}) ({scale === 'us' ? 'US' : 'Int'}): {cbc_temp.scales?.[gender]?.[age]?.[scale]?.['conventional']}</li>
                                        </div>
                                        :
                                        <>
                                            <li>Functional Range ({gender=== 'female-cycling' ? 'F-C' : gender=== 'female-noncycling' ? 'F-NC' : 'M'}) ({scale === 'us' ? 'US' : 'Int'}): {cbc_temp.scales?.[gender]?.[scale]?.['functional']}</li>
                                            <li>Conventional Range ({gender=== 'female-cycling' ? 'F-C' : gender=== 'female-noncycling' ? 'F-NC' : 'M'}) ({scale === 'us' ? 'US' : 'Int'}): {cbc_temp.scales?.[gender]?.[scale]?.['conventional']}</li>
                                        </>
                                    }
                                </ul>
                            </div>
                            <div className="w-75 symptoms">
                                <ul >
                                    {bm_data?.[dm?.key]?.[
                                        cbc_temp.key
                                    ]?.description
                                        ?.split("\n")
                                        .map((d, i3) => (
                                            <li key={i3}>{d}</li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
export default MakerAnalysisItem