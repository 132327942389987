export const clinicalPearlData = [
{
    name:"Blood Sugar",
    dataKey:"blood_sugar"
},
{
    name:"Cardiovascular",
    dataKey:"cardiovascular"
},
{
    name:"Immune and Inflammation",
    dataKey:"immune_and_inflammation"
},
{
    name:"Kidneys, Adrenals and Dehydration",
    dataKey:"kidneys_adrenals_dehydration"
},
{
    name:"RBC, Digestion and Nutritional Deficiences",
    dataKey:"rbc_digestion_and_nutritional_deficiences"
},
{
    name:"Liver and Gallbladder",
    dataKey:"liver_and_gallbladder"
},
{
    name:"Thyroid",
    dataKey:"thyroid"
}
]