import React, { useState } from "react";
import { Button, Col, Row, Form} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { validate } from "../../services/formValidation";
import { emailRegex } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { actions } from "../../store/actions";
import CustomTextField from "../CustomTextField/CustomTextField";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    email: {
      value: "",
      error: false,
      regex: emailRegex,
      required: true,
    },
  });

  const handleForm = (e) => {
    e.preventDefault()
    const { n_form, error } = validate(form);
    setForm({ ...form, ...n_form });
    if (error) {
      return;
    }
    dispatch({
      type: actions.FORGOT_PASSWORD,
      payload: {
        email: form.email.value,
      },
    });
    navigate("/");
  };
  return (
    <>
        <Form onSubmit={handleForm}>
      <Row>
        <Col></Col>
        <Col md={9}>
          <CustomTextField
            label={"Email Address"}
            type="email"
            form={form}
            setForm={setForm}
            fkey="email"
          />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col md={6}>
          <Button
            variant="primary"
            size="md"
            className="w-100 p-6 primary-bg mb-4 mt-5 btn-auth"
            type="submit"
          >
            Forgot Password
          </Button>
          <div style={{ textAlign: "center" }} className="mb-5">
            <Link className="primary-fg" to="/">
              Go Back to Login
            </Link>
          </div>
        </Col>
        <Col></Col>
      </Row>
      </Form>
    </>
  );
};

export default ForgotPassword;
