import React from "react";
import { Col, Row } from "react-bootstrap";
import rwsLogo from "./../../images/rwslogo.svg";
import { useNavigate } from "react-router-dom";

const RWSLogo = () => {
  const navigate = useNavigate();
  return (
    <Row>
      <Col xs={12} sm={12} md={2} className="text-center p-2 d-none d-lg-block" >
        <img className="rws-logo cursor-pointer" src={rwsLogo} alt="img" onClick={()=>navigate("/")}></img>
      </Col>
      <Col></Col>
    </Row>
  );
};

export default RWSLogo;
