import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectClientSearchData } from "../../store";
import { actions } from "../../store/actions";
import { toast } from "react-toastify";
const ReportNotes = ({ id, name }) => {
  const dispatch = useDispatch();
  const clientSearchData = useSelector(selectClientSearchData);
  const [note, setNote] = useState("");
  const [active, setActive] = useState(null);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...clientSearchData.clientData,
      rws_input: {
        ...clientSearchData.clientData.rws_input,
        note,
      },
    };
    // const { id } = clientSearchData.clientData.rws_input;

    dispatch({
      type: actions.UPDATE_TEST_DATA,
      payload: {
        data: payload,
        id,
        openNext: () => {
          toast.success("Successfully updated");
        },
      },
    });
  };
  useEffect(() => {
    dispatch({
      type: actions.CLIENT_BY_ID,
      payload: {
        id,
      },
    });
  }, [name]);

  useEffect(() => {
    if (clientSearchData.clientData && clientSearchData.clientData.rws_input) {
      setNote(clientSearchData?.clientData?.rws_input.note);
      setActive(!!clientSearchData?.clientData?.rws_input.note ? '0' : null)
    }
  }, [clientSearchData]);

  return (
    <Row>
      <Col sm={12} md={12} lg={12} className="">
        <Accordion className="accordian maker-accordion"
            activeKey={active}
            onSelect={(e) => setActive(parseInt(active) ===0 ? null : '0')}
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Notes</Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={handleFormSubmit}>
                <Form.Control
                  as="textarea"
                  className="report-notes-section"
                  placeholder="Leave a note here"
                  style={{ height: "100px" }}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <Button
                  variant="primary"
                  size="md"
                  className="secondary-bg d-block mx-auto hide-section-print"
                  type="submit"
                >
                  Save
                </Button>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};
export default ReportNotes;
