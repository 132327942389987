import React from "react";
import { Form } from "react-bootstrap";

const units = [{ name: "Kg" }, { name: "cm" }];

const SelectUnitDropdown = ({ data, scale}) => {
  return (
    <Form.Select className="greyTextfield" placeholder={"Select Unit"}>
      {data?.units?.[scale].map((m, i) => {
        return (
          <option key={i} value={m}>
            {m}
          </option>
        );
      })}
    </Form.Select>
  );
};

export default SelectUnitDropdown;
