const clientSearchState = {
  comparisonReviewData: null,
  clientSearchData: [],
  clientSearchByName: null,
  clientData: null,
  bloodMakerAnalysisData: null,
  patternAnalysisData: {
    blood_sugar_pattern: {
      blood_sugar_analysis: { trend_analysis: {}, pattern_analysis: {} },
    },
    cardiovascular_pattern: {
      cardio_vascular_patterns: { trend_analysis: {}, pattern_analysis: {} },
    },
    inflammation_patterns: {
      inflammation_patterns: { trend_analysis: {}, pattern_analysis: {} },
    },
    liver_gall_bladder_patterns: {
      liver_gall_bladder_patterns: { trend_analysis: {}, pattern_analysis: {} },
    },
    nutritional_deficiency_anemia_patterns: {
      nutritional_deficiency_and_anemia_patterns: {
        trend_analysis: {},
        pattern_analysis: {},
      },
    },
    thyroid_patterns: {
      thyroid_patterns: { trend_analysis: {}, pattern_analysis: {} },
    },
  },
  clinicalPearlData:null,
  clientResults: null,
  patternComparisonReviewData: null,
  accountSetting: null,
};

export { clientSearchState };
