import * as moment from "moment-timezone";
import React, { useEffect, useState, useMemo } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectClientSearchData } from "../../store";
import { actions } from "../../store/actions";
import ComparisonReview from "../ClientSearchModule/ComparisonReview";
import DeletePopup from "../Shared/DeletePopup";
import { ReactComponent as EditIcon } from "./../../images/editicon.svg";
import { ReactComponent as TrashIcon } from "./../../images/trashicon.svg";
import "./style.css";

const ViewAllResults = ({ client_id, name, setPage }) => {
  const [cR, openCR] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const { id: testId } = useParams();
  const [selectedDates, setSelectedDates] = useState({});

  const clientSearchData = useSelector(selectClientSearchData);

  useEffect(() => {
    setData(clientSearchData?.clientData || {});
  }, [clientSearchData?.clientData]);
  useEffect(() => {
    let dates = {};
    if (clientSearchData.clientSearchByName) {
      for (let data of clientSearchData.clientSearchByName) {
        dates[data.rws_model_id] = {
          checked: false,
          date: data.date,
        };
      }
      setSelectedDates(dates);
    }
  }, [clientSearchData?.clientSearchByName]);
  useEffect(() => {
    if (data) {
      dispatch({
        type: actions.GET_CLIENT_SEARCH_NAME,
        payload: { name: data.rws_input.client_name },
      });
    }
  }, [data]);
  const moreThanOneDatesSelected = useMemo(() => {
    return (
      Object.keys(selectedDates).length > 0 &&
      Object.values(selectedDates).filter((el) => el.checked).length > 1
    );
  }, [selectedDates]);
  //selected date handler
  const handleSelect = (id) => {
    setSelectedDates((prev) => {
      return {
        ...prev,
        [id]: {
          ...selectedDates[id],
          checked: !selectedDates[id].checked,
        },
      };
    });
  };

  const getSelectedDates = () => {
    return Object.values(selectedDates)
      .filter((el) => el.checked)
      .map((item) => item.date);
  };
  const openComparisonReview = () => {
    navigate(
      `/comparison-review/${
        data.rws_input.client_name
      }/?your_dates=${getSelectedDates().join(",")}`
    );
  };
  const deleteRecord = (id) => {
    dispatch({
      type: actions.DELETE_CLIENT,
      payload: {
        data: {
          id,
        },
        openNext: () => {
          if (Number(testId) === id) {
            navigate("/clientscreensearch");
          } else {
            dispatch({
              type: actions.GET_CLIENT_SEARCH_NAME,
              payload: { name: data.rws_input.client_name },
            });
          }
        },
      },
    });
  };

  if (cR) return <ComparisonReview />;

  return (
    <Container fluid className="main-content main-container">
      <div className="p-3 d-flex align-items-center flex-sm-nowrap flex-wrap">
        <div className="nameLabel-report me-sm-5 me-3 pe-md-5">
          {data?.client_name}
        </div>
        <div>
          {moreThanOneDatesSelected && (
            <Button
              variant="primary"
              size="md"
              className="w-100 primary-bg comparison-review-btn"
              onClick={openComparisonReview}
            >
              Comparison Review
            </Button>
          )}
        </div>
      </div>
      <div className="cNStyle save_action p-3 d-flex align-items-center justify-content-between">
        <div className="">Saved Results</div>
        <div className="">Action</div>
      </div>
      <div className="">
        {clientSearchData?.clientSearchByName?.map((m, i) => {
          return (
            <div key={i} className="">
              <div className="d-flex justify-content-between align-items-center pb-2 result_checkboxes py-13px">
                <div className="pe-0">
                  <Form.Check
                    className="form_checkbox comparison-checkbox"
                    type="checkbox"
                    checked={
                      selectedDates[m.rws_model_id]
                        ? selectedDates[m.rws_model_id].checked
                        : false
                    }
                    onChange={() => handleSelect(m.rws_model_id)}
                    label={
                      <Card.Link
                        className="primary-fg p-2 cursor-pointer"
                        onClick={() => {
                          setPage(1);
                          navigate(
                            `/client-report/${m.rws_model_id}/${m.client_name}`
                          );
                        }}
                      >
                        <u>{moment(m.date).format("MMMM DD, YYYY")}</u>
                      </Card.Link>
                    }
                  />
                </div>
                <div className="text-end edit_delete">
                  <Button
                    variant="secondary"
                    className="actionButton"
                    onClick={() => {
                      navigate(`/report-update/${m.rws_model_id}/`);
                    }}
                  >
                    <EditIcon />
                  </Button>

                  <Button
                    variant="secondary"
                    className="actionButton me-0"
                    onClick={() => {
                      setId(m.rws_model_id);
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              </div>
              <div className="divider-list my-0"></div>
            </div>
          );
        })}
      </div>
      <DeletePopup
        message={"Are you sure you want to delete this report?"}
        deleteRecord={deleteRecord}
        data={id}
        setData={setId}
      />
    </Container>
  );
};

export default ViewAllResults;
