import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import createSagaMiddleware from "redux-saga";
import loginSaga from "./saga/loginSaga";
import clientSearchSlice from "./slices/clientSearch";
import clientSearchSaga from "./saga/clientSearch";
let sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
  sagaMiddleware,
];

// Configure Store
const store = configureStore({
  reducer: {
    userData: userSlice.reducer,
    clientSearch: clientSearchSlice.reducer,
  },
  middleware,
});

// Select Products
export const selectUserData = (state) => state.userData;
export const selectToken = (state) => state.userData.token;
export const selectLoader = (state) => state.userData.loader;

export const selectClientSearchData = (state) => state.clientSearch;
sagaMiddleware.run(loginSaga);
sagaMiddleware.run(clientSearchSaga);

export const { loginReducer, signupReducer, logout, showLoader, hideLoader, setPaymentStatus } =
  userSlice.actions;

export const {
  saveComparisonReview,
  savePatternComparisonReview,
  saveClientSearch,
  saveClientSearchByName,
  deleteRWSClientFromArray,
  saveClient,
  saveMakerAnalysisData,
  savePatternAnalysisData,
  saveClinicalPearlData,
  getClientByID,
  updateClientResults,
  saveCustomerInfo,
  updateClientData,
} = clientSearchSlice.actions;

export { userSlice, store };
