import React, { useState } from "react";
import { Button, Col, Row,Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { validate } from "../../services/formValidation";
import { actions } from "../../store/actions";
import { emailRegex } from "../../utils/constants";
import CustomTextField from "../CustomTextField/CustomTextField";

const SignInPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: {
      value: "",
      error: false,
      regex: emailRegex,
      required: true,
    },
    password: { value: "", error: false, required: true },
  });

  const onSignIn = (e) => {
    e.preventDefault()
    const { n_form, error } = validate(form);
    setForm({ ...form, ...n_form });
    if (error) {
      return;
    }
    dispatch({
      type: actions.LOGIN,
      payload: {
        email: form.email.value.toString().toLowerCase(),
        password: form.password.value,
      },
    });
  };
  return (
    <>
      <Form onSubmit={onSignIn}>
      <Row>
        <Col></Col>
        <Col md={9}>
          <CustomTextField
            label={"Email Address"}
            type="email"
            form={form}
            setForm={setForm}
            fkey="email"
          />
          <CustomTextField
            label={"Password"}
            type="password"
            form={form}
            setForm={setForm}
            fkey="password"
          />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col md={6}>
          <Button
            variant="primary"
            size="md"
            type="submit"
            className="w-100 p-6 primary-bg mb-4 mt-5 btn-auth"
          >
            Sign In
          </Button>
          <div style={{ textAlign: "center" }} className="mb-5">
            <Link className="primary-fg" to="/forgot-password">
              Forgot Password?
            </Link>
            <Link className="primary-fg ms-1" to="/signup">
              Sign Up
            </Link>
          </div>
        </Col>
        <Col></Col>
      </Row>{" "}
      </Form>
    </>
  );
};

export default SignInPage;
