import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch,useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MakerAnalysis from "./MakerAnalysis";
import PatternAnalysis from "./PatternAnalysis";
import ReportNotes from "./ReportNotes";
import ViewAllResults from "./ViewAllResults";
import { actions } from "../../store/actions";
import { toast } from 'react-toastify';
import { selectClientSearchData } from "../../store";
import ClinicalPearls from "./ClinicalPearls";
import * as moment from "moment-timezone";

const ClientDashboard = () => {
  const dispatch = useDispatch();
  let { id, name } = useParams();
  const [page, setPage] = useState(1);
  const [gapiInited, setGapiInited] = useState(false);
  const [tokenClient, setTokenClient] = useState("")
  const { accountSetting } = useSelector(selectClientSearchData);
  const clientSearchData = useSelector(selectClientSearchData);
  useEffect(() => {
    window.gapi.load('client', () => {
      window.gapi.client.init({
        apiKey: process.env.REACT_APP_API_KEY,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
      }).then(() => {
        setGapiInited(true)
      })
    dispatch({
      type: actions.ACCOUNT_SETTINGS,
      payload: {
        type: "GET",
      },
    });
    })
  }, [])
  useEffect(()=>{
    console.log(clientSearchData?.clientData?.rws_input?.date)
    setTokenClient(window.google.accounts.oauth2.initTokenClient({
      client_id: process.env.REACT_APP_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/drive.file',
      callback: async (resp) => {
        if (resp?.access_token) {
          dispatch({
            type: actions.GENERATE_CLIENT_REPORT,
            payload: {
              data: {
                access_token: resp?.access_token,
                date:moment(clientSearchData?.clientData?.rws_input?.date).format("MM/DD/YYYY")
              },
              id,
              openNext: (data) => {
                toast.success("Google Doc is being generated in a new tab. Please ensure you do not have a pop-up blocker!")
                setTimeout(() => {
                  window.open(data.link.webViewLink, '_blank');
                }, 2000)
              }
            },
          });
        }
      }
    })
    )
  },[clientSearchData])

  const googleLogin = () => {
    let token = window.gapi.client.getToken()
    if (token === null) {
      tokenClient.requestAccessToken({ prompt: 'consent' })
    } else {
      tokenClient.requestAccessToken({ prompt: '' })
    }
  }
  const checkUserDetails = () =>{
    if(!accountSetting["practitioner_name"] || !accountSetting["business_name"]){
      toast.error("Please go to Account Settings to enter your Name and Business Name before generating a report.")
      return false
    }
    return true
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [page]);

  return (
    <Container fluid className="main-container">
      <div className="result-accordion">
        <Row className="">
          <Col sm={12} className="nameLabel">
            {name} {clientSearchData?.clientData?.rws_input?.date ? ` - ${moment(clientSearchData?.clientData?.rws_input?.date).format("MMMM DD, YYYY")}`:''} 
          </Col>
        </Row>
        <Row className="maker-analysis mb-30 hide-section-print">
          <Col sm={12} md={4} lg={4} className="">
            <Button
              variant="primary"
              size="md"
              className={`w-100 tab-btn ${page === 1 ? "primary-bg" : "secondary-bg"
                }`}
              onClick={() => {
                setPage(1);
              }}
            >
              Marker Analysis
            </Button>
          </Col>
          <Col
            sm={12}
            md={4}
            lg={4}
            className=""
            onClick={() => {
              setPage(2);
            }}
          >
            <Button
              variant="primary"
              size="md"
              className={`w-100 tab-btn ${page === 2 ? "primary-bg" : "secondary-bg"
                }`}
            >
              Pattern Analysis
            </Button>
          </Col>
          <Col
            sm={12}
            md={4}
            lg={4}
            className=""
            onClick={() => {
              setPage(3);
            }}
          >
            <Button
              variant="primary"
              size="md"
              className={`w-100 tab-btn ${page === 3 ? "primary-bg" : "secondary-bg"
                }`}
            >
              View All Results
            </Button>
          </Col>
        </Row>
        <Row className="maker-analysis mb-30 hide-section-print">
          <Col sm={12} md={6} lg={6} className="">
            {gapiInited ?
              <Button
                variant="primary"
                size="md"
                className="w-100 generate-btn secondary-bg"
                onClick={() => {
                  if(checkUserDetails()) googleLogin()
                }}
              >
                Generate Client Report
              </Button>
              : null}
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <Button
              variant="primary"
              size="md"
              className={`w-100 tab-btn ${
                page === 4 ? "primary-bg" : "secondary-bg"
              }`}
              onClick={() => {
                setPage(4);
              }}
            >
              Clinical Pearls
            </Button>
          </Col>
        </Row>
        {page === 1 || page === 2 || page === 4 ? <ReportNotes id={id} name={name} /> : null}
        {(() => {
          switch (page) {
            case 1: 
            return <MakerAnalysis id={id} name={name} date={clientSearchData?.clientData?.rws_input?.date}/>;
            case 2:
              return <PatternAnalysis id={id} name={name} date={clientSearchData?.clientData?.rws_input?.date}/>;
            case 3:
              return <ViewAllResults client_id={id} name={name} setPage={setPage} date={clientSearchData?.clientData?.rws_input?.date}/>
            case 4:
              return <ClinicalPearls id={id} name={name} date={clientSearchData?.clientData?.rws_input?.date}/>
            default:
              return <MakerAnalysis id={id} name={name} date={clientSearchData?.clientData?.rws_input?.date}/>;
          }
        })()}
      </div>
    </Container>
  );
};

export default ClientDashboard;
