import React from "react";
import { Form } from "react-bootstrap";

const CustomTextField = ({
  label,
  type,
  form,
  setForm,
  fkey,
  options,
  placeholder,
}) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label className="textfield-label">{label}</Form.Label>
      {options && options.length > 0 ? (
        <Form.Select
          className="textField"
          placeholder={"Enter " + label}
          value={form?.[fkey]?.value}
          onChange={(e) => {
            setForm({
              ...form,
              [fkey]: {
                ...(form[fkey] || {}),
                value: e.target.value,
                error: false,
              },
            });
          }}
        >
          {options.map((m, i) => {
            return (
              <option key={i} value={m.value}>
                {m.key}
              </option>
            );
          })}
        </Form.Select>
      ) : (
        <Form.Control
          className={`textField ${fkey === "logo" ? "h-100" : ""}`}
          type={type || "text"}
          max={fkey === "dob" ? new Date().toISOString().split("T")[0] : ""}
          placeholder={placeholder ? placeholder : "Enter " + label}
          value={form?.[fkey]?.value}
          accept={fkey === "logo" ? ".png,.jpeg,.jpg" : ""}
          onChange={(e) => {
            if (fkey === "logo") {
              setForm(e);
            } else {
              setForm({
                ...form,
                [fkey]: {
                  ...form[fkey],
                  value: e.target.value,
                  error:  e.target.value ? false: true,
                },
              });
            }
          }}
        />
      )}

      {fkey !== "logo" && form?.[fkey]?.error && (
        <Form.Text style={{ color: "red" }}>
          {form?.[fkey]?.value?.length > 0
            ? "Invalid " + label
            : label + " is required"}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default CustomTextField;
