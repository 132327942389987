import * as moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { validate } from "../../services/formValidation";
import { selectClientSearchData } from "../../store";
import { actions } from "../../store/actions";
import AddNewClient2 from "./AddNewClient2";
import AddPayment from "./AddPayment";
import CustomTextField from "../CustomTextField/CustomTextField";
import { useSearchParams, useParams } from 'react-router-dom';
import AddNewClient3 from "./AddNewClient3";
const AddNewClient1 = () => {
  const location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [searchResult, setSearchResult] = useState([]);
  const [searchParams] = useSearchParams();
  const { clientSearchData, clientData } = useSelector(selectClientSearchData);
  const [searchText, setSearchText] = useState("");
  const [form, setForm] = useState({
    client_name: {
      value: "",
      error: false,
      required: true,
    },
    date: {
      value: moment(new Date()).format("YYYY-MM-DD"),
      error: false,
      required: true,
    },
    sex: {
      value: "male",
      error: false,
      required: true,
    },
    dob: {
      value: moment(new Date()).format("YYYY-MM-DD"),
      error: false,
      required: true,
    },
    units: {
      value: "us",
      error: false,
      required: true,
    },
  });
  useEffect(() => {
    dispatch({
      type: actions.GET_CLIENT_SEARCH,
    });
    setSearchResult([])
    setForm({
      client_name: {
        value: "",
        error: false,
        required: true,
      },
      date: {
        value: moment().format("YYYY-MM-DD"),
        error: false,
        required: true,
      },
      sex: {
        value: "male",
        error: false,
        required: true,
      },
      dob: {
        value: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        error: false,
        required: true,
      },
      units: {
        value: "us",
        error: false,
        required: true,
      },
    });

  }, []);
  useEffect(() => {
    if (id) {
      dispatch({
        type: actions.ADD_NEW_TEST,
        payload: {
          id,
        },
      });
    }
  }, [id]);
  useEffect(() => {
    if (clientData && clientData.client_name && id) {
      // console.log(clientData)
      setSearchResult([clientData])
      setForm({
        client_name: {
          value: clientData.client_name,
          error: false,
          required: true,
        },
        date: {
          value: moment(clientData.date).format("YYYY-MM-DD"),
          error: false,
          required: true,
        },
        sex: {
          value: clientData.sex,
          error: false,
          required: true,
        },
        dob: {
          value: moment(clientData.date_of_birth).subtract(1, 'day').format('YYYY-MM-DD'),
          error: false,
          required: true,
        },
        units: {
          value: clientData.units_us_or_intl,
          error: false,
          required: true,
        },
      });
    }
  }, [clientData])
  useEffect(() => {
    setForm((f) => {
      return {
        ...f,
        client_name: {
          ...(f["client_name"] || {}),
          value: searchText,
          error: false,
        },
      };
    });
  }, [searchText]);

  useEffect(() => {
    let data = location?.state?.data;
    if (data) {
      setForm({
        client_name: {
          value: data.client_name,
          error: false,
          required: true,
        },
        date: {
          value: data.date.split("T")[0],
          error: false,
          required: true,
        },
        sex: {
          value: data.sex,
          error: false,
          required: true,
        },
        dob: {
          value: data.date_of_birth.split("T")[0],
          error: false,
          required: true,
        },
        units: {
          value: data.units_us_or_intl,
          error: false,
          required: true,
        },
      });
    }
  }, [location?.state?.data]);

  const [openNext, setOpenNext] = useState(false);
  const onNext = (e) => {
    e.preventDefault()
    const { n_form, error } = validate(form);

    setForm({ ...form, ...n_form });
    if (error) {
      return;
    }
    setOpenNext(true);
  };
  const setFormData = (e) => {
    if (e) {
      // console.log("e", e);
      setSearchResult(e);
      setForm({
        ...form,
        client_name: {
          ...(form?.["client_name"] || {}),
          value: e[0]?.client_name,
          error: false,
        },
        date: {
          ...(form?.["date"] || {}),
          value: e[0]?.date
            ? new Date(e[0].date).toISOString().split("T")[0]
            : new Date(Date.now()).toISOString().split("T")[0],
          error: false,
        },
        sex: {
          ...(form["sex"] || {}),
          value: e[0]?.sex ?? "Male",
          error: false,
        },
        dob: {
          ...(form["dob"] || {}),
          value: e[0]?.date_of_birth
            ? new Date(e[0].date_of_birth).toISOString().split("T")[0]
            : new Date(Date.now()).toISOString().split("T")[0],
          error: false,
        },
        units: {
          ...(form["units"] || {}),
          value: e[0]?.units_us_or_intl ?? "US",
          error: false,
        },
      });
    }
  };
  if (openNext) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (id) return <AddNewClient3 form={form} />
    return <AddNewClient2 form={form} />;
  }
  return (
    <Container fluid className="main-container p-sm-4 p-3">
      <Row>
        <Col md={12} lg={8} className="center-card form-card mx-auto">
          <Form onSubmit={onNext}>
            <Form.Group>
              <Form.Label>Client Name</Form.Label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="client_name"
                onChange={setFormData}
                options={clientSearchData}
                className="mb-3"
                placeholder="Choose a Client Name..."
                selected={searchResult || ""}
                onInputChange={(text) => {
                  if (searchResult.length === 0) setSearchText(text);
                }}
                disabled={id ? true : false}
              />
              {form?.client_name?.error && (
                <Form.Text style={{ color: "red" }}>
                  {form?.client_name?.value?.length > 0
                    ? "Invalid Client Name"
                    : "Client Name is required"}
                </Form.Text>
              )}
            </Form.Group>
            <CustomTextField
              label={"Test Date"}
              type="date"
              form={form}
              setForm={setForm}
              fkey="date"
            />
            <CustomTextField
              label={"Sex"}
              form={form}
              setForm={setForm}
              fkey="sex"
              options={[
                { value: "male", key: "Male" },
                { value: "female-cycling", key: "Female - Cycling" },
                { value: "female-noncycling", key: "Female - NonCycling" },
              ]}
            />
            <CustomTextField
              label={"Date of Birth"}
              type="date"
              form={form}
              setForm={setForm}
              fkey="dob"
            />
            <CustomTextField
              label={"US Units or International Units"}
              form={form}
              setForm={setForm}
              fkey="units"
              options={[
                { value: "us", key: "US" },
                { value: "intl", key: "International" },
              ]}
            />
            <Button
              variant="primary"
              size="md"
              className="w-100 primary-bg next-btn"
              type="submit"
            >
              Next
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewClient1;
