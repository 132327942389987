import { createSlice } from "@reduxjs/toolkit";
import { clientSearchState } from "../states/clientSearchState";

// Create Product Slice
const clientSearchSlice = createSlice({
  name: "clientSearch",
  initialState: clientSearchState,
  reducers: {
    saveClient: (state, { payload }) => {
      return { ...state, clientData: payload };
    },
    saveComparisonReview: (state, { payload }) => {
      return {
        ...state,
        comparisonReviewData: payload,
      };
    },
    savePatternComparisonReview: (state, { payload }) => {
      return {
        ...state,
        patternComparisonReviewData: payload,
      };
    },
    saveClientSearch: (state, { payload }) => {
      return {
        ...state,
        clientSearchData: payload,
      };
    },
    saveClientSearchByName: (state, { payload }) => {
      return {
        ...state,
        clientSearchByName: payload,
      };
    },
    deleteRWSClientFromArray: (state, { payload }) => {
      return {
        ...state,
        clientSearchByName: state.clientSearchByName.filter(
          (f) => f.id !== payload.id
        ),
      };
    },
    saveMakerAnalysisData: (state, { payload }) => {
      return {
        ...state,
        bloodMakerAnalysisData: payload,
      };
    },
    savePatternAnalysisData: (state, { payload }) => {
      return {
        ...state,
        patternAnalysisData: payload,
      };
    },
    saveClinicalPearlData: (state, { payload }) => {
      return {
        ...state,
        clinicalPearlData: payload,
      };
    },
    updateClientResults: (state, { payload }) => {
      return {
        ...state,
        clientResults: payload.data,
      };
    },
    updateClientData: (state, { payload }) => {
      return {
        ...state,
        clientData: payload,
      };
    },
    saveCustomerInfo: (state, { payload }) => {
      return {
        ...state,
        accountSetting: payload,
      };
    },
  },
});

export default clientSearchSlice;
