export const bloodMarkerData = [
    {
        name: "Complete Blood Count (CBC)",
        key: "complete_bloodcount",
        childs: [
            {
                name: "WBC", key: "white_blood_cells_wbc",
                scales: {
                    male: {
                        us: {
                            functional: "5 - 7.5",
                            conventional: "3.7 - 11",
                        },
                        intl: {
                            functional: "5 - 7.5 x 109/L",
                            conventional: "3.7 - 11 x 109/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "5 - 7.5",
                            conventional: "3.7 - 11",
                        },
                        intl: {
                            functional: "5 - 7.5 x 109/L",
                            conventional: "3.7 - 11 x 109/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "5 - 7.5",
                            conventional: "3.7 - 11",
                        },
                        intl: {
                            functional: "5 - 7.5 x 109/L",
                            conventional: "3.7 - 11 x 109/L",
                        },
                    },
                },

            },
            {
                name: "RBC", key: "red_blood_cell_rbc",
                scales: {
                    male: {
                        us: {
                            functional: "4.2 - 4.9 million/uL",
                            conventional: "3.9 - 5.1 million/uL",
                        },
                        intl: {
                            functional: "4.2 - 4.9 x 1012/L",
                            conventional: "3.9 - 5.1 x 1012/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "3.9 - 4.5 million/uL",
                            conventional: "3.9 - 5.1 million/uL",
                        },
                        intl: {
                            functional: "3.9 - 4.5 x 1012/L",
                            conventional: "3.9 - 5.1 x 1012/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "3.9 - 4.5 million/uL",
                            conventional: "3.9 - 5.1 million/uL",
                        },
                        intl: {
                            functional: "3.9 - 4.5 x 1012/L",
                            conventional: "3.9 - 5.1 x 1012/L",
                        },
                    },
                },

            },
            {
                name: "Hemoglobin",
                key: "hemoglobin_hgb",
                scales: {
                    male: {
                        us: {
                            functional: "14 - 15 g/dL",
                            conventional: "12 - 16 g/dL",
                        },
                        intl: {
                            functional: "140 - 150 g/L",
                            conventional: "120 - 160 g/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "13.5 - 14.5 g/dL",
                            conventional: "12 - 16 g/dL",
                        },
                        intl: {
                            functional: "135 - 145 g/L",
                            conventional: "120 - 160 g/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "13.5 - 14.5 g/dL",
                            conventional: "12 - 16 g/dL",
                        },
                        intl: {
                            functional: "135 - 145 g/L",
                            conventional: "120 - 160 g/L",
                        },
                    },
                },
            },
            {
                name: "Hematocrit",
                key: "hematocrit_hct",
                scales: {
                    male: {
                        us: {
                            functional: "40 - 48 %",
                            conventional: "36 - 48.2 %",
                        },
                        intl: {
                            functional: "40 - 48 %",
                            conventional: "36 - 48.2 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "37 - 44 %",
                            conventional: "36 - 48.2 %",
                        },
                        intl: {
                            functional: "37 - 44 %",
                            conventional: "36 - 48.2 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "37 - 44 %",
                            conventional: "36 - 48.2 %",
                        },
                        intl: {
                            functional: "37 - 44 %",
                            conventional: "36 - 48.2 %",
                        },
                    },
                },
            },
            
            {
                name: "MCV",
                key: "mean_corpuscular_volume_mcv",
                scales: {
                    male: {
                        us: {
                            functional: "82 - 89.9 μ^3",
                            conventional: "79 - 97 μ^3",
                        },
                        intl: {
                            functional: "82 - 89.9 fL",
                            conventional: "79 - 97 fL",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "82 - 89.9 μ^3",
                            conventional: "79 - 97 μ^3",
                        },
                        intl: {
                            functional: "82 - 89.9 fL",
                            conventional: "79 - 97 fL",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "82 - 89.9 μ^3",
                            conventional: "79 - 97 μ^3",
                        },
                        intl: {
                            functional: "82 - 89.9 fL",
                            conventional: "79 - 97 fL",
                        },
                    },
                },
            },
            {
                name: "MCH",
                key: "mean_corpuscular_hemoglobin_mch",
                scales: {
                    male: {
                        us: {
                            functional: "26.6 - 33 pg",
                            conventional: "28 - 31.9 pg",
                        },
                        intl: {
                            functional: "26.6 - 33 pg",
                            conventional: "28 - 31.9 pg",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "28 - 31.9 pg",
                            conventional: "26.6 - 33 pg",
                        },
                        intl: {
                            functional: "28 - 31.9 pg",
                            conventional: "26.6 - 33 pg",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "28 - 31.9 pg",
                            conventional: "26.6 - 33 pg",
                        },
                        intl: {
                            functional: "28 - 31.9 pg",
                            conventional: "26.6 - 33 pg",
                        },
                    },
                },
            },
            {
                name: "MCHC",
                key: "mean_corpuscular_hemoglobin_concentration_mchc",
                scales: {
                    male: {
                        us: {
                            functional: "32 - 35 g/dL",
                            conventional: "31.5 - 37 g/dL",
                        },
                        intl: {
                            functional: "19.84 - 21.7 mmol/L",
                            conventional: "19.53 - 22.94 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "32 - 35 g/dL",
                            conventional: "31.5 - 37 g/dL",
                        },
                        intl: {
                            functional: "19.84 - 21.7 mmol/L",
                            conventional: "19.53 - 22.94 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "32 - 35 g/dL",
                            conventional: "31.5 - 37 g/dL",
                        },
                        intl: {
                            functional: "19.84 - 21.7 mmol/L",
                            conventional: "19.53 - 22.94 mmol/L",
                        },
                    },
                },
            },
            
            {
                name: "RDW",
                key: "random_distribution_weight_rdw",
                scales: {
                    male: {
                        us: {
                            functional: "9 - 13 %",
                            conventional: "12.3 - 15.4 %",
                        },
                        intl: {
                            functional: "9 - 13 %",
                            conventional: "12.3 - 15.4 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "9 - 13 %",
                            conventional: "12.3 - 15.4 %",
                        },
                        intl: {
                            functional: "9 - 13 %",
                            conventional: "12.3 - 15.4 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "9 - 13 %",
                            conventional: "12.3 - 15.4 %",
                        },
                        intl: {
                            functional: "9 - 13 %",
                            conventional: "12.3 - 15.4 %",
                        },
                    },
                },
            },
            {
                name: "Platelet Count",
                key: "platelets",
                scales: {
                    male: {
                        us: {
                            functional: "155 - 385 mm^3, x 1000",
                            conventional: "155 - 385 mm^3, x 1000",
                        },
                        intl: {
                            functional: "155 - 385 10^9/L",
                            conventional: "155 - 385 10^9/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "155 - 385 mm^3, x 1000",
                            conventional: "155 - 385 mm^3, x 1000",
                        },
                        intl: {
                            functional: "155 - 385 10^9/L",
                            conventional: "155 - 385 10^9/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "155 - 385 mm^3, x 1000",
                            conventional: "155 - 385 mm^3, x 1000",
                        },
                        intl: {
                            functional: "155 - 385 10^9/L",
                            conventional: "155 - 385 10^9/L",
                        },
                    },
                },
            },
            {
                name: "MPV",
                key: "mean_platelet_volume_mpv",
                scales: {
                    male: {
                        us: {
                            functional: "7.5 - 12.5",
                            conventional: "7.5 - 12.5",
                        },
                        intl: {
                            functional: "7.5 - 12.5 fL",
                            conventional: "7.5 - 12.5 fL",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "7.5 - 12.5",
                            conventional: "7.5 - 12.5",
                        },
                        intl: {
                            functional: "7.5 - 12.5 fL",
                            conventional: "7.5 - 12.5 fL",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "7.5 - 12.5",
                            conventional: "7.5 - 12.5",
                        },
                        intl: {
                            functional: "7.5 - 12.5 fL",
                            conventional: "7.5 - 12.5 fL",
                        },
                    },
                },
            },
            {
                name: "Neutrophils",
                key: "neutrophils",
                scales: {
                    male: {
                        us: {
                            functional: "40 - 60 %",
                            conventional: "35 - 74 %",
                        },
                        intl: {
                            functional: "40 - 60 %",
                            conventional: "35 - 74 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "40 - 60 %",
                            conventional: "35 - 74 %",
                        },
                        intl: {
                            functional: "40 - 60 %",
                            conventional: "35 - 74 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "40 - 60 %",
                            conventional: "35 - 74 %",
                        },
                        intl: {
                            functional: "40 - 60 %",
                            conventional: "35 - 74 %",
                        },
                    },
                },
            },
            {
                name: "Lymphocytes",
                key: "lymphocytes",
                scales: {
                    male: {
                        us: {
                            functional: "24 - 44 %",
                            conventional: "14 - 46 %",
                        },
                        intl: {
                            functional: "24 - 44 %",
                            conventional: "14 - 46 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "24 - 44 %",
                            conventional: "14 - 46 %",
                        },
                        intl: {
                            functional: "24 - 44 %",
                            conventional: "14 - 46 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "24 - 44 %",
                            conventional: "14 - 46 %",
                        },
                        intl: {
                            functional: "24 - 44 %",
                            conventional: "14 - 46 %",
                        },
                    },
                },
            },

            {
                name: "Monocytes",
                key: "monocytes",
                scales: {
                    male: {
                        us: {
                            functional: "0 - 7 %",
                            conventional: "4 - 13 %",
                        },
                        intl: {
                            functional: "0 - 7 %",
                            conventional: "4 - 13 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0 - 7 %",
                            conventional: "4 - 13 %",
                        },
                        intl: {
                            functional: "0 - 7 %",
                            conventional: "4 - 13 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0 - 7 %",
                            conventional: "4 - 13 %",
                        },
                        intl: {
                            functional: "0 - 7 %",
                            conventional: "4 - 13 %",
                        },
                    },
                },
            },
            
            {
                name: "Eosinophils",
                key: "eosinophils",
                scales: {
                    male: {
                        us: {
                            functional: "0 - 3 %",
                            conventional: "0 - 7 %",
                        },
                        intl: {
                            functional: "0 - 3 %",
                            conventional: "0 - 7 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0 - 3 %",
                            conventional: "0 - 7 %",
                        },
                        intl: {
                            functional: "0 - 3 %",
                            conventional: "0 - 7 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0 - 3 %",
                            conventional: "0 - 7 %",
                        },
                        intl: {
                            functional: "0 - 3 %",
                            conventional: "0 - 7 %",
                        },
                    },
                },
            },    
            {
                name: "Basophils",
                key: "basophils",
                scales: {
                    male: {
                        us: {
                            functional: "0 - 1 %",
                            conventional: "0 - 3 %",
                        },
                        intl: {
                            functional: "0 - 1 %",
                            conventional: "0 - 3 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0 - 1 %",
                            conventional: "0 - 3 %",
                        },
                        intl: {
                            functional: "0 - 1 %",
                            conventional: "0 - 3 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0 - 1 %",
                            conventional: "0 - 3 %",
                        },
                        intl: {
                            functional: "0 - 1 %",
                            conventional: "0 - 3 %",
                        },
                    },
                },
            }
        ],
    },
    {
        name: "Complete Metabolic Panel (CMP)",
        key: "complete_metabolicpanel",
        childs: [
            {
                name: "Glucose",
                key: "glucose",
                scales: {
                    male: {
                        us: {
                            functional: "70 - 90 mg/dL",
                            conventional: "65 - 115 mg/dL",
                        },
                        intl: {
                            functional: "3.9 - 5 mmol/L",
                            conventional: "3.6 - 6.4 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "70 - 90 mg/dL",
                            conventional: "65 - 115 mg/dL",
                        },
                        intl: {
                            functional: "3.9 - 5 mmol/L",
                            conventional: "3.6 - 6.4 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "70 - 90 mg/dL",
                            conventional: "65 - 115 mg/dL",
                        },
                        intl: {
                            functional: "3.9 - 5 mmol/L",
                            conventional: "3.6 - 6.4 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Blood Urea Nitogen",
                key: "blood_urea_nitogen",
                scales: {
                    male: {
                        us: {
                            functional: "10 - 16 mg/dL",
                            conventional: "5 - 25 mg/dL",
                        },
                        intl: {
                            functional: "3.6 - 5.7 mmol/L",
                            conventional: "1.8 - 9 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "10 - 16 mg/dL",
                            conventional: "5 - 25 mg/dL",
                        },
                        intl: {
                            functional: "3.6 - 5.7 mmol/L",
                            conventional: "1.8 - 9 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "10 - 16 mg/dL",
                            conventional: "5 - 25 mg/dL",
                        },
                        intl: {
                            functional: "3.6 - 5.7 mmol/L",
                            conventional: "1.8 - 9 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Creatinine",
                key: "creatinine",
                scales: {
                    male: {
                        us: {
                            functional: "0.8 - 1.1 mg/dL",
                            conventional: "0.6 - 1.5 mg/dL",
                        },
                        intl: {
                            functional: "70.7 - 97.2 µmol/L",
                            conventional: "53 - 132.6 µmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0.8 - 1.1 mg/dL",
                            conventional: "0.6 - 1.5 mg/dL",
                        },
                        intl: {
                            functional: "70.7 - 97.2 µmol/L",
                            conventional: "53 - 132.6 µmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0.8 - 1.1 mg/dL",
                            conventional: "0.6 - 1.5 mg/dL",
                        },
                        intl: {
                            functional: "70.7 - 97.2 µmol/L",
                            conventional: "53 - 132.6 µmol/L",
                        },
                    },
                },
            },
            {
                name: "Glomerular Filtration Rate",
                key: "glomerular_filtration_rate",
                scales: {
                    male: {
                        us: {
                            functional: "59 - 9999 mL/min/1.73^2",
                            conventional: "60 - 9999 mL/min/1.73^2",
                        },
                        intl: {
                            functional: "59 - 9999 mL/min/1.73^2",
                            conventional: "60 - 9999 mL/min/1.73^2",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "59 - 9999 mL/min/1.73^2",
                            conventional: "60 - 9999 mL/min/1.73^2",
                        },
                        intl: {
                            functional: "59 - 9999 mL/min/1.73^2",
                            conventional: "60 - 9999 mL/min/1.73^2",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "59 - 9999 mL/min/1.73^2",
                            conventional: "60 - 9999 mL/min/1.73^2",
                        },
                        intl: {
                            functional: "59 - 9999 mL/min/1.73^2",
                            conventional: "60 - 9999 mL/min/1.73^2",
                        },
                    },
                },
            },
            {
                name: "BUN/Creat ratio",
                key: "bun_creat_ratio",
                scales: {
                    male: {
                        us: {
                            functional: "10 - 16",
                            conventional: "6 - 20",
                        },
                        intl: {
                            functional: "10 - 16",
                            conventional: "6 - 20",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "10 - 16",
                            conventional: "6 - 20",
                        },
                        intl: {
                            functional: "10 - 16",
                            conventional: "6 - 20",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "10 - 16",
                            conventional: "6 - 20",
                        },
                        intl: {
                            functional: "10 - 16",
                            conventional: "6 - 20",
                        },
                    },
                },
            },
            {
                name: "Sodium (Na)",
                key: "sodium",
                scales: {
                    male: {
                        us: {
                            functional: "135 - 142 mEq/L",
                            conventional: "135 - 145 mEq/L",
                        },
                        intl: {
                            functional: "135 - 142 mmol/L",
                            conventional: "135 - 145 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "135 - 142 mEq/L",
                            conventional: "135 - 145 mEq/L",
                        },
                        intl: {
                            functional: "135 - 142 mmol/L",
                            conventional: "135 - 145 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "135 - 142 mEq/L",
                            conventional: "135 - 145 mEq/L",
                        },
                        intl: {
                            functional: "135 - 142 mmol/L",
                            conventional: "135 - 145 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Potassium (K)",
                key: "potassium",
                scales: {
                    male: {
                        us: {
                            functional: "4 - 4.5 mEq/L",
                            conventional: "3.5 - 5.3 mEq/L",
                        },
                        intl: {
                            functional: "4 - 4.5  mmol/L",
                            conventional: "3.5 - 5.3 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "4 - 4.5 mEq/L",
                            conventional: "3.5 - 5.3 mEq/L",
                        },
                        intl: {
                            functional: "4 - 4.5  mmol/L",
                            conventional: "3.5 - 5.3 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "4 - 4.5 mEq/L",
                            conventional: "3.5 - 5.3 mEq/L",
                        },
                        intl: {
                            functional: "4 - 4.5  mmol/L",
                            conventional: "3.5 - 5.3 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Chloride (Cl)",
                key: "chloride",
                scales: {
                    male: {
                        us: {
                            functional: "100 - 106 mEq/L",
                            conventional: "96 - 109 mEq/L",
                        },
                        intl: {
                            functional: "100 - 106  mmol/L",
                            conventional: "96 - 109 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "100 - 106 mEq/L",
                            conventional: "96 - 109 mEq/L",
                        },
                        intl: {
                            functional: "100 - 106  mmol/L",
                            conventional: "96 - 109 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "100 - 106 mEq/L",
                            conventional: "96 - 109 mEq/L",
                        },
                        intl: {
                            functional: "100 - 106  mmol/L",
                            conventional: "96 - 109 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Carbon Dioxide (CO2)",
                key: "carbon_dioxide",
                scales: {
                    male: {
                        us: {
                            functional: "25 - 30 mEq/L",
                            conventional: "23 - 32 mEq/L",
                        },
                        intl: {
                            functional: "25 - 30  mmol/L",
                            conventional: "23 - 32 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "25 - 30 mEq/L",
                            conventional: "23 - 32 mEq/L",
                        },
                        intl: {
                            functional: "25 - 30  mmol/L",
                            conventional: "23 - 32 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "25 - 30 mEq/L",
                            conventional: "23 - 32 mEq/L",
                        },
                        intl: {
                            functional: "25 - 30  mmol/L",
                            conventional: "23 - 32 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Calcium",
                key: "calcium",
                scales: {
                    male: {
                        us: {
                            functional: "9.2 - 10 mg/dL",
                            conventional: "8.5 - 10.8 mg/dL",
                        },
                        intl: {
                            functional: "2.3 - 2.5  mmol/L",
                            conventional: "2.1 - 2.7 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "9.2 - 10 mg/dL",
                            conventional: "8.5 - 10.8 mg/dL",
                        },
                        intl: {
                            functional: "2.3 - 2.5  mmol/L",
                            conventional: "2.1 - 2.7 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "9.2 - 10 mg/dL",
                            conventional: "8.5 - 10.8 mg/dL",
                        },
                        intl: {
                            functional: "2.3 - 2.5  mmol/L",
                            conventional: "2.1 - 2.7 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Total Protein",
                key: "total_protien",
                scales: {
                    male: {
                        us: {
                            functional: "6.9 - 7.4 g/dL",
                            conventional: "6 - 8.5 g/dL",
                        },
                        intl: {
                            functional: "69 - 74  g/L",
                            conventional: "60 - 85 g/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "6.9 - 7.4 g/dL",
                            conventional: "6 - 8.5 g/dL",
                        },
                        intl: {
                            functional: "69 - 74  g/L",
                            conventional: "60 - 85 g/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "6.9 - 7.4 g/dL",
                            conventional: "6 - 8.5 g/dL",
                        },
                        intl: {
                            functional: "69 - 74  g/L",
                            conventional: "60 - 85 g/L",
                        },
                    },
                },
            },

            {
                name: "Albumin",
                key: "albumin",
                scales: {
                    male: {
                        us: {
                            functional: "4.6 - 5 g/dL",
                            conventional: "3.6 - 4.8 g/dL",
                        },
                        intl: {
                            functional: "46 - 50  g/L",
                            conventional: "36 - 48 g/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "4.6 - 5 g/dL",
                            conventional: "3.6 - 4.8 g/dL",
                        },
                        intl: {
                            functional: "46 - 50  g/L",
                            conventional: "36 - 48 g/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "4.6 - 5 g/dL",
                            conventional: "3.6 - 4.8 g/dL",
                        },
                        intl: {
                            functional: "46 - 50  g/L",
                            conventional: "36 - 48 g/L",
                        },
                    },
                },
            },
            {
                name: "Globulin",
                key: "globulin",
                scales: {
                    male: {
                        us: {
                            functional: "2.4 - 2.8 g/dL",
                            conventional: "2 - 3.9 g/dL",
                        },
                        intl: {
                            functional: "24 - 28  g/L",
                            conventional: "20 - 39 g/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "2.4 - 2.8 g/dL",
                            conventional: "2 - 3.9 g/dL",
                        },
                        intl: {
                            functional: "24 - 28  g/L",
                            conventional: "20 - 39 g/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "2.4 - 2.8 g/dL",
                            conventional: "2 - 3.9 g/dL",
                        },
                        intl: {
                            functional: "24 - 28  g/L",
                            conventional: "20 - 39 g/L",
                        },
                    },
                },
            },
            {
                name: "A/G ratio",
                key: "a_g_ratio",
                scales: {
                    male: {
                        us: {
                            functional: "1.5 - 2",
                            conventional: "1.1 - 2.5",
                        },
                        intl: {
                            functional: "1.5 - 2",
                            conventional: "1.1 - 2.5",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "1.5 - 2",
                            conventional: "1.1 - 2.5",
                        },
                        intl: {
                            functional: "1.5 - 2",
                            conventional: "1.1 - 2.5",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "1.5 - 2",
                            conventional: "1.1 - 2.5",
                        },
                        intl: {
                            functional: "1.5 - 2",
                            conventional: "1.1 - 2.5",
                        },
                    },
                },
            },
            {
                name: "Total Bilirubin",
                key: "total_bilirubin",
                scales: {
                    male: {
                        us: {
                            functional: "0.5 - 1.2 mg/dL",
                            conventional: "0.1 - 1.2 mg/dL",
                        },
                        intl: {
                            functional: "8.6 - 20.5 µmol/L",
                            conventional: "1.7 - 20.5 µmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0.5 - 1.2 mg/dL",
                            conventional: "0.1 - 1.2 mg/dL",
                        },
                        intl: {
                            functional: "8.6 - 20.5 µmol/L",
                            conventional: "1.7 - 20.5 µmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0.5 - 1.2 mg/dL",
                            conventional: "0.1 - 1.2 mg/dL",
                        },
                        intl: {
                            functional: "8.6 - 20.5 µmol/L",
                            conventional: "1.7 - 20.5 µmol/L",
                        },
                    },
                },
            },
            {
                name: "Alkaline Phosphatase",
                key: "alkaline_phosphatase",
                scales: {
                    male: {
                        us: {
                            functional: "40 - 100 μ/L",
                            conventional: "25 - 120 μ/L",
                        },
                        intl: {
                            functional: "40 - 100 μ/L",
                            conventional: "25 - 120 μ/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "40 - 100 μ/L",
                            conventional: "25 - 120 μ/L",
                        },
                        intl: {
                            functional: "40 - 100 μ/L",
                            conventional: "25 - 120 μ/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "40 - 100 μ/L",
                            conventional: "25 - 120 μ/L",
                        },
                        intl: {
                            functional: "40 - 100 μ/L",
                            conventional: "25 - 120 μ/L",
                        },
                    },
                },
            },
            {
                name: "SGOT (AST)",
                key: "sgot",
                scales: {
                    male: {
                        us: {
                            functional: "12 - 26 μ/L",
                            conventional: "0 - 40 μ/L",
                        },
                        intl: {
                            functional: "12 - 26 μ/L",
                            conventional: "0 - 40 μ/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "9 - 21 μ/L",
                            conventional: "0 - 40 μ/L",
                        },
                        intl: {
                            functional: "9 - 21 μ/L",
                            conventional: "0 - 40 μ/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "9 - 21 μ/L",
                            conventional: "0 - 40 μ/L",
                        },
                        intl: {
                            functional: "9 - 21 μ/L",
                            conventional: "0 - 40 μ/L",
                        },
                    },
                },
            },

            {
                name: "SGPT (ALT)",
                key: "sgpt",
                scales: {
                    male: {
                        us: {
                            functional: "13 - 22 μ/L",
                            conventional: "0 - 45 μ/L",
                        },
                        intl: {
                            functional: "13 - 22 μ/L",
                            conventional: "0 - 45 μ/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "10 - 19 μ/L",
                            conventional: "0 - 45 μ/L",
                        },
                        intl: {
                            functional: "10 - 19 μ/L",
                            conventional: "0 - 45 μ/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "10 - 19 μ/L",
                            conventional: "0 - 45 μ/L",
                        },
                        intl: {
                            functional: "10 - 19 μ/L",
                            conventional: "0 - 45 μ/L",
                        },
                    },
                },
            },
            {
                name: "Hemoglobin A1c",
                key: "hemoglobin_a1c",
                scales: {
                    male: {
                        us: {
                            functional: "4.1 - 5.4 %",
                            conventional: "4.1 - 5.7 %",
                        },
                        intl: {
                            functional: "21.31 - 35.52 mmol/mol",
                            conventional: "21.31 - 38.8 mmol/mol",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "4.1 - 5.4 %",
                            conventional: "4.1 - 5.7 %",
                        },
                        intl: {
                            functional: "21.31 - 35.52 mmol/mol",
                            conventional: "21.31 - 38.8 mmol/mol",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "4.1 - 5.4 %",
                            conventional: "4.1 - 5.7 %",
                        },
                        intl: {
                            functional: "21.31 - 35.52 mmol/mol",
                            conventional: "21.31 - 38.8 mmol/mol",
                        },
                    },
                },
            },
            {
                name: "Estimated Average Glucose (eAG)",
                key: "estimated_average_glucose",
                scales: {
                    male: {
                        us: {
                            functional: "70 - 108 mg/dL",
                            conventional: "70 - 117 mg/dL",
                        },
                        intl: {
                            functional: "3.94 - 6.09 mmol/L",
                            conventional: "3.94 - 6.41 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "70 - 108 mg/dL",
                            conventional: "70 - 117 mg/dL",
                        },
                        intl: {
                            functional: "3.94 - 6.09 mmol/L",
                            conventional: "3.94 - 6.41 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "70 - 108 mg/dL",
                            conventional: "70 - 117 mg/dL",
                        },
                        intl: {
                            functional: "3.94 - 6.09 mmol/L",
                            conventional: "3.94 - 6.41 mmol/L",
                        },
                    },
                },

            },
            {
                name: "Magnesium",
                key: "magnesium",
                scales: {
                    male: {
                        us: {
                            functional: "2 - 2.5 mg/dL",
                            conventional: "1.5 - 2.6 mg/dL",
                        },
                        intl: {
                            functional: "0.8 - 1 mmol/L",
                            conventional: "0.6 - 1.1 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "2 - 2.5 mg/dL",
                            conventional: "1.5 - 2.6 mg/dL",
                        },
                        intl: {
                            functional: "0.8 - 1 mmol/L",
                            conventional: "0.6 - 1.1 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "2 - 2.5 mg/dL",
                            conventional: "1.5 - 2.6 mg/dL",
                        },
                        intl: {
                            functional: "0.8 - 1 mmol/L",
                            conventional: "0.6 - 1.1 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Phosphorus",
                key: "phosphorus",
                scales: {
                    male: {
                        us: {
                            functional: "3 - 3.5 mg/dL",
                            conventional: "2.5 - 4.5 mg/dL",
                        },
                        intl: {
                            functional: "1 - 1.11 mmol/L",
                            conventional: "0.8 - 1.5 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "3.5 - 4 mg/dL",
                            conventional: "2.5 - 4.5 mg/dL",
                        },
                        intl: {
                            functional: "1.1 - 1.3 mmol/L",
                            conventional: "0.8 - 1.5 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "3.5 - 4 mg/dL",
                            conventional: "2.5 - 4.5 mg/dL",
                        },
                        intl: {
                            functional: "1.1 - 1.3 mmol/L",
                            conventional: "0.8 - 1.5 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Uric Acid",
                key: "uric_acid",
                scales: {
                    male: {
                        us: {
                            functional: "3.5 - 5.9 mg/dL",
                            conventional: "2.2 - 7.7 mg/dL",
                        },
                        intl: {
                            functional: "208.2 - 350.9 µmol/L",
                            conventional: "130.9 - 458 µmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: " 3 - 5.5 mg/dL",
                            conventional: "2.2 - 7.7 mg/dL",
                        },
                        intl: {
                            functional: "178.4 - 327.1 µmol/L",
                            conventional: "130.9 - 458 µmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: " 3 - 5.5 mg/dL",
                            conventional: "2.2 - 7.7 mg/dL",
                        },
                        intl: {
                            functional: "178.4 - 327.1 µmol/L",
                            conventional: "130.9 - 458 µmol/L",
                        },
                    },
                },
            },
            {
                name: "LDH",
                key: "ldh",
                scales: {
                    male: {
                        us: {
                            functional: "140 - 200 U/L",
                            conventional: "100 - 220 U/L",
                        },
                        intl: {
                            functional: "2.3 - 3.3 µkat/L",
                            conventional: "1.7 - 3.7 µkat/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "140 - 200 U/L",
                            conventional: "100 - 220 U/L",
                        },
                        intl: {
                            functional: "2.3 - 3.3 µkat/L",
                            conventional: "1.7 - 3.7 µkat/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "140 - 200 U/L",
                            conventional: "100 - 220 U/L",
                        },
                        intl: {
                            functional: "2.3 - 3.3 µkat/L",
                            conventional: "1.7 - 3.7 µkat/L",
                        },
                    },
                },
            },
            {
                name: "GGTP/GGT",
                key: "ggtp_ggt",
                scales: {
                    male: {
                        us: {
                            functional: "10 - 24 U/L",
                            conventional: "1 - 70 U/L",
                        },
                        intl: {
                            functional: "10 - 24 U/L",
                            conventional: "1 - 70 U/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "10 - 24 U/L",
                            conventional: "1 - 70 U/L",
                        },
                        intl: {
                            functional: "10 - 24 U/L",
                            conventional: "1 - 70 U/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "10 - 24 U/L",
                            conventional: "1 - 70 U/L",
                        },
                        intl: {
                            functional: "10 - 24 U/L",
                            conventional: "1 - 70 U/L",
                        },
                    },
                },
            },
        ],
    },
    {
        name: "Cholesterol",
        key: "cholesterol",
        childs: [
            {
                key: "total_cholesterol", name: "Total Cholesterol",
                scales: {
                    male: {
                        59: {
                            us: {
                                functional: "120 - 240 mg/dL",
                                conventional: "100 - 200 mg/dL",
                            },
                            intl: {
                                functional: "3.1 - 6.2 mmol/L",
                                conventional: "2.6 - 5.2 mmol/L",
                            }
                        },
                        60: {
                            us: {
                                functional: "170 - 270 mg/dL",
                                conventional: "100 - 200 mg/dL",
                            },
                            intl: {
                                functional: "4.4 - 7 mmol/L",
                                conventional: "2.6 - 5.2 mmol/L",
                            }
                        }
                    },
                    "female-cycling": {
                        59: {
                            us: {
                                functional: "120 - 240 mg/dL",
                                conventional: "100 - 200 mg/dL",
                            },
                            intl: {
                                functional: "3.1 - 6.2 mmol/L",
                                conventional: "2.6 - 5.2 mmol/L",
                            }
                        },
                        60: {
                            us: {
                                functional: "200 - 300 mg/dL",
                                conventional: "100 - 200 mg/dL",
                            },
                            intl: {
                                functional: "5.2 - 7.8 mmol/L",
                                conventional: "2.6 - 5.2 mmol/L",
                            }
                        }
                    },
                    "female-noncycling": {
                        59: {
                            us: {
                                functional: "120 - 240 mg/dL",
                                conventional: "100 - 200 mg/dL",
                            },
                            intl: {
                                functional: "3.1 - 6.2 mmol/L",
                                conventional: "2.6 - 5.2 mmol/L",
                            }
                        },
                        60: {
                            us: {
                                functional: "200 - 300 mg/dL",
                                conventional: "100 - 200 mg/dL",
                            },
                            intl: {
                                functional: "5.2 - 7.8 mmol/L",
                                conventional: "2.6 - 5.2 mmol/L",
                            }
                        }
                    },
                },
            },
            {
                key: "high_density_lipoprotein", name: "High Density Lipoprotein (HDL)",
                scales: {
                    male: {
                        us: {
                            functional: "55 - 75 mg/dL",
                            conventional: "39 - 9999 mg/dL",
                        },
                        intl: {
                            functional: "1.4 - 1.9 mmol/L",
                            conventional: "1 - 9999 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "65 - 85 mg/dL",
                            conventional: "39 - 9999 mg/dL",
                        },
                        intl: {
                            functional: "1.7 - 2.2 mmol/L",
                            conventional: "1 - 9999 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "65 - 85 mg/dL",
                            conventional: "39 - 9999 mg/dL",
                        },
                        intl: {
                            functional: "1.7 - 2.2 mmol/L",
                            conventional: "1 - 9999 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Triglycerides", key: "triglycerides",
                scales: {
                    male: {
                        us: {
                            functional: "50 - 100 mg/dL",
                            conventional: "0 - 149 mg/dL",
                        },
                        intl: {
                            functional: "0.6 - 1.1 mmol/L",
                            conventional: "0 - 1.7 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "50 - 100 mg/dL",
                            conventional: "0 - 149 mg/dL",
                        },
                        intl: {
                            functional: "0.6 - 1.1 mmol/L",
                            conventional: "0 - 1.7 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "50 - 100 mg/dL",
                            conventional: "0 - 149 mg/dL",
                        },
                        intl: {
                            functional: "0.6 - 1.1 mmol/L",
                            conventional: "0 - 1.7 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Low Density Lipoprotein (LDL)", key: "low_density_lipoprotein",
                scales: {
                    male: {
                        59: {
                            us: {
                                functional: "40 - 120 mg/dL",
                                conventional: "0 - 99 mg/dL",
                            },
                            intl: {
                                functional: "1 - 3.1 mmol/L",
                                conventional: "0 - 2.6 mmol/L",
                            }
                        },
                        60: {
                            us: {
                                functional: "120 - 170 mg/dL",
                                conventional: "0 - 99 mg/dL",
                            },
                            intl: {
                                functional: "3.1 - 4.4 mmol/L",
                                conventional: "0 - 2.6 mmol/L",
                            }
                        }
                    },
                    "female-cycling": {
                        59: {
                            us: {
                                functional: "40 - 120 mg/dL",
                                conventional: "0 - 99 mg/dL",
                            },
                            intl: {
                                functional: "1 - 3.1 mmol/L",
                                conventional: "0 - 2.6 mmol/L",
                            }
                        },
                        60: {
                            us: {
                                functional: "120 - 170 mg/dL",
                                conventional: "0 - 99 mg/dL",
                            },
                            intl: {
                                functional: "3.1 - 4.4 mmol/L",
                                conventional: "0 - 2.6 mmol/L",
                            }
                        }
                    },
                    "female-noncycling": {
                        59: {
                            us: {
                                functional: "40 - 120 mg/dL",
                                conventional: "0 - 99 mg/dL",
                            },
                            intl: {
                                functional: "1 - 3.1 mmol/L",
                                conventional: "0 - 2.6 mmol/L",
                            }
                        },
                        60: {
                            us: {
                                functional: "120 - 170 mg/dL",
                                conventional: "0 - 99 mg/dL",
                            },
                            intl: {
                                functional: "3.1 - 4.4 mmol/L",
                                conventional: "0 - 2.6 mmol/L",
                            }
                        }
                    },
                },
            },
            {
                name: "Very Low Density Lipoprotein (VLDL)",
                key: "very_low_density_lipoprotein", 
                scales: {
                    male: {
                        us: {
                            functional: "5 - 40 mg/dL",
                            conventional: "5 - 40 mg/dL",
                        },
                        intl: {
                            functional: "0.1 - 1 mmol/L",
                            conventional: "0.1 - 1 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "5 - 40 mg/dL",
                            conventional: "5 - 40 mg/dL",
                        },
                        intl: {
                            functional: "0.1 - 1 mmol/L",
                            conventional: "0.1 - 1 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "5 - 40 mg/dL",
                            conventional: "5 - 40 mg/dL",
                        },
                        intl: {
                            functional: "0.1 - 1 mmol/L",
                            conventional: "0.1 - 1 mmol/L",
                        },
                    },
                },

            },
            
            
            {
                name: "Apolipoprotein A-1", key: "apolipoprotein_a_1",
                scales: {
                    male: {
                        us: {
                            functional: "120 - 9999 mg/dL",
                            conventional: "101 - 178 mg/dL",
                        },
                        intl: {
                            functional: "43 - 9999 µmol/L",
                            conventional: "36 - 64 µmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "140 - 9999 mg/dL",
                            conventional: "116 - 209 mg/dL",
                        },
                        intl: {
                            functional: "50 - 9999 µmol/L",
                            conventional: "41 - 75 µmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "140 - 9999 mg/dL",
                            conventional: "116 - 209 mg/dL",
                        },
                        intl: {
                            functional: "50 - 9999 µmol/L",
                            conventional: "41 - 75 µmol/L",
                        },
                    },
                },
            },
            {
                name: "Apolipoprotein B", key: "apolipoprotein_b",
                scales: {
                    male: {
                        us: {
                            functional: "0 - 90 mg/dL",
                            conventional: "0 - 90 mg/dL",
                        },
                        intl: {
                            functional: "0 - 1.75 µmol/L",
                            conventional: "0 - 1.75 µmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0 - 90 mg/dL",
                            conventional: "0 - 90 mg/dL",
                        },
                        intl: {
                            functional: "0 - 1.75 µmol/L",
                            conventional: "0 - 1.75 µmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0 - 90 mg/dL",
                            conventional: "0 - 90 mg/dL",
                        },
                        intl: {
                            functional: "0 - 1.75 µmol/L",
                            conventional: "0 - 1.75 µmol/L",
                        },
                    },
                }
            },
            {
                name: "Cholesterol : HDL Ratio", key: "cholesterol_hdl_ratio",

                scales: {
                    male: {
                        us: {
                            functional: "0 - 3 mg/dL",
                            conventional: "0 - 4.4 mg/dL",
                        },
                        intl: {
                            functional: "0 - 3 mmol/L",
                            conventional: "0 - 4.4 mmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0 - 3 mg/dL",
                            conventional: "0 - 4.4 mg/dL",
                        },
                        intl: {
                            functional: "0 - 3 mmol/L",
                            conventional: "0 - 4.4 mmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0 - 3 mg/dL",
                            conventional: "0 - 4.4 mg/dL",
                        },
                        intl: {
                            functional: "0 - 3 mmol/L",
                            conventional: "0 - 4.4 mmol/L",
                        },
                    },
                },
            },
            {
                name: "Apolipoprotein B:A-1 Ratio", key: "apolipoprotein_ratio",
                scales: {
                    male: {
                        us: {
                            functional: "0 - 0.77",
                            conventional: "0.95 - 9999",
                        },
                        intl: {
                            functional: "0 - 0.77",
                            conventional: "0.95 - 9999",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0 - 0.63",
                            conventional: "0.78 - 9999",
                        },
                        intl: {
                            functional: "0 - 0.63",
                            conventional: "0.78 - 9999",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0 - 0.63",
                            conventional: "0.78 - 9999",
                        },
                        intl: {
                            functional: "0 - 0.63",
                            conventional: "0.78 - 9999",
                        },
                    },
                }
            },
        ],
    },

    {
        name: "Thyroid",
        key: "thyroid",
        childs: [
            {
                name: "TSH (Thyroid Stimulating Hormone)",
                key: "thyroid_stimulating_hormone",
                scales: {
                    male: {
                        us: {
                            functional: "1 - 2 mlU/L",
                            conventional: "0.45 - 4.5 mlU/L",
                        },
                        intl: {
                            functional: "1 - 2 mlU/L",
                            conventional: "0.45 - 4.5 mlU/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "1 - 2 mlU/L",
                            conventional: "0.45 - 4.5 mlU/L",
                        },
                        intl: {
                            functional: "1 - 2 mlU/L",
                            conventional: "0.45 - 4.5 mlU/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "1 - 2 mlU/L",
                            conventional: "0.45 - 4.5 mlU/L",
                        },
                        intl: {
                            functional: "1 - 2 mlU/L",
                            conventional: "0.45 - 4.5 mlU/L",
                        },
                    },
                },
            },
            {
                name: "Free T4 (Thyroxine)", key: "free_t4",
                scales: {
                    male: {
                        us: {
                            functional: "1 - 1.5 ng/dL",
                            conventional: "0.7 - 2.4 ng/dL",
                        },
                        intl: {
                            functional: "12.9 - 19.3 pmol/L",
                            conventional: "9 - 30.9 pmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "1 - 1.5 ng/dL",
                            conventional: "0.7 - 2.4 ng/dL",
                        },
                        intl: {
                            functional: "12.9 - 19.3 pmol/L",
                            conventional: "9 - 30.9 pmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "1 - 1.5 ng/dL",
                            conventional: "0.7 - 2.4 ng/dL",
                        },
                        intl: {
                            functional: "12.9 - 19.3 pmol/L",
                            conventional: "9 - 30.9 pmol/L",
                        },
                    },
                },
            },
            {
                name: "Free T3 (Triiodothyronine)", key: "free_t3",
                scales: {
                    male: {
                        us: {
                            functional: "3 - 3.25 pg/mL",
                            conventional: "2.3 - 4.8 pg/mL",
                        },
                        intl: {
                            functional: "4.6 - 5 pmol/L",
                            conventional: "3.5 - 7.4 pmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "3 - 3.25 pg/mL",
                            conventional: "2.3 - 4.8 pg/mL",
                        },
                        intl: {
                            functional: "4.6 - 5 pmol/L",
                            conventional: "3.5 - 7.4 pmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "3 - 3.25 pg/mL",
                            conventional: "2.3 - 4.8 pg/mL",
                        },
                        intl: {
                            functional: "4.6 - 5 pmol/L",
                            conventional: "3.5 - 7.4 pmol/L",
                        },
                    },
                },
            },
            {
                name: "Total T4 (Thyroxine)", key: "total_t4",
                scales: {
                    male: {
                        us: {
                            functional: "7.5 - 8.1 ng/dL",
                            conventional: "4.5 - 12.5 ng/dL",
                        },
                        intl: {
                            functional: "96.5 - 104.2 nmol/L",
                            conventional: "57.9 - 160.9 nmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "7.5 - 8.1 ng/dL",
                            conventional: "4.5 - 12.5 ng/dL",
                        },
                        intl: {
                            functional: "96.5 - 104.2 nmol/L",
                            conventional: "57.9 - 160.9 nmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "7.5 - 8.1 ng/dL",
                            conventional: "4.5 - 12.5 ng/dL",
                        },
                        intl: {
                            functional: "96.5 - 104.2 nmol/L",
                            conventional: "57.9 - 160.9 nmol/L",
                        },
                    },
                },

            },
            {
                name: "Total T3 (Triiodothyronine)", key: "total_t3",
                scales: {
                    male: {
                        us: {
                            functional: "90 - 168 ng/dL",
                            conventional: "80 - 230 ng/dL",
                        },
                        intl: {
                            functional: "1.4 - 2.6 nmol/L",
                            conventional: "1.2 - 3.5 nmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "90 - 168 ng/dL",
                            conventional: "80 - 230 ng/dL",
                        },
                        intl: {
                            functional: "1.4 - 2.6 nmol/L",
                            conventional: "1.2 - 3.5 nmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "90 - 168 ng/dL",
                            conventional: "80 - 230 ng/dL",
                        },
                        intl: {
                            functional: "1.4 - 2.6 nmol/L",
                            conventional: "1.2 - 3.5 nmol/L",
                        },
                    },
                },

            },
            {
                name: "T3 Uptake", key: "t3_uptake",
                scales: {
                    male: {
                        us: {
                            functional: "27 - 37 ng/dL %",
                            conventional: "24 - 39 ng/dL %",
                        },
                        intl: {
                            functional: "27 - 37 %",
                            conventional: "24 - 39 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "27 - 37 ng/dL %",
                            conventional: "24 - 39 ng/dL %",
                        },
                        intl: {
                            functional: "27 - 37 %",
                            conventional: "24 - 39 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "27 - 37 ng/dL %",
                            conventional: "24 - 39 ng/dL %",
                        },
                        intl: {
                            functional: "27 - 37 %",
                            conventional: "24 - 39 %",
                        },
                    },
                },
            },
            {
                name: "Reverse T3 (rT3)", key: "reverse_t3",
                scales: {
                    male: {
                        us: {
                            functional: "14.9 - 26.7 ng/dL",
                            conventional: "9.2 - 24.1 ng/dL",
                        },
                        intl: {
                            functional: "0.2 - 0.4 nmol/L",
                            conventional: "0.1 - 0.4 nmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "14.9 - 26.7 ng/dL",
                            conventional: "9.2 - 24.1 ng/dL",
                        },
                        intl: {
                            functional: "0.2 - 0.4 nmol/L",
                            conventional: "0.1 - 0.4 nmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "14.9 - 26.7 ng/dL",
                            conventional: "9.2 - 24.1 ng/dL",
                        },
                        intl: {
                            functional: "0.2 - 0.4 nmol/L",
                            conventional: "0.1 - 0.4 nmol/L",
                        },
                    },
                },
            },
            {
                name: "Thyroxine Binding Globulin (TGB)", key: "thyroxine_binding_globulin",
                scales: {
                    male: {
                        us: {
                            functional: "18 - 27 ug/mL",
                            conventional: "12.7 - 30.9 ug/mL",
                        },
                        intl: {
                            functional: "333.3 - 500 nmol/L",
                            conventional: "235.2 - 572.2 nmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "18 - 27 ug/mL",
                            conventional: "12.7 - 30.9 ug/mL",
                        },
                        intl: {
                            functional: "333.3 - 500 nmol/L",
                            conventional: "235.2 - 572.2 nmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "18 - 27 ug/mL",
                            conventional: "12.7 - 30.9 ug/mL",
                        },
                        intl: {
                            functional: "333.3 - 500 nmol/L",
                            conventional: "235.2 - 572.2 nmol/L",
                        },
                    },
                },
            },
            {
                name: "TPO Antibody (Thyroid Peroxidase Ab)", key: "tpo_antibody",
                scales: {
                    male: {
                        us: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                        intl: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                        intl: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                        intl: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                    },
                },
            },
            {
                name: "TGB Antibody (Anti-Thyroglobulin Ab)", key: "tgb_antibody",
                scales: {
                    male: {
                        us: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                        intl: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                        intl: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                        intl: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                    },
                },
            },
            {
                name: "Tg (Thyroglobulin)", key: "thyroglobulin",
                scales: {
                    male: {
                        us: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                        intl: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                        intl: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                        intl: {
                            functional: "negative - positive",
                            conventional: "0 - 0",
                        },
                    },
                },
            },
        ],
    },
    {
        name: "Iron",
        key: "iron",
        childs: [
            {
                name: "Total Iron", key: "iron",
                scales: {
                    male: {
                        us: {
                            functional: "85 - 130 μg/dL",
                            conventional: "30 - 170 μg/dL",
                        },
                        intl: {
                            functional: "15.2 - 23.3 µmol/L",
                            conventional: "5.4 - 30.4 µmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "85 - 130 μg/dL",
                            conventional: "30 - 170 μg/dL",
                        },
                        intl: {
                            functional: "15.2 - 23.3 µmol/L",
                            conventional: "5.4 - 30.4 µmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "85 - 130 μg/dL",
                            conventional: "30 - 170 μg/dL",
                        },
                        intl: {
                            functional: "15.2 - 23.3 µmol/L",
                            conventional: "5.4 - 30.4 µmol/L",
                        },
                    },
                },
            },
            {
                name: "Ferritin", key: "ferretin",
                scales: {
                    male: {
                        us: {
                            functional: "50 - 200 ng/ml",
                            conventional: "33 - 236 ng/ml",
                        },
                        intl: {
                            functional: "50 - 200 ug/L",
                            conventional: "33 - 236 ug/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "50 - 122 ng/ml",
                            conventional: "10 - 122 ng/ml",
                        },
                        intl: {
                            functional: "50 - 122 ug/L",
                            conventional: "10 - 122 ug/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "50 - 200 ng/ml",
                            conventional: "33 - 236 ng/ml",
                        },
                        intl: {
                            functional: "50 - 200 ug/L",
                            conventional: "33 - 236 ug/L",
                        },
                    },
                },

            },
            {
                name: "Total Iron Binding Capacity (TIBC)", key: "tibc",
                scales: {
                    male: {
                        us: {
                            functional: "250 - 350 μg/dL",
                            conventional: "250 - 350 μg/dL",
                        },
                        intl: {
                            functional: "44.8 - 62.7 µmol/L",
                            conventional: "44.8 - 62.7 µmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "250 - 350 μg/dL",
                            conventional: "250 - 350 μg/dL",
                        },
                        intl: {
                            functional: "44.8 - 62.7 µmol/L",
                            conventional: "44.8 - 62.7 µmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "250 - 350 μg/dL",
                            conventional: "250 - 350 μg/dL",
                        },
                        intl: {
                            functional: "44.8 - 62.7 µmol/L",
                            conventional: "44.8 - 62.7 µmol/L",
                        },
                    },
                },
            },
            {
                name: "% Transferrin Saturation", key: "tsat",
                scales: {
                    male: {
                        us: {
                            functional: "20 - 35 %",
                            conventional: "16 - 60 %",
                        },
                        intl: {
                            functional: "20 - 35 %",
                            conventional: "16 - 60 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "20 - 35 %",
                            conventional: "16 - 60 %",
                        },
                        intl: {
                            functional: "20 - 35 %",
                            conventional: "16 - 60 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "20 - 35 %",
                            conventional: "16 - 60 %",
                        },
                        intl: {
                            functional: "20 - 35 %",
                            conventional: "16 - 60 %",
                        },
                    },
                },

            },
            {
                name: "Reticulocyte Count", key: "retc",
                scales: {
                    male: {
                        us: {
                            functional: "0.5 - 1 %",
                            conventional: "0.5 - 1 %",
                        },
                        intl: {
                            functional: "0.5 - 1 %",
                            conventional: "0.5 - 1 %",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0.5 - 1 %",
                            conventional: "0.5 - 1 %",
                        },
                        intl: {
                            functional: "0.5 - 1 %",
                            conventional: "0.5 - 1 %",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0.5 - 1 %",
                            conventional: "0.5 - 1 %",
                        },
                        intl: {
                            functional: "0.5 - 1 %",
                            conventional: "0.5 - 1 %",
                        },
                    },
                },
            },
        ],
    },
    {
        name: "Other",
        key: "other",
        childs: [
            {
                name: "25-hydroxy Vitamin D", key: "vitd",
                scales: {
                    male: {
                        us: {
                            functional: "30 - 50 ng/ml",
                            conventional: "30 - 100 ng/ml",
                        },
                        intl: {
                            functional: "75 - 125 nmol/L",
                            conventional: "74.9 - 249.6 nmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "30 - 50 ng/ml",
                            conventional: "30 - 100 ng/ml",
                        },
                        intl: {
                            functional: "75 - 125 nmol/L",
                            conventional: "74.9 - 249.6 nmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "30 - 50 ng/ml",
                            conventional: "30 - 100 ng/ml",
                        },
                        intl: {
                            functional: "75 - 125 nmol/L",
                            conventional: "74.9 - 249.6 nmol/L",
                        },
                    },
                },
            },
            {
                name: "1,25-(OH)2D", key: "oh2d",
                scales: {
                    male: {
                        us: {
                            functional: "20 - 55 pg/ml",
                            conventional: "19.6 - 54.3 pg/ml",
                        },
                        intl: {
                            functional: "48 - 130 pmol/L",
                            conventional: "47 - 130 pmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "20 - 55 pg/ml",
                            conventional: "19.6 - 54.3 pg/ml",
                        },
                        intl: {
                            functional: "48 - 130 pmol/L",
                            conventional: "47 - 130 pmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "20 - 55 pg/ml",
                            conventional: "19.6 - 54.3 pg/ml",
                        },
                        intl: {
                            functional: "48 - 130 pmol/L",
                            conventional: "47 - 130 pmol/L",
                        },
                    },
                },
            },
            {
                name: "Fibrinogen", key: "fibr",
                scales: {
                    male: {
                        us: {
                            functional: "200 - 300 mg/dL",
                            conventional: "150 - 400 mg/dL",
                        },
                        intl: {
                            functional: "5.9 - 8.8 µmol/L",
                            conventional: "4.4 - 11.8 µmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "200 - 300 mg/dL",
                            conventional: "150 - 400 mg/dL",
                        },
                        intl: {
                            functional: "5.9 - 8.8 µmol/L",
                            conventional: "4.4 - 11.8 µmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "200 - 300 mg/dL",
                            conventional: "150 - 400 mg/dL",
                        },
                        intl: {
                            functional: "5.9 - 8.8 µmol/L",
                            conventional: "4.4 - 11.8 µmol/L",
                        },
                    },
                },
            },
            {
                name: "C-Reactive Protein (CRP) / C-Reactive Protein - Quant", key: "crp",
                scales: {
                    male: {
                        us: {
                            functional: "0 - 8 mg/L",
                            conventional: "0 - 8 mg/L",
                        },
                        intl: {
                            functional: "0 - 8 mg/L",
                            conventional: "0 - 8 mg/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0 - 8 mg/L",
                            conventional: "0 - 8 mg/L",
                        },
                        intl: {
                            functional: "0 - 8 mg/L",
                            conventional: "0 - 8 mg/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0 - 8 mg/L",
                            conventional: "0 - 8 mg/L",
                        },
                        intl: {
                            functional: "0 - 8 mg/L",
                            conventional: "0 - 8 mg/L",
                        },
                    },
                },
            },
            {
                name: "High Sensitivity C-Reactive Protein (HsCRP) / C-Reactive Protein - Cardiac", key: "hcrp",
                scales: {
                    male: {
                        us: {
                            functional: "0 - 1 mg/L",
                            conventional: "0 - 1 mg/L",
                        },
                        intl: {
                            functional: "0 - 9.5 nmol/L",
                            conventional: "0 - 9.5 nmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0 - 1 mg/L",
                            conventional: "0 - 1 mg/L",
                        },
                        intl: {
                            functional: "0 - 9.5 nmol/L",
                            conventional: "0 - 9.5 nmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0 - 1 mg/L",
                            conventional: "0 - 1 mg/L",
                        },
                        intl: {
                            functional: "0 - 9.5 nmol/L",
                            conventional: "0 - 9.5 nmol/L",
                        },
                    },
                },
            },
            {
                name: "Sedimentation Rate", key: "sedr",
                scales: {
                    male: {
                        us: {
                            functional: "0 - 5 mm/hr",
                            conventional: "0 - 15 mm/hr",
                        },
                        intl: {
                            functional: "0 - 5 mm/hr",
                            conventional: "0 - 15 mm/hr",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "0 - 10 mm/hr",
                            conventional: "0 - 20 mm/hr",
                        },
                        intl: {
                            functional: "0 - 10 mm/hr",
                            conventional: "0 - 20 mm/hr",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "0 - 10 mm/hr",
                            conventional: "0 - 20 mm/hr",
                        },
                        intl: {
                            functional: "0 - 10 mm/hr",
                            conventional: "0 - 20 mm/hr",
                        },
                    },
                },
            },
            {
                name: "Homocysteine", key: "homo",
                scales: {
                    male: {
                        us: {
                            functional: "4 - 7 umol/L",
                            conventional: "0 - 11.5 umol/L",
                        },
                        intl: {
                            functional: "4 - 7 umol/L",
                            conventional: "5 - 12 umol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "4 - 7 umol/L",
                            conventional: "0 - 10.4 umol/L",
                        },
                        intl: {
                            functional: "4 - 7 umol/L",
                            conventional: "5 - 12 umol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "4 - 7 umol/L",
                            conventional: "0 - 10.4 umol/L",
                        },
                        intl: {
                            functional: "4 - 7 umol/L",
                            conventional: "5 - 12 umol/L",
                        },
                    },
                },
            },
            {
                name: "Insulin", key: "insu",
                scales: {
                    male: {
                        us: {
                            functional: "2 - 8 μU/ml",
                            conventional: "2 - 19.6 μU/ml",
                        },
                        intl: {
                            functional: "13.9 - 55.6 pmol/L",
                            conventional: "13.9 - 136.1  pmol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "2 - 8 μU/ml",
                            conventional: "2 - 19.6 μU/ml",
                        },
                        intl: {
                            functional: "13.9 - 55.6 pmol/L",
                            conventional: "13.9 - 136.1  pmol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "2 - 8 μU/ml",
                            conventional: "2 - 19.6 μU/ml",
                        },
                        intl: {
                            functional: "13.9 - 55.6 pmol/L",
                            conventional: "13.9 - 136.1  pmol/L",
                        },
                    },
                },
            },
            {
                name: "Fructosamine", key: "fruc",
                scales: {
                    male: {
                        us: {
                            functional: "190 - 270 umol/L",
                            conventional: "190 - 270 umol/L",
                        },
                        intl: {
                            functional: "190 - 270 umol/L",
                            conventional: "190 - 270 umol/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "190 - 270 umol/L",
                            conventional: "190 - 270 umol/L",
                        },
                        intl: {
                            functional: "190 - 270 umol/L",
                            conventional: "190 - 270 umol/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "190 - 270 umol/L",
                            conventional: "190 - 270 umol/L",
                        },
                        intl: {
                            functional: "190 - 270 umol/L",
                            conventional: "190 - 270 umol/L",
                        },
                    },
                },
            },
            {
                name: "C-peptide", key: "cpep",
                scales: {
                    male: {
                        us: {
                            functional: "1.1 - 2.1 ng/mL",
                            conventional: "0.8 - 3.85 ng/mL",
                        },
                        intl: {
                            functional: "0.4 - 0.7 nmol/mL",
                            conventional: "0.3 - 1.3 nmol/mL",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "1.1 - 2.1 ng/mL",
                            conventional: "0.8 - 3.85 ng/mL",
                        },
                        intl: {
                            functional: "0.4 - 0.7 nmol/mL",
                            conventional: "0.3 - 1.3 nmol/mL",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "1.1 - 2.1 ng/mL",
                            conventional: "0.8 - 3.85 ng/mL",
                        },
                        intl: {
                            functional: "0.4 - 0.7 nmol/mL",
                            conventional: "0.3 - 1.3 nmol/mL",
                        },
                    },
                },
            },
            {
                name: "Leptin", key: "lep",
                scales: {
                    male: {
                        us: {
                            functional: "4 - 6 ng/dl",
                            conventional: "4 - 10 ng/dl",
                        },
                        intl: {
                            functional: "4 - 6 µg/L",
                            conventional: "4 - 10 µg/L",
                        },
                    },
                    "female-cycling": {
                        us: {
                            functional: "4 - 6 ng/dl",
                            conventional: "4 - 10 ng/dl",
                        },
                        intl: {
                            functional: "4 - 6 µg/L",
                            conventional: "4 - 10 µg/L",
                        },
                    },
                    "female-noncycling": {
                        us: {
                            functional: "4 - 6 ng/dl",
                            conventional: "4 - 10 ng/dl",
                        },
                        intl: {
                            functional: "4 - 6 µg/L",
                            conventional: "4 - 10 µg/L",
                        },
                    },
                },
            },
        ]
    }
];