import React, { useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { validate } from "../../services/formValidation";
import { useDispatch } from "react-redux";
import { actions } from "../../store/actions";
import CustomTextField from "../CustomTextField/CustomTextField";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {uid, token} = useParams()
  const [form, setForm] = useState({
    password1: { value: "", error: false, required: true },
    password2: { value: "", error: false, required: true },
  });

  const handleForm = (e) => {
    e.preventDefault()
    const { n_form, error } = validate(form);
    setForm({ ...form, ...n_form });
    if (error) {
      return;
    }
    dispatch({
      type: actions.CHANGE_PASSWORD,
      payload: {
        password1: form.password1.value,
        password2: form.password2.value,
        navigate,
        uid,
        token
      },
    });
  };
  return (
    <>
    <Form onSubmit={handleForm}>
      <Row>
        <Col></Col>
        <Col md={9}>
          <CustomTextField
            label={"Password"}
            type="password"
            form={form}
            setForm={setForm}
            fkey="password1"
          />
          <CustomTextField
            label={"Confirm Password"}
            type="password"
            form={form}
            setForm={setForm}
            fkey="password2"
          />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col md={6}>
          <Button
            variant="primary"
            size="md"
            className="w-100 p-6 primary-bg mb-4 mt-5 btn-auth"
            type="submit"
          >
            Change Password
          </Button>
          <div style={{ textAlign: "center" }} className="mb-5">
            <Link className="primary-fg" to="/">
              Go Back to Login
            </Link>
          </div>
        </Col>
        <Col></Col>
      </Row>
      </Form>
    </>
  );
};

export default ChangePassword;
