import axios from "axios";
import { hideLoader, showLoader, store } from "../store";

/**
 * axios instance
 */
let instance = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

// request header
instance.interceptors.request.use(
  async (config) => {
    store.dispatch(showLoader());
    // let token = getToken();
    // if (token != null) {
    //   await validateRefreshToken();
    let token = getToken();
    if (token) {
      config.headers.Authorization = "Token " + token;
    }
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response parse
instance.interceptors.response.use(
  (response) => {
    store.dispatch(hideLoader());
    return response;
  },
  (error) => {
    store.dispatch(hideLoader());
    throw error;
    // if (error && error.response && error.response.status === 401) {
    //   alert(error.response.data.message);
    //   window.location.reload();
    // }
    // if (error.response) {
    //   return parseError(error.response.data);
    // } else {
    //   return Promise.reject(error);
    // }
  }
);

// function parseError(messages) {
//   // error
//   if (messages) {
//     if (messages instanceof Array) {
//       return Promise.reject({ messages: messages });
//     } else {
//       return Promise.reject({ messages: [messages] });
//     }
//   } else {
//     return Promise.reject({ messages: ["Error"] });
//   }
// }

// /**
//  * parse response
//  */
// function parseBody(response) {
//   store.dispatch(hideLoader());
//   if (response.status === 200) {
//     return response.data;
//   } else {
//     return this.parseError(response.data.messages);
//   }
// }

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
};

export default instance;
