import { put, takeEvery } from "redux-saga/effects";
import {
  saveClientSearch,
  saveClientSearchByName,
  saveComparisonReview,
  saveClient,
  saveMakerAnalysisData,
  savePatternAnalysisData,
  saveClinicalPearlData,
  updateClientResults,
  updateClientData,
  savePatternComparisonReview,
  saveCustomerInfo,
} from "..";
import instance from "../../services/requestHandler";
import { actions } from "../actions.js";
import { toast } from "react-toastify";

export function* fetchComparisonReview(action) {
  // console.log(action)
  try {
    let result = yield instance.get(
      `comparison-review/${action?.payload?.name}/${action.payload.dates}`
    );
    if (result.data) {
      yield put(saveComparisonReview(result.data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* fetchPatterComparisonReview(action) {
  // console.log(action)
  try {
    let result = yield instance.get(
      `pattern-comparison-review/${action?.payload?.name}/${action.payload.dates}`
    );
    if (result.data) {
      yield put(savePatternComparisonReview(result.data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export function* addNewClient(action) {
  try {
    let result = yield instance.post(`rws_input/`, action.payload.data);
    if (result.data) {
      action.payload.openNext(result.data);
      yield put(saveClient(result.data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export function* generateClientReport(action) {
  try {
    let {data} = yield instance.post(`google-docx/${action.payload.id}/`, action.payload.data);
    if(data){
    action.payload.openNext(data);
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* uploadFile(action) {
  const formdata = new FormData();
  formdata.append(
    "file",
    action.payload.data,
    "Labcorp sample - RWS comprehensive panel.pdf"
  );
  try {
    let result = yield instance.post("upload/", formdata);

    if (result.data) {
      const clientResults = {
        "Complete Blood Count (CBC)": result.data.cbc,
        "Complete Metabolic Panel (CMP)": result.data.cmp,
        Thyroid: result.data.thyroid,
        Cholesterol: result.data.cholesterol,
        Iron: result.data.iron,
        Other: result.data.other,
      };
      // console.log('result', result.data)
      yield put(updateClientResults({ data: clientResults }));
      action.payload.openNext();
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* api(action) {
  const userID = window.location.href.split("/")[4];

  try {
    //if there is userID in url means it is running for a get request
    if (userID) {
      let { data } = yield instance.get(`rws/${userID}/`);
      // console.log("data", data);
      const clientResults = {
        "Complete Blood Count (CBC)": data.cbc,
        "Complete Metabolic Panel (CMP)": data.cmp,
        Thyroid: data.thyroid,
        Cholesterol: data.cholestrol,
        Iron: data.iron,
        Other: data.other,
      };
      // console.log('clientResult', clientResults)

      yield put(updateClientResults({ data: clientResults }));
      yield put(updateClientData(data.rws_input));
      action.payload.openNext(data.rws_input.units_us_or_intl);
    } else {
      // console.log('action.payload.data', action.payload.data)
      let result = yield instance.post("rws/", action.payload.data);
      if (result.data) {
        // console.log('result', result)
        const info = {
          id: result.data.rws_models_id,
          client_name: result.data.rws_input.client_name,
        };
        action.payload.openNext(info);
        // yield put(saveComparisonReview(result.data));
      }
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* updateTestData(action) {
  try {
    // console.log('action', action.payload.data)
    let result = yield instance.put(
      `rws/${action.payload.id}/`,
      action.payload.data
    );
    if (result.data) {
      const info = {
        id: result.data.rws_models_id,
        client_name: result.data.rws_input.client_name,
      };
      yield put(updateClientData(result.data));
      action.payload.openNext(info);
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* getClientSearchData(action) {
  try {
    let result = yield instance.get(`rws_input/`);
    if (result.data) {
      const data = Object.values(
        result.data.reduce(
          (acc, obj) => ({ ...acc, [obj.client_name]: obj }),
          {}
        )
      ).sort((a, b) => a.client_name.localeCompare(b.client_name));
      // action.payload.openNext(true);
      yield put(saveClientSearch(data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* getClientSearchByName(action) {
  try {
    let result = yield instance.get(`rws_input/${action.payload.name}`);
    if (result.data) {
      // action.payload.openNext(true);
      const data = result.data.sort((a, b)=>new Date(a.date)- new Date(b.date))
      yield put(saveClientSearchByName(data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* getClientByID(action) {
  try {
    let result = yield instance.get(`rws/${action.payload.id}`);
    if (result.data) {
      // action.payload.openNext(true);
      yield put(saveClient(result.data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* deleteRWSclient(action) {
  try {
    yield instance.delete(`rws/${action.payload.data.id}`);
    action.payload.openNext();
    toast.success("Report Deleted Successfully");
    // yield put(deleteRWSClientFromArray(result.data));
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export function* updateClientName(action){
  try {
     yield instance.post("change_client_name/", action.payload.data)
    toast.success("Client Name Updated Successfully");
    action.payload.openNext();
    // yield put(deleteRWSClientFromArray(result.data));
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export function* fetchMakerAnalysis(action) {
  try {
    let result = yield instance.get(
      `blood-marker-analysis/${action.payload.id}/` //${action.payload.id}
    );
    if (result.data) {
      yield put(saveMakerAnalysisData(result.data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export function* fetchPatternAnalysis(action) {
  try {
    let result = yield instance.get(
      `pattern-trend-analysis/${action.payload.id}/`
    );
    if (result.data) {
      yield put(savePatternAnalysisData(result.data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export function* fetchClinicalPearls(action) {
  try {
    let result = yield instance.get(
      `clinical-pearls/${action.payload.id}/` //${action.payload.id}
    );
    if (result.data) {
      yield put(saveClinicalPearlData(result.data));
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}

export function* accountDetails(action) {
  try {
    if (action.payload.type === "GET") {
      let result = yield instance.get("users/custom-user-info/");
      if (result.data) {
        yield put(saveCustomerInfo(result.data));
      }
    }
    if (action.payload.type === "PUT") {
      const formdata = new FormData();
      for (let key of Object.keys(action.payload.data)) {
        if (key === "logo" && action.payload.data.logo) {
          formdata.append(
            key,
            action.payload.data[key],
            action.payload.data[key].name
          );
        } else {
          formdata.append(key, action.payload.data[key]);
        }
      }

      let result = yield instance.put("users/custom-user-info/", formdata);
      if (result.data) {
        yield put(saveCustomerInfo(result.data));
        toast.success("Account Settings Updated Successfully");
      }
    }
  } catch (e) {
    yield put({ type: "TODO_FETCH_FAILED" });
  }
}
export default function* clientSearchSaga() {
  yield takeEvery(actions.COMPARISON_REVIEW, fetchComparisonReview);
  yield takeEvery(
    actions.PATTERN_COMPARISON_REVIEW,
    fetchPatterComparisonReview
  );
  yield takeEvery(actions.ADD_NEW_CLIENT, addNewClient);
  yield takeEvery(actions.ADD_NEW_TEST, api);
  yield takeEvery(actions.UPDATE_TEST_DATA, updateTestData);
  yield takeEvery(actions.UPLOAD_FILE, uploadFile);
  yield takeEvery(actions.ACCOUNT_SETTINGS, accountDetails);

  // yield takeEvery(actions.COMPLETE_BLOOD_COUNT, api);
  // yield takeEvery(actions.CMP, api);
  // yield takeEvery(actions.CHOLESTROL, api);
  // yield takeEvery(actions.THYROID, api);
  // yield takeEvery(actions.IRON, api);
  // yield takeEvery(actions.OTHER, api);

  yield takeEvery(actions.GET_CLIENT_SEARCH, getClientSearchData);
  yield takeEvery(actions.GET_CLIENT_SEARCH_NAME, getClientSearchByName);
  yield takeEvery(actions.DELETE_CLIENT, deleteRWSclient);
  yield takeEvery(actions.UPDATE_CLIENT_NAME, updateClientName);
  yield takeEvery(actions.MAKER_ANALYSIS, fetchMakerAnalysis);
  yield takeEvery(actions.PATTERN_ANALYSIS, fetchPatternAnalysis);
  yield takeEvery(actions.CLINICAL_PEARLS, fetchClinicalPearls);
  yield takeEvery(actions.CLIENT_BY_ID, getClientByID);
  yield takeEvery(actions.GENERATE_CLIENT_REPORT, generateClientReport);
}
