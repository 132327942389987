import React, { useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { ReactComponent as FileIcon } from "./../../images/fileicon.svg";
import AddNewClient3 from "./AddNewClient3";
import { actions } from "../../store/actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AddNewClient1 from "./AddNewClient1";
import { useSearchParams } from 'react-router-dom';
import AddPayment from "./AddPayment";
const AddNewClient2 = ({form}) => {
  const [openNext, setOpenNext] = useState(false);
  const inputRef = useRef();
  const [clientStartScreen, setClientStartScreen] = useState(false);
  const dispatch = useDispatch();
  const [file,setFile]=useState(false)

  const onNext = () => {
    setOpenNext(true);
  };

  const handleUpload = (e) => {
    const file= e.target.files[0]
    setFile(true)
    dispatch({
      type: actions.UPLOAD_FILE,
      payload:{
      data:file,
      openNext:()=>{
        setOpenNext(true);
      }
    }
    });
};  
  if (openNext) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    return <AddNewClient3 form={form} file={file}/>;
  }
  
  if (clientStartScreen) return <AddNewClient1 />;

  // if (clientStartScreen) {
  //   if(openPaymentScreen) return <AddNewClient1 />;
  //   return <AddPayment/>
  // }
  
  return (
    <Container fluid className="main-container p-sm-4 p-3">
      <div className="center-card manual-pdf-section mx-auto">
        <div className="position-relative">
         <Button
            variant="secondary"
            size="md"
            className="mx-auto d-block secondary-bg upload-pdf"
            onClick={()=>inputRef.current?.click()}
          >
            <FileIcon className="p-1" />
            Upload PDF Results
          </Button>
          <span className="position-absolute">?</span>
        </div>
        <Button
          variant="primary"
          size="md"
          className="mx-auto d-block primary-bg manual-btn-2"
          onClick={onNext}
        >
          Enter Results Manually
        </Button>
        <div className="mt-3 text-center">
        <Link
            onClick={() => setClientStartScreen(true)}
            className="primary-fg cursor-pointer"
          >
            <u>Go Back</u>
          </Link>
          </div>
      </div>
      <input ref={inputRef} onChange={handleUpload} className="d-none" type="file" accept="application/pdf" />
    </Container>
  );
};

export default AddNewClient2;
