import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <Row className="p-4 mt-5">
      <Col sm={12} xs={12} md={4}>
        <div className="text-center">
          <Card.Link className="primary-fg" href="https://app.restorativewellnesssolutions.com/index.php/privacy-policy/" target="_blank">
            Privacy Policy
          </Card.Link>
        </div>
      </Col>
      <Col sm={12} xs={12} md={4}>
        <div className="text-center">
          <Card.Link className="primary-fg" href="https://app.restorativewellnesssolutions.com/index.php/refund-policy/" target="_blank">
            Refund Policy
          </Card.Link>
        </div>
      </Col>
      <Col sm={12} xs={12} md={4}>
        <div className="text-center">
          <Card.Link className="primary-fg" href="https://app.restorativewellnesssolutions.com/index.php/terms-conditions/" target="_blank">
            Terms & Conditions
          </Card.Link>
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
<Row></Row>;
