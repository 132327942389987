import { useLocation } from "react-router-dom"
import MasterListValidation from "../components/ClientDashboard/MasterListValidation"
import { useEffect, useState } from "react"
import { actions } from "../store/actions"
import { useDispatch } from "react-redux"
const UseLocationHook = () => {
    const [showMessage, setShowMessage] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        if (location.pathname === '/client') {
            dispatch({
                type: actions.STRIPE_SUBSCRIPTION_AND_ELIGIBILITY,
                payload: {
                    type: "GET",
                    openNext: () => {
                        setShowMessage(true)
                    }
                },
            });
        }
        console.log('123')
    }, [location.pathname])
    return (
        <>
            {showMessage ? <MasterListValidation setShowMessage={setShowMessage} /> : null}
        </>
    )
}
export default UseLocationHook