import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./styles.css";
import { getTestData } from "./testsData";
import { useDispatch } from "react-redux";
import { actions } from "../../store/actions";
import { testObjs } from "./testsData"
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

export const Tests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const generate = (info, testcase) => {
    const testData = getTestData(info);
    dispatch({
      type: actions.ADD_NEW_TEST,
      payload: {
        data: testData,
        openNext: (info) => {
          navigate(`/client-report/${info.id}/${info.client_name}`)
        }
      }
    })
  };

  return (
    <div>
      <Row>
      <ol>
        {testObjs.map((item, index) => {
          return (
            <Col sm={12} xs={12} md={4} key={index} className="mb-3">
            <li>
              <TestInfo
                gender={item.gender}
                ageGroup={item.age_group}
                scale={item.unit_type}
                data={item.common}
                alt={item.altitude}
              />
              <Button
                variant="primary"
                size="md"
                className="primary-bg next-btn"
                onClick={() => {
                  const info = {
                    gender: item.gender,
                    unit_type: item.unit_type,
                    age_group: item.age_group,
                    common: item.common,
                    altitude: item.altitude,
                  };
                  generate(info, index);
                }}
              >
                Generate
              </Button>
            </li>
            </Col>
          );
        })}
      </ol>
      </Row>
    </div>
  );
};

const TestInfo = ({ gender, ageGroup, scale, data, alt }) => {
  return (
    <div>
      <p>
        <strong>Gender:</strong> {gender === "FC" ? "Female-cycling" : gender === "FNC" ? "Female-Noncycling" : "Male"}      </p>
      <p>
        <strong>Age Group:</strong> {ageGroup === "60>" ? "above 60" : "below 60"}
      </p>
      <p>
        <strong>Scale:</strong> {scale === "US" ? "US" : "Interntional"}
      </p>
      <p>
        <strong>Data:</strong> {data === "CONV" ? "Conventional" : "Functional"}
      </p>
      <p>
        <strong>Altitude:</strong> {alt === "HIGH" ? "High" : "Low"}
      </p>
    </div>
  );
};
