import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { validate } from "../../services/formValidation";
import { actions } from "../../store/actions";
import { emailRegex } from "../../utils/constants";
import "./style.css";
import CustomTextField from "../CustomTextField/CustomTextField";

const SignUpPage = () => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    email: {
      value: "",
      error: false,
      regex: emailRegex,
      required: true,
    },
    password: { value: "", error: false, required: true },
    firstName: { value: "", error: false, required: true },
    lastName: { value: "", error: false, required: true },
    confirmPassword: {
      value: "",
      error: false,
      required: true,
    },
  });

  const onSignUp = (e) => {
    e.preventDefault()
    const { n_form, error } = validate(form);
    setForm({ ...form, ...n_form });
    if (error) {
      return;
    }
    dispatch({
      type: actions.SIGN_UP,
      payload: {
        password2: form.confirmPassword.value,
        password1: form.password.value,
        email: form.email.value.toString().toLowerCase(),
        first_name: form.firstName.value,
        last_name: form.lastName.value,
      },
    });
  };
  return (
    <>
      <Form onSubmit={onSignUp}>
        <Row>
          <Col></Col>
          <Col md={9}>
              <CustomTextField
                label={"First Name"}
                type="text"
                form={form}
                setForm={setForm}
                fkey="firstName"
              />
              <CustomTextField
                label={"Last Name"}
                form={form}
                setForm={setForm}
                fkey="lastName"
              />
              <CustomTextField
                label={"Email Address"}
                type="email"
                form={form}
                setForm={setForm}
                fkey="email"
              />
              <CustomTextField
                label={"Password"}
                type="password"
                form={form}
                setForm={setForm}
                fkey="password"
              />
              <CustomTextField
                label={"Confirm Password"}
                type="password"
                form={form}
                setForm={setForm}
                fkey="confirmPassword"
              />
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col md={6}>
            <Button
              variant="primary"
              size="md"
              type='submit'
              className="w-100 p-6 primary-bg mb-4 mt-5 btn-auth"
            >
              Sign Up
            </Button>
            <div style={{ textAlign: "center" }} className="mb-3">
              <Link className="primary-fg" to="/signin">
                Sign In
              </Link>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  );
};

export default SignUpPage;
