import { useState, useEffect } from "react";
import { Accordion} from "react-bootstrap";
const ComparisonNotes = ({date,index, notes}) =>{
    const [active, setActive] = useState(!!notes ? index : null);
    return(
        <Accordion className="accordian"
        activeKey={active}
        onSelect={(e) => setActive(parseInt(active) >= 0 ? null : index)}
        >
        <Accordion.Item eventKey={index}>
          <Accordion.Header>
            <div>{date}</div>
          </Accordion.Header>
          <Accordion.Body>
            {notes}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
}
export default ComparisonNotes