import React, {useEffect} from "react";
import { Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, selectUserData, store } from "../../store";
import helpIcon from "./../../images/helpicon.svg";
import logoutIcon from "./../../images/logouticon.svg";
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
const HeaderButtons = () => {
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
    const location = useLocation()
    useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },[location])
  return (
    <Navbar expand="lg" className="p-0 header_navbar">
      <Navbar.Brand href="#home"></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto flex-row justify-content-center">
          <Nav.Link className="p-0"
            href="https://app.restorativewellnesssolutions.com/index.php/troubleshooting/"
            target="_blank"
          >
            <Button
              variant="secondary"
              size="sm"
              className="secondary-bg btn-help"
            >
              <img className="pe-2" src={helpIcon} alt="logo"></img>
              Help
            </Button>
          </Nav.Link>
          {userData.login ? (
            <Nav.Link className="p-0">
              <Button
                variant="primary"
                size="sm"
                className="primary-bg btn-logout"
                onClick={() => {
                  toast.success("Logout Successful")
                  store.dispatch(logout());
                  navigate("/signin");
                }}
              >
                <img className="pe-2" src={logoutIcon} alt="logo"></img>
                Logout
              </Button>
            </Nav.Link>
          ) : null}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HeaderButtons;
