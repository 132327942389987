import React, { useEffect, useState } from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectClientSearchData } from "../../store";
import { actions } from "../../store/actions";
// import { clients } from "../../utils/constants";
import ClientSearchDates from "./ClientSearchDates";
import { ReactComponent as EditIcon } from "./../../images/editicon.svg";
import "./style.css";
import UpdateClientData from "./updateClientData";
const ClientSearchSceen = () => {
  const clientSearchData = useSelector(selectClientSearchData);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [clientName, setClientName] = useState('')
  const [form, setForm] = useState({
    clientName: {
      value: "",
      error: false,
      required: true,
    },
  });

  useEffect(() => {
    setData(clientSearchData?.clientSearchData);
  }, [clientSearchData]);
  
  const [clientSearchDates, setClientSearchDates] = useState(null);

  const openClientSearchDates = (item) => {
    setClientSearchDates(item);
  };

  const updateClientName = (name) =>{
    dispatch({
      type: actions.UPDATE_CLIENT_NAME,
      payload: {
        data: {
          client_old_name: clientName,
          client_new_name: name
        },
        openNext: () => {
          window.location.reload()
        },
      },
    });
  }
  useEffect(() => {
    dispatch({ type: actions.GET_CLIENT_SEARCH });
  }, []);
  if (clientSearchDates) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    return <ClientSearchDates data={clientSearchDates} />;
  }

  return (
    <Container fluid className="main-content main-container">
      <div className="client_search">
        <Form.Control
          className="greyTextfield"
          type={"text"}
          placeholder={"Client Name Search"}
          value={form?.["clientName"]?.value}
          onChange={(e) => {
            setForm({
              ...form,
              clientName: {
                ...(form["clientName"] || {}),
                value: e.target.value,
                error: false,
              },
            });

            let d = e.target.value?.length
              ? clientSearchData?.clientSearchData?.filter((ff) => {
                return ff?.client_name
                  ?.toLowerCase()
                  .includes(e.target.value.toLowerCase());
              })
              : clientSearchData?.clientSearchData;
            setData(d);
          }}
        />
      </div>
      <div className="cNStyle save_action p-3 d-flex align-items-center justify-content-between">
        <div className="">Client Name</div>
        <div className="">Update</div>
      </div>
      <div className="client_link">
        {data?.map((m, i) => {
          return (
            <div key={i}>
              <div className="d-flex justify-content-between align-items-center pb-2 result_checkboxes py-13px">
                <div className="pe-0">
                  <Card.Link
                    className="primary-fg cursor-pointer"
                    onClick={() => openClientSearchDates(m)}
                  >
                    {m.client_name}
                  </Card.Link>
                </div>
                <div>
                <Button
                    variant="secondary"
                    className="actionButton"
                    onClick={() => {
                      setClientName(m.client_name)
                    }}
                  >
                    <EditIcon />
                  </Button>
                </div>
              </div>
              <div>
                <div className="divider-list"></div>
              </div>
            </div>
          );
        })}
      </div>
      <UpdateClientData clientName={clientName} updateClientName={updateClientName}/>
    </Container>
  );
};

export default ClientSearchSceen;
